import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeworkService } from 'src/app/homework/homework.service';

@Component({
  selector: 'app-latest-homework',
  templateUrl: './latest-homework.component.html',
  styleUrls: ['./latest-homework.component.css']
})
export class LatestHomeworkComponent implements OnInit {

  currentUser: any;
  userId: number;
  userData: any;
  currentSelSchoolId: number;
  userType: number;
  user_subjects: any;
  classIds = [];
  sectionIds = [];
  subjectIds = [];  
  homeworkData: any;

  constructor(
    private homeworkService: HomeworkService,
    private router: Router
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.userId = this.currentUser.id;
      this.currentSelSchoolId = this.currentUser.currentSelSchoolId;
      this.userData = this.currentUser.userData;
      this.userType = this.currentUser.userData[0].user_type;
      this.user_subjects = this.currentUser.user_assigned_subjects;
  
      this.userData.forEach(userVal => {
        if(this.classIds.indexOf(userVal.class.id) == -1){
          this.classIds.push(userVal.class.id);
          // this.classes.push({id:userVal.class.id,className: userVal.class.class_name})
        }
        if (this.sectionIds.indexOf(userVal.section_id) == -1) {
          this.sectionIds.push(userVal.section_id);
          // this.sections.push(userVal.section);
        }
        
      });
      this.user_subjects.forEach(sub => {
        this.subjectIds.push(sub.subject_id);
      });

      if(this.userType == 0){
        this.getStudentHomework();
      } else {
        this.getTeacherHomework();
      }
    }
  }

  getTeacherHomework() {
    let obj = {
      school_id: [this.currentSelSchoolId],
      class_id: this.classIds,
      section_id: this.sectionIds,
      subject_id: this.subjectIds,
      created_by: this.userId,
      offset: 0,
      limit: 3
    }
    // console.log(obj);
    this.homeworkService.getHWTeacher(obj).subscribe((data: any) => {
      // console.log(data);
      this.homeworkData = data.data;
    })
  }

  getStudentHomework() {
    let obj = {
      school_id: this.currentSelSchoolId,
      class_id: this.classIds[0],
      section_id: this.sectionIds[0],
      subject_id: this.subjectIds,
      user_id: this.userId,
      offset: 0,
      limit: 3
    }
    // console.log(obj);
    this.homeworkService.getHWStudent(obj).subscribe((data: any) => {
      // console.log(data);
      this.homeworkData = data.data;
    })
  }
}
