import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ReportService } from '../report.service';

@Component({
  selector: 'app-fun-quiz-dashboard',
  templateUrl: './fun-quiz-dashboard.component.html',
  styleUrls: ['./fun-quiz-dashboard.component.css']
})
export class FunQuizDashboardComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['name', 'className', 'mobile', 'city', 'score', 'date'];
  pageTitle = 'Fun Quiz Dashboard';
  dataSource: any;
  limit = 12;
  offset = 0;
  customFilters = {};
  searchFilter: any = {
    name: '',
    class_name: '',
    mobile: '',
    city: '',
    score: '',
    from_date: '',
    to_date: ''
  };
  errorMessage: any;
  loading: boolean = false;
  disableDownload: boolean;
  from_date: string;
  to_date: string;
  funQuizDetails: any;
  funQuizList: any;
  constructor(
    private reportService: ReportService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getFunQuizDetails();
  }

  getFunQuizDetails() {
    this.disableDownload = true;
    this.loading = true;
    // Set from_date
    if (this.searchFilter.from_date !== '') {
      const timestamp_from_date = new Date(this.searchFilter.from_date).getTime();
      const adjustedFromDate = new Date(timestamp_from_date);
      const month = adjustedFromDate.getMonth() + 1;
      const day = adjustedFromDate.getDate();
      const year = adjustedFromDate.getFullYear();
      const isoStringFromDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
      this.from_date = isoStringFromDate;
    } else {
      this.from_date = '';
    }

    // Set to_date
    if (this.searchFilter.to_date !== '') {
      const timestamp_to_date = new Date(this.searchFilter.to_date).getTime();
      const adjustedToDate = new Date(timestamp_to_date);
      const month = adjustedToDate.getMonth() + 1;
      const day = adjustedToDate.getDate();
      const year = adjustedToDate.getFullYear();
      const isoStringToDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
      this.to_date = isoStringToDate;
    } else {
      this.to_date = '';
    }

    //console.log(this.searchFilter)
    let obj = {
      name: this.searchFilter.name.trim(),
      class_name: this.searchFilter.class_name.trim(),
      mobile: this.searchFilter.mobile.trim(),
      city: this.searchFilter.city.trim(),
      score: this.searchFilter.score,
      from_date: this.from_date,
      to_date: this.to_date
    }
    // console.log(obj);
    this.funQuizDetails = this.reportService.getFunQuizDetails(obj).subscribe((data: any) => {
      //console.log(data);
      let listStudent = data;
      if (data.status !== false) {
        if (listStudent.data.length != 0) {
          this.funQuizList = listStudent.data;
          this.dataSource = new MatTableDataSource(this.funQuizList);
          this.dataSource.paginator = this.paginator;
          this.disableDownload = false;
          //this.dataSource.sort = this.sort;
        } else {
          this.disableDownload = true;
          this.dataSource = new MatTableDataSource();
          this.errorMessage = data.message;
          this.openSnackBar('Data not found. Search again with different criteria !!!', 'Close');
          if (this.funQuizDetails) {
            this.funQuizDetails.unsubscribe();
          }
        }
      } else {
        this.errorMessage = data.message;
        this.disableDownload = true;
        this.openSnackBar(this.errorMessage, 'Close');
        this.dataSource = new MatTableDataSource();
        this.dataSource.paginator = this.paginator;
        if (this.funQuizDetails) {
          this.funQuizDetails.unsubscribe();
        }
      }
      this.loading = false;
    },
      (error) => {
        this.loading = false;
        this.openSnackBar('An error occurred.', 'Close');
        console.error('An error occurred:', error);
        if (this.funQuizDetails) {
          this.funQuizDetails.unsubscribe();
        }
      }
    )
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2500,
    });
  }

  searchUserData(filters: any) {
    this.searchFilter = filters;
    this.disableDownload = true;
    // console.log(this.searchFilter)
    this.loading = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.getFunQuizDetails();
  }

  reset() {
    this.loading = false;
    this.searchFilter = {
      name: '',
      class_name: '',
      mobile: '',
      city: '',
      score: '',
      from_date: '',
      to_date: ''
    };
    this.disableDownload = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.getFunQuizDetails();
  }

}
