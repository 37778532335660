import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-search-data',
  template: `
  <app-search-class [searchData]="searchData"></app-search-class>
  `,
  styles: []
})
export class SearchDataComponent implements OnInit {

  searchData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    
    this.route.queryParams.subscribe((params: any) => {
      if (params.customFilters) {
        this.searchData = JSON.parse(params.customFilters);
      }
    })
  }

}
