import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import stripHtml from './../../../node_modules/string-strip-html';
import * as ClassicEditor from '../../assets/js/ck-editor-mathtype/ckeditor.js';

@Component({
  selector: 'app-decode-html',
  template:` <ckeditor [editor]="Editor" [data]="description" [disabled]=true></ckeditor>
  <br> `,
  styles: []
})
export class DecodeHtmlComponent implements OnInit {

  @Input() description;
  private imgUrl = environment.imgUrl;
  public Editor = ClassicEditor;

  constructor() { }

  ngOnInit() {
    this.getDescription();
  }

  decodeEntities(str) {
    // this prevents any overhead from creating the object each time
    const element = document.createElement('div');
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^''>]|'[^']*'|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }
    str = str.replace(/src="/g, `src="`);
    return str;
  }

  getDescription(){
    const stripOption = {
      ignoreTags: ['xml'],
      onlyStripTags: ['span'],
      stripTogetherWithTheirContents: ['script'],
      skipHtmlDecoding: false,
      returnRangesOnly: false,
      trimOnlySpaces: false,
      dumpLinkHrefsNearby: {
        enabled: false,
        putOnNewLine: false,
        wrapHeads: '',
        wrapTails: ''
      }
    };
  
    let desc = this.decodeEntities(this.description);
    desc = desc.replace(/\r?\n|\r/g, '');
    desc = desc.replace(/>\s+</g, '><');
    let dstr1 = desc.indexOf('annotation');
    do {
      if (desc.indexOf('/annotation') > -1) {
        const dstr3 = '/annotation';
        const dstr2 = desc.indexOf('/annotation') + dstr3.length + 2;
        const dres = desc.substring(dstr1, dstr2);
        desc = desc.replace(dres, '');
      }
      dstr1 = desc.indexOf('annotation');
    }while (dstr1 !== -1);
    desc = stripHtml(desc, stripOption);
    this.description = desc;
    // console.log(des)
  
  }

}
