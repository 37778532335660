import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from 'src/app/dashboard/dashboard.service';

@Component({
  selector: 'app-post-lesson-student-tests',
  templateUrl: './post-lesson-student-tests.component.html',
  styleUrls: ['./post-lesson-student-tests.component.css']
})
export class PostLessonStudentTestsComponent implements OnInit {

  displayedPreColumns = ['Sno', 'pre_test_completion_time', 'pre_test_score'];
  displayedPostColumns = ['Sno', 'post_test_completion_time', 'post_test_score'];
  dataSource: any;
  lessonDataList: any;
  studentName: string;
  preTest: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public lessonData: any,
    public dialog: MatDialog,
    private dashboardService : DashboardService,
  ) { }

  ngOnInit() {
    this.preTest = this.lessonData.preTest;
    //console.log(this.preTest);
    if(this.preTest){
      this.getPrelessonTestData();
    } else{
      this.getPostlessonTestData();
    }
  }

  getPrelessonTestData(){
    let obj = this.lessonData.obj;
    this.dashboardService.getLessonUserPerformance(obj).subscribe((lessonTestData: any) => {
      //console.log(lessonTestData);
       this.lessonDataList = lessonTestData.pre_post_test;
      //console.log(this.lessonDataList);
       this.studentName = this.lessonDataList[0].name;
       let filteredLessonDataList = this.lessonDataList.filter(item => item.pre_time_spent !== 'NA' || item.pre_score !== 'NA');
       //console.log(filteredLessonDataList);
      this.dataSource = new MatTableDataSource(filteredLessonDataList);
    });
  }

  getPostlessonTestData(){
    let obj = this.lessonData.obj;
    //console.log(JSON.stringify(obj))
    this.dashboardService.getLessonUserPerformance(obj).subscribe((lessonTestData: any) => {
     //console.log(lessonTestData);
       this.lessonDataList = lessonTestData.pre_post_test;
       this.studentName = this.lessonDataList[0].name;
      this.dataSource = new MatTableDataSource(this.lessonDataList);
    });
  }

}
