import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { PostDashboardService } from 'src/app/dashboard/post-dashboard.service';
import { QuizSetService } from 'src/app/quiz-set/quiz-set.service';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-post-lesson-topic-ques',
  templateUrl: './post-lesson-topic-ques.component.html',
  styleUrls: ['./post-lesson-topic-ques.component.css']
})
export class PostLessonTopicQuesComponent implements OnInit {
  school_id: number;
  class_id: number;
  section_id: number;
  lesson_id: number;
  tempObj: any;
  school_name: any;
  class_name: any;
  section_name: any;
  pageTitle = 'Quiz Questions'
  topic_id: any;
  quiz_set_id: [];
  message: string;
  quizVal: any;
  quizValues: any;
  quizData: any;
  paramsData: {};
  topic_name: any;
  lesson_name: any;
  defaultTab =  0;
  backToQues: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private backendService: BackendService,
    private quizSetService: QuizSetService,
    private postDashboardService: PostDashboardService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
        this.lesson_id = +params['lesson_id'];
        this.topic_id = +params['topic_id'];
        //this.quiz_set_id = params['quiz_set_id'];
      }

    );
    this.route.queryParams.subscribe(params => {
     //console.log(params)
      if (params.topic_id) {
        this.topic_id = params.topic_id;
        
      }
      if (params.topic_name) {
        this.topic_name = params.topic_name;
        
      }
      if(params.ques_dash == 'ques_dash'){
        this.backToQues = true;
      }
      //console.log(this.topic_id)
    });

    this.route.queryParams.subscribe(params => {
      if (params.quiz_set_id) {
        this.quiz_set_id = params.quiz_set_id;
      }
      if( params.postTest == '1'){
        this.defaultTab = 1
      }
      if( params.tabType == '1'){
        this.defaultTab = 1
      }
    });
   

   // console.log(this.quiz_set_id)
    this.paramsData = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      lesson_id: this.lesson_id,
      topic_id: this.topic_id,
      quiz_set_id: this.quiz_set_id
    }
   // console.log(this.topic_id)
    //console.log(this.quiz_set_id)
   
    this.breadcrumbs();

    this.getQuizSetDataQues();

  }

  //GET QUESTIONS FOR THE TOPIC 
  getQuizSetDataQues() {

    this.message = '';
  //quiz_set_id is not sent data will be retrived on the basis of school_id, class_id, sectio_id, lesson_id and topic_id
      const topicParams = {
        school_id: [this.school_id],
        class_id: [this.class_id],
        section_id: [this.section_id],
        lesson_id: [this.lesson_id],
        topic_id: [this.topic_id],
        quiz_set_id: [this.quiz_set_id]
      };
     // console.log(JSON.stringify(topicParams));
      // we will call new service from postDashboardService -> change the path in api as it's using previous api
      this.postDashboardService.getQuizSetTopicQues(topicParams)
        .subscribe(quizData => {
          //console.log(quizData)
          this.quizVal = quizData;
          this.quizValues = this.quizVal;
          if (this.quizValues.length > 0) {
            this.quizData = this.quizValues;
          } else {
            this.quizData = [];
            this.message = 'No student of this class/section attempt this quiz.';
          }
        }
    );

  }

  breadcrumbs() {
    this.tempObj = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id
    }
    // get School Name
    this.backendService.getSchoolById(this.tempObj).subscribe((schoolData: any) => {
      // console.log(schoolData);
      this.school_name = schoolData.data.school_name;
    })

    // get Class Name
    this.backendService.getClass(this.class_id).subscribe((classData: any) => {
      // console.log(classData);
      this.class_name = classData.data.class_name;
    })

    // get Section Name
    this.backendService.getSection(this.tempObj).subscribe((sectionData: any) => {
      // console.log(sectionData);
      this.section_name = sectionData.data.class_section.section_name;
    });

    this.dashboardService.getLessonById(this.lesson_id).subscribe((lesson : any ) => {
      //console.log(lesson);
      this.lesson_name = lesson.data.lesson_name;
    })

  }



}
