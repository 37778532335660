import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { MatOption, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';

@Component({
  selector: 'app-teacher-signup',
  templateUrl: './teacher-signup.component.html',
  styleUrls: ['./teacher-signup.component.css']
})
export class TeacherSignupComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  @ViewChild('allSelectedSection') private allSelectedSection: MatOption;
  isLinear: boolean = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  countryList: any;
  schoolList: any;
  classList: any;
  sectionList: any;
  subjectList: any;
  signupType: number;
  pageTitle: string;
  user_type_id: number;
  assigned_to: number;
  user_type: number;
  assignObj: any;
  user_id: any;
  errorMessage: any;
  alreadyExist: boolean = false;
  country_id: number;
  class_id = [];
  section_id = [];
  subject_id = [];
  schoolTutor: any;
  loading = false;
  liveUrl = environment.liveUrl;
  productName = environment.productName;
  productUrl = environment.productUrl;
  countryCode: string;
  classCode: any;
  COUNTRY_CODES: any;
  SCHOOL_IDS: any
  BOARD_IDS: any;
  CLASS_IDS: any;
  SECTION_IDS: any
  SUBJECT_IDS: any;
  checkCd: any = 1;
  uploadResponse = { status: '', message: '', file: '' };
  displayMessage: { [key: string]: string } = {};
  trial: any = 0;
  trialCountry_id: number;
  trialclass_id: number;
  trialUser: boolean = false;
  trialUserData: any;

  // COUNTRY_CODE-SCHOOL_ID-BOARD_ID-CLASS_IDS-SECTION_IDS-SUBJECT_IDS
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  private sub: Subscription;
  email: any;

  constructor(private _formBuilder: FormBuilder,
    private _backendService: BackendService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router) {
      this.validationMessages = {
        country_id: {
          required: 'country is required.'
        },
        school_id: {
          required: 'school is required.'
        },
        class_id: {
          required: 'class is required.'
        },
        section_id: {
          required: 'section is required.'
        },
        subject_id: {
          required: 'subject is required.'
        },
        email: {
          required: 'email is required'
        },
        password: {
          required: 'password is required'
        },
        confirmPassword: {
          required: 'confirm password is required'
        },
        firstName: {
          required: 'first name is required'
        },
        lastName: {
          required: 'last name is required'
        },
      };
  
      this.genericValidator = new GenericValidator(this.validationMessages);
     }





  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      email: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9 ]+$")]],
      lastName: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9 ]+$")]],
      contactNumber: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      referredBy: ['', ''],
      termAndCond: ['', [Validators.required]]
    });
    this.secondFormGroup = this._formBuilder.group({
      classCode: ['', ''],
      country_id: ['', [Validators.required]],
      school_id: ['', ''],
      class_id: ['', [Validators.required]],
      section_id: ['', [Validators.required]],
      subject_id: ['', [Validators.required]]
    });

    this._backendService.getCountries().subscribe((data: any) => { 
      this.countryList = data.data;
     })

    this.route.queryParams.subscribe(paramsData => {
      this.signupType = paramsData.signupType;
      if (this.signupType == 1) {
        this.pageTitle = "Student Signup"
        this.user_type = 0;
        this.schoolTutor = 1;
        this.user_type_id = 8;
      } else {
        this.pageTitle = "Teacher Signup"
        this.schoolTutor = 3;
        this.user_type_id = 3;
        this.assigned_to = 2;
        this.user_type = 1;
      }
      //console.log(paramsData.classCode)
      if(paramsData.classCode != undefined) {
        this.checkCd = 1;
        this.secondFormGroup.patchValue({classCode: paramsData.classCode})
        this._backendService.getCountries().subscribe((data: any) => { 
          this.countryList = data.data;
          this.onBlurMethod();
         })
      }else{
        let classObj = {
          board_id: 1
        }
        this._backendService.getClasses(classObj).subscribe((data: any) => {
          //console.log(data);
          this.classList = data.data;
          if(this.signupType ==2){
            this.classList.forEach(element => { this.class_id.push(element.id)});
            this.secondFormGroup.patchValue({class_id: this.class_id})
            this.changeClass(this.class_id);
          }
        });
      }
      if(paramsData.referredby != undefined && paramsData.referredby != null) {
        // console.log(paramsData.referredby);
        this.firstFormGroup.patchValue({referredBy: paramsData.referredby})
      }
    })

    // let classObj = {
    //   board_id: 1
    // }
    // this._backendService.getClasses(classObj).subscribe((data: any) => {
    //   console.log(data);
    //   this.classList = data.data;
    //   if(this.signupType ==2){
    //     this.classList.forEach(element => { this.class_id.push(element.id)});
    //     this.secondFormGroup.patchValue({class_id: this.class_id})
    //     this.changeClass(this.class_id);
    //   }
    // });
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    Observable.merge(this.secondFormGroup.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.secondFormGroup);
    });
    Observable.merge(this.firstFormGroup.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.firstFormGroup);
    });
  }

  changeCountry(strVal) {
    let params = { country_id: strVal }
    this.country_id = strVal
    this._backendService.getCountrySchool(params).subscribe((data: any) => {
      this.schoolList = data.data;
    })
    this.countryList.forEach(country => {
      if(country.id == strVal){
        this.countryCode = country.countryCode;
      }
    });
  }


  changeClass(class_id) {
    let classObj = {}
    classObj = { class_id: class_id }
    //console.log(classObj);
    this._backendService.getClassSections(classObj).subscribe((result: any) => {
      //console.log(result);
      this.sectionList = result.data;
      if(this.signupType ==2){
        this.sectionList.forEach(element => { this.section_id.push(element.id)});
        this.secondFormGroup.patchValue({section_id: this.section_id})
        // this.changeClass(this.class_id);
      }
    })
    this._backendService.getSubjects(classObj).subscribe((result: any) => {
      this.subjectList = result.data;
      this.subjectList.forEach(element => { this.subject_id.push(element.id)});
      this.secondFormGroup.patchValue({subject_id: this.subject_id})
    });
  }

  selectAllSection() {
    let sectionVal = [0];
    if (this.allSelectedSection.selected) {
      this.secondFormGroup.controls.section_id
        .patchValue([...this.sectionList.map(item => item.id), 0]);
      sectionVal = this.secondFormGroup.controls.section_id.value;
    } else {
      this.secondFormGroup.controls.section_id.patchValue([]);
    }
  }

  tosslePerSection() {
    if (this.allSelectedSection.selected) {
      this.allSelectedSection.deselect();
      return false;
    }
    if (this.secondFormGroup.controls.section_id.value.length === this.sectionList.length) {
      this.allSelectedSection.select();
    }
  }

  checkPass(confrmPass){
    if(this.firstFormGroup.value.password != confrmPass){
      // console.log("here is pass error");
      this.errorMessage = "Password and confirm password does not match"
      return;
    }else{
      this.errorMessage = '';
    }
  }

  tAndC(termAndcond){
    if(!termAndcond)
    this.firstFormGroup.controls.termAndCond.setValue('');
  }

  signup() {
    //console.log(this.secondFormGroup.value);
    this.loading = true;

    if (this.firstFormGroup.value.confirmPassword != this.firstFormGroup.value.password) {
      this.errorMessage = "Password and confirm password does not match";
      this.loading = false;
      return;
    }

    if(this.schoolTutor == 1 && !this.secondFormGroup.value.school_id){
      this.errorMessage = "School is required."
      this.loading = false;
      return;
    }

    if (this.secondFormGroup.value.country_id == 1 && !this.firstFormGroup.value.contactNumber) {
      this.errorMessage = "Contact Number is required";
      this.loading = false;
      return;
    } else if (this.secondFormGroup.value.country_id != 1 && !this.firstFormGroup.value.contactNumber) {
      this._backendService.getrandomnum({ country_id: this.secondFormGroup.value.country_id }).subscribe((data: any) => {
        if (data.randomNumber) {
          this.firstFormGroup.value.contactNumber = data.randomNumber;
          // console.log(data.randomNumber);
          this.assignObj = Object.assign({},
            this.firstFormGroup.value,
            this.secondFormGroup.value,
            { username: this.firstFormGroup.value.email },
            { user_type: this.user_type },
            { user_type_id: this.user_type_id },
            { assigned_to: this.assigned_to },
            { packageName: 'com.oksedu.marksharks.cbse.g07' },
            { fcm_id: "jhg76tb768" },
            { status: 0 },
            {created_by: 2},
            {modified_by: 2},
            { emailVerified: 0 });
            //console.log(this.assignObj);
            this.appsignup();
        // this.classMnagtUser()
          // this.updateQuizUser(123456);
        }
      })
    } else {
      this.firstFormGroup.value.contactNumber = this.countryCode+'-'+this.firstFormGroup.value.contactNumber;
      this.assignObj = Object.assign({},
        this.firstFormGroup.value,
        this.secondFormGroup.value,
        { username: this.firstFormGroup.value.email },
        { user_type: this.user_type },
        { user_type_id: this.user_type_id },
        { assigned_to: this.assigned_to },
        { packageName: 'com.oksedu.marksharks.cbse.g07' },
        { fcm_id: "jhg76tb768" },
        { status: 0 },
        {created_by: 2},
        {modified_by: 2},
        { emailVerified: 0 });
        //console.log(this.assignObj);
        this.appsignup();
        // this.classMnagtUser()
      // this.updateQuizUser(123456);
    }
    // console.log(this.assignObj);
  }

  appsignup() {
    this._backendService.getuserdetails({ username: this.firstFormGroup.value.email }).subscribe((data: any) => {
      //console.log('DS = '+JSON.stringify(data));
      if (!data.status) {
        this._backendService.appsignup(this.assignObj).subscribe((appData: any) => {
          //console.log('AD = '+JSON.stringify(appData));
          if(appData.status==false){
            this.errorMessage = appData.message;
            this.loading = false;
          }
          this.updateQuizUser(appData.data.userId);
        })
      } else {
        this.errorMessage = "User is already registered. Please login again"
        this.loading = false;
      }
    });

    // this.sentOtp();
  }

  updateQuizUser(app_user_id) {
    // let username = { username: this.assignObj.email }
    if(this.schoolTutor == 2){
      this.assignObj.user_type_id = 9;
      let obj = {
        country_id: this.secondFormGroup.value.country_id,
        firstName: this.firstFormGroup.value.firstName
      }
      this._backendService.tutorCreatSchool(obj).subscribe((data: any)=> {
        if(data.status){
          //console.log(data.data);
          this.secondFormGroup.patchValue({school_id:[data.data.id]})
          this.assignObj.school_id = [data.data.id];
          //console.log(this.secondFormGroup.value.school_id);
          this.createQuizUser(app_user_id);
        }else{
          this.errorMessage = data.message;
          this.loading = false;
        }
      })
    }else{
     this.createQuizUser(app_user_id)
    }
    // if(this.signupType == 2){
    //   this.adminUser(app_user_id);
    // }
  }

  createQuizUser(app_user_id){
    this._backendService.updateUser(app_user_id, this.assignObj).subscribe((userData: any) => {
      //console.log(userData);
      if (userData.status) {
        this.classMnagtUser();
        this.adminUser(app_user_id);
        // if(this.signupType == 2){
        //   this.adminUser(app_user_id);
        // }else{
        //   this.sentOtp(app_user_id);
        // }
      }else{
        this.loading = false;
        this.errorMessage = userData.message;
      }
    })
  }

  classMnagtUser(){
    let status = 2;
    if(this.trialUser){
      status = 1
    }else{
      status = 2
    }
    let obj = {
      studentName: this.firstFormGroup.value.firstName + ' ' + this.firstFormGroup.value.lastName,
      email: this.firstFormGroup.value.email,
      studentContactNo: this.firstFormGroup.value.contactNumber,
      city: '',
      user_type: this.user_type,
      school_id: this.secondFormGroup.value.school_id,
      class_id: this.secondFormGroup.value.class_id,
      section_id: this.secondFormGroup.value.section_id,
      subject_id: this.secondFormGroup.value.subject_id,
      created_by: 2,
      status: status
    }

    //console.log(obj);
    this._backendService.addUsersData(obj).subscribe((data: any)=> {
      //console.log(data);
    })
  }

  adminUser(app_user_id) {
    this.assignObj.status = 1;
    //console.log(this.assignObj);
    this._backendService.chkAdminUser(this.assignObj).subscribe((data: any) => {
      console.log(data);
      if (!data.status) {
        // console.log()
        if(data.schoolAdmin.length>=1){
          this.assignObj.assigned_to = data.schoolAdmin[data.schoolAdmin.length-1].id
        }
        if(data.schoolAdmin.length>=1 && this.user_type == 1){
          this.assignObj.user_type_id = 3
        }
        this.assignObj.country_id = [this.assignObj.country_id]
        this.assignObj.status = 1;
        this.assignObj.quiz_user_id = app_user_id;
        this._backendService.createUser(this.assignObj).subscribe((adminUser: any) => {
          //console.log(adminUser);
          if(adminUser.status){
            this.sentOtp(app_user_id, this.assignObj.country_id);
          }else{
            this.loading = false;
            this.errorMessage = adminUser;
          }
        })
        // this.sentOtp();
      } else {
        this.loading = false;
        this.errorMessage = data.message;
        // this.openSnackBar(data.message, 'Close');
        // this.sentOtp(app_user_id);
      }
    })
  }

  sentOtp(app_user_id, country_id) {
    let obj = {
      user_id: app_user_id,
      country_id: [country_id],
      template: 'SIGNUP'
    }
    let trialUser = ""
    if(this.trialUser){
      trialUser = "Yes"
    }else{
      trialUser = "No"
    }
    // this.user_id = 12345678;
    //   this.user_id = btoa(this.user_id);
    //   this.router.navigate(['user-verification', this.user_id]);
    this._backendService.generateOtp(obj).subscribe((data: any) => {
      //console.log(data);
      if(data.status){
        this.openSnackBar(data.message, 'Close');
        this.user_id = app_user_id;
        this.user_id = btoa(this.user_id);
        this.router.navigate(['user-verification'],{
          queryParams: { 
            user_id: this.user_id,
            trialUser: trialUser
          }
        });
      }else{
        this.errorMessage = data.message
        this.loading = false;
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  onBlurCheck(){
    this.email = this.firstFormGroup.value.email;
    //console.log(this.firstFormGroup.value.email);
    this._backendService.getuserdetails({ username: this.firstFormGroup.value.email }).subscribe((data: any) => {
    
      if(data.status == true ){
        this.errorMessage = "Email is already registered. Please try again"
      }else{
        this.errorMessage = "";
      }

    });
  }

  onBlurMethod(){
    //console.log(this.secondFormGroup.value.classCode);
    this.classCode = this.secondFormGroup.value.classCode;
    let codeArr = this.classCode.split("/");
    //console.log(codeArr);
    if(codeArr.length != 7){
      this.errorMessage = "Invalid class code"
      return;
    }else{
      this.errorMessage = ''
    }
    this.secondFormGroup.controls.country_id.setValue(0);
    this.secondFormGroup.controls.school_id.setValue(0);
    this.secondFormGroup.controls.class_id.setValue(0);
    this.secondFormGroup.controls.section_id.setValue(0);
    this.secondFormGroup.controls.subject_id.setValue(0);
    this.COUNTRY_CODES = parseInt(codeArr[1]);
    // if(codeArr[1].includes('#')){
    //   this.COUNTRY_CODES = codeArr[1].split('#').map(Number)
    //   if(!this.checkArray(this.COUNTRY_CODES)){
    //     this.errorMessage = "Invalid class code"
    //     return;
    //   }
    // }else{ 
    //   this.COUNTRY_CODES = [parseInt(codeArr[1])]
    //   if(!this.checkArray(this.COUNTRY_CODES)){
    //     this.errorMessage = "Invalid class code"
    //     return;
    //   }
    // }
    if(codeArr[2].includes('-')){
      this.SCHOOL_IDS = codeArr[2].split('-').map(Number);
      if(!this.checkArray(this.SCHOOL_IDS)){
        this.errorMessage = "Invalid class code"
        return;
      }
    }else{ 
      this.SCHOOL_IDS = [parseInt(codeArr[2])]
      if(!this.checkArray(this.SCHOOL_IDS)){
        this.errorMessage = "Invalid class code"
        return;
      }
    }
    if(codeArr[3].includes('-')){
      this.BOARD_IDS = codeArr[3].split('-').map(Number)
      if(!this.checkArray(this.BOARD_IDS)){
        this.errorMessage = "Invalid class code"
        return;
      }
    }else{ 
      this.BOARD_IDS = [parseInt(codeArr[3])]
      if(!this.checkArray(this.BOARD_IDS)){
        this.errorMessage = "Invalid class code"
        return;
      }
    }
    if(codeArr[4].includes('-')){
      this.CLASS_IDS = codeArr[4].split('-').map(Number)
      if(!this.checkArray(this.CLASS_IDS)){
        this.errorMessage = "Invalid class code"
        return;
      }
    }else{ 
      this.CLASS_IDS = [parseInt(codeArr[4])]
      if(!this.checkArray(this.CLASS_IDS)){
        this.errorMessage = "Invalid class code"
        return;
      }
    }
    if(codeArr[5].includes('-')){
      this.SECTION_IDS = codeArr[5].split('-').map(Number)
      if(!this.checkArray(this.SECTION_IDS)){
        this.errorMessage = "Invalid class code"
        return;
      }
    }else{ 
      this.SECTION_IDS = [parseInt(codeArr[5])]
      if(!this.checkArray(this.SECTION_IDS)){
        this.errorMessage = "Invalid class code"
        return;
      }
    }
    if(codeArr[6].includes('-')){
      this.SUBJECT_IDS = codeArr[6].split('-').map(Number)
      if(!this.checkArray(this.SUBJECT_IDS)){
        this.errorMessage = "Invalid class code"
        return;
      }
    }else{ 
      this.SUBJECT_IDS = [parseInt(codeArr[6])]
      if(!this.checkArray(this.SUBJECT_IDS)){
        this.errorMessage = "Invalid class code"
        return;
      }
    }

    // this.country_id = this.secondFormGroup.value.country_id;
    //console.log(this.COUNTRY_CODES);
    this.secondFormGroup.value.country_id = this.COUNTRY_CODES
    this.secondFormGroup.value.school_id = this.SCHOOL_IDS
    this.secondFormGroup.value.class_id = this.CLASS_IDS
    this.secondFormGroup.value.section_id = this.SECTION_IDS
    this.secondFormGroup.value.subject_id = this.SUBJECT_IDS
    this.changeCountry(this.secondFormGroup.value.country_id);
  }

  checkArray(codeArray: any){
    if(codeArray.some(isNaN)){
      return false
    }else{
      return true
    }
  }

  msTrial(){
    if(this.trial == 1){
      this.trialUser = true;
      this.secondFormGroup.reset();
      this._backendService.getClasses({board_id: 1}).subscribe((data: any) => {
        //console.log(data);
        this.classList = data.data;
      });
    }else{
      this.trialUser = false;
      this.secondFormGroup.reset();
      this.trialCountry_id = undefined;
      this.trialclass_id = undefined;
      this.route.queryParams.subscribe(paramsData => {
        if(paramsData.classCode != undefined) {
          this.checkCd = 1;
          this.secondFormGroup.patchValue({classCode: paramsData.classCode})
          this._backendService.getCountries().subscribe((data: any) => { 
            this.countryList = data.data;
            this.onBlurMethod();
           })
        }
      })
    }
  }

  TrialClass(){
    let obj = {
      country_id: this.trialCountry_id,
      class_id: this.trialclass_id
    }
    let trialSubject_id = [];
    this._backendService.trialUserInfo(obj).subscribe((data: any)=> {
      // console.log(data);
      this.trialUserData = data;

      this.trialUserData.subject.forEach(element => {
        trialSubject_id.push(element.id);
      });

    this.secondFormGroup.controls.country_id.setValue(this.trialCountry_id);
    this.secondFormGroup.controls.school_id.setValue([this.trialUserData.school.id]);
    this.secondFormGroup.controls.class_id.setValue([this.trialclass_id]);
    this.secondFormGroup.controls.section_id.setValue([this.trialUserData.section.id]);
    this.secondFormGroup.controls.subject_id.setValue(trialSubject_id);

    //console.log(this.secondFormGroup.value);
    })
  }
}
