import { Component, OnInit } from '@angular/core';
import { Http } from '@angular/http';
import { BackendService } from '../_services/backend.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-online-class',
  templateUrl: './online-class.component.html',
  styleUrls: ['./online-class.component.css']
})
export class OnlineClassComponent implements OnInit {

  currentUser: any;
  userid: number;
  userName: string;
  userType: any;
  userTypeVal: any;
  schoolId: number;
  classId: number;
  sectionId: number;
  subjectId: number;
  onlineClassUrl = environment.onlineClassUrl;

  constructor(
    private backend: BackendService,
    private http: Http
    ) { 

  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
        this.userid = this.currentUser.id;
        this.userType = this.currentUser.userData[0].user_type;
        this.schoolId = this.currentUser.currentSelSchoolId;
        this.classId = this.currentUser.userData[0].class_id;
        this.sectionId = this.currentUser.userData[0].section_id;
        this.subjectId = this.currentUser.userData[0].subjectId;
        this.userName = this.currentUser.userData[0].studentName;
    }  
    // this.userTypeVal = 0; 
    // if(this.userType == 'teacher') {
    //   this.userTypeVal = 1;
    // }
    let queryParams = "user_id="+this.userid+"&user_type="+this.userType+"&school_id="+this.schoolId+"&class_id="+this.classId+"&section_id="+this.sectionId+"&subject_id="+this.subjectId+"&userName="+this.userName;
    window.location.href = this.onlineClassUrl+'?'+queryParams;
    // let userData = {user_id: this.userid, user_type: this.userType}
    // this.backend.initiateOnlineClass(userData).subscribe()
  }
}
  