import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[appNumericInput]'
})
export class NumericInputDirective {

  constructor(private el: ElementRef, @Optional() private ngControl: NgControl) { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const numericValue = input.value.replace(/[^0-9+-]/g, '');
    input.value = numericValue;

    if (this.ngControl) {
      if (this.ngControl.control) {
        this.ngControl.control.setValue(numericValue, { emitEvent: false });
        this.ngControl.control.markAsDirty();
        this.ngControl.control.updateValueAndValidity();
      }
    }
  }
}
