import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {

  paymentFrm: FormGroup;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.createPaymentFrm();
  }

  createPaymentFrm() {
    this.paymentFrm = this.fb.group({
      name: ['',[Validators.required]],
      email: ['',[Validators.required]],
      amount: ['',[Validators.required]]
    });
  }

}
