import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';
import { isArray } from 'util';
import { ContentService } from '../content.service';

@Component({
  selector: 'app-textbook-content',
  templateUrl: './textbook-content.component.html',
  styleUrls: ['./textbook-content.component.css']
})

export class TextbookContentComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'Textbook Content';
  contentUrl = environment.contentUrl;
  displayedColumns: string[] = ['Sno', 'syllabus', 'title', 'version', 'modified_by', 'modified_on', 'status', 'preview', 'id'];
  currentUser: any;
  dataSource: any;
  customFilters: any = {};
  limit = 10;
  offset = 0;
  totalContent: number;
  user_id: number;
  type_order: number;
  contentForm: FormGroup
  school_id = [];
  class_id = [];
  subject_id = [];
  lesson_id = [];
  topic_id = [];
  country_lesson_id = [];
  status: number;
  paramsFilters: any = {};

  schoolList: any;
  classList = [];
  subjectList = [];
  lessonList = [];
  topicList = [];
  scheduleData = [];
  userList: any;
  schoolArr = [];
  classArr = [];
  sectionArr = [];
  userData: any;
  showCountryLesson = false;
  mapLessonList = [];
  countryLessonList: any;
  country_id: number;

  constructor(
    private contentService: ContentService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private backendService: BackendService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.user_id = this.currentUser.id;
    // this.type_order = this.currentUser.user_Type.type_order;
    this.user_id = this.currentUser.adminUser[0].id;
    this.type_order = this.currentUser.adminUser[0].user_Type.type_order;


    this.contentForm = this.fb.group({
      school_id: ['', [Validators.required]],
      class_id: ['', [Validators.required]],
      subject_id: ['', [Validators.required]],
      country_lesson_id: ['', ''],
      lesson_id: ['', [Validators.required]],
      topic_id: ['', [Validators.required]],
      status: ['', [Validators.required]]
    });

    this.route.queryParams.subscribe((params: any) => {
     
        if (params.customFilters) {
        this.paramsFilters = JSON.parse(params.customFilters);
        // this.paramsFilters = this.customFilters;

        // console.log(this.paramsFilters);
        if (this.paramsFilters.school_id) {
          this.school_id = this.paramsFilters.school_id.map(Number);
          this.changeSchool(this.school_id);
          this.contentForm.patchValue({ school_id: this.school_id });
        }
        if (this.paramsFilters.class_id) {
          this.class_id = this.paramsFilters.class_id.map(Number);
          this.changeClass(this.class_id);
          this.contentForm.patchValue({ class_id: this.class_id });
        }
        if (this.paramsFilters.subject_id) {
          this.subject_id = this.paramsFilters.subject_id.map(Number);
          this.changeSubject(this.subject_id);
          this.contentForm.patchValue({ subject_id: this.subject_id });
        }
        if (this.paramsFilters.country_lesson_id) {
          this.country_lesson_id = this.paramsFilters.country_lesson_id.map(Number);
          this.getCountryMapLessons(this.country_lesson_id);
          this.contentForm.patchValue({ country_lesson_id: this.country_lesson_id });
        }
        if (this.paramsFilters.lesson_id) {
          this.lesson_id = this.paramsFilters.lesson_id.map(Number);
          this.changeLesson(this.lesson_id);
          this.contentForm.patchValue({ lesson_id: this.lesson_id });
        }
        if (this.paramsFilters.topic_id) {
          this.topic_id = this.paramsFilters.topic_id.map(Number);
          this.changeTopic(this.topic_id);
          this.contentForm.patchValue({ topic_id: this.topic_id });
        }
        if (this.paramsFilters.status) {
          this.status = this.paramsFilters.status.map(Number);
          this.changeStatus(this.status);
          this.contentForm.patchValue({ status: this.status });
        }
      }
    })

    if (this.type_order == 1 || this.type_order == 2) {
      this.contentService.getActiveSchools(this.user_id).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.getUserClasses();
        this.getContent();
      });
    } else {

      this.userData = this.currentUser.userData;
      this.userData.forEach(userVal => {
        if (this.classArr.indexOf(userVal.class.id) == -1) {
          this.classArr.push(userVal.class.id);
          // this.classes.push({ id: userVal.class.id, className: userVal.class.class_name })
        }
      });
      this.schoolList = this.currentUser.userSchools
      this.currentUser.userSchools.forEach(school => {
        this.schoolArr.push(school.id);
      });


      // let obj = { user_id: this.user_id }
      // this.userService.userSchools(obj).subscribe((data: any) => {
      //   this.schoolList = data.data;
      //   this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
      //   this.classArr = data.userClass;

        // this.changeSchool(this.schoolArr);
        if (Object.keys(this.customFilters).length === 0) {
          this.customFilters = {
            school_id: this.schoolArr,
            class_id: this.classArr
          }
        }
        this.getContent();
      // });
    }

  }

  getContent() {

    this.customFilters = {
      school_id: this.customFilters.school_id,
      class_id: this.customFilters.class_id,
      subject_id: this.customFilters.subject_id,
      country_lesson_id: this.customFilters.country_lesson_id,
      lesson_id: this.customFilters.lesson_id,
      topic_id: this.customFilters.topic_id,
      status: this.customFilters.status,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
    // console.log(this.customFilters);
    this.contentService.getTextbookContent(this.customFilters).subscribe((data: any) => {
      // console.log(data);
      this.totalContent = data.totalContent;
      this.dataSource = new MatTableDataSource(data.data);
    })
  }

  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.contentService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  getCountryBySchool(school_id) {
    this.backendService.getSchoolById({ school_id: school_id }).subscribe((data: any) => {
      console.log(data);
      this.country_id = data.data.country_id;
      if (this.country_id == 2) {
        this.showCountryLesson = true;
      } else {
        this.showCountryLesson = false;
      }
    })
  }

  changeSchool(school_id){
    this.classList = [];
    this.subjectList = [];
    this.lessonList = [];
    this.topicList = [];
    this.customFilters.school_id = school_id;
    this.customFilters.class_id = [];
    this.customFilters.subject_id = [];
    this.customFilters.lesson_id = [];
    this.customFilters.topic_id = [];
    this.customFilters.country_lesson_id = [];
    this.getCountryBySchool(school_id[0]);
    // if(this.contentForm.value.school_id.length == 0){
    //   this.contentForm.value.class_id = []
    //   this.customFilters = {
    //     school_id: this.schoolArr,
    //     class_id: this.classArr
    //   }
    // }
    // this.customFilters
    this.getUserClasses();
    this.getContent();
  }

  changeClass(class_id) {
    this.subjectList = [];
    this.lessonList = [];
    this.topicList = [];
    this.customFilters.class_id = class_id
    this.customFilters.subject_id = [];
    this.customFilters.lesson_id = [];
    this.customFilters.topic_id = [];
    this.customFilters.country_lesson_id = [];
    this.getContent();

    let classObj = { class_id: class_id, section_id: this.sectionArr }
    this.contentService.getSubjects(classObj).subscribe((result: any) => {
      this.subjectList = result.data;
    });
  }

  changeSubject(subject_id, defaultVal = 0) {
    this.lessonList = [];
    this.topicList = [];
    this.customFilters.subject_id = subject_id;
    this.customFilters.lesson_id = [];
    this.customFilters.topic_id = [];
    this.customFilters.country_lesson_id = [];
    this.getContent();
    this.backendService.getSchoolById({ school_id: this.contentForm.value.school_id[0] }).subscribe((data: any) => {
      // console.log(data);
      this.country_id = data.data.country_id;
      if (this.country_id == 2) {
        this.getCountryLessons(subject_id);
      } else {
        const paramsVal = { subject_id: subject_id };
        this.contentService.getLessons(paramsVal).subscribe((result: any) => {
          this.lessonList = result.data;
        });
      }
    })
    
  }

  changeLesson(lesson_id, defaultVal = 0) {
    this.topicList = [];
    this.customFilters.lesson_id = lesson_id
    this.customFilters.topic_id = [];
    this.getContent();

    const paramsVal = { lesson_id: lesson_id };
    // console.log(paramsVal);
    this.contentService.getTopics(paramsVal).subscribe((result: any) => {
      this.topicList = result.data;
    });
  }

  changeTopic(topic_id){
    this.customFilters.topic_id = topic_id
    this.getContent();
  }

  changeStatus(status){
    this.customFilters.status = status
  }

  getCountryLessons(strVal, defaultVal = 0) {
    const paramsVal = { subject_id: strVal };
    this.contentService.getCountryLessons(paramsVal).subscribe((result: any) => {
      this.countryLessonList = result.data;
    });
  }

  getCountryMapLessons(strVal, defaultVal = 0) {
    this.topicList = [];
    this.customFilters.country_lesson_id = strVal;
    this.customFilters.topic_id = [];
    this.getContent();

    const paramsVal = { country_lesson_id: strVal };
    this.contentService.getCountryMapLessons(paramsVal).subscribe((result: any) => {
      this.mapLessonList = result.data;
    });
  }

  resetPageIndex() {
    this.paginator.pageIndex = 0;
  }

  reset() {
    this.getContent();
  }

  previewContent(content) {
    this.router.navigate(['/textbookcontentdata', `${content.id}`], {
      queryParams: {
        customFilters: JSON.stringify(this.customFilters),
        prwCheck: 'prew'
      }
    })
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          // this.sortBy = data.action;
          // this.sortDirection = data.direction;
          this.getContent();
        })
      ).subscribe();
  }

  addContent(){
    // console.log(this.customFilters);
    this.router.navigate(['/addtextbookcontent'], {
      queryParams: {
        customFilters: JSON.stringify(this.customFilters)
      }
    })
  }
}
