import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig, MatDialog, MatPaginator, MatSort, MatTableDataSource, MatOption, MatSnackBar } from '@angular/material';
import { BackendService } from '../../_services/backend.service';
// import { FormsModule } from '@angular/forms';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-teacher-attendance',
  templateUrl: './teacher-attendance.component.html',
  styleUrls: ['./teacher-attendance.component.css']
})
export class TeacherAttendanceComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('allSelectedSection') private allSelectedSection: MatOption;
  dataSource: any;
  displayedColumns: string[] = ['S_No', 'name', 'email', 'class', 'subject', 'duration', 'attendanceDate', 'attendanceStatus', 'id'];
  pageTitle = 'Attendance';
  limit = 10;
  offset = 0;
  total_students: number;
  customFilters: any = null;
  currentUser: any;
  user_id: number;
  userData = [];
  userDataSchool = [];
  schoolArr = [];
  classArr = [];
  sectionArr = [];
  schoolList: any;
  classList: any;
  sectionList: any;
  schedule_id: number;
  cstmFltr: any = {};
  search: any;
  schoolId: number;

  searchFilter: any = {
    studentName: '',
    from_date: '',
    to_date: '',
    school_id: [],
    class_id: [],
    section_id: [],
    limit: 10,
    offset: 0
  };

  constructor(public dialog: MatDialog,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private backendService: BackendService,
    public router: Router) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = this.currentUser.id;
    this.schoolId = this.currentUser.currentSelSchoolId;
    this.route.params.subscribe(params => {
      this.schedule_id = +params['schedule_id'];
    });
    this.route.queryParams.subscribe((params: any) => {
      if(params.cstmFltr){
        this.cstmFltr = JSON.parse(params.cstmFltr);
        this.search = this.cstmFltr.search;
      }
      if (params.customFilters) {
        this.customFilters = JSON.parse(params.customFilters);
        this.searchFilter = this.customFilters;
        this.offset = this.paginator.pageIndex = (this.customFilters.offset / this.customFilters.limit);
        this.limit = this.paginator.pageSize = this.customFilters.limit;
      } else {
        // this.customFilters = {
        //   takenBy: this.user_id,
        //   limit: this.paginator.pageSize,
        //   offset: this.paginator.pageIndex * this.paginator.pageSize
        // }
        this.customFilters = {}
      }
    })

    let obj = {user_id: this.user_id}
    this.backendService.userSchools(obj).subscribe((data: any) => {
      this.schoolList = data.data;
      // this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
      this.schoolArr.push(this.schoolId);
      this.classArr = data.userClass;
      this.sectionArr = data.userSection;
      this.getUserClasses();

      if(Object.keys(this.customFilters).length === 0){
        this.customFilters = {
          school_id: this.schoolArr,
          class_id: this.classArr,
          section_id: this.sectionArr,
          limit: this.paginator.pageSize,
          offset: this.paginator.pageIndex * this.paginator.pageSize
        }
      }
      this.getUserData();
    });

    // this.getUserData();
    let classObj = {
      board_id: 1
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  getUserData() {
    this.customFilters = {
      studentName: this.customFilters.studentName,
      from_date: this.customFilters.from_date,
      to_date: this.customFilters.to_date,
      school_id: this.customFilters.school_id,
      class_id: this.customFilters.class_id,
      section_id: this.customFilters.section_id,
      takenBy: this.user_id,
      schedule_id: this.schedule_id,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limi0t: this.paginator.pageSize
    }
    // console.log(this.customFilters);
    this.backendService.getAttendanceList(this.customFilters).subscribe((result: any) => {
      // console.log(result);
      this.total_students = result.totalCount;
      result.data.forEach(element => {
        if(element.startTime != null && element.endTime != null){
          var startTime = new Date(element.startTime).getTime();
          var endTime = new Date(element.endTime).getTime();
          var duration = endTime - startTime
          duration = 1000 * Math.round(duration / 1000); 
          var d = new Date(duration);
          element.duration = d.getUTCHours() + ':' + d.getUTCMinutes() + ':' + d.getUTCSeconds();
          this.userData.push(element);
        }else{
          element.duration = "";
          this.userData.push(element);
        }

      });
      this.dataSource = new MatTableDataSource(result.data);
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    });
  }


  resetPage() {
    this.getUserData();
  }

  searchUserData(filters: any) {
    this.resetPageIndex();
    // console.log(filters.from_date);
    if (filters) {
      if (!filters.school_id || filters.school_id.length <= 0) {
        filters.school_id = this.schoolArr;
      }
      if (!filters.class_id || filters.class_id.length <= 0) {
        filters.class_id = this.classArr;
      }
      if (!filters.section_id || filters.section_id.length <= 0) {
        filters.section_id = this.sectionArr;
      }
      filters.takenBy = this.user_id;
      filters.limit = this.paginator.pageSize;
      filters.offset = this.paginator.pageIndex * this.paginator.pageSize;
      this.customFilters = filters;
      this.getUserData();
    }
  }

  resetSearchFilter(searchPanel: any) {
    this.resetPageIndex();
    searchPanel.toggle();
    this.searchFilter = {};
    this.sectionArr = null;
    this.customFilters = {
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr,
      takenBy: this.user_id,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
    this.getUserData();
  }

  resetPageIndex() {
    this.paginator.pageIndex = 0;
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  getUserClasses(){
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  changeClass(class_id) {
    // this.sectionIdArr = [class_id];
    let classObj = { class_id: class_id, section_id: this.sectionArr }
    // console.log(classObj)
    this.backendService.getClassSections(classObj).subscribe((result: any) => {
      // console.log(result)
      this.sectionList = result.data;
      
    })
  }

  selectAllSection() {
    let sectionVal = [0];
    if (this.allSelectedSection.selected) {

      this.sectionList.forEach(element => {
        sectionVal.push(element.id);
      });
      this.searchFilter.section_id = sectionVal;
    }else{
      this.searchFilter.section_id = []
    }
  }

  tosslePerSection() {
    if (this.allSelectedSection.selected) {
      this.allSelectedSection.deselect();
      // this.searchFilter.section_id = [];
      return false;
    }
    if (this.searchFilter.section_id.length === this.sectionList.length) {
      this.allSelectedSection.select();
    }
  }

  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          // this.sortBy = data.action;
          // this.sortDirection = data.direction;
          this.getUserData();
        })
      )
      .subscribe();
  }
}
