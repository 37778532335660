
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { merge } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { PostDashboardService } from '../../post-dashboard.service';

@Component({
  selector: 'app-post-school-dashboard',
  templateUrl: './post-school-dashboard.component.html',
  styleUrls: ['./post-school-dashboard.component.css']
})
export class PostSchoolDashboardComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentUser: any;
  type_order: String;
  schoolObj: any = null;
  schoolList: any;
  dataSource: any;
  displayedColumns = ['school_name'];
  limit = 6;
  offset = 0;
  schoolLength: number;
  rowHeight = '650px';
  heading = false;
  total_school: number;
  searchFilter: any = {
    start_date: '',
    end_date: '',
    subject_label: [],
  };
  currentDate = new Date();
  pageIndex = 0;
  pageSize = 6;

  subject_label: any;
  start_date: Date;
  end_date: Date;
  customFilters: { user_id: any; school_id: any; class_id: any; section_id: any; chart_type: any; quiz_set_id: any; topic_id: any; student_id: any; subject_label: any; start_date: Date; end_date: Date; };
  displayedSchools: any;
  tempSchool: any;

  constructor(private postDashboardService: PostDashboardService,
    private dashboardService: DashboardService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    // if(this.type_order == '1' || this.type_order == '2'){
    //   this.schoolObj = {
    //     type_name: this.currentUser.adminUser[0].user_Type.type_name, //this.currentUser.user_Type.type_name,
    //     offset: this.offset,
    //     limit: this.limit
    //   };
    //   this.dashboardService.getSchoolsList(this.schoolObj).subscribe((schoolList: any) => {
    //    //console.log(schoolList);
    //     this.total_school = schoolList.total_school;
    //     this.schoolList = schoolList.data;
    //     this.schoolLength = this.total_school;
    //     if(this.schoolLength > 2){
    //       this.rowHeight = "480px";
    //       this.schoolLength = 3;
    //       this.heading = true;
    //     }
    //   });
    // }else{
    //   this.schoolObj = {
    //     user_id: this.currentUser.adminUser[0].id,
    //     offset: this.offset,
    //     limit: this.limit
    //   }
    //   this.dashboardService.getUserSchool(this.schoolObj).subscribe((schoolList: any) => {
    //     this.total_school = schoolList.total_school;
    //     this.schoolList = schoolList.data;
    //     this.schoolLength = this.total_school;
    //     if(this.schoolLength > 2){
    //       this.rowHeight = "480px";
    //       this.schoolLength = 3;
    //       this.heading = true
    //     }
    //     //console.log(this.schoolLength)
    //   });
    //}
    this.refreshData();
    this.dashboardService.getSearchFilter().subscribe(searchFilterData => {
      //console.log(searchFilterData);
      this.searchFilter.subject_label = searchFilterData.subject_label;
      this.searchFilter.start_date = searchFilterData.start_date;
      this.searchFilter.end_date = searchFilterData.end_date;
    });

  }

  filterData(searchFilter) {
    //console.log(searchFilter);
    this.subject_label = searchFilter.subject_label;
    this.start_date = searchFilter.start_date;
    this.end_date = searchFilter.end_date;
    this.refreshData();
    let searchFilterData = {
      subject_label: this.subject_label,
      end_date: this.end_date,
      start_date: this.start_date
    }
    this.dashboardService.setSearchFilter(searchFilterData);
  }

  refreshData() {
    // if (this.type_order == '1' || this.type_order == '2') {
    //   this.schoolObj = {
    //     type_name: this.currentUser.adminUser[0].user_Type.type_name, //this.currentUser.user_Type.type_name,
    //     subject_label: this.subject_label,
    //     start_date: this.start_date,
    //     end_date: this.end_date,
    //     // offset: this.offset,
    //     // limit: this.limit
    //   };
    //   this.dashboardService.getSchoolsList(this.schoolObj).subscribe((schoolList: any) => {
    // //   console.log(schoolList);
    //     this.total_school = schoolList.total_school;
    //     this.schoolList = schoolList.data;
    //     this.schoolLength = this.total_school;
    //     if(this.schoolLength > 2){
    //       this.rowHeight = "480px";
    //       this.schoolLength = 3;
    //       this.heading = true;
    //     }
    //   });
    //   //New API FOR SCHOOL DASH IN PRE/POST
    //   //¯console.log(this.schoolObj)
    //   // this.dashboardService.getPrePostSchoolsList(this.schoolObj).subscribe((schoolsList: any) => {
    //   //   console.log(schoolsList);
    //   //   this.schoolList = Object.values(schoolsList)
    //   //   this.total_school = this.schoolList.length;
    //   //   this.schoolLength = this.total_school;
    //   //   if (this.schoolLength > 2) {
    //   //     this.rowHeight = "480px";
    //   //     this.schoolLength = 3;
    //   //     this.heading = true;
    //   //   }
    //   // });

    // } else {
    //   this.schoolObj = {
    //     user_id: this.currentUser.adminUser[0].id,
    //     offset: this.offset,
    //     limit: this.limit
    //   }
    //   this.dashboardService.getUserSchool(this.schoolObj).subscribe((schoolList: any) => {
    //     this.total_school = schoolList.total_school;
    //     this.schoolList = schoolList.data;
    //     this.schoolLength = this.total_school;
    //     if (this.schoolLength > 2) {
    //       this.rowHeight = "480px";
    //       this.schoolLength = 3;
    //       this.heading = true
    //     }
    //     //console.log(this.schoolLength)
    //   });
    // }

    if (this.currentUser.newSchoolId !== undefined) {
      // Filter the schoolList to include only the school with currentSelSchoolId
      if (this.type_order == '1') {
        this.schoolObj = {
          type_name: this.currentUser.adminUser[0].user_Type.type_name, //this.currentUser.user_Type.type_name,
          subject_label: this.subject_label,
          start_date: this.start_date,
          end_date: this.end_date,
          // offset: this.offset,
          // limit: this.limit
        };
        this.dashboardService.getSchoolsList(this.schoolObj).subscribe((schoolList: any) => {
       // console.log(schoolList);
          this.tempSchool = schoolList.data;
          let listSchool = schoolList.data;
          this.schoolList = listSchool.filter(school => this.currentUser.newSchoolId.includes(school.id));
          //console.log(this.schoolList);
          this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
          this.total_school = this.schoolList.length;
          this.schoolLength = this.total_school;
          if (this.schoolLength > 2) {
            this.rowHeight = "480px";
            this.schoolLength = 3;
            this.heading = true;
          }
          if (this.schoolLength === 0) {     
              this.schoolList = this.tempSchool
              this.total_school = schoolList.data.length;
              this.schoolList = schoolList.data;
              this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
              this.schoolLength = this.total_school;
              if (this.schoolLength > 2) {
                this.rowHeight = "480px";
                this.schoolLength = 3;
                this.heading = true;
              }
           
          }
        });
      } else if (this.type_order == '2' || this.type_order == '3') {
        this.schoolObj = {
          user_id: this.currentUser.adminUser[0].id,
        }
        this.dashboardService.getUserSchool(this.schoolObj).subscribe((schoolList: any) => {
          //console.log(schoolList);
          const schoolIds = new Set(schoolList.data.map(school => school.id));
          const uniqueSchools = this.currentUser.userSchools.filter(school => !schoolIds.has(school.id));
          //console.log(uniqueSchools);
          let listSchool = schoolList.data;
          this.schoolList = listSchool.filter(school => this.currentUser.newSchoolId.includes(school.id));
          this.total_school = this.schoolList.length;
          this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
          this.schoolLength = this.total_school;
          if (this.schoolLength > 2) {
            this.rowHeight = "480px";
            this.schoolLength = 3;
            this.heading = true;
          }
          if (this.schoolLength === 0) {
            this.schoolObj = {
              user_id: this.currentUser.adminUser[0].id,
            };
            this.dashboardService.getUserSchool(this.schoolObj).subscribe((schoolList: any) => {
              //console.log(schoolList);
              this.total_school = schoolList.total_school;
              this.schoolList = schoolList.data;
              this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
              this.schoolLength = this.total_school;
              if (this.schoolLength > 2) {
                this.rowHeight = "480px";
                this.schoolLength = 3;
                this.heading = true;
              }
            });
          }

        })

      }
    }
    else {
     if(this.type_order == '1'){
       this.schoolObj = {
        type_name: this.currentUser.adminUser[0].user_Type.type_name, //this.currentUser.user_Type.type_name,
        subject_label: this.subject_label,
        start_date: this.start_date,
        end_date: this.end_date,
        // offset: this.offset,
        // limit: this.limit
      };
      this.dashboardService.getSchoolsList(this.schoolObj).subscribe((schoolList: any) => {
    //   console.log(schoolList);
        this.total_school = schoolList.data.length;
        this.schoolList = schoolList.data;
        this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
        this.schoolLength = this.total_school;
        if (this.schoolLength > 2) {
          this.rowHeight = "480px";
          this.schoolLength = 3;
          this.heading = true;
        }
      });
     } else {
      this.schoolObj = {
        user_id: this.currentUser.adminUser[0].id,
      };
      this.dashboardService.getUserSchool(this.schoolObj).subscribe((schoolList: any) => {
        //console.log(schoolList);
        this.total_school = schoolList.total_school;
        this.schoolList = schoolList.data;
        this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
        this.schoolLength = this.total_school;
        if (this.schoolLength > 2) {
          this.rowHeight = "480px";
          this.schoolLength = 3;
          this.heading = true;
        }
      });
    
     }
    }


  }

  resetSearchFilter(searchPanel: any) {
    this.searchFilter = {};
    this.end_date = null;
    this.start_date = null;
    this.subject_label = null;
    this.refreshData();
    let searchFilterData = {
      subject_label: '',
      end_date: '',
      start_date: '',
    }
    this.dashboardService.setSearchFilter(searchFilterData);
  }

  // ngAfterViewInit() {

  //   merge(this.paginator.page)
  //     .pipe(
  //       tap(() => {
  //         this.offset = this.paginator.pageIndex * this.paginator.pageSize;
  //         this.limit = this.paginator.pageSize;
  //         this.ngOnInit();
  //       })
  //     )
  //     .subscribe();

  // }

  onPageChange(event) {
    this.offset = event.pageIndex * event.pageSize;
    this.limit = event.pageSize;
    this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
  }




}
