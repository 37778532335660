import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MsContentComponent } from './ms-content.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';
import { MathModule } from '../math/math.module';
import { MsContentDataComponent } from './ms-content-data/ms-content-data.component';
import { NotfoundpageComponent } from '../notfoundpage/notfoundpage.component';
import { MsContentImageComponent } from './ms-content-image/ms-content-image.component';
import { MsContentPdfComponent } from './ms-content-pdf/ms-content-pdf.component';
import { MsContentHtmlComponent } from './ms-content-html/ms-content-html.component';
import { MsContentMp4Component } from './ms-content-mp4/ms-content-mp4.component';
import { ContentTopicComponent } from './content-topic/content-topic.component';
import { ContentTitleComponent } from './content-title/content-title.component';
import { ContentItemsComponent } from './content-items/content-items.component';
import { ContentPlayerComponent } from './content-player/content-player.component';
import { ContentYoutubePlayerComponent } from './content-youtube-player/content-youtube-player.component';
import { AuthGuard } from '../_guard/auth.guard';
import { MsContentMapLessonsComponent } from './ms-content-map-lessons/ms-content-map-lessons.component';
import { MsTextbookComponent } from './ms-textbook/ms-textbook.component';

const routes = [
  {
    path: 'mscontent',
    component: MsContentComponent, 
    canActivate:[AuthGuard]
  },
  {
    path: 'content-data/:class_id/:content_id/:lesson_id',
    component: MsContentDataComponent
  },
  {
    path: 'content-topic/:class_id/:lesson_id',
    component: ContentTopicComponent
  },
  {
    path: 'content-title/:class_id/:lesson_id/:topic_id',
    component: ContentTitleComponent
  },
  {
    path: 'content-items/:class_id/:lesson_id',
    component: ContentItemsComponent
  },
  {
    path: 'content-mapLesson/:class_id/:country_lesson_id',
    component: MsContentMapLessonsComponent
  },
  {
    path: 'content-textbook/:class_id/:content_id/:lesson_id',
    component: MsTextbookComponent
  }
];

@NgModule({
  declarations: [
    MsContentComponent,
    MsContentDataComponent,
    MsContentImageComponent,
    MsContentPdfComponent,
    MsContentHtmlComponent,
    MsContentMp4Component,
    ContentTopicComponent,
    ContentTitleComponent,
    ContentItemsComponent,
    ContentPlayerComponent,
    ContentYoutubePlayerComponent,
    MsContentMapLessonsComponent,
    MsTextbookComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(routes),
    MathModule.forRoot()
  ]
})
export class MsContentModule { }
