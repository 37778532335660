import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { from } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { MaterialModule } from '../shared';
import { RouterModule } from '@angular/router';
import { MathModule } from '../math/math.module';
import { HttpClientModule } from '@angular/common/http';
import { AddEditContentComponent } from './add-edit-content/add-edit-content.component';
import { ContentComponent } from './content.component';
import { ContentImageComponent } from './content-file-data/content-image.component';
import { ContentHtmlComponent } from './content-file-data/content-html.component';
import { ContentMp4Component } from './content-file-data/content-mp4.component';
import { ContentPdfComponent } from './content-file-data/content-pdf.component';
import { ContentFileDataComponent } from './content-file-data/content-file-data.component';
import { ContentSearchComponent } from './content-search/content-search.component';
import { ContentYoutubeComponent } from './content-file-data/content-youtube.component';
import { MaterialModule } from '../shared/material.module';
import { AuthGuard } from '../_guard/auth.guard';
import { TextbookContentComponent } from './textbook-content/textbook-content.component';
import { AddEditTextbookComponent } from './textbook-content/add-edit-textbook/add-edit-textbook.component';
import { TextbookFileComponent } from './textbook-content/textbook-file/textbook-file.component';

const routes = [

  { path: 'content', component: ContentComponent, 
  canActivate:[AuthGuard] },
  { path: 'addcontent', component: AddEditContentComponent, 
  canActivate:[AuthGuard] },
  { path: 'editcontent/:content_id', component: AddEditContentComponent, 
  canActivate:[AuthGuard] },
  { path: 'contentdata/:content_id', component: ContentFileDataComponent },
  { path: 'textbookcontent', component: TextbookContentComponent, 
  canActivate:[AuthGuard] },
  { path: 'addtextbookcontent', component: AddEditTextbookComponent, 
  canActivate:[AuthGuard] },
  { path: 'edittextbookcontent/:content_id', component: AddEditTextbookComponent, 
  canActivate:[AuthGuard] },
  { path: 'textbookcontentdata/:content_id', component: TextbookFileComponent },

];

@NgModule({
declarations: [
  ContentComponent,
  AddEditContentComponent,
  ContentImageComponent,
  ContentHtmlComponent,
  ContentMp4Component,
  ContentPdfComponent,
  ContentFileDataComponent,
  ContentSearchComponent,
  ContentYoutubeComponent,
  TextbookContentComponent,
  AddEditTextbookComponent,
  TextbookFileComponent
],
imports: [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  RouterModule.forChild(routes),
  MathModule.forRoot(),
  HttpClientModule
],
entryComponents: [ContentSearchComponent],
})

export class ContentModule { }
