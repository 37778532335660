import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../shared/material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowOnlineScheduleComponent } from './show-online-schedule.component';
import { ShowOnlineScheduleService } from './show-online-schedule.service';
import { CurrentClassComponent } from './current-class/current-class.component';
import { UpcomingClassComponent } from './upcoming-class/upcoming-class.component';
import { PastClassComponent } from './past-class/past-class.component';
import { SearchClassComponent } from './search-class/search-class.component';
import { SearchDataComponent } from './search-class/search-data.component';
import { TableComponent } from './table/table.component';
import { OnlineScheduleLessonComponent } from './online-schedule-lesson/online-schedule-lesson.component';
import { AuthGuard } from '../_guard/auth.guard';
const routes = [
  {
    path: 'showonlineSchedule',
    component: ShowOnlineScheduleComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'search-class',
    component: SearchClassComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'search-data',
    component: SearchDataComponent,
    canActivate:[AuthGuard]
  }
];

@NgModule({
  declarations: [
    ShowOnlineScheduleComponent,
    CurrentClassComponent,
    UpcomingClassComponent,
    PastClassComponent,
    SearchClassComponent,
    SearchDataComponent,
    TableComponent,
    OnlineScheduleLessonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(routes)
  ],
  providers: [ShowOnlineScheduleService],
  entryComponents: [OnlineScheduleLessonComponent]
})
export class ShowOnlineScheduleModule { }
