import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-school-dashboard',
  templateUrl: './school-dashboard.component.html',
  styleUrls: ['./school-dashboard.component.css']
})
export class SchoolDashboardComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  currentUser: any;
  type_order: String;
  schoolObj: any = null;
  schoolList: any;
  dataSource: any;
  displayedColumns = ['school_name'];
  limit = 6;
  offset = 0;
  schoolLength: number;
  rowHeight = '650px';
  heading = false;
  total_school: number;
  displayedSchools: any;
  constructor(private dashboardService: DashboardService,
    private backendService: BackendService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
   // console.log(this.currentUser)
    // Check if currentSelSchoolId is defined
    if (this.currentUser.newSchoolId !== undefined) {
      // Filter the schoolList to include only the school with currentSelSchoolId
      if (this.type_order == '1') {
        this.backendService.getActiveSchools({}).subscribe((data: any) => {
          //console.log(data)
          let listSchool = data.data;
          this.schoolList = listSchool.filter(school => this.currentUser.newSchoolId.includes(school.id));
          //console.log(this.schoolList);
          this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
          this.total_school = this.schoolList.length;
          this.schoolLength = this.total_school;
          if (this.schoolLength > 2) {
            this.rowHeight = "480px";
            this.schoolLength = 3;
            this.heading = true;
          }
          if (this.schoolLength === 0) {     
            this.backendService.getActiveSchools({}).subscribe((schoolList: any) => {
              //console.log(schoolList);
              this.total_school = schoolList.data.length;
              this.schoolList = schoolList.data;
              this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
              this.schoolLength = this.total_school;
              if (this.schoolLength > 2) {
                this.rowHeight = "480px";
                this.schoolLength = 3;
                this.heading = true;
              }
            });
          }
        });
      } else if (this.type_order == '2' || this.type_order == '3') {
        this.schoolObj = {
          user_id: this.currentUser.adminUser[0].id,
        }
        this.dashboardService.getUserSchool(this.schoolObj).subscribe((schoolList: any) => {
          //console.log(schoolList);
          const schoolIds = new Set(schoolList.data.map(school => school.id));
          const uniqueSchools = this.currentUser.userSchools.filter(school => !schoolIds.has(school.id));
          //console.log(uniqueSchools);
          let listSchool = schoolList.data;
          this.schoolList = listSchool.filter(school => this.currentUser.newSchoolId.includes(school.id));
          this.total_school = this.schoolList.length;
          this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
          this.schoolLength = this.total_school;
          if (this.schoolLength > 2) {
            this.rowHeight = "480px";
            this.schoolLength = 3;
            this.heading = true;
          }
          if (this.schoolLength === 0) {
            this.schoolObj = {
              user_id: this.currentUser.adminUser[0].id,
            };
            this.dashboardService.getUserSchool(this.schoolObj).subscribe((schoolList: any) => {
              //console.log(schoolList);
              this.total_school = schoolList.total_school;
              this.schoolList = schoolList.data;
              this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
              this.schoolLength = this.total_school;
              if (this.schoolLength > 2) {
                this.rowHeight = "480px";
                this.schoolLength = 3;
                this.heading = true;
              }
            });
          }

        })

      }
    }
    else {
     if(this.type_order == '1'){
      this.backendService.getActiveSchools({}).subscribe((schoolList: any) => {
        //console.log(schoolList);
        this.total_school = schoolList.data.length;
        this.schoolList = schoolList.data;
        this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
        this.schoolLength = this.total_school;
        if (this.schoolLength > 2) {
          this.rowHeight = "480px";
          this.schoolLength = 3;
          this.heading = true;
        }
      });
     } else {
      this.schoolObj = {
        user_id: this.currentUser.adminUser[0].id,
      };
      this.dashboardService.getUserSchool(this.schoolObj).subscribe((schoolList: any) => {
        //console.log(schoolList);
        this.total_school = schoolList.total_school;
        this.schoolList = schoolList.data;
        this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
        this.schoolLength = this.total_school;
        if (this.schoolLength > 2) {
          this.rowHeight = "480px";
          this.schoolLength = 3;
          this.heading = true;
        }
      });
    
     }
    }

    

  }
  onPageChange(event) {
    this.offset = event.pageIndex * event.pageSize;
    this.limit = event.pageSize;
    this.displayedSchools = this.schoolList.slice(this.offset, this.offset + this.limit);
  }
  
  // ngAfterViewInit() {

  //   merge(this.paginator.page)
  //     .pipe(
  //       tap(() => {
  //         this.offset = this.paginator.pageIndex * this.paginator.pageSize;
  //         this.limit = this.paginator.pageSize;
  //         this.ngOnInit();
  //       })
  //     )
  //     .subscribe();
  // }

}
