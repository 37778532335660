import { Component, OnInit } from '@angular/core';
import { QuizSetService } from 'src/app/_services/quiz-set.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-attempted-quiz-history',
  templateUrl: './attempted-quiz-history.component.html',
  styleUrls: ['./attempted-quiz-history.component.css']
})
export class StudentAttemptedQuizHistoryComponent implements OnInit {

  pageTitle = 'Quiz Result Details';
  currentUser
  quizParams: any;
  sub: Subscription;
  quizVal: any;
  quizData: any;
  attempted_set_id: number;
  setId: number;
  tempObj = {};
  set_name: string;
  schoolId: number;
  classId: number;
  sectionId: number;
  backTo: string;

  constructor(
    private quizSetService: QuizSetService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.schoolId = this.currentUser.currentSelSchoolId;
      this.classId = this.currentUser.userData[0].class_id;
      this.sectionId = this.currentUser.userData[0].section_id;
    }
    this.sub = this.route.params.subscribe(
      params => {
        this.setId = +params['id'];
        this.attempted_set_id = +params['set_id'];
      }
    )

    this.route.queryParams.subscribe((paramsData: any) => {
        this.getQuizSetData(this.attempted_set_id);
        if(paramsData.backTo){
          this.backTo = paramsData.backTo;
        }
    });

    this.breadcrumbs();
  }

  getQuizSetData(attempted_set_id) {
    this.quizParams = { attempted_set_id: attempted_set_id };
    
    this.quizSetService.getUserQuizSetDetails(this.quizParams)
      .subscribe(quizData => {
        this.quizVal = quizData;
        this.quizData = this.quizVal.data.attempted_quiz_questions;
      }
    );    
  }

  breadcrumbs() {
    this.tempObj = {
      school_id: this.schoolId,
      class_id: this.classId,
      section_id: this.sectionId,
      set_id: this.setId
    }   

    this.quizSetService.getQuizSetDetails(this.tempObj).subscribe((quizDetail: any) => {
      // console.log(quizDetail);
      this.set_name = quizDetail.data.set_name;
    })
  }

}
