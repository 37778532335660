import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-tutor-dashboard',
  templateUrl: './tutor-dashboard.component.html',
  styleUrls: ['./tutor-dashboard.component.css']
})
export class TutorDashboardComponent implements OnInit {

  @Input() currentUser;
  @Input() students;
  cardClass = 'dash-card';
  mediaQueryList: any = null;
  mediaQueryMin: any = null;
  isMobile = false;
  colNum: number = 4;
  rowHeight = '300px';
  colSpan: number = 2;
  // userClasses = [];
  currentSelSchoolId: number;
  subGridRowHeight = '350px';
  subGridColNum: number = 3;
  subGridCol: number = 2;
  chartGridWidth = '300px';
  userId: number;
  userData: any;
  classIds = [];
  sectionIds = [];
  // students = [];

  constructor(
    private dashboardService: DashboardService,
    breakpointObserver: BreakpointObserver,
    mediaMatcher: MediaMatcher,
    private router: Router
  ) { 
    this.mediaQueryList = mediaMatcher.matchMedia('(min-width: 640px)');
    this.mediaQueryMin = mediaMatcher.matchMedia('(min-width: 1000px)');

    breakpointObserver.observe([
      // Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
        this.colNum = this.mediaQueryList.matches ? 2 : 1;
        this.colSpan = this.mediaQueryList.matches ? 2 : 1;
        this.cardClass = 'dash-card-inline';
        this.subGridColNum = this.mediaQueryList.matches ? 3 : 1;
        this.subGridCol = this.mediaQueryList.matches ? 2 : 1;
        this.subGridRowHeight = '500px';
        this.chartGridWidth = '250px';
        
      }
    });
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
        this.colNum = this.mediaQueryList.matches ? 2 : 1;
        this.colSpan = this.mediaQueryList.matches ? 2 : 1;
        this.cardClass = 'dash-card-inline';
        this.subGridColNum = this.mediaQueryList.matches ? 3 : 1;
        this.subGridCol = this.mediaQueryList.matches ? 2 : 1;
        this.subGridRowHeight = '350px';
        this.chartGridWidth = '300px';
        
      }
    });
  }

  ngOnInit() {
    // this.userClasses = this.currentUser.userData;
    // console.log(this.userClasses)
    this.currentSelSchoolId = this.currentUser.currentSelSchoolId;
    this.userId = this.currentUser.adminUser[0].id;
    this.userData = this.currentUser.userData;
    // this.userClasses = this.userClasses.filter(data => data.school_id == this.currentSelSchoolId);
    // console.log(this.userClasses)    
    this.userData.forEach(userVal => {
      if(this.classIds.indexOf(userVal.class.id) == -1){
        this.classIds.push(userVal.class.id);
        // this.classes.push({id:userVal.class.id,className: userVal.class.class_name})
      }
      if (this.sectionIds.indexOf(userVal.section_id) == -1) {
        this.sectionIds.push(userVal.section_id);
        // this.sections.push(userVal.section);
      }      
    });
    // console.log("student",this.studentData)
    // if(this.studentData){
    //   this.students = this.studentData;
    // } else {
      this.getClassSectionUsers();
    // }

  }

  openQuizSets(school_id, class_id, section_id, student_id){
    localStorage.setItem("maind","1");
    this.router.navigate(['/student_quiz_dash', `${school_id}`, `${class_id}`, `${section_id}`, `${student_id}`], {queryParams:{main_dashboard: 1}});
    // this.router.navigate(['/quiz_dashboard',`${school_id}`,`${class_id}`,`${section_id}`],{queryParams:{main_dashboard: 1}})
  }

  getClassSectionUsers(){
    let params = {
      school_id: [this.currentSelSchoolId],
      class_id: this.classIds,
      section_id: this.sectionIds
    }
    this.dashboardService.getClassStudents(params)
      .subscribe((userData:any) => {
        // console.log(userData);
        if(userData.status){
          this.students = userData.data;
        }        
      });
  }
}
