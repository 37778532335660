import { Component, OnInit, ViewChild } from '@angular/core';
import { ShowOnlineScheduleService } from './show-online-schedule.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '../_services/backend.service';

@Component({
  selector: 'app-show-online-schedule',
  templateUrl: './show-online-schedule.component.html',
  styleUrls: ['./show-online-schedule.component.css']
})
export class ShowOnlineScheduleComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'School Management';
  displayedColumns: string[] = ['Sno', 'class', 'subject', 'lessonNum', 'lesson', 'classLevel', 
  'startDate', 'endDate', 'day', 'id'];
  currentUser: any;
  customFilters: any = null;
  dataSource: any;
  total_schedule: number;
  schoolList: any;
  classList: any;
  sectionList: any;
  scheduleData = [];
  userId: number;
  schoolId: number;
  userType: number;
  // scheduleArr 
  limit = 10;
  offset = 0;
  schoolArr = [];
  classArr = [];
  sectionArr = [];
  teacherId: number;
  searchBtn: boolean = false;
  tabIndex: number = 0;

  searchFilter: any = {
    start_date: '',
    end_date: '',
    school_id: [],
    class_id: [],
    section_id: [],
    user_id: '',
    limit: 10,
    offset: 0
  };
  constructor(
    private onlineScheduleService: ShowOnlineScheduleService,
    private backendService: BackendService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.currentUser.id;
    // this.userId = this.currentUser.adminUser[0].id;
    this.schoolId = this.currentUser.currentSelSchoolId;
    this.userType = this.currentUser.userData[0].user_type;
    
    if(this.userType == 1){
      this.teacherId = this.userId;
    }
    this.route.queryParams.subscribe((params: any) => {
      if (params.customFilters) {
        this.customFilters = JSON.parse(params.customFilters);
        // console.log(this.customFilters);
        this.searchFilter = this.customFilters;
        this.sectionArr = this.customFilters.section_id
        if (this.customFilters.class_id != '') {
          this.changeClass(this.customFilters.class_id);
        }
        if(this.customFilters.scheduleType=="current"){
          this.tabIndex = 0;
        }else if(this.customFilters.scheduleType=="upcoming"){
          this.tabIndex = 1;
        }
        else if(this.customFilters.scheduleType=="past"){
          this.tabIndex = 2;
        }
        // this.offset = this.paginator.pageIndex = (this.customFilters.offset / this.customFilters.limit);
        // this.limit = this.paginator.pageSize = this.customFilters.limit;
      } else {
        // console.log("qwsertyhuiopasdfghjk");
        // this.customFilters = {
        //   school_id: this.schoolArr,
        //   class_id: this.classArr,
        //   section_id: this.sectionArr,
        //   limit: this.paginator.pageSize,
        //   offset: this.paginator.pageIndex * this.paginator.pageSize
        // }
        this.customFilters = {};
      }
    })
    let obj = {user_id: this.userId}
    this.backendService.userSchools(obj).subscribe((data: any) => {
      this.schoolList = data.data;
      // this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
      this.schoolArr.push(this.schoolId)
      this.classArr = data.userClass;
      this.sectionArr = data.userSection;
      this.getUserClasses();

      if(Object.keys(this.customFilters).length === 0){
        this.customFilters = {
          school_id: this.schoolArr,
          class_id: this.classArr,
          section_id: this.sectionArr,
          user_id: this.teacherId,
          limit: this.limit,
          offset: this.offset
        }
      }
    });
    let classObj = {
      board_id: 1
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  reset(){
  }

  searchUserData(filters: any) {
    this.resetPageIndex();
    if (filters) {
      this.searchBtn = false;
      if (!filters.school_id || filters.school_id.length <= 0) {
        filters.school_id = this.schoolArr;
      }
      if (!filters.class_id || filters.class_id.length <= 0) {
        filters.class_id = this.classArr;
      }
      if (!filters.section_id || filters.section_id.length <= 0) {
        filters.section_id = this.sectionArr;
      }
      filters.user_id = this.teacherId;
      // filters.limit = this.paginator.pageSize;
      // filters.offset = this.paginator.pageIndex * this.paginator.pageSize;
      this.customFilters = filters;
      this.router.navigate(['/search-data'],{ queryParams: { customFilters: JSON.stringify(this.customFilters) } });
      // console.log(this.customFilters);
      this.searchBtn = true;
    }
  }

  resetSearchFilter(searchPanel: any) {
    this.resetPageIndex();
    searchPanel.toggle();
    this.searchFilter = {};
    this.sectionArr = null;
    this.customFilters = {
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr,
      user_id: this.teacherId,
      // offset: this.paginator.pageIndex * this.paginator.pageSize,
      // limit: this.paginator.pageSize
    }
    this.searchBtn = false;
  }

  resetPageIndex() {
    // this.paginator.pageIndex = 0;
  }

  changeClass(class_id) {
    // this.sectionIdArr = [class_id];
    let classObj = { class_id: class_id, section_id: this.sectionArr }
    // console.log(classObj)
    this.backendService.getClassSections(classObj).subscribe((result: any) => {
      // console.log(result)
      this.sectionList = result.data;
      
    })
  }

  getUserClasses(){
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  changeTab(event){
    this.tabIndex = event.index;
 }

}
