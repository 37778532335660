import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { PostDashboardService } from 'src/app/dashboard/post-dashboard.service';

@Component({
  selector: 'app-post-class-dash-data',
  templateUrl: './post-class-dash-data.component.html',
  styleUrls: ['./post-class-dash-data.component.css']
})
export class PostClassDashDataComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser: any;
  type_order: String;
  classObj: any = null;
  classList: any;
  school_id: number;
  limit = 6;
  offset = 0;
  classLength: number;
  total_class: number;
  heading = false;
  school_name: string;
  rowHeight = '650px';
  searchFilter: any = {
    start_date: '',
    end_date: '',
    subject_label: [],
  };
  currentDate = new Date();
  subject_label: any;
  start_date: Date;
  end_date: Date;
  defaultTab= 1;
  quiz_set_id: [];


  constructor(private postDashboardService: PostDashboardService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute) {  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
      }
    );
    // console.log(this.school_id);
    
    this.route.queryParams.subscribe((paramsData: any) => {
      if (paramsData.quiz_set_id) {
        this.quiz_set_id = paramsData.quiz_set_id;
      }
      if(paramsData.tabType == '2'){
        this.defaultTab = 2;
      } else {
        this.defaultTab = 1;
      }
    })

    if(isNaN(this.school_id)){
      this.school_id = this.currentUser.user_class[0].school_id;
    }
    this.getSchool();    
    this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    
    // if(this.type_order == '1' || this.type_order == '2'){
    //   this.classObj = {
    //     school_id: this.school_id,
    //     offset: this.offset,
    //     limit: this.limit
    //   }
    //   this.dashboardService.getClassList(this.classObj).subscribe((classList: any) => {
    //     // console.log(classList);
    //     this.total_class = classList.total_class;
    //     this.classList = classList.data;
    //     this.classLength = this.total_class;
    //     if(this.classLength > 2){
    //       this.rowHeight = '480px';
    //       this.classLength = 3;
    //       this.heading = true;
    //     }
    //   });
    // }else{
    //   this.classObj = {
    //     user_id: this.currentUser.adminUser[0].id,
    //     school_id: this.school_id,
    //     offset: this.offset,
    //     limit: this.limit
    //   }
    //   this.dashboardService.getUserClass(this.classObj).subscribe((classList: any) => {
    //     // console.log( classList);
    //     this.total_class = classList.total_class;
    //     this.classList = classList.data;
    //     this.classLength = this.total_class;
    //     if(this.classLength > 2) {
    //       this.rowHeight = '480px';
    //       this.classLength = 3;
    //       this.heading = true;
    //     }
    //   });
    // }
    this.dashboardService.getSearchFilter().subscribe(searchFilterData => {
     // console.log(searchFilterData);
      this.searchFilter.subject_label = searchFilterData.subject_label;
      this.searchFilter.start_date = searchFilterData.start_date;
      this.searchFilter.end_date = searchFilterData.end_date;
    });
    this.refreshData();
  }

  getSchool(){
    this.classObj = {
      school_id: this.school_id
    }
    this.dashboardService.getSchoolById(this.classObj).subscribe((schoolData: any) => {
      // console.log(schoolData);
      this.school_name = schoolData.data.school_name;
    })
  }

  filterData(searchFilter){
    //console.log(searchFilter)
    this.subject_label = searchFilter.subject_label;
    this.start_date = searchFilter.start_date;
    this.end_date = searchFilter.end_date;
    let searchFilterData = {
      subject_label: this.subject_label,
      end_date: this.end_date,
      start_date: this.start_date
    }
    this.dashboardService.setSearchFilter(searchFilterData);
    this.refreshData();
  }
  
  resetSearchFilter(searchPanel: any) {
    this.searchFilter = {};
    this.end_date = null;
    this.start_date = null;
    this.subject_label = null;
    let searchFilterData = {
      subject_label: '',
      end_date: '',
      start_date: '',
    }
    this.dashboardService.setSearchFilter(searchFilterData);
    this.refreshData();
  }

  refreshData(){
    if(this.type_order == '1' || this.type_order == '2'){
      this.classObj = {
        school_id: [this.school_id],
       // quiz_set_id: this.quiz_set_id,
        subject_label: this.subject_label,
        start_date: this.start_date,
        end_date: this.end_date,
        offset: this.offset,
        limit: this.limit
      }
     // console.log(this.classObj)
      this.dashboardService.getClassList(this.classObj).subscribe((classList: any) => {
        // console.log(classList);
        this.total_class = classList.total_class;
        this.classList = classList.data;
        this.classLength = this.total_class;
        if(this.classLength > 2){
          this.rowHeight = '510px';
          this.classLength = 3;
          this.heading = true;
        }
      });

      // //New API FOR CLASS DASH
      // this.heading = true;
      // this.dashboardService.getPrePostClassList(this.classObj).subscribe((classListData: any) => {
      //    console.log(classListData);
      //    this.classList = Object.values(classListData)
      //   this.total_class = this.classList.length;
      //   this.classList = this.classList;
      //   this.classLength = this.total_class;
      //   if(this.classLength > 2){
      //     this.rowHeight = '510px';
      //     this.classLength = 3;
      //     this.heading = true;
      //   }
      // });
    }else{
      this.classObj = {
        user_id: this.currentUser.adminUser[0].id,
        school_id: this.school_id,
        offset: this.offset,
        limit: this.limit
      }
      this.dashboardService.getUserClass(this.classObj).subscribe((classList: any) => {
        // console.log( classList);
        this.total_class = classList.total_class;
        this.classList = classList.data;
        this.classLength = this.total_class;
        if(this.classLength > 2) {
          this.rowHeight = '510px';
          this.classLength = 3;
          this.heading = true;
        }
      });
    }

  }


  ngAfterViewInit() {

    merge( this.paginator.page)
    .pipe(
    tap(() => {
        this.offset = this.paginator.pageIndex * this.paginator.pageSize;
        this.limit = this.paginator.pageSize;
        this.ngOnInit();
        })
    )
    .subscribe();
  }

}

