import { Component, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { detectChangesInRootView } from '@angular/core/src/render3/instructions';
import { AuthenticationService } from 'src/app/_services';
import { Subject, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.css']
})
export class LeftNavigationComponent implements OnInit {

  @Output() sideNavClose = new Subject<boolean>();

  productName = environment.productName;
  title = this.productName+' Classroom';
  userType: string;
  currentUser: any;
  userId: number;
  liveUrl = environment.liveUrl;

  config = {
    paddingAtStart: true,
    interfaceWithRoute: false,
    classname: 'navigation-menu',
    listBackgroundColor: `#fff`,
    fontColor: `#000`,
    backgroundColor: `#fff`,
    selectedListFontColor: `blue`,
    highlightOnSelect: true,
    collapseOnSelect: true,
    useDividers: true,
    rtlLayout: false
};

  appitems = [];
  schoolVal = [];
  publish_status: number;
  sub: Subscription;
  isMobile = false;
  
  constructor(
    private authService: AuthenticationService,
    breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        // this.activateHandsetLayout();
        this.isMobile = true;
      }
    });
    this.sub = this.authService.getLeftNavMenus().subscribe((data) => {
      this.appitems = data;
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser){
      this.userType = this.currentUser.adminUser[0].user_type_id;
      this.userId = this.currentUser.adminUser[0].id;
     // console.log(this.currentUser)
     // this.schoolVal = this.currentUser.userSchools.filter(data => data.id == this.currentUser.currentSelSchoolId);
     if (Array.isArray(this.currentUser.currentSelSchoolId) && this.currentUser.currentSelSchoolId.length > 0) {
      this.schoolVal = this.currentUser.userSchools.filter(data => data.id === this.currentUser.currentSelSchoolId[0]);
     // console.log(this.schoolVal)
      if(this.schoolVal.length === 0 ){
        this.schoolVal.push(this.currentUser.userSchools[0]);
      }
    } else if (this.currentUser.currentSelSchoolId.length === 0) {
      this.schoolVal.push(this.currentUser.userSchools[0]);
    }
     else {
      this.schoolVal = this.currentUser.userSchools.filter(data => data.id === this.currentUser.currentSelSchoolId);
    } 
    if (this.currentUser.adminUser[0].user_Type.type_order == '1' && this.schoolVal.length == 0) {
      this.schoolVal = this.currentUser.activeSchools.filter(data => data.id === this.currentUser.currentSelSchoolId);    
    }
    this.publish_status = this.schoolVal[0].publish_status;

      this.getLeftNavigationMenus();
    }
  }

  getLeftNavigationMenus() {
    let params = {
      user_type_id: this.userType,
      user_id: this.userId,
      parent_id: 0
    };
    this.sub = this.authService.getLeftNavigationMenus(params).subscribe((navData: any) => {
      if(navData.status) {
        // console.log(navData)
        this.appitems = navData.data;
        let permissions = this.mapArr(this.appitems);
        localStorage.setItem('menus', JSON.stringify(permissions));
      }      
    });
  }

  mapArr(arrVal, newArr=[]) : any {
    arrVal.forEach(Array => {
        newArr.push(Array);
        if(Array.items){
          this.mapArr(Array.items, newArr);
        }
    })
    return newArr;
  }

  selectedItem($event) {
    // console.log($event);
    if($event.hasChilden){
      this.sideNavClose.next(false);
    } else {
      this.sideNavClose.next(true);
    }
  }

  selectedLabel($event) {
    //console.log($event);
    if($event.items.length){
      var closeStatus = true;
      $event.items.forEach((itemVal) => {
        if(itemVal.showStatus == 1){ //To check if any child item has show status enabled, then left navigation show remain open
          closeStatus = false;
        }
      })
      this.sideNavClose.next(closeStatus);
    } else {
      this.sideNavClose.next(true);
    }
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.sub.unsubscribe();
  }

}
