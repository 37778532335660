import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Http, Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class QuizSetService {

  private apiUrl = environment.apiUrl;

  constructor(private http: Http) { }

  getQuizSets(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_set_schools/getuserquizsets`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getDasboardQuizSets(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/quiz_sets/getDasboardQuizSets`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getAttemptedQuizSets(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getattemptedquizsets`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
  }

  getattemptquizsetsLimit(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getattemptquizsetsLimit`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
  }

  getQuizDataReport(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getQuizDataReport`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserQuizSetDetails(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getquizsetdetails`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getQuizSetDetails(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_sets/getquizsetdetails`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  createQuizSet(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/create`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  updateQuizSet(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/update`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getQuestion(question_id): Observable<any[]> {
    return this.http.get(`${this.apiUrl}/questions/getQuestion/${question_id}`)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserSetDetails(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getUserSetDetails`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getQuizSetsData(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getFilteredQuizData`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getAttemptedQuizSetData(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getquizsetsdata`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getTopicWiseResult(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_sets/getUserQuizTopicPerformance`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  private handleError(error: Response): Observable<any> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error.json());
    return Observable.throw(error.json().error || 'Server error');
  }
}
