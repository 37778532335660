import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ms-content-pdf',
  templateUrl: './ms-content-pdf.component.html',
  styleUrls: ['./ms-content-pdf.component.css']
})
export class MsContentPdfComponent implements OnInit {

  @Input() url;
  constructor(
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }
  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
     }

}
