import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
// export class WizenozeService {
//   private data = new Subject<any>();
//   public subscribeData = this.data.asObservable();

//   constructor() { }

//   emitdata(class_id: any){
//     this.data.next(class_id);
//   }
// }

export class WizenozeService {

  private data = new Subject<any>();
  public subscribeData = this.data.asObservable();
  
  private apiUrl = environment.apiUrl;

  constructor(private http: Http) { }

  emitdata(class_id: any){
    this.data.next(class_id);
  }

  getWizenozeboard(): Observable<any[]> {
    console.log("gthrfhgfgb");
    return this.http.get(`${this.apiUrl}/wizenoze/getWizenozeboard`)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getWizenozeClass(board_id: number): Observable<any[]> {
    return this.http.get(`${this.apiUrl}/wizenoze/getWizenozeClass/${board_id}`)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getWizenozeCurriculum(class_id: number, user_id: number): Observable<any[]> {
    return this.http.get(`${this.apiUrl}/wizenoze/getWizenozeCurriculum/${class_id}/${user_id}`)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getWizenozeTitle(topic_id: number): Observable<any[]> {
    return this.http.get(`${this.apiUrl}/wizenoze/getWizenozeTitle/${topic_id}`)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  private handleError(error: Response): Observable<any> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error.json());
    return Observable.throw(error.json().error || 'Server error');
  }
}
