import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.css']
})
export class OtpVerifyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
