import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-error',
  template: `
    <h3>
      Error in updating subscription. Please contact administrator.

      <span 
      [ngClass]="['cursorPointer','textVal']"
      (click)="redirectDash()">Click</span> here to redirect to dashboard.
    </h3>
  `,
  styles: [`
  .cursorPointer {
    cursor: Pointer
  }
  .textVal {
    color: blue
  }
`]
})
export class PaymentErrorComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  redirectDash() {
    this.router.navigate(['/dashboard']);
  }
}
