import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-check-user-token',
  template: ``,
  styles: []
})
export class CheckUserTokenComponent implements OnInit {

  currentUser: any;
  user_id: number;
  token: string;
  tokenStatus  =false;
  constructor(private authService: AuthenticationService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.chkUserToken();
  }

  chkUserToken(){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser){  
      this.user_id = this.currentUser.id;
      this.token = this.currentUser.token; 
     
    }
    let paramsVal = {user_id: this.user_id, token: this.token};
    return this.authService.chkUserToken(paramsVal).subscribe(
      data =>{
        this.tokenStatus = data.status;
        if(!this.tokenStatus){
          this.openSnackBar("You've been logged in different Computer", 'Close');
          this.authService.logout();
        }
      }
    )
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}
