import {
  Component,
  OnInit,
  OnChanges,
  ViewEncapsulation,
  DoCheck
} from '@angular/core'

import { RootComponent } from './root/root.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  productName = environment.productName;
  title = this.productName;
}
