import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/_services/backend.service';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { UserService } from '../user.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/_services';
import { float } from 'html2canvas/dist/types/css/property-descriptors/float';
import { NumericInputDirective } from 'src/app/shared/numeric-input.directive';

@Component({
  selector: 'app-user-payment',
  templateUrl: './user-payment.component.html',
  styleUrls: ['./user-payment.component.css']
})
export class UserPaymentComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  userSubForm: FormGroup;
  displayMessage: { [key: string]: string } = {};
  boards: any;
  errorMessage: any;
  schoolSubForm: FormGroup;
  currentUser: any;
  classList: any;
  schoolList: any;
  loginUser_id: number;
  user_id: any;
  type_order: any;
  schoolArr = [];
  classArr = [];
  customFilters: any;
  userData: any;
  frequencyTypeList: any;
  totalAmount: number;
  userSubId: number;
  userSubscription: any;
  school_id: number;
  class_id: number;
  isAmountEditable: boolean = true;
  isCheckoutAmountEditable: boolean = true;

  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  countryList: any;
  userEmail: any;
  checkout_amount: number;
  subscriptionId: number;
  tax_amount: number;
  subId: any;
  setReadOnly: boolean = false;
  loginUser_name: any;
  received_by_Name: any;
  selectedCountryId: any;
  showGSTField: boolean = false;
  statesList: any;
  statesListIndonesia: any;

  constructor(
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private backendService: BackendService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef
  ) {
    this.validationMessages = {
      school_id: {
        required: 'school is required.'
      },
      class_id: {
        required: 'Class is required.'
      },
      freq_type_id: {
        required: 'Frequency Type is required.'
      },
      amount: {
        required: 'Amount is required.'
      },
      total_amount: {
        required: 'Total amount is required.'
      },
      status: {
        required: 'Status is required.'
      },
      firstName: {
        required: 'First name is required.'
      },
      address: {
        required: 'Address is required.'
      },
      city: {
        required: 'City is required.'
      },
      country_id: {
        required: 'Country is required.'
      },
      state: {
        required: 'State is required.'
      },
      pincode: {
        required: 'Pincode is required.'
      },
      contactNumber: {
        required: 'Contact number is required.'
      },
      email: {
        required: 'Email is required.'
      },
      payment_type: {
        required: 'Payment Method is required.'
      },
      received_by: {
        required: 'Received By is required.'
      },
      no_of_installment: {
        required: 'Number of EMI is required.'
      },
      emi_amount: {
        required: 'EMI amount is required.'
      },
      down_payment: {
        required: 'Down payment is required.'
      },
      cheque_receipt_no: {
        required: 'Cheque number is required.'
      },
      receipt_no: {
        required: 'Receipt number is required.'
      },
      down_payment_type: {
        required: 'Down payment method is required.'
      },
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    Observable.merge(this.userSubForm.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.userSubForm);
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.user_id = this.currentUser.id;
    if (this.currentUser) {
      // console.log(this.currentUser)
      // this.user_id = this.currentUser.id;
      // this.type_order = this.currentUser.user_Type.type_order;
      // this.countryList = this.currentUser.user_countries;

      this.loginUser_id = this.currentUser.adminUser[0].id;
      this.loginUser_name = this.currentUser.adminUser[0].firstName + ' ' + this.currentUser.adminUser[0].lastName;
      //this.received_by_Name = this.loginUser_name + ' - '+this.loginUser_id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
      // this.countryList = this.currentUser.user_countries;
    }


    this.userSubForm = this.fb.group({
      user_id: ['', [Validators.required]],
      school_id: ['', [Validators.required]],
      class_id: ['', [Validators.required]],
      freq_type_id: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      discount_amt: ['', ''],
      total_amount: ['', [Validators.required]],
      checkout_amount: ['', [Validators.required]],
      payment_type: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', ''],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country_id: ['', [Validators.required]],
      state: ['', Validators.required],
      pincode: ['', [Validators.required]],
      contactNumber: ['', [Validators.required]],
      email: ['', [Validators.required]],
      received_by: ['', ''],
      emi_amount: ['', ''],
      no_of_installment: ['', ''],
      down_payment: ['', ''],
      subscription_id: ['', ''],
      gst_amount: ['', ''],
      gst_no: ['', ''],
      state_id: ['', ''],
      cheque_receipt_no: ['', ''],
      down_payment_type: ['', '']

    });

    this.userSubForm.get('checkout_amount').valueChanges.subscribe(value => {
      if (value == 0) {
        this.userSubForm.controls.payment_type.setValue('demo');
        this.setReadOnly = true;

      } else {
        this.setReadOnly = false;
        this.userSubForm.controls.payment_type.reset();
      }
    });


    this.userSubForm.get('country_id').valueChanges.subscribe(value => {
      if (value === 1) {
        this.selectedCountryId = value;

      } else {
        this.selectedCountryId = value;
      }
    });


    this.userSubForm.get('gst_no').valueChanges.subscribe(value => {
      if (value !== undefined && value !== null && value !== '') {
        this.showGSTField = true;

      }
    });



    this.userSubForm.get('payment_type').valueChanges.subscribe(value => {
      if (value === 'cod') {
        this.userSubForm.controls.received_by.setValue(this.loginUser_name);
        this.userSubForm.get('emi_amount').reset();
        this.userSubForm.get('no_of_installment').reset();
        this.userSubForm.get('down_payment').reset();
      } else {
        this.userSubForm.get('received_by').reset();
      }
    });



    this.userSubForm.get('payment_type').valueChanges.subscribe(value => {
      if (value === 'cod') {
        this.userSubForm.get('received_by').setValidators([Validators.required]);
        this.userSubForm.get('cheque_receipt_no').setValidators([Validators.required]);
        this.userSubForm.get('emi_amount').clearValidators();
        this.userSubForm.get('no_of_installment').clearValidators();
        this.userSubForm.get('down_payment').clearValidators();
        this.userSubForm.get('down_payment_type').clearValidators();
        this.userSubForm.get('cheque_receipt_no').reset();
        this.userSubForm.get('emi_amount').reset();
        this.userSubForm.get('no_of_installment').reset();
        this.userSubForm.get('down_payment').reset();
        this.userSubForm.get('down_payment_type').reset();
      } else if (value === 'bajajfin' || value === 'tcpl') {
        this.userSubForm.get('emi_amount').setValidators([Validators.required]);
        this.userSubForm.get('no_of_installment').setValidators([Validators.required]);
        this.userSubForm.get('down_payment').setValidators([Validators.required]);
        this.userSubForm.get('down_payment_type').setValidators([Validators.required]);
        this.userSubForm.get('received_by').clearValidators();
        this.userSubForm.get('cheque_receipt_no').clearValidators();
        this.userSubForm.get('received_by').reset();
        this.userSubForm.get('cheque_receipt_no').reset();
        this.userSubForm.get('emi_amount').reset();
        this.userSubForm.get('no_of_installment').reset();
        this.userSubForm.get('down_payment').reset();
        this.userSubForm.get('down_payment_type').reset();
      } else if (value === 'razorpay') {
        this.userSubForm.get('received_by').clearValidators();
        this.userSubForm.get('cheque_receipt_no').clearValidators();
        this.userSubForm.get('emi_amount').clearValidators();
        this.userSubForm.get('no_of_installment').clearValidators();
        this.userSubForm.get('down_payment').clearValidators();
        this.userSubForm.get('down_payment_type').clearValidators();
        this.userSubForm.get('received_by').reset();
        this.userSubForm.get('cheque_receipt_no').reset();
        this.userSubForm.get('emi_amount').reset();
        this.userSubForm.get('no_of_installment').reset();
        this.userSubForm.get('down_payment').reset();
        this.userSubForm.get('down_payment_type').reset();
      } else if (value === 'demo') {
        this.userSubForm.get('received_by').clearValidators();
        this.userSubForm.get('cheque_receipt_no').clearValidators();
        this.userSubForm.get('emi_amount').clearValidators();
        this.userSubForm.get('no_of_installment').clearValidators();
        this.userSubForm.get('down_payment').clearValidators();
        this.userSubForm.get('down_payment_type').clearValidators();
        this.userSubForm.get('received_by').reset();
        this.userSubForm.get('cheque_receipt_no').reset();
        this.userSubForm.get('emi_amount').reset();
        this.userSubForm.get('no_of_installment').reset();
        this.userSubForm.get('down_payment').reset();
        this.userSubForm.get('down_payment_type').reset();
      } else if (value === 'cheque') {
        this.userSubForm.get('cheque_receipt_no').setValidators([Validators.required]);
        this.userSubForm.get('received_by').clearValidators();
        this.userSubForm.get('emi_amount').clearValidators();
        this.userSubForm.get('no_of_installment').clearValidators();
        this.userSubForm.get('down_payment').clearValidators();
        this.userSubForm.get('down_payment_type').clearValidators();
        this.userSubForm.get('received_by').reset();
        this.userSubForm.get('cheque_receipt_no').reset();
        this.userSubForm.get('emi_amount').reset();
        this.userSubForm.get('no_of_installment').reset();
        this.userSubForm.get('down_payment').reset();
        this.userSubForm.get('down_payment_type').reset();
      } else if (value === 'doku'){
        this.userSubForm.get('received_by').clearValidators();
        this.userSubForm.get('cheque_receipt_no').clearValidators();
        this.userSubForm.get('emi_amount').clearValidators();
        this.userSubForm.get('no_of_installment').clearValidators();
        this.userSubForm.get('down_payment').clearValidators();
        this.userSubForm.get('down_payment_type').clearValidators();
        this.userSubForm.get('received_by').reset();
        this.userSubForm.get('cheque_receipt_no').reset();
        this.userSubForm.get('emi_amount').reset();
        this.userSubForm.get('no_of_installment').reset();
        this.userSubForm.get('down_payment').reset();
        this.userSubForm.get('down_payment_type').reset();
      }

      this.userSubForm.get('cheque_receipt_no').updateValueAndValidity();
      this.userSubForm.get('received_by').updateValueAndValidity();
      this.userSubForm.get('emi_amount').updateValueAndValidity();
      this.userSubForm.get('no_of_installment').updateValueAndValidity();
      this.userSubForm.get('down_payment').updateValueAndValidity();
      this.userSubForm.get('down_payment_type').updateValueAndValidity()
    });

    this.userService.getCountries().subscribe((data: any) => {
      this.countryList = data.data;
    })

    let indObj = {
      country_id : 1
    }
    this.userService.getStates(indObj).subscribe((data: any) => {
      this.statesList = data;
    })


    let indoObj = {
      country_id : 2
    }
    this.userService.getStates(indoObj).subscribe((data: any) => {
      this.statesListIndonesia = data;
    })

    this.backendService.getActiveSchools({}).subscribe((data: any) => {
      this.schoolList = data.data;
      //this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
    });

    this.subscriptionService.getAllFrequencyType({}).subscribe((data: any) => {
      // console.log(data);
      this.frequencyTypeList = data.data;
    })

    let classObj = {
      board_id: 1
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });

    this.route.params.subscribe(params => {
      this.user_id = params['user_id'];

    });

    this.subscriptionService.getSubPaymentFromData().subscribe(formData => {
      // console.log(formData)
      if (formData.user_id !== undefined) {
        this.getuserSub();
        this.userSubForm.setValue(formData);
        if (this.userSubForm.value.down_payment_type === 'cod') {
          this.userSubForm.get('received_by').setValidators([Validators.required]);
          this.userSubForm.get('cheque_receipt_no').setValidators([Validators.required]);
        } else if (this.userSubForm.value.down_payment_type === 'cheque') {
          this.userSubForm.get('cheque_receipt_no').setValidators([Validators.required]);
        }

      } else {
        this.getUser();
        this.userSubForm.controls.user_id.setValue(this.user_id);
      }
    });

  }



  savecontent() {
    let obj = {};
    // console.log(this.subId)
    if (this.userSubForm.value.discount_amt == undefined || this.userSubForm.value.discount_amt == '' || this.userSubForm.value.discount_amt == null) {
      this.userSubForm.controls.discount_amt.setValue(0)
    }
    obj = Object.assign({}, this.userSubForm.value);
    // console.log(obj)
    this.subscriptionService.setSubPaymentFromData(obj);
    this.router.navigate(['/userPaymentConfirm', `${this.user_id}`]);

  }

  getUser() {
    this.userService.getUser(this.user_id).subscribe((user: any) => {
      // console.log(user)
      this.userEmail = user.email
      this.userSubForm.patchValue({
        country_id: user.user_countries[0].country_id,
      })
      //console.log(this.user_name)
      this.getUserDetails();
    },
      (error: any) => this.errorMessage = error as any
    );
  }

  getUserDetails() {
    let obj = {
      username: this.userEmail
    }
    this.authenticationService.getUserDetails(obj).subscribe(
      data => {
        // console.log(data)
        // console.log(data.data.contactNumber)
        this.class_id = data.data.userData[0].class_id;
        this.school_id = data.data.userData[0].school_id;
        this.userSubForm.patchValue({
          class_id: data.data.userData[0].class_id,
          school_id: data.data.userData[0].school_id,
          firstName: data.data.firstName,
          lastName: data.data.lastName,
          email: data.data.email,
          contactNumber: this.extractNumbersFromString(data.data.contactNumber),
          //country_id: data.countryDetail.id,
          city: data.data.userData[0].city

        })
        this.getuserSub();
      });
  }

  extractNumbersFromString(input: string): string {
    return input.replace(/[^\d+]/g, '');
  }

  getuserSub() {
    let obj = {
      //user_id: this.user_id,
      school_id: [this.school_id],
      class_id: [this.class_id],
    }
    // console.log(obj);
    this.subscriptionService.getUserSubById(obj).subscribe((data: any) => {
      //  console.log(data);
      this.userSubscription = data.data;
      if (!this.userSubscription) {
        //  console.log(obj)
        this.subscriptionService.getSubscriptionMSList(obj).subscribe((data: any) => {
          // console.log(data);
          this.userSubscription = data.data;
          if (this.userSubscription) {
            // this.userSubId = this.userSubscription.id;

          }
        })
      } else {
        this.userSubId = this.userSubscription.id;

      }
    })
  }

  onSchoolChange(school_id: number) {
    this.errorMessage =  '';
    this.userSubForm.get('freq_type_id').reset();
    this.userSubForm.get('amount').reset();
    this.userSubForm.get('discount_amt').reset();
    this.userSubForm.get('total_amount').reset();
    this.userSubForm.get('checkout_amount').reset();
    this.userSubForm.get('gst_amount').reset();


    // console.log(school_id)
    //console.log(this.userSubForm.controls.class_id.value)
    const schoolId = school_id;
    const classId = this.userSubForm.controls.class_id.value
    let obj = {
      //user_id: this.user_id,
      school_id: [schoolId],
      class_id: [classId],
    }
    //console.log(obj);
    this.subscriptionService.getUserSubById(obj).subscribe((data: any) => {
      //  console.log(data);
      this.userSubscription = data.data;
      if (!this.userSubscription) {
        //  console.log(obj)
        this.subscriptionService.getSubscriptionMSList(obj).subscribe((data: any) => {
          // console.log(data);
          this.userSubscription = data.data;
          if (this.userSubscription) {
            // this.userSubId = this.userSubscription.id;

          }
        })
      } else {
        this.userSubId = this.userSubscription.id;

      }
    })

  }

  onClassChange(class_id: number) {
    this.errorMessage =  '';
    this.userSubForm.get('freq_type_id').reset();
    this.userSubForm.get('amount').reset();
    this.userSubForm.get('discount_amt').reset();
    this.userSubForm.get('total_amount').reset();
    this.userSubForm.get('checkout_amount').reset();
    this.userSubForm.get('gst_amount').reset();


    // console.log(class_id)
    //console.log(this.userSubForm.controls.school_id.value)
    const schoolId = this.userSubForm.controls.school_id.value;
    const classId = class_id
    let obj = {
      //user_id: this.user_id,
      school_id: [schoolId],
      class_id: [classId],
    }
    //console.log(obj);
    this.subscriptionService.getUserSubById(obj).subscribe((data: any) => {
      //  console.log(data);
      this.userSubscription = data.data;
      if (!this.userSubscription) {
        //  console.log(obj)
        this.subscriptionService.getSubscriptionMSList(obj).subscribe((data: any) => {
          // console.log(data);
          this.userSubscription = data.data;
          if (this.userSubscription) {
            // this.userSubId = this.userSubscription.id;

          }
        })
      } else {
        this.userSubId = this.userSubscription.id;

      }
    })

  }



  onFreqTypeChange(freq_type_id: number) {
    //console.log('Selected value:', freq_type_id);
    this.userSubForm.get('amount').reset();
    this.userSubForm.get('discount_amt').reset();
    this.userSubForm.get('total_amount').reset();
    this.userSubForm.get('checkout_amount').reset();
    this.userSubForm.get('gst_amount').reset();

    const selectedData = this.userSubscription.find(item => item.freq_type_id === freq_type_id);


    if (selectedData) {
      const { amount, discount_amt, total_amount, id } = selectedData;
      //Patch the form values  with the matched values in data
      // console.log('Amount:', amount);
      // console.log('Discount Amount:', discount_amt);
      // console.log('Total Amount:', total_amount);
      // console.log('School_sub_id:', id);
      this.subId = id;
      this.userSubForm.controls.subscription_id.setValue(this.subId);
      let amt_var = amount - discount_amt;
      if(this.selectedCountryId == 2){
        this.tax_amount = 0;
      } else {
        this.tax_amount = ((amt_var * 0.18));
      }
      
      //let tax_amt = this.tax_amount;
      this.checkout_amount = total_amount + this.tax_amount;
      // console.log(this.checkout_amount)
      if (this.checkout_amount) {
        this.userSubForm.patchValue({
          gst_amount: this.tax_amount.toFixed(2),
          amount: parseFloat(amount).toFixed(2),
          discount_amt: parseFloat(discount_amt).toFixed(2),
          total_amount: parseFloat(total_amount).toFixed(2),
          checkout_amount: this.checkout_amount.toFixed(2)
        })
        this.errorMessage = '';
      }
    } else {
      // Handle case when freq_type_id is not found in data array
      this.errorMessage = "School Subcription not avaibale for this frequency type";
      // console.log('Invalid freq_type_id');
    }
  }


  onDownPaymentChange(value: string) {
    //console.log(value);
    if (this.userSubForm.value.payment_type === 'bajajfin' || this.userSubForm.value.payment_type === 'tcpl') {
      if (value === 'cod') {
        //console.log('cash');
        this.userSubForm.get('received_by').setValidators([Validators.required]);
        this.userSubForm.get('cheque_receipt_no').setValidators([Validators.required]);
        this.userSubForm.controls.received_by.setValue(this.loginUser_name);
        this.userSubForm.get('cheque_receipt_no').reset();
      } else if (value === 'cheque') {
        //console.log('cheque');
        this.userSubForm.get('cheque_receipt_no').setValidators([Validators.required]);
        this.userSubForm.get('received_by').clearValidators();
        this.userSubForm.get('cheque_receipt_no').reset();
        this.userSubForm.get('received_by').reset();
      } else {
        this.userSubForm.get('received_by').clearValidators();
        this.userSubForm.get('cheque_receipt_no').clearValidators();
        this.userSubForm.get('received_by').reset();
        this.userSubForm.get('cheque_receipt_no').reset();

      }
      this.userSubForm.get('cheque_receipt_no').updateValueAndValidity();
      this.userSubForm.get('received_by').updateValueAndValidity();

    }


  }


  // amountFunc(amount) {
  //   this.userSubForm.get('discount_amt').reset();
  //   this.userSubForm.get('total_amount').reset();
  //   this.userSubForm.get('checkout_amount').reset();
  //   this.userSubForm.get('gst_amount').reset();
  //   // console.log(amount);


  //   let amtVal = amount - this.userSubForm.value.discount_amt
  //   let tax_amt = ((amtVal * 0.18));
  //   this.tax_amount = tax_amt;
  //   this.checkout_amount = parseFloat(amount) + this.tax_amount;
  //   //(this.tax_amount)
  //   this.userSubForm.patchValue({
  //     gst_amount: this.tax_amount,
  //     total_amount: amount,
  //     checkout_amount: this.checkout_amount,
  //     discount_amt: 0
  //   });
  // }

  // discountAmtFunc(discountAmount) {

  //   if (discountAmount > this.userSubForm.value.amount) {
  //     // console.log("here is discount cancel");
  //     this.errorMessage = "Discount amount should not be more than amount";
  //     this.userSubForm.controls.discount_amt.setValue(undefined);
  //     this.userSubForm.controls.checkout_amount.setValue(undefined);
  //     this.tax_amount = ((this.userSubForm.value.amount * 0.18));
  //     this.checkout_amount = this.userSubForm.value.amount + this.tax_amount;
  //     this.userSubForm.patchValue({
  //       total_amount: this.userSubForm.value.amount,
  //       checkout_amount: this.checkout_amount,
  //       gst_amount: this.tax_amount,
  //       discount_amt: 0
  //     });
  //     return;
  //   } else {
  //     this.errorMessage = "";
  //   }
  //   this.totalAmount = this.userSubForm.value.amount - discountAmount;
  //   //this.checkout_amount = ((this.totalAmount  * 18) / 100 )+ this.totalAmount;
  //   this.tax_amount = ((this.totalAmount * 0.18));
  //   //console.log(this.tax_amount)
  //   this.checkout_amount = this.totalAmount + this.tax_amount;
  //   // console.log(this.checkout_amount)
  //   this.userSubForm.patchValue({
  //     total_amount: this.totalAmount,
  //     checkout_amount: this.checkout_amount,
  //     gst_amount: this.tax_amount
  //   })
  // }

  // updateCheckoutAmount() {
  //   this.userSubForm.get('discount_amt').reset();
  //   this.userSubForm.get('total_amount').reset();
  //   this.userSubForm.get('checkout_amount').reset();
  //   this.userSubForm.get('gst_amount').reset();

  //   const amount = this.userSubForm.get('amount').value;
  //   const discountAmt = this.userSubForm.get('discount_amt').value;

  //   // Check if both fields are empty
  //   if (!amount && !discountAmt) {
  //     this.isAmountEditable = true;
  //     this.isCheckoutAmountEditable = true;
  //     return;
  //   }

  //   let amtVal = amount - this.userSubForm.value.discount_amt
  //   let tax_amt = ((amtVal * 0.18));
  //   this.tax_amount = tax_amt;
  //   this.checkout_amount = parseFloat(amount) + this.tax_amount;
  //   //(this.tax_amount)
  //   this.userSubForm.patchValue({
  //     gst_amount: this.tax_amount,
  //     total_amount: amount,
  //     checkout_amount: this.checkout_amount,
  //     discount_amt: 0
  //   });
  //   // this.isAmountEditable = true;
  //   // this.isCheckoutAmountEditable = false;

  //   // // Calculate and update the checkout amount based on the amount and discount amount fields
  //   // const checkoutAmount =  ''// Perform your calculation here
  //   // this.userSubForm.get('checkout_amount').setValue(checkoutAmount);
  // }

  updateCheckoutAmount() {
    //this.userSubForm.get('discount_amt').reset();
    this.userSubForm.get('total_amount').reset();
    this.userSubForm.get('checkout_amount').reset();
    this.userSubForm.get('gst_amount').reset();

    const amount = this.userSubForm.get('amount').value;
    const discountAmt = this.userSubForm.get('discount_amt').value;

    this.isAmountEditable = true;
    this.isCheckoutAmountEditable = false;

    if (amount == 0 || amount == undefined || amount == null || amount == '') {
      // console.log('kd')
      this.userSubForm.patchValue({
        gst_amount: 0,
        total_amount: 0,
        discount_amt: 0,
        checkout_amount: 0
      });
    } else {
      if (discountAmt > amount || amount === 0) {
        this.errorMessage = "Discount amount should not be more than amount";
        this.userSubForm.controls.discount_amt.setValue(undefined);
        this.userSubForm.controls.checkout_amount.setValue(undefined);
       // const taxAmount = amount * 0.18;
       let taxAmount = 0;
       if(this.selectedCountryId == 2){
         taxAmount = 0;
         //console.log('case Indo')
       } else {
         //console.log('case Ind')
         taxAmount = ((amount * 0.18));
       }
        const checkoutAmount = amount + taxAmount;
        this.userSubForm.patchValue({
          total_amount: parseFloat(amount).toFixed(2),
          checkout_amount: parseFloat(checkoutAmount).toFixed(2),
          gst_amount: taxAmount.toFixed(2),
          discount_amt: 0
        });
        return;
      } else {
        this.errorMessage = "";
      }
    
      const totalAmount = amount - discountAmt;
      let taxAmount = 0;
      if(this.selectedCountryId == 2){
        taxAmount = 0;
        //console.log('case Indo')
      } else {
        //console.log('case Ind')
        taxAmount = ((totalAmount * 0.18));
      }
      //const taxAmount = totalAmount * 0.18;
      const checkoutAmount = totalAmount + taxAmount;

      this.userSubForm.patchValue({
        total_amount: totalAmount.toFixed(2),
        checkout_amount: checkoutAmount.toFixed(2),
        gst_amount: taxAmount.toFixed(2)
      });

    }


  }


  updateAmount() {

    this.isAmountEditable = false;
    this.isCheckoutAmountEditable = true;
    
    //this.userSubForm.get('discount_amt').reset();
    this.userSubForm.get('total_amount').reset();
    this.userSubForm.get('amount').reset();
    this.userSubForm.get('gst_amount').reset();

    const checkoutAmount = this.userSubForm.get('checkout_amount').value;
    let amount = this.userSubForm.get('amount').value;
    let discountAmt = parseFloat(this.userSubForm.get('discount_amt').value)
    //console.log('Check Out Value', checkoutAmount);
     // console.log(discountAmt)
    if( discountAmt != null ){
      this.errorMessage = "";
    }

    // Check if both fields are empty
    // console.log('Before condition:', checkoutAmount);
    if (checkoutAmount == 0 || checkoutAmount == undefined || checkoutAmount == null || checkoutAmount == '') {
      // console.log('kd')
      this.userSubForm.patchValue({
        gst_amount: 0,
        total_amount: 0,
        amount: 0,
        discount_amt: 0
      });
    } else {
      // Calculate and update the amount based on the checkout amount field
      //const gstAmount = (checkoutAmount - ((checkoutAmount * 100) / 118));
      let gstAmount = 0;
      if(this.selectedCountryId == 2){
        gstAmount = 0;
      } else{
        gstAmount = (checkoutAmount - ((checkoutAmount * 100) / 118));
      }
      if(Number.isNaN(discountAmt)){
        discountAmt = 0
      }
      const totalAmount = checkoutAmount - gstAmount;
      const finalAmount = totalAmount +discountAmt;
      this.userSubForm.patchValue({
        gst_amount: gstAmount.toFixed(2),
        total_amount: totalAmount.toFixed(2),
        amount: finalAmount.toFixed(2),
        // discount_amt: discountAmt
      });

    }

  }

  onDiscountAmountChange(value: number) {
    if (this.isAmountEditable) {
      this.updateCheckoutAmount();
      // console.log('amount first')
    } else if (this.isCheckoutAmountEditable) {
      this.updateAmount();
      //console.log('checkout first')
    }

    // Additional code for handling the discount amount input value
  }

  onCountrySelectionChange() {
    const countryId = this.userSubForm.get('country_id').value;
    this.userSubForm.controls.state.reset();
    this.userSubForm.controls.pincode.reset();
    this.userSubForm.controls.state_id.reset();
    this.userSubForm.controls.gst_no.reset();
    this.selectedCountryId = countryId;
    this.cdr.detectChanges();
    //console.log(this.selectedCountryId)
    this.userSubForm.controls.payment_type.reset();
    this.resetValidations();
    this.updateGst();
  }

  updateGst(){
    if(this.selectedCountryId == 1){
      let gstTax = parseFloat(this.userSubForm.controls.total_amount.value) * 0.18;
      let checkoutAmount = parseFloat(this.userSubForm.controls.total_amount.value) + gstTax;
      this.userSubForm.patchValue({
        gst_amount: gstTax.toFixed(2),
        checkout_amount: checkoutAmount.toFixed(2)
      })
      
    }else{
      let gstTax = 0;
      let checkoutAmount = parseFloat(this.userSubForm.controls.total_amount.value) + gstTax;
      this.userSubForm.patchValue({
        gst_amount: gstTax.toFixed(2),
        checkout_amount: checkoutAmount.toFixed(2)
      })
    }
  }

  resetValidations() {
    this.userSubForm.get('received_by').clearValidators();
    this.userSubForm.get('cheque_receipt_no').clearValidators();
    this.userSubForm.get('emi_amount').clearValidators();
    this.userSubForm.get('no_of_installment').clearValidators();
    this.userSubForm.get('down_payment').clearValidators();
    this.userSubForm.get('down_payment_type').clearValidators();
    this.userSubForm.get('received_by').reset();
    this.userSubForm.get('cheque_receipt_no').reset();
    this.userSubForm.get('emi_amount').reset();
    this.userSubForm.get('no_of_installment').reset();
    this.userSubForm.get('down_payment').reset();
    this.userSubForm.get('down_payment_type').reset();
  }

  toggleGSTField(event: Event) {
    event.preventDefault();
    this.userSubForm.controls.gst_no.reset();
    this.showGSTField = !this.showGSTField;
  }

  onStateSelectionChange(selectedState: any) {
    //console.log(selectedState)
    const state = this.statesList.find(item => item.state_name === selectedState)
    //console.log(state)
    this.userSubForm.patchValue({ state_id: state.id });
    //console.log(this.userSubForm.value.state_id)
  }

  onStateSelectionChangeIndo(selectedState: any) {
    //console.log(selectedState)
    const state = this.statesListIndonesia.find(item => item.state_name === selectedState)
    //console.log(state)
    this.userSubForm.patchValue({ state_id: state.id });
    //console.log(this.userSubForm.value.state_id)
  }

  getPincodeMaxLength(): number {
    const countryId = this.userSubForm.get('country_id').value;
    return countryId === 1 ? 6 : countryId === 2 ? 5 : null;
  }


  resetSubFormData() {
    const obj = {};
    this.subscriptionService.setSubPaymentFromData(obj);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}
