import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-trial-success',
  template: `
    <h3>
      Subscription updated successfully. You are being redirected to login page. Please re-login.
    </h3>
  `,
  styles: []
})
export class PaymentTrialSuccessComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    setTimeout(() => {
      localStorage.removeItem('currentUser');
      this.router.navigate(['login']);
    }, 5000);
  }

}
