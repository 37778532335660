import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import {  ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from 'src/app/user/user.service';
import { environment } from 'src/environments/environment';
import { DashboardService } from '../dashboard.service';
import { MatTableExporterModule } from 'mat-table-exporter';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dash-usage-report',
  templateUrl: './dash-usage-report.component.html',
  styleUrls: ['./dash-usage-report.component.css']
})
export class DashUsageReportComponent implements OnInit {
  @Input() filterData;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['userName', 'email', 'contact_no', 'user_last_login', 'total_quiz_attempt', 'total_attempted', 'total_correct', 'total_incorrect', 'total_skipped', 'percentage', 'total_usage', 'lesson_usage'];

  dataSource: any;
  school_id: any;
  class_id: any;
  section_id: any;
  limit = 12;
  offset = 0;
  customFilters = {}
  studentList: any;
  section_name: string;
  student_id: any;
  currentUser: any;
  userId: any;
  type_order: any;
  schoolList: any;
  classList: any;
  schoolArr = [];
  classArr = [];
  sectionArr: [];
  classObjArr = [];
  sectionList: any;
  searchFilter: any = {
    phone: '',
    email: '',
    school_id: [],
    class_id: [],
    section_id: [],
    user_type_id: ''
  };
  suggestEmail: any;
  errorMessage: any;
  loading: boolean = false;
  liveUrl = environment.liveUrl;
  customFilter: any;
  userTypes: void;
  disableDownload: boolean = true;
  subscription: Subscription;
  school_disable : boolean = false
  class_disable: boolean = false;
  section_disable: boolean = false;
  dash: any;
  user_type_disable: boolean = false;
  
  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

   //.log(this.currentUser)
    //console.log(this.filterData)
    
    if (this.currentUser) {
      this.userId = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    }
    if (this.type_order == 1 || this.type_order == 2) {
      this.userService.getActiveSchools(this.userId).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.getUserClasses();
      });
    } else {
      let obj = { user_id: this.userId }
      this.userService.userSchools(obj).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.classArr = data.userClass;
        this.sectionArr = data.userSection;
        this.getUserClasses();
      });
    }

    this.userService.getUserTypes(this.userId).subscribe((data) => {
      this.userTypes = data;
     // console.log(this.userTypes)
    });

    this.route.queryParams.subscribe((params: any) => {
     // console.log(params)
      if (params.searchFilter) {
        this.loading = true;
        this.customFilter = JSON.parse(params.searchFilter);
       // console.log(this.customFilter)
        if (this.customFilter.school_id && this.customFilter.class_id == '') {
          this.searchFilter.school_id = this.customFilter.school_id;
          this.getUserList();
        }
        if (this.customFilter.class_id != '' && this.customFilter.school_id != '') {
          this.searchFilter.school_id = this.customFilter.school_id;
          this.changeClass(this.customFilter.class_id, () => {
            this.searchFilter.class_id = this.customFilter.class_id;
           // console.log(this.searchFilter.class_id)
            this.searchFilter.section_id = this.customFilter.section_id;
            this.searchFilter.user_type_id = this.customFilter.user_type_id,
            this.searchFilter.email = this.customFilter.email,
            this.searchFilter.phone = this.customFilter.phone
            this.getUserList();
          });
        } else {
          this.customFilters = {};
        }
      } else {
        this.customFilters = {};
      }
    })

    if(this.filterData){ 
      this.dash = this.filterData.dash;
      this.searchFilter.user_type_id = 8;
      this.user_type_disable = true;
      if(this.filterData.school_id && !this.filterData.class_id && !this.filterData.section_id){
        this.searchFilter.school_id = this.filterData.school_id;
        this.school_disable = true;
        this.loading = true;
        this.getUserList();
      }
      if(this.filterData.class_id && this.filterData.school_id){ 
        this.searchFilter.school_id = this.filterData.school_id;
        this.school_disable = true;
        this.searchFilter.class_id = this.filterData.class_id;
        this.changeClass(this.searchFilter.class_id, '')
        this.class_disable = true; 
        this.loading = true;
        this.getUserList();
      }
      if(this.filterData.section_id && this.filterData.school_id && this.filterData.class_id){
        this.searchFilter.school_id = this.filterData.school_id;
        this.school_disable = true;
        this.searchFilter.class_id = this.filterData.class_id;
        this.changeClass(this.searchFilter.class_id, '')
        this.class_disable = true; 
        this.searchFilter.section_id = this.filterData.section_id;
        this.section_disable = true; 
        this.loading = true;
        this.getUserList();
      }
    } else {
      this.dash = 'main_dash'
    }
  }

  getUserList(){
    let obj = {
      school_id: this.searchFilter.school_id,
      class_id: this.searchFilter.class_id,
      section_id: this.searchFilter.section_id,
      email: this.searchFilter.email,
      mobile: this.searchFilter.phone,
      user_type_id: this.searchFilter.user_type_id
    }
   // console.log(obj);
    // this.subscription = this.dashboardService.getAttemptListUser(obj).subscribe((data:any) => {
    // // console.log(data);
    //   let listStudent = data;
    //   if( data.status !== false){
    //     if(listStudent.length != 0){
    //       this.studentList = listStudent.filter(item => item !== null);
    //       this.dataSource = new MatTableDataSource(this.studentList);
    //       this.dataSource.paginator = this.paginator;
    //       this.disableDownload = false;
    //       //this.dataSource.sort = this.sort;
          
    //     } else {
    //       this.disableDownload = true;
    //       this.dataSource = new MatTableDataSource();
    //       this.errorMessage = data.message;
    //       this.openSnackBar('User not found. Search again with different criteria !!!', 'Close');
    //       if (this.subscription) {
    //         this.subscription.unsubscribe();
    //       }
    //     }   
    //   } else {
    //     this.errorMessage = data.message;
    //     this.disableDownload = true;
    //     this.openSnackBar(this.errorMessage, 'Close');
    //     this.dataSource = new MatTableDataSource();
    //     this.dataSource.paginator = this.paginator;
    //     if (this.subscription) {
    //       this.subscription.unsubscribe();
    //     }
    //   }
    //   this.loading = false;
    // },
    // (error) => {
    //   this.loading = false;
    //   this.openSnackBar('An error occurred.', 'Close');
    //   console.error('An error occurred:', error);
    //   if (this.subscription) {
    //     this.subscription.unsubscribe();
    //   }
    // }
    // )
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2500,
    });
  }

  openStudentLessonList(school_id, class_id, section_id, user_id) {
    this.student_id = user_id;
    //console.log(this.student_id)

    // this.router.navigate(['/post_student_lesson_list', `${school_id}`, `${class_id}`, `${section_id}`, `${this.student_id}`], 
    // {queryParams: {usage_report: 'Yes', dash: this.dash, searchFilter: JSON.stringify(this.searchFilter)}});
    this.router.navigate(['/post_student_lesson_list', school_id, class_id, section_id, this.student_id], {
      queryParams: { usage_report: 'Yes', dash: this.dash, searchFilter: JSON.stringify(this.searchFilter) }
    });
  }

  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.userService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  // changeClass(class_id) {
  //   this.searchFilter.section_id = '';
  //   let classObj = { class_id: [class_id], section_id: this.sectionArr }
  //   //console.log(classObj)
  //   this.userService.getClassSections(classObj).subscribe((result: any) => {
  //     this.sectionList = result.data;
  //     //console.log(this.sectionList)
  //   })
  // }

  changeClass(class_id, callback) {
   // console.log(class_id)
    this.searchFilter.section_id = '';
    let classObj = { class_id: [class_id], section_id: this.sectionArr };
    this.userService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
      if (callback) {
        callback();
      }
    });
  }
  

  searchUserData(filters: any) {
    this.searchFilter = filters;
    this.disableDownload = true;
   // console.log(this.searchFilter)
   this.loading = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.getUserList();
  }

  reset() {
    this.loading = false;
    this.searchFilter = {};
    this.disableDownload = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.sectionList = [];
    if(this.filterData){
      this.searchFilter.school_id = this.filterData.school_id;
      this.searchFilter.class_id = this.filterData.class_id;
      this.changeClass(this.searchFilter.class_id, '');
      this.searchFilter.section_id = this.filterData.section_id;
      this.searchFilter.user_type_id = 8;
      this.searchFilter.email = '';
      this.searchFilter.phone = '';
    } else {
      this.searchFilter = {};
    }
    //this.getUserList();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  emailFilter(filterValue: string) {
    //this.errorMessage = {};
    if (filterValue.length > 4) {
      let obj = { email: filterValue};
      //console.log(obj)
      this.userService.getSuggestData(obj).subscribe((emailData: any) => {
       // console.log(emailData);
        this.suggestEmail = emailData.data;
        // console.log(filterValue.indexOf('@'),  this.suggestData.length);
        // if (filterValue.indexOf('@') > 0 && this.suggestEmail.length <= 0) {
        //   this.errorMessage.message = "User is not registered"
        // }
      });
    }
  }
  
  fillData(suggest) {
    this.searchFilter.email = suggest.email;
  } 

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

}
