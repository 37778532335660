import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-payment-config-list',
  templateUrl: './payment-config-list.component.html',
  styleUrls: ['./payment-config-list.component.css']
})
export class PaymentConfigListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'User Subscriptions';
  displayedColumns: string[] = ['school', 'class', 'frequencyType', 'amount', 'totalAmount', 'id'];
  errorMessage: any;
  currentUser: any;
  
  loginUserId: number;
  type_order: any;
  user_id: any;
  subscribeData: any;
  dataSource: any = null;
  totalSub: number;
  limit = 10;
  offset = 0;
  name: string;
  user_class = [];

  constructor(
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private backendService: BackendService) {
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.user_id = this.currentUser.id;
    if (this.currentUser) {
      this.loginUserId = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    }

    this.route.params.subscribe(params => {
      this.user_id = params['user_id'];
    });
    
    this.getUserData();
  }

  getUserData(){
    this.backendService.getUser(this.user_id).subscribe((data: any)=> {
      console.log(data);
      this.name = data.firstName + ' ' + data.lastName;
      this.user_class = data.user_class;
      this.user_class = this.user_class.filter((element, index, self) =>
        index === self.findIndex((t) => (
          t.school_id === element.school_id && t.class_id === element.class_id
        ))
      );
      console.log(this.user_class);
      this.dataSource = new MatTableDataSource(this.user_class);
      this.dataSource.paginator = this.paginator;
    })
  }

  reset() {
    this.getUserData();
  }

}
