import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/_services/backend.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-student-report',
  template: `
  <div id="pdfTable" #pdfTable>
  <p>
    <label>Name: </label> <strong> {{name}}</strong>
  </p>
  <p>
    <label>Contact Number: </label> {{contactNumber}}
  </p>
  <p>
    <label>Email: </label> {{email}}
  </p>
  <p>Average Percentage: {{avgPercent}}</p>
  <p>Report Date: {{from_date}} to {{to_date}}</p>
  <div align="right"> 
    <button mat-raised-button class=" mat-primary" 
      (click)="exportAsPDF()">Download As PDF</button>
  </div>
  <table class="report-table" border="1" cellpadding="8">
    <tr>
      <th>Quiz Name</th>
      <th>Total Question</th>
      <th>Total  Correct</th>
      <th>Total  Incorrect</th>
      <th>Total Skipped</th>
      <th>Score</th>
    </tr>
    <tr *ngFor="let quizData of quizSetData">
      <td>{{quizData.attempted_quiz_sets.set_name}}</td>
      <td>{{quizData.total_attempted}}</td>
      <td>{{quizData.total_correct}}</td>
      <td>{{quizData.total_incorrect}}</td>
      <td>{{quizData.total_skipped}}</td>
      <td>{{quizData.percent}}%</td>
    </tr>
  </table>
</div>
`,
  styles: [
    ` table.report-table {   border-spacing: 30px;   border: 1px solid #000000;}

      th td{
        border: 1px solid #000000;
        width: 50px;
      }
    `
  ]
})
export class StudentMsReportComponent implements OnInit {

  percent: any;
  quizSetData: any;
  avgPercent: any;
  totalQuizAttempt: number;
  name: string;
  contactNumber: string;
  email: string;
  report_type: string;
  currentUser: any;
  from_date;
  to_date;

  constructor(
    private route: ActivatedRoute,
    private backendService: BackendService
  ) { }

  ngOnInit() {    
    // this.route.queryParams.subscribe((params: any) => {
    //   this.report_type = params.report_type;
    //   this.from_date = params.from_date;
    //   this.to_date = params.to_date;
    // });
    this.route.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length > 0) {
        Object.keys(params).forEach((pKey) => {
          params = pKey;
        });
        params = atob(decodeURIComponent(params));
        params = params.split('&');
        this.from_date = (params ? params[2].split('=') : '');
        this.from_date = (this.from_date ? this.from_date[1] : '');
        this.to_date = (params ? params[3].split('=') : '');
        this.to_date = (this.to_date ? this.to_date[1] : '');
        this.report_type = (params ? params[4].split('=') : '');
        this.report_type = (this.report_type ? this.report_type[1] : '');
      }
    });
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(this.report_type);
    if(this.report_type == 'posttest'){
      this.getStudentPostReport();
    }else{
      this.getStudentReport();
    }
    
  }

  getStudentReport() {
    let obj = {
      student_id: this.currentUser.id,
      // student_id: 42074949,
      from_date: this.from_date,
      to_date: this.to_date
    }
    this.backendService.getStudentReport(obj).subscribe((data: any) => {
      this.quizSetData = data.attemptedQuiz;
      this.totalQuizAttempt = this.quizSetData.length;
      // console.log(data);
      this.name = data.userData.firstName + ' ' + data.userData.lastName;
      this.contactNumber = data.userData.contactNumber;
      this.email = data.userData.email;
      this.getAvgPercentage();
    })
  }

  getStudentPostReport() {
    // console.log(this.currentUser);
    let obj = {
      student_id: this.currentUser.id,
      // student_id: 42074949,
      from_date: this.from_date,
      to_date: this.to_date
    }
    // console.log(obj);
    this.backendService.getStudentPostReport(obj).subscribe((data: any) => {
      // console.log(data);
      this.quizSetData = data.attemptedQuiz;
      this.totalQuizAttempt = this.quizSetData.length;
      this.name = data.userData.firstName + ' ' + data.userData.lastName;
      this.contactNumber = data.userData.contactNumber;
      this.email = data.userData.email;
      this.getAvgPercentage();
    })
  }

  getAvgPercentage() {
    let totalPercent = 0;
    let count = 0;
    let tempQuizData = [];
    this.quizSetData.forEach(td => {
      this.percent = parseFloat(((td.total_correct / td.total_attempted) * 100).toFixed(2));
      td.percent = this.percent;
      totalPercent = this.percent + totalPercent;
      count++;
      tempQuizData.push(td);
    });
    this.quizSetData = tempQuizData;
    this.avgPercent = (totalPercent / count).toFixed(2);
    // console.log(this.avgPercent);
    if (isNaN(this.avgPercent)) { this.avgPercent = 0; }
    // this.exportAsPDF();
  }

  // exportAsPDF() {
  //   setTimeout(() => {
  //     let data = document.getElementById('pdfTable');
  //     html2canvas(data).then(canvas => {
  //       const contentDataURL = canvas.toDataURL('image/png')
  //       let pdf = new jspdf.jsPDF(); //Generates PDF in landscape mode
  //       pdf.addImage(contentDataURL, 5, 0, 0, 0);
  //       pdf.save('studentReport.pdf');
  //     });
  //   }, 0);
  // }

  exportAsPDF() {
    let data = document.getElementById('pdfTable');
    console.log(data);
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jspdf.jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      }
      doc.save( 'file.pdf');
    });
  }

}