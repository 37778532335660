import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatDialogConfig, MatDialog, MatSnackBar } from '@angular/material';
import { DashboardService } from '../../dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { QuizInfoDashboardComponent } from '../quiz-info-dashboard/quiz-info-dashboard.component';
import { TopicQuesUserListComponent } from '../../dash-quizset-post-test/post-lesson-dash/post-lesson-topic-ques/topic-ques-user-list/topic-ques-user-list.component';
import { error } from 'console';

@Component({
  selector: 'app-quiz-dashboard',
  templateUrl: './quiz-dashboard.component.html',
  styleUrls: ['./quiz-dashboard.component.css']
})
export class QuizDashboardComponent implements OnInit {

  @Input() inputData;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;
  title = 'Quiz Wise Performance'
  currentUser: any;
  type_order: String;
  school_id: number;
  class_id: number;
  section_id: number;
  limit = 12;
  offset = 0;
  quizSetData: any;
  total_count: number;
  customFilters = {}
  quizSetLength: number;
  heading = false;
  section_name: string;
  rowHeight = '650px';
  chartTile = '';
  main_dashboard = false;
  userId: number;
  tempObj = {};
  school_name: any;
  class_name: any;
  componentToDisplay: string;
  quiz_dash: boolean = false;
  currentDate = new Date();

  constructor(private dashboardService: DashboardService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    public dialog: MatDialog) { }

  ngOnInit() {

    let mainDash = JSON.parse(localStorage.getItem('maind'));

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.currentUser.id;

    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
      }
    );

    this.route.queryParams.subscribe((paramsData: any) => {

      if (paramsData.quiz_dash == 'Yes') {
        //console.log(paramsData)
        this.quiz_dash = true;
      }

    })

    this.route.queryParamMap.subscribe(params => {
      const listView = params.get('listView');
      if (listView === 'Yes') {
        this.componentToDisplay = 'user-list-dashboard';
        this.title = 'Student Performance List';
      }
    });

    this.route.queryParamMap.subscribe(params => {
      const studentDash = params.get('studentDash');
      if (studentDash === 'Yes') {
        this.componentToDisplay = 'user-dashboard';
        this.title = 'Student Wise Performance';
      }
    });


    this.breadcrumbs();

    if (mainDash == 1) {
      this.main_dashboard = true;
    }
    // this.route.queryParams.subscribe(
    //   params => {
    //     if(params.main_dashboard==1){
    //       // console.log("params",params.main_dashboard)
    //       this.main_dashboard = true;
    //     }
    //   }
    // );

    this.section_name = this.inputData.section_name;
    this.customFilters = {
      user_id: this.currentUser.adminUser[0].id,
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      chart_type: 'quizDash',
      limit: this.limit,
      offset: this.offset
    }


    this.getQuizSets();

  }


  getQuizSets() {
    this.dashboardService.getDasboardQuizSets(this.customFilters).subscribe((quizSets: any) => {
      this.quizSetData = quizSets.data;
      this.total_count = quizSets.total_count;
      // console.log(this.quizSetData);
      this.quizSetLength = this.total_count;
      if (this.quizSetLength > 2) {
        this.rowHeight = "480px";
        this.quizSetLength = 3;
        this.heading = true;
      }
    });
  }

  ngAfterViewInit() {

    merge(this.paginator.page)
      .pipe(
        tap(() => {
          this.offset = this.paginator.pageIndex * this.paginator.pageSize;
          this.limit = this.paginator.pageSize;
          this.ngOnInit();
        })
      )
      .subscribe();
  }

  openDialog(quiz_set_id) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      quiz_set_id: quiz_set_id
    };
    this.dialog.open(QuizInfoDashboardComponent, dialogConfig);
  }


  openQuizUserDialog(quiz_set_id) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      quiz_set_id: quiz_set_id
    };
    //this.dialog.open(QuizInfoDashboardComponent, dialogConfig);
    this.dialog.open(TopicQuesUserListComponent, { data: { paramsData: dialogConfig.data, main_quiz_dash: 'Yes' } });

  }


  breadcrumbs() {
    this.tempObj = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id
    }

    // get School Name
    this.dashboardService.getSchoolById(this.tempObj).subscribe((schoolData: any) => {
      //console.log(schoolData);
      this.school_name = schoolData.data.school_name;
    })

    // get Class Name
    // this.dashboardService.getClass(this.class_id).subscribe((classData: any) => {
    //   //console.log(classData);
    //   this.class_name = classData.data.class_name;
    // })
    this.class_name = 'Grade ' + this.class_id;
    // get Section Name
    this.dashboardService.getSection(this.tempObj).subscribe((sectionData: any) => {
      // console.log(sectionData);
      this.section_name = sectionData.data.class_section.section_name;
    })

  }

  isQuizSubmissionDateValid(quizSet: any): boolean {
    const quizSubmissionDate = new Date(quizSet.quiz_last_submition_date);
    return quizSubmissionDate < this.currentDate;
  }

  sendReport(quizSet: any) {
    //console.log(quizSet)
    let obj = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      quiz_set_id: quizSet.quiz_set_id
    }
    // console.log(obj)
    this.dashboardService.sendQuizReport(obj).subscribe((data: any) => {
      // console.log(data)
      if (data.status == true) {
        this.openSnackBar('Mail Sent Successfully.', 'Close');
      } else {
        this.openSnackBar('Mail not sent retry.', 'Close');
      }
    },
      (error) => {
        console.error('An error occurred:', error);
      })
  }

  backBtn() {
    // console.log("main",this.main_dashboard)
    // if(this.main_dashboard){
    //   this.router.navigate(['/dashboard']);
    // } else {
    this.router.navigate(['/section_dashboard', this.school_id, this.class_id]);
    //   }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }
}
