import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/subscription/subscription.service';

@Component({
  selector: 'app-pay-config-list-data',
  template: ` <div *ngIf="rowData"> {{rowData}} </div>`,
  styles: []
})
export class PayConfigListDataComponent implements OnInit {

  @Input() userData;
  rowData: any;
  userSubData: any;

  constructor(
    private subscriptionService: SubscriptionService) { }

  ngOnInit() {
    console.log(this.userData);
    this.subscriptionService.getUserSubById(this.userData).subscribe((data: any)=> {
       console.log(data);
      this.userSubData = data.data;
      if(this.userSubData){
        if(this.userData.show == 'frequency'){
          this.rowData = this.userSubData.subscription_freq_types.frequency;
        }else if( this.userData.show == 'amount'){
          this.rowData = this.userSubData.amount;
        }else if( this.userData.show == 'totalAmount'){
          this.rowData = this.userSubData.total_amount;
        }
      }else{
        this.subscriptionService.getSchoolSubForUser(this.userData).subscribe((data: any)=> {
          console.log(data);
          this.userSubData = data.data;
          if(this.userSubData){
            if(this.userData.show == 'frequency'){
              this.rowData = this.userSubData.subscription_freq_types.frequency;
            }else if( this.userData.show == 'amount'){
              this.rowData = this.userSubData.amount;
            }else if( this.userData.show == 'totalAmount'){
              this.rowData = this.userSubData.total_amount;
            }
          }else{
            this.subscriptionService.getSchoolSubOfOKS({}).subscribe((data: any)=> {
              console.log(data);
              this.userSubData = data.data[0];
              if(this.userSubData){
                if(this.userData.show == 'frequency'){
                  this.rowData = this.userSubData.subscription_freq_types.frequency;
                }else if( this.userData.show == 'amount'){
                  this.rowData = this.userSubData.amount;
                }else if( this.userData.show == 'totalAmount'){
                  this.rowData = this.userSubData.total_amount;
                }
              }
            })
          }
          
        })
      }
    })
  }

}
