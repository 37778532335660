import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatOption, MatSnackBar } from '@angular/material';
import { HomeworkService } from '../homework.service';
import { BackendService } from 'src/app/_services/backend.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-teacher-homework',
  templateUrl: './teacher-homework.component.html',
  styleUrls: ['./teacher-homework.component.css']
})
export class TeacherHomeworkComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('allSelectedSection') private allSelectedSection: MatOption;

  pageTitle = 'Teacher Homework';
  displayedColumns: string[] = ['title', 'classSec', 'assigned_on', 'due_on', 'status', 'id'];

  dataSource: any;
  currentUser: any;
  user_id: number;
  // type_order: number;
  schoolList: any;
  schoolArr = [];
  classArr = [];
  sectionArr = [];
  customFilters: any = null;
  totalHw: number;
  limit = 10;
  offset = 0;
  classList: any;
  sectionList: any;
  subjectList: any;
  classIdArr = [];
  user_subjects = [];
  subjectArr = [];
  schoolId: number;
  userData: any;

  searchFilter: any = {
    title: '',
    // date: '',
    start_date: '',
    end_date: '',
    school_id: [],
    class_id: [],
    section_id: [],
    subject_id: [],
    status: 0,
    limit: 10,
    offset: 0
  };

  constructor(
    private backendService: BackendService,
    private homeworkService: HomeworkService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = this.currentUser.id;
    // this.user_id = this.currentUser.adminUser[0].id;
    this.user_subjects = this.currentUser.user_assigned_subjects;
    //console.log('curuser',this.currentUser);
    this.schoolId = this.currentUser.currentSelSchoolId;
    this.user_subjects.forEach(sub => {
      this.subjectArr.push(sub.subject_id);
    });
    // this.type_order = this.currentUser.user_Type.type_order;

    this.userData = this.currentUser.userData;
    this.userData.forEach(userVal => {
      if (this.classArr.indexOf(userVal.class.id) == -1) {
        this.classArr.push(userVal.class.id);
      }
      if (this.sectionArr.indexOf(userVal.section_id) == -1) {
        this.sectionArr.push(userVal.section_id);
      }
    });
    this.schoolList = this.currentUser.userSchools
    for(let i=0; i<this.currentUser.userSchools.length; i++){
      this.schoolArr.push(this.currentUser.userSchools[i].id);
    }
    //this.schoolArr.push(this.schoolId)

    this.customFilters = {
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr,
      subject_id: this.subjectArr,
      limit: this.paginator.pageSize,
      offset: this.paginator.pageIndex * this.paginator.pageSize
    }
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });

    this.route.queryParams.subscribe((params: any) => {
      if (params.customFilters) {
        this.customFilters = JSON.parse(params.customFilters);
        this.searchFilter = this.customFilters;
        if (this.customFilters.class_id.length > 0) {
          this.changeClass(this.customFilters.class_id, 1);
        }
        this.offset = this.paginator.pageIndex = (this.customFilters.offset / this.customFilters.limit);
        this.limit = this.paginator.pageSize = this.customFilters.limit;
      }
    })
    this.getHomework();

    // let obj = { user_id: this.user_id }
    // this.backendService.userSchools(obj).subscribe((data: any) => {
    //   this.schoolList = data.data;
    //   this.schoolArr.push(this.schoolId)
    //   this.classArr = data.userClass;
    //   this.sectionArr = data.userSection;
    //   this.customFilters = {
    //     school_id: this.schoolArr,
    //     class_id: this.classArr,
    //     section_id: this.sectionArr,
    //     subject_id: this.subjectArr,
    //     limit: this.paginator.pageSize,
    //     offset: this.paginator.pageIndex * this.paginator.pageSize
    //   }
    //   let classObj = {
    //     board_id: 1,
    //     class_id: this.classArr
    //   }
    //   this.backendService.getClasses(classObj).subscribe((data: any) => {
    //     this.classList = data.data;
    //   });
    //   this.getHomework();
    //   this.route.queryParams.subscribe((params: any) => {
    //     if (params.customFilters) {
    //       this.customFilters = JSON.parse(params.customFilters);
    //       this.searchFilter = this.customFilters;
    //       if (this.customFilters.class_id.length > 0) {
    //         this.changeClass(this.customFilters.class_id, 1);
    //       }
    //       this.offset = this.paginator.pageIndex = (this.customFilters.offset / this.customFilters.limit);
    //       this.limit = this.paginator.pageSize = this.customFilters.limit;
    //       this.getHomework();
    //     }
    //   })
    // });

  }

  getHomework() {
    this.customFilters = {
      title: this.customFilters.title,
      // date: this.customFilters.date,
      start_date: this.customFilters.start_date,
      end_date: this.customFilters.end_date,
      school_id: this.customFilters.school_id,
      class_id: this.customFilters.class_id,
      section_id: this.customFilters.section_id,
      subject_id: this.customFilters.subject_id,
      created_by: this.user_id,
      status: this.customFilters.status,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
    //console.log(this.customFilters);
    this.homeworkService.getHWTeacher(this.customFilters).subscribe((data: any) => {
      //console.log(data);
      this.totalHw = data.totalHW;
      this.dataSource = new MatTableDataSource(data.data);
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    })
  }

  reset() {
    this.getHomework();
  }

  changeClass(class_id, defaultVal = 0) {
    this.classIdArr = class_id;
    let classObj = { class_id: this.classIdArr, section_id: this.sectionArr, subject_id: this.subjectArr }
    this.backendService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
    })
    this.backendService.getSubjects(classObj).subscribe((result: any) => {
      this.subjectList = result.data;
      // console.log(this.subjectList);
    });
  }

  selectAllSection() {
    let sectionVal = [0];
    if (this.allSelectedSection.selected) {

      this.sectionList.forEach(element => {
        sectionVal.push(element.id);
      });
      this.searchFilter.section_id = sectionVal;
    } else {
      this.searchFilter.section_id = []
    }
  }
  tosslePerSection() {
    if (this.allSelectedSection.selected) {
      this.allSelectedSection.deselect();
      // this.searchFilter.section_id = [];
      return false;
    }
    if (this.searchFilter.section_id.length === this.sectionList.length) {
      this.allSelectedSection.select();
    }
  }

  searchUserData(filters: any) {
    this.resetPageIndex();
    if (filters) {
      if (!filters.school_id || filters.school_id.length <= 0) {
        filters.school_id = this.schoolArr;
      }
      if (!filters.class_id || filters.class_id.length <= 0) {
        filters.class_id = this.classArr;
      }
      if (!filters.section_id || filters.section_id.length <= 0) {
        filters.section_id = this.sectionArr;
      }
      if (!filters.subject_id || filters.subject_id.length <= 0) {
        filters.subject_id = this.subjectArr;
      }
      filters.limit = this.paginator.pageSize;
      filters.offset = this.paginator.pageIndex * this.paginator.pageSize;
      this.customFilters = filters;
      this.getHomework();
    }
  }

  resetSearchFilter(searchPanel: any) {
    this.resetPageIndex();
    searchPanel.toggle();
    this.searchFilter = {};
    this.customFilters = {
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
    this.getHomework();
  }

  resetPageIndex() {
    this.paginator.pageIndex = 0;
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          // this.sortBy = data.action;
          // this.sortDirection = data.direction;
          this.getHomework();
        })
      ).subscribe();
  }


  deletehomework(homework_id) {
    var result = confirm("Are you sure, you want to delete the homework?");
    if (result) {
      let obj = { homework_id: homework_id }
      this.homeworkService.deleteHomework(obj).subscribe((result: any) => {

        if (result.status) {
          this.getHomework();
        } else {
          this.openSnackBar(result.message, 'Close');
          this.getHomework();
        }
      })
    }
  }

  activeHomework(homework_id) {
    var result = confirm("Are you sure, you want to send the homework?");
    if (result) {
      let obj = { homework_id: homework_id }
      this.homeworkService.activeHomework(obj).subscribe((result: any) => {
        if (result.status) {
          this.getHomework();
        }
      })
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}
