import { Component, OnInit, ViewChild } from '@angular/core';
import { SchoolService } from '.././school.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { CouponImportComponent } from '../coupon-import/coupon-import.component';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})

export class CouponComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'Coupons';
  displayedColumns: string[] = ['Sno', 'coupon', 'id'];
  searchFilter: any = {
    limit: 10,
    offset: 0
  }
  currentUser: any;
  customFilters: any = {};
  dataSource: any;
  limit = 10;
  offset = 0;
  total_coupons: number;
  schoolList: [];
  classList: [];
  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private schoolService: SchoolService,
    private _backendService: BackendService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.schoolService.getSuggestSchools({}).subscribe((schoolList: any) => {
      this.schoolList = schoolList.data;
    })
    let classObj = {
      board_id: 1
    }
    this._backendService.getClasses(classObj).subscribe((data: any) => {
      console.log(data);
      this.classList = data.data;
    });
    this.getCoupon();
  }

  getCoupon() {
    this.customFilters = {
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
    this.schoolService.getCouponsList(this.customFilters).subscribe((couponList: any) => {
      // console.log(couponList);
      this.total_coupons = couponList.total_coupons;
      this.dataSource = new MatTableDataSource(couponList.data);
    })
  }

  reset() {
    this.getCoupon();
  }

  searchUserData(filter){
    let obj = {
      school_id: filter.school_id
    }
    this.schoolService.getCouponsBySearch(obj).subscribe((data : any) => {
      console.log(data);
      this.total_coupons = data.total_coupons;
      this.dataSource = new MatTableDataSource(data.data);
      if(data.data.length == 0){
        this.openSnackBar('Coupon is not available for the selected school. ', 'Close');
      }

    })  
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 1500,
    });
  }

  resetPageIndex() {
    // reset the paginator
    this.paginator.pageIndex = 0;
  }
  resetSearchFilter(searchPanel: any) {
    this.resetPageIndex();
    searchPanel.toggle();
    this.searchFilter = {};
    this.getCoupon();
  }

  importDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(CouponImportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => this.getCoupon()
    );
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          // this.sortBy = data.action;
          // this.sortDirection = data.direction;
          this.getCoupon();
        })
      ).subscribe();
  }

}
