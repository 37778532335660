import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { NotfoundpageComponent } from './notfoundpage/notfoundpage.component';
import { RootComponent } from './root/root.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guard';
import { DownloadMsReportComponent } from './download-report/download-report.component';
import { StudentMsReportComponent } from './download-report/student-report/student-report.component';
import { SchoolMsReportComponent } from './download-report/school-report/school-report.component';
import { UserVerificationComponent } from './ms-user/user-verification/user-verification.component';
import { SignupComponent } from './ms-user/signup/signup.component';
import { TeacherSignupComponent } from './ms-user/signup/teacher-signup/teacher-signup.component';
import { OtpVerifyComponent } from './ms-user/signup/otp-verify/otp-verify.component';
import { ClassCodeComponent } from './ms-user/class-code/class-code.component';
import { ForgotPasswordComponent } from './ms-user/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './ms-user/forgot-password/update-password/update-password.component';
import { AutologinComponent } from './login/autologin.component';


export const ROUTES: Routes = [
  { path: '', component: RootComponent, canActivate: [AuthGuard] },
  { path: 'root', component: RootComponent },
  { path: 'login', component: RootComponent },
  { path: 'applogin', component: AutologinComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },


  // { path: 'login/:school_code', component: RootComponent },
  { path: 'download-report', component: DownloadMsReportComponent, canActivate:[AuthGuard]},
  { path: 'student-report', component: StudentMsReportComponent},
  { path: 'school-report', component: SchoolMsReportComponent},
  { path: 'user-verification', component: UserVerificationComponent },
  { path: 'signup', component: SignupComponent},
  { path: 'teacherSignUp', component: TeacherSignupComponent, canActivate:[AuthGuard]},
  { path: 'classCode', component: ClassCodeComponent},
  { path: 'updatePassword', component: UpdatePasswordComponent},
  { path: '**', component: NotfoundpageComponent, canActivate: [AuthGuard] }
];
