import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  questionChange = new Subject<any>();

  constructor() { }

  changeQuestion(questionData){
    // console.log(questionData);
    this.questionChange.next(questionData);
  }
  
}
