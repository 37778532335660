import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-latest-attendance',
  templateUrl: './latest-attendance.component.html',
  styleUrls: ['./latest-attendance.component.css']
})
export class LatestAttendanceComponent implements OnInit {

  currentUser: any;
  userId: number;
  userData: any;
  currentSelSchoolId: any;
  classIds = [];
  // classes = [];
  sectionIds = [];
  // sections = [];
  subjectIds = [];
  teacherAttendance = [];
  studentAttendance = [];
  userType: number;

  constructor(
    private backendService: BackendService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.userId = this.currentUser.id;
      this.currentSelSchoolId = this.currentUser.currentSelSchoolId;
      this.userData = this.currentUser.userData;
      this.userType = this.currentUser.userData[0].user_type;
  
      this.userData.forEach(userVal => {
        if(this.classIds.indexOf(userVal.class.id) == -1){
          this.classIds.push(userVal.class.id);
          // this.classes.push({id:userVal.class.id,className: userVal.class.class_name})
        }
        if (this.sectionIds.indexOf(userVal.section_id) == -1) {
          this.sectionIds.push(userVal.section_id);
          // this.sections.push(userVal.section);
        }
      });
    }

    if(this.userType == 0){
      this.getStudentAttendence();
    } else {
      this.getTeacherAttendence();
    }
  }

  getTeacherAttendence() {
    let obj = {
      school_id: [this.currentUser.currentSelSchoolId],
      class_id: this.classIds,
      section_id: this.sectionIds,
      subject_id: this.subjectIds,
      takenBy: this.currentUser.id,
      offset: 0,
      limit: 3
    }
    // console.log(this.customFilters);
    this.backendService.teacherAttendanceReport(obj).subscribe((result: any) => {
      // console.log(result);
      this.teacherAttendance = result.data;
    });
  }  

  getStudentAttendence() {
    let obj = {
      school_id: [this.currentSelSchoolId],
      class_id: this.classIds,
      section_id: this.sectionIds,
      studentUserId: this.userId,
      offset: 0,
      limit: 3
    }
    this.backendService.getStudentAttendance(obj).subscribe((result: any) => {
      // console.log(result);
      this.studentAttendance = result.data;
    });
  }

}
