import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaperSetService } from './paper-set.service';
import { MathModule } from '../math/math.module';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaperSetComponent } from './paper-set.component';
import { PaperSetEditComponent } from './paper-set-edit/paper-set-edit.component';
import { PaperSetPreviewComponent } from './paper-set-preview/paper-set-preview.component';
import { PaperSetSchoolComponent } from './paper-set-school/paper-set-school.component';

const routes = [
  { path: 'paperset', component: PaperSetComponent },
  {
    path: 'paperSetAdd',
    component: PaperSetEditComponent
  },
  {
    path: 'paperSetEdit/:id',
    component: PaperSetEditComponent
  },
];

@NgModule({
  declarations: [
    PaperSetComponent,
    PaperSetEditComponent,
    PaperSetPreviewComponent,
    PaperSetSchoolComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(routes),
    MathModule.forRoot()
  ],
  providers: [PaperSetService]
})

export class PaperSetModule { }
