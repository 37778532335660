import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RootComponent } from './root/root.component';
import { MaterialModule } from './shared/material.module';
import { Http, HttpModule, BrowserXhr } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';

import {
  AuthenticationService
} from './_services';

import { AppPreloader } from './app.preloader';

import '../styles/headings.css';
import '../styles/styles.scss';
import 'hammerjs';
import { LoginComponent } from './login/login.component';
import { AutologinComponent } from './login/autologin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RootModule } from './root/root.module';
// import { DashboardItemsComponent } from './dashboard/dashboard-items/dashboard-items.component';
import { OnlineClassComponent } from './online-class/online-class.component';
import { NotfoundpageComponent } from './notfoundpage/notfoundpage.component';
import { AuthGuard } from './_guard/auth.guard';
import { TeacherAttendanceComponent } from './attendance/teacher-attendance/teacher-attendance.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { StuAttendenceDetailComponent } from './attendance/stu-attendance-detail/stu-attendance-detail.component';
import { StudentAttendanceComponent } from './attendance/student-attendance/student-attendance.component';
import { AttendanceDetailComponent } from './attendance/attendance-detail/attendance-detail.component';
import { OnlineSubjectsComponent } from './online-class/online-subjects/online-subjects.component';
import { JoinClassComponent } from './online-class/online-subjects/join-class.component';
import { StudentQuizSetComponent } from './student-quiz-set/student-quiz-set.component';
import { QuizSetItemsComponent } from './student-quiz-set/quiz-set-items/quiz-set-items.component';
import { QuizListComponent } from './student-quiz-set/quiz-list/quiz-list.component';
import { AttemptedQuizListComponent } from './student-quiz-set/attempted-quiz-list/attempted-quiz-list.component';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app.routes';
import { StudentQuizChartComponent } from './student-quiz-set/student-quiz-chart/student-quiz-chart.component';
import { CheckUserTokenComponent } from './check-user-token/check-user-token.component';
import { StudentAttemptedQuizHistoryComponent } from './student-quiz-set/attempted-quiz-list/attempted-quiz-history/attempted-quiz-history.component';
import { StudentUserQuizQuesComponent } from './student-quiz-set/attempted-quiz-list/attempted-quiz-history/user-quiz-ques.component';
import { StudentUserQuizQuesAnsComponent } from './student-quiz-set/attempted-quiz-list/attempted-quiz-history/user-quiz-ques-ans.component';
import { MathModule } from './math/math.module';
import { StudentUserQuizAnsAttemptedComponent } from './student-quiz-set/attempted-quiz-list/attempted-quiz-history/user-quiz-ans-attempted.component';
import { AttemptQuizComponent } from './student-quiz-set/attempt-quiz/attempt-quiz.component';
import { QuizQuestionComponent } from './student-quiz-set/attempt-quiz/quiz-question.component';
import { QuizQuesAnsComponent } from './student-quiz-set/attempt-quiz/quiz-ques-ans.component';
import { ShowOnlineScheduleModule } from './show-online-schedule/show-online-schedule.module';
import { StartQuizComponent } from './student-quiz-set/attempt-quiz/start-quiz/start-quiz.component';
import { PollQueAnsComponent } from './poll-que-ans/poll-que-ans.component';
import { TimerComponent } from './student-quiz-set/attempt-quiz/start-quiz/timer.component';
import { HomeworkModule } from './homework/homework.module';
import { HttpClientModule } from '@angular/common/http';
import { PostTestModule } from './post-test/post-test.module';
import { MsUserComponent } from './ms-user/ms-user.component';
import { DownloadMsReportComponent } from './download-report/download-report.component';
import { StudentMsReportComponent } from './download-report/student-report/student-report.component';
import { SchoolMsReportComponent } from './download-report/school-report/school-report.component';
import { LeaderBoardComponent } from './student-quiz-set/leader-board/leader-board.component';
import { MsContentModule } from './ms-content/ms-content.module';
import { UserVerificationComponent } from './ms-user/user-verification/user-verification.component';
import { SignupComponent } from './ms-user/signup/signup.component';
import { TeacherSignupComponent } from './ms-user/signup/teacher-signup/teacher-signup.component';
import { OtpVerifyComponent } from './ms-user/signup/otp-verify/otp-verify.component';
import { UserInviteComponent } from './ms-user/user-invite/user-invite.component';

import { LeftNavigationComponent } from './root/left-navigation/left-navigation.component';
import { CommonNavigationComponent } from './root/left-navigation/common-navigation/common-navigation.component';

import { NgMaterialMultilevelMenuModule, MultilevelMenuService  } from 'ng-material-multilevel-menu';


import { QuizSetModule } from './quiz-set/quiz-set.module';
import { QuizHistoryComponent } from './quiz-history/quiz-history.component';
import { UserQuizHistoryComponent } from './quiz-history/user-quiz-history/user-quiz-history.component';
import { QuestionHistoryComponent } from './quiz-history/question-history/question-history.component';
import { ChartsModule } from 'ng2-charts';
import 'chart.piecelabel.js';
import { QuesDataComponent } from './quiz-history/question-history/ques-data.component';
import { QuesChartDataComponent } from './quiz-history/question-history/ques-chart-data.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { OnlineScheduleModule } from './online-schedule/online-schedule.module';
import { ContentModule } from './content/content.module';
import { AppContentModule } from './app-content/app-content.module';
import { ReportModule } from './report/report.module';
import { NotificationModule } from './notification/notification.module';
import { QuestionModule } from './question/question.module';
import { SchoolModule } from './school/school.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { UserQuizQuesComponent } from './quiz-history/user-quiz-history/user-quiz-ques.component';
import { UserQuizQuesAnsComponent } from './quiz-history/user-quiz-history/user-quiz-ques-ans.component';
import { UserQuizAnsAttemptedComponent } from './quiz-history/user-quiz-history/user-quiz-ans-attempted.component';
import { QuestionDataComponent } from './quiz-history/question-history/question-data/question-data.component';
import { ProfileMenuComponent } from './root/profile-menu/profile-menu.component';
import { AclModule } from './acl/acl.module';
import { UserModule } from './user/user.module';
import { SyllabusModule } from './syllabus/syllabus.module';
import { ClassCodeComponent } from './ms-user/class-code/class-code.component';
import { WizenozeModule } from './wizenoze-content/wizenoze.module';
import { AlertMessageComponent } from './login/alert-message.component';
import { SubscriptionModule } from './subscription/subscription.module';
import { PaymentsComponent } from './payments/payments.component';
import { PaperSetModule } from './paper-set/paper-set.module';
import { ForgotPasswordComponent } from './ms-user/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './ms-user/forgot-password/update-password/update-password.component';
import { UserDeleteComponent } from './user/user-delete/user-delete.component';
import { TopicWiseResultComponent } from './student-quiz-set/attempted-quiz-list/topic-wise-result/topic-wise-result.component';
import { QuizTopicQuesComponent } from './student-quiz-set/attempted-quiz-list/topic-wise-result/quiz-topic-ques/quiz-topic-ques.component';
// import { NgProgressHttpModule } from '@ngx-progressbar/http';

@NgModule({
  declarations: [
    AppComponent,
    RootComponent,
    LoginComponent,
    AutologinComponent,
    OnlineClassComponent,
    NotfoundpageComponent,
    TeacherAttendanceComponent,
    AttendanceComponent,
    StuAttendenceDetailComponent,
    StudentAttendanceComponent,
    AttendanceDetailComponent,
    OnlineSubjectsComponent,
    JoinClassComponent,
    StudentQuizSetComponent,
    QuizSetItemsComponent,
    QuizListComponent,
    AttemptedQuizListComponent,
    StudentQuizChartComponent,
    CheckUserTokenComponent,
    StudentAttemptedQuizHistoryComponent,
    StudentUserQuizQuesComponent,
    StudentUserQuizQuesAnsComponent,
    StudentUserQuizAnsAttemptedComponent,
    AttemptQuizComponent,
    QuizQuestionComponent,
    QuizQuesAnsComponent,
    StartQuizComponent,
    PollQueAnsComponent,
    TimerComponent,
    MsUserComponent,
    DownloadMsReportComponent,
    StudentMsReportComponent,
    SchoolMsReportComponent,
    LeaderBoardComponent,
    UserVerificationComponent,
    SignupComponent,
    TeacherSignupComponent,
    OtpVerifyComponent,
    UserInviteComponent,
    LeftNavigationComponent,
    CommonNavigationComponent,
    QuizHistoryComponent,
    UserQuizHistoryComponent,
    QuestionHistoryComponent,
    QuesDataComponent,
    QuesChartDataComponent,
    ForbiddenComponent,
    UserQuizQuesComponent,
    UserQuizQuesAnsComponent,
    UserQuizAnsAttemptedComponent,
    QuestionDataComponent,
    ProfileMenuComponent,
    ClassCodeComponent,
    AlertMessageComponent,
    PaymentsComponent,
    ForgotPasswordComponent,
    UpdatePasswordComponent,
    UserDeleteComponent,
    TopicWiseResultComponent,
    QuizTopicQuesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    RootModule,
    RouterModule.forRoot(ROUTES, { useHash: true, onSameUrlNavigation: 'reload' }),
    MathModule.forRoot(),
    ShowOnlineScheduleModule,
    HomeworkModule,
    HttpClientModule,
    PostTestModule,
    ContentModule,
    NgMaterialMultilevelMenuModule,
    MsContentModule,
    QuizSetModule,
    ChartsModule,
    OnlineScheduleModule,
    ContentModule,
    AppContentModule,
    ReportModule,
    NotificationModule,
    QuestionModule,
    SchoolModule,
    DashboardModule,
    AclModule,
    UserModule,
    SyllabusModule,
    WizenozeModule,
    SubscriptionModule,
    PaperSetModule,
    MatMenuModule
  ],
  providers: [
    AuthenticationService,
    AuthGuard,
    MultilevelMenuService 
  ],
  bootstrap: [AppComponent],
  exports: [MaterialModule, StudentUserQuizAnsAttemptedComponent],
  entryComponents: [
    OnlineSubjectsComponent,AlertMessageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
