import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { SubscriptionService } from 'src/app/subscription/subscription.service';

@Component({
  selector: 'app-user-invoice',
  templateUrl: './user-invoice.component.html',
  styleUrls: ['./user-invoice.component.css']
})
export class UserInvoiceComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  user_id: any;
  errorMessage: any;
  user_name: string;
  user_country_id: any;
  displayedColumns: string[] = ['name', 'email', 'class', 'payment_type', 'amount', 'invoice_date', 'id'];
  subscribeData: any;
  totalSub: any;
  dataSource: any;
  limit = 10;
  offset = 0;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private subscriptionService: SubscriptionService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.user_id = params['user_id'];
    });
    //console.log(this.user_id);
    this.getUser(this.user_id);
   // this.getUserInvoices(this.user_id)
  }

  getUser(user_id: string): void {
    this.userService.getUser(this.user_id).subscribe((user: any) => {
      
      this.user_name = user.firstName + ' ' + user.lastName;
      this.user_country_id = user.user_countries[0].country_id;
      localStorage.setItem('invoiceuser_country_id', this.user_country_id);
      //console.log('ci = '+this.user_country_id)
      if(this.user_country_id != undefined){
        this.getUserInvoices(this.user_id)
      }
    },
      (error: any) => this.errorMessage = error as any
    );
  }

  getUserInvoices(user_id) {
    let obj = {
      user_id: this.user_id
    }
    //console.log('cidf',localStorage.getItem('invoiceuser_country_id'))
    //console.log(this.user_country_id)
    let invoiceuser_country_id = parseInt(localStorage.getItem('invoiceuser_country_id'));
    if(this.user_country_id == 2) {
      //console.log('cid',invoiceuser_country_id)
      this.subscriptionService.getUserInvoicesIndonesia(obj).subscribe((data: any) => {
        //console.log(data);
        this.subscribeData = data;
        //this.totalSub = data.length;
        this.dataSource = new MatTableDataSource(this.subscribeData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
    } else {
      //console.log('cid1',invoiceuser_country_id)
      this.subscriptionService.getUserInvoices(obj).subscribe((data: any) => {
        //console.log(data);
        this.subscribeData = data;
        //this.totalSub = data.length;
        this.dataSource = new MatTableDataSource(this.subscribeData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      })
    }

  }

  reset() {
    this.getUser(this.user_id);
    //this.getUserInvoices(this.user_id);
  }

}
