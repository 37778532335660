import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MathContent } from 'src/app/math/math-content';


@Component({
  selector: 'app-attempted-tests-ques',
  template: `
  <!-- <p [innerHTML]='quesVal'> </p> -->
  <span style="padding-left:15px">
    <button mat-mini-fab [ngClass]="{'correct':attemptedAnsStatusCorrect,'incorrect':attemptedAnsStatusInCorrect}">
      <mat-icon>{{attemptedAnsStatusCorrect ? 'done' : 'clear'}}</mat-icon>
    </button>
  </span>
  <div [appMath]="mathMl"></div>
  <p *ngFor='let ansoption of question.attempted_quiz_question.ansoptions'>
    <app-attempted-tests-ques-ans [answerOption]='ansoption'></app-attempted-tests-ques-ans>
  </p>
  <div *ngIf="attemptAns.length<1; else attemptOptions">
    <b>Attempted Answer:</b> 
    <div>Skipped</div>
  </div>
  <ng-template #attemptOptions>
  <b>Attempted Answer:</b> 
    <p *ngFor='let attemptansoption of question.attempted_quiz_question.postAttemptQuizQues'>
      <app-attempted-tests-ans [answerOption]='attemptansoption.attempted_quiz_answer'></app-attempted-tests-ans>
    </p>
  </ng-template>
  
   <!-- <p [innerHTML]='attemptAns'> </p> -->

  <b>Right Answer:</b> <p *ngFor='let ansoption of question.attempted_quiz_question.rightansoption'>
  <app-attempted-tests-ques-ans [answerOption]='ansoption.right_ans'></app-attempted-tests-ques-ans>
  <app-attempted-test-sol [question]="question" ></app-attempted-test-sol>
</p>
<hr />
  `,
  styles: [
    `.correct {
      background-color: green;
    }
  .incorrect {
    background-color: red;
  }`
  ]
})
export class AttemptedTestsQuesComponent implements OnInit {

  @Input() question;
  private imgUrl = environment.imgUrl;

  quesVal: string;
  quesLevel: string;
  attemptAns: any;
  rightAns: string;
  mathMl: MathContent = {
    mathml: ''
  };
  attemptedAnsStatusCorrect: boolean;
  attemptedAnsStatusInCorrect: boolean;
  attemptAnsIds = [];
  rightAnsOptionIds = [];
  chkAttemptAns = [];
  

  constructor() { }

  ngOnInit() {
   // console.log(this.question)
  this.quesVal = this.question.attempted_quiz_question.question;
    let str1 = this.quesVal.indexOf('annotation');
    do{
      if(this.quesVal.indexOf('/annotation') > -1) {
        const str3 = '/annotation';
        const str2 = this.quesVal.indexOf('/annotation') + str3.length + 2;
        const res = this.quesVal.substring(str1, str2);
        this.quesVal = this.quesVal.replace(res, '');
      }
      str1 = this.quesVal.indexOf('annotation');
    }while (str1 !== -1);
    this.quesVal = this.quesVal.replace(/style=/g, 'style1=');
    this.quesVal = this.quesVal.replace(/&lt;b&gt;/g, '&lt;b1&gt;');
    this.quesVal = this.quesVal.replace(/&lt;\/b&gt;/g, '&lt;\/b1&gt;');
    this.quesVal = this.quesVal.replace(/&nbsp;&nbsp;/g, '');
    this.quesVal = this.quesVal.replace(/ &lt;t;/g, '');
    this.quesVal = this.quesVal.replace(/&lt;strong&gt;/g, '');
    this.quesVal = this.quesVal.replace(/&lt;\/strong&gt;/g, '');
    this.quesVal = this.toHTML(this.quesVal);    
    this.quesVal = this.quesVal.replace(/src="/g, `src="${this.imgUrl}/`);
    this.mathMl.mathml = this.quesVal;

    this.attemptAns = this.question.attempted_quiz_question.postAttemptQuizQues;
    // let attemptAnsIds = [];
    // let rightAnsOptionIds = [];
    let rightVal = [];

    if (this.attemptAns === undefined) {
      this.attemptAns = [];
    } else if(!this.attemptAns[0].attempted_quiz_answer){
      this.attemptAns = [];
    } else {
      this.attemptAns.forEach(attemptVal => {
        if(attemptVal.attempted_quiz_answer){
          this.attemptAnsIds.push(attemptVal.attempted_quiz_answer.id);
        }     
      });

      this.question.attempted_quiz_question.rightansoption.forEach(rightVal => {
        this.rightAnsOptionIds.push(rightVal.right_ans.id);
      });
      if(this.attemptAnsIds.length > 0){
        this.attemptAnsIds.forEach((rightAttemptVal,index)=>{
          if(!this.rightAnsOptionIds.includes(rightAttemptVal)){
            this.attemptAnsIds = [];
          }
        });
      } else {
        this.attemptAnsIds = []
      }
    }

    // if (this.question.attempted_quiz_answer.id === this.question.attempted_quiz_question.rightansoption[0].right_ans.id) {
    if(this.attemptAnsIds.length == this.rightAnsOptionIds.length && this.attemptAnsIds.length>0){
      this.attemptedAnsStatusCorrect = true;
      this.attemptedAnsStatusInCorrect = false;
    } else if(this.attemptAns.length>0){
      this.attemptedAnsStatusCorrect = false;
      this.attemptedAnsStatusInCorrect = true;
    } else {
      this.attemptedAnsStatusCorrect = false;
      this.attemptedAnsStatusInCorrect = false;
    }
    
  }

  toHTML(input): any {
    return new DOMParser().parseFromString(input, 'text/html').documentElement.textContent;
  }

}
