import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { merge, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-sub-payment-config',
  templateUrl: './sub-payment-config.component.html',
  styleUrls: ['./sub-payment-config.component.css']
})
export class SubPaymentConfigComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  userSubForm: FormGroup;
  displayMessage: { [key: string]: string } = {};
  pageTitle = "Subscriptions Payment Configration";
  boards: any;
  errorMessage: any;
  schoolSubForm: FormGroup;
  currentUser: any;
  classList: any;
  schoolList: any;
  loginUser_id: number;
  user_id: number;
  type_order: any;
  schoolArr = [];
  classArr = [];
  customFilters: any;
  userData: any;
  frequencyTypeList: any;
  totalAmount: number;
  userSubId: number;
  userSubscription: any;
  school_id: number;
  class_id: number;


  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;

  constructor(
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    // private userService: UserService,
    private backendService: BackendService
  ) {
    this.validationMessages = {
      school_id: {
        required: 'school is required.'
      },
      class_id: {
        required: 'Class is required.'
      },
      freq_type_id: {
        required: 'Frequency Type is required.'
      },
      amount: {
        required: 'Amount is required.'
      },
      total_amount: {
        required: 'Total amount is required.'
      },
      status: {
        required: 'Status is required.'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    Observable.merge(this.userSubForm.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.userSubForm);
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.user_id = this.currentUser.id;
    if (this.currentUser) {
      // this.user_id = this.currentUser.id;
      // this.type_order = this.currentUser.user_Type.type_order;
      // this.countryList = this.currentUser.user_countries;

      this.loginUser_id = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
      // this.countryList = this.currentUser.user_countries;
    }

    this.userSubForm = this.fb.group({
      user_id: ['', [Validators.required]],
      school_id: ['', [Validators.required]],
      class_id: ['', [Validators.required]],
      freq_type_id: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      discount_amt: ['', ''],
      total_amount: ['', [Validators.required]],
      status: ['', [Validators.required]]
    });

    this.subscriptionService.getAllFrequencyType({}).subscribe((data: any) => {
      console.log(data);
      this.frequencyTypeList = data.data;
    })

    let classObj = {
      board_id: 1
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });

    if (this.type_order == 1 || this.type_order == 2) {
      this.backendService.getActiveSchools({}).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
      });
    } else {
      this.userData = this.currentUser.userData;
      this.userData.forEach(userVal => {
        if (this.classArr.indexOf(userVal.class.id) == -1) {
          this.classArr.push(userVal.class.id);
          // this.classes.push({ id: userVal.class.id, className: userVal.class.class_name })
        }
      });
      this.currentUser.userSchools.forEach(school => {
        this.schoolArr.push(school.id);
      });

      let classObj = {
        board_id: 1,
        class_id: this.classArr
      }
      this.backendService.getClasses(classObj).subscribe((data: any) => {
        this.classList = data.data;
      });
    }

    this.route.params.subscribe(params => {
      this.user_id = params['user_id'];
      this.userSubForm.controls.user_id.setValue(this.user_id);
      this.school_id = params.school_id,
        this.class_id = params.class_id
      this.getuserSub();
    });
  }

  getuserSub() {
    let obj = {
      user_id: this.user_id,
      school_id: this.school_id,
      class_id: this.class_id,
    }
    console.log(obj);
    this.subscriptionService.getUserSubById(obj).subscribe((data: any) => {
      console.log(data);
      this.userSubscription = data.data;
      if (!this.userSubscription) {
        console.log(obj)
        this.subscriptionService.getSchoolSubForUser(obj).subscribe((data: any) => {
          console.log(data);
          this.userSubscription = data.data;
          if (this.userSubscription) {
            // this.userSubId = this.userSubscription.id;
            this.userSubForm.patchValue({
              school_id: this.userSubscription.school_id,
              class_id: this.userSubscription.class_id,
              freq_type_id: this.userSubscription.freq_type_id,
              amount: this.userSubscription.amount,
              discount_amt: this.userSubscription.discount_amt,
              total_amount: this.userSubscription.total_amount,
              status: this.userSubscription.status
            })
          }
        })
      } else {
        this.userSubId = this.userSubscription.id;
        this.userSubForm.patchValue({
          school_id: this.userSubscription.school_id,
          class_id: this.userSubscription.class_id,
          freq_type_id: this.userSubscription.freq_type_id,
          amount: this.userSubscription.amount,
          discount_amt: this.userSubscription.discount_amt,
          total_amount: this.userSubscription.total_amount,
          status: this.userSubscription.status
        })
      }
    })
  }

  amountFunc(amount) {
    console.log(amount);
    this.userSubForm.patchValue({
      total_amount: amount
    });
  }

  discountAmtFunc(discountAmount) {
    if (discountAmount > this.userSubForm.value.amount) {
      console.log("here is discount cancel");
      this.errorMessage = "Discount amount should not be more than amount";
      this.userSubForm.controls.discount_amt.setValue(undefined);
      this.userSubForm.patchValue({
        total_amount: this.userSubForm.value.amount
      });
      return;
    } else {
      this.errorMessage = "";
    }
    this.totalAmount = this.userSubForm.value.amount - discountAmount
    this.userSubForm.patchValue({
      total_amount: this.totalAmount
    })
  }

  savecontent() {
    // if(this.schoolSubForm.value.discount_amt && this.schoolSubForm.value.discount_amt > this.schoolSubForm.value.amount){
    //   console.log(this.schoolSubForm.value);
    //   console.log("here is discount cancel");
    //   this.errorMessage = "Discount amount should not be more than amount";
    //   return;
    // }else{
    //   this.errorMessage = "";
    // }
    let obj = {};
    if (this.userSubId) {
      obj = Object.assign({}, { userSubId: this.userSubId }, this.userSubForm.value);
    } else {
      obj = Object.assign({}, this.userSubForm.value);
    }
    console.log(obj);
    this.subscriptionService.createUserSubscription(obj).subscribe((data: any) => {
      console.log(data);
      if (data.status == true) {
        this.openSnackBar('Configure has been updated successfully. ', 'Close');
        this.router.navigate(['/PaymentConfigList', `${this.user_id}`], { queryParamsHandling: 'preserve' });
      } else {
        this.errorMessage = data.message;
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}
