import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { MatDialog } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { PostLessonStudentListComponent } from './post-lesson-student-list/post-lesson-student-list.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-post-quiz-lesson-dash',
  templateUrl: './post-quiz-lesson-dash.component.html',
  styleUrls: ['./post-quiz-lesson-dash.component.css']
})
export class PostQuizLessonDashComponent implements OnInit {

  @Input() inputData;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['lessonName'];
  detailDisplayedColumns =  ['email', 'phone', 'address']

  dataSource: any;
  school_id: number;
  section_id: number;
  class_id: number;
  classArr = [];
  lessons = [
    {
      "id": "1",
      "lessonName": "Maths Chapter 1"
    },
    {
      "id": "2",
      "lessonName": "Science Chapter 1",
    }
  ];
  subjects: [];
  currentSelSubjectId: any;
  liveUrl = environment.liveUrl;
  loading: boolean = false;
  
 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private dashboardService : DashboardService,
  ) { }

  ngOnInit() {

    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
      }
    );
    //console.log(this.class_id)
    this.getSubjects(this.class_id);
  }

  getSubjects(class_id){ 
    let classObj = { class_id: [class_id] }
    this.dashboardService.getSubjects(classObj).subscribe((result: any) => {
      //console.log(result)
      this.subjects = result.data;
      let subjectList = [];
      subjectList =  this.subjects;
      if(subjectList.length > 0){
        this.currentSelSubjectId = subjectList[0].id;
        this.changeSubject(this.currentSelSubjectId);
      };
    });
  }

  changeSubject(subject_id){
    this.loading = true;
    this.dataSource = new MatTableDataSource();
    const paramsVal = { subject_id: [subject_id] };
      this.dashboardService.getLessons(paramsVal).subscribe((result: any) => {
        //console.log(result)
        this.lessons = result.data;
        this.dataSource = new MatTableDataSource(this.lessons);
        this.loading = false;
      });
  }

  
  openLessonStudentList(lesson_id:number, subject_id:number){
    this.router.navigate(['/post_lesson_student_list',  `${this.school_id}`, `${this.class_id}`, `${this.section_id}`, `${subject_id}`,`${lesson_id}`]);
  }

}
