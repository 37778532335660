import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.css']
})
export class ProfileMenuComponent implements OnInit {

  currentUser: any;
  userName: string;
  email:string;
  user_id: string;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let firstName = this.currentUser.adminUser[0].firstName ? this.currentUser.adminUser[0].firstName : '';
    let lastName = this.currentUser.adminUser[0].lastName ? this.currentUser.adminUser[0].lastName : '';
    this.userName = firstName + ' ' + lastName;
    this.email = this.currentUser.adminUser[0].email;
    this.user_id = this.currentUser.adminUser[0].quiz_user_id;
  }

  logout(): void {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('menus');
    localStorage.removeItem('allowed');
    localStorage.removeItem('appitems');
    // this.close();
    this.router.navigate(['login']);
  }

  profile(){
    this.router.navigate(['ms-user']);
  }

  
  changePass(){
    this.user_id = btoa(this.user_id);
        this.router.navigate(['/updatePassword'],{
          queryParams: { 
            user_id: this.user_id,
            changePassword : 'Yes'
          }
        })
  }
}
