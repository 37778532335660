import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { QuizSetService } from 'src/app/_services/quiz-set.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validateVerticalPosition } from '@angular/cdk/overlay';
import { environment } from 'src/environments/environment';
import { MathContent } from 'src/app/math/math-content';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-attempt-quiz',
  templateUrl: './attempt-quiz.component.html',
  styleUrls: ['./attempt-quiz.component.css']
})
export class AttemptQuizComponent implements OnInit, OnDestroy {
  @ViewChild('newStepper') newStepper: ElementRef;
  pageTitle: string;
  sub: Subscription;
  quizSetId: number;
  currentUser: any;
  schoolId: number;
  quizData: any;
  quizForm: FormGroup;
  userId: number;
  quizQuestions: any;
  duration: number;
  quizRes: any;
  quesArr = [];
  userType: number;

  private imgUrl = environment.imgUrl;
  userQuestions: any = {
    quiz_questions: [
    ]
  };

  mathMl: MathContent = {
    mathml: ''
  };

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private quizSetService: QuizSetService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.schoolId = this.currentUser.currentSelSchoolId;
      this.userId = this.currentUser.id;
      this.userType = this.currentUser.userData[0].user_type;
    }
    this.sub = this.route.params.subscribe(
      params => {
        this.quizSetId = Number(atob(params['id']));
      }
    )
    if(!this.quizSetId){
      this.invalidQuiz();
    }
    if(this.userType == 1){
      this.openSnackBar("Only students can attempt test",'close');
      this.router.navigate(['/dashboard']);
    }else{
      this.quizForm = this.fb.group({
        user_id: ["",[Validators.required]],
        quiz_set_id: ['',[Validators.required]],
        total_attempted: ['',''],
        total_correct:['',''],
        questions: this.fb.array([])
      })
      this.getQuizSetDetails();
    }

  }

  getQuizSetDetails(){
    let paramsVal = {
      set_id: this.quizSetId,
      school_id: this.schoolId
    };
    return this.quizSetService.getQuizSetDetails(paramsVal)
      .subscribe((data)=> {        
        console.log(data);
        this.quizData = data;
        this.quizData = this.quizData.data;
        if(!this.quizData){
          this.invalidQuiz();
        } 
        this.pageTitle = this.quizData.set_name;
        this.quizQuestions = this.quizData.quiz_questions;
        this.duration = this.quizData.duration;
        this.saveQuiz();
        this.saveQuizVal();
      });
  }

  invalidQuiz(){
    this.router.navigate(['/quiz-list']);
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  saveQuiz(){
    this.userQuestions.set_name = this.pageTitle;
    this.userQuestions.quiz_set_id = this.quizSetId;
    this.userQuestions.quiz_questions = this.quizQuestions;
    this.userQuestions.myval = btoa(String(this.duration)+":"+"00");
    this.userQuestions.myquizval = btoa(String(this.duration));

    let keepFieldsArr = ['question_id','question','ansoptions','answer_id','attempt_status'];
    this.userQuestions.quiz_questions.map((value,key)=>{
      let answer_id = this.userQuestions.quiz_questions[key].quiz_question.answer_id;
      if(!answer_id){
        this.userQuestions.quiz_questions[key].quiz_question.answer_id = "0";
      }
      this.userQuestions.quiz_questions[key].quiz_question.rightAnsOptionCount = value.quiz_question.rightansoption.length;
       let objKeys = Object.keys(value.quiz_question);
       
       objKeys.forEach((keyVal)=>{
         if(!keepFieldsArr.includes(keyVal)){
          delete this.userQuestions.quiz_questions[key].quiz_question[keyVal];
         }
       })
      //  this.userQuestions.quiz_questions[key].quiz_question.question = value.quiz_question.question;
    });
    this.userQuestions = localStorage.setItem('userQuestions',JSON.stringify(this.userQuestions));
  }

  openQuiz(){
    this.router.navigate([`/start-quiz/${btoa(String(this.quizSetId))}/0`]);
  }

  saveQuizVal(){
    this.quizQuestions.map((value,key) => {
      let question_id = this.quizQuestions[key].question_id;
      this.quesArr[key] = {
        question_id : question_id, 
        answer_id : 0,
        attempt_status:0
      };
    });

    let quesParams = {
      user_id: this.userId,
      quiz_set_id: this.quizSetId,
      total_attempted: this.quizQuestions.length,
      total_correct: 0,
      total_incorrect: 0,
      total_skipped: this.quizQuestions.length,
      time_spent: 0,
      questions: this.quesArr
    }

    return this.quizSetService.createQuizSet(quesParams).subscribe(
      quizResult => {

        this.quizRes = quizResult;
        if(this.quizRes.status){
          this.openQuiz();
        } else {
          this.openSnackBar(this.quizRes.message,'close');
          this.invalidQuiz();
        }
      }
    );
  }

  openSnackBar(message: string, action: string, duration = 2500) {
    this.snackBar.open(message, action, {
        duration: duration,
    });
  }
  
}
