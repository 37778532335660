import { Component, OnInit } from '@angular/core';
import { QuizSetService } from 'src/app/_services/quiz-set.service';
import { ContentService } from 'src/app/content/content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quiz-topic-ques',
  templateUrl: './quiz-topic-ques.component.html',
  styleUrls: ['./quiz-topic-ques.component.css']
})
export class QuizTopicQuesComponent implements OnInit {

  pageTitle = 'Quiz Topic Result Details';
  currentUser
  quizParams: any;
  sub: Subscription;
  quizVal: any;
  quizData: any;
  attempted_set_id: number;
  setId: number;
  topic_id: number;
  tempObj = {};
  topicObj = {};
  set_name: string;
  topic_name: string;
  schoolId: number;
  classId: number;
  sectionId: number;
  backTo: string;

  constructor(
    private quizSetService: QuizSetService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.schoolId = this.currentUser.currentSelSchoolId;
      this.classId = this.currentUser.userData[0].class_id;
      this.sectionId = this.currentUser.userData[0].section_id;
    }
    this.sub = this.route.params.subscribe(
      params => {
        this.setId = +params['quiz_set_id'];
        this.attempted_set_id = +params['attempted_set_id'];
        this.topic_id = +params['topic_id'];
      }
    )

    this.route.queryParams.subscribe((paramsData: any) => {
        this.getQuizSetData(this.attempted_set_id, this.topic_id);
        if(paramsData.backTo){
          this.backTo = paramsData.backTo;
        }
    });

    this.breadcrumbs();
  }

  getQuizSetData(attempted_set_id, topic_id) {
    this.quizParams = { attempted_set_id: attempted_set_id, topic_id: topic_id };
    
    let finalTopicQuesData = [];
    this.quizSetService.getUserQuizSetDetails(this.quizParams)
      .subscribe(quizData => {
        this.quizVal = quizData;
        //console.log(this.quizVal.data.attempted_quiz_questions);
        for(let i=0; i<this.quizVal.data.attempted_quiz_questions.length; i++) {
          if(this.quizVal.data.attempted_quiz_questions[i].attempted_quiz_question.syllabus_question.length > 0) {
            finalTopicQuesData.push(this.quizVal.data.attempted_quiz_questions[i]);
          }
        }
        this.quizData = finalTopicQuesData; //this.quizVal.data.attempted_quiz_questions;
      }
    );    
  }

  breadcrumbs() {
    this.tempObj = {
      school_id: this.schoolId,
      class_id: this.classId,
      section_id: this.sectionId,
      set_id: this.setId
    }   

    this.quizSetService.getQuizSetDetails(this.tempObj).subscribe((quizDetail: any) => {
      // console.log(quizDetail);
      this.set_name = quizDetail.data.set_name;
    });

    this.topicObj = {topic_id: this.topic_id};
    this.contentService.getTopics(this.topicObj).subscribe((topicDetail: any) => {
      this.topic_name = topicDetail.data[0].topic_name;
    })
  }

}
