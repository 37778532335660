import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ms-content-image',
  templateUrl: './ms-content-image.component.html',
  styleUrls: ['./ms-content-image.component.css']
})
export class MsContentImageComponent implements OnInit {

  @Input() url;
  constructor(
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    // console.log(this.url);
  }
  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
     }

}
