import { Component, OnInit, ChangeDetectorRef, Output, Input } from '@angular/core';
import { timer, Subscription, Subject } from 'rxjs';
import * as moment from 'moment';
import { QuizSetService } from 'src/app/_services/quiz-set.service';

@Component({
  selector: 'app-timer',
  template: `
    <!--<p>
      timer works!
      {{timerVal}}
    </p> -->
    <div [ngClass]="{'box': true}">

      <span [ngClass]="{'heading': true}">Hours : </span>
      <span [ngClass]="{'boxValue': true}">{{hours}}</span>
      <span [ngClass]="{'heading': true}">Minutes : </span>
      <span [ngClass]="{'boxValue': true}">{{minutes}}</span>
      <span [ngClass]="{'heading': true}">Seconds : </span>
      <span [ngClass]="{'boxValue': true}">{{seconds}}</span>
    </div>
  `,
  styles: [
    `.box {
      border: 1px solid #000000;
      background-color: green;
      text-align: center;
      line-height: 50px;
    }`,
    `.heading {
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;      
      padding-left: 10px;
    }`,
    `.boxValue {
    display: inline-block;
    border:1px solid black;
    width:40px;
    line-height:30px;
    background-color:blue;
    color:#ffffff;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }`]
})
export class TimerComponent implements OnInit {

  @Input() schoolId: number;
  @Output() getTime = new Subject<any>();

  userQuestions: any;
  quizData: any;
  timerVal: any;
  sourceTime: any;
  sub: Subscription;  
  SearchDate: moment.Moment = moment();
  searchEndDate: moment.Moment;
  ElapsTime: number;  
  remainingTime: number;
  hours: number;
  minutes: number;
  seconds: number;
  showMin: number;
  timeVal: any;
  duration: number;
  quizSetId: number;
  totalDuration: number;

  constructor(
    private ref: ChangeDetectorRef,
    private quizSetService: QuizSetService
  ) { 
  }

  ngOnInit() {  
    this.userQuestions = JSON.parse(localStorage.getItem('userQuestions'));    
    let duration = atob(this.userQuestions.myval); 
    this.totalDuration = Number(atob(this.userQuestions.myquizval)); 
    let durationArr = duration.split(':');
    this.ElapsTime = Number(durationArr[0]);
    //this.ElapsTime = 2;  
        
    this.searchEndDate = this.SearchDate.add(Number(durationArr[0]), "minutes").add(Number(durationArr[1]), "seconds");

    this.sourceTime = timer(0, 1000);

    this.sub = this.sourceTime.subscribe((tVal)=>{
    var currentTime: moment.Moment = moment();
    this.remainingTime = this.searchEndDate.diff(currentTime)
    this.remainingTime = this.remainingTime / 1000;

    if (this.remainingTime <= 0) {
      this.SearchDate = moment();
      this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");      
    }
    else {
      this.hours = Math.floor((this.remainingTime / 60 ) / 60);
      this.showMin = Math.floor((this.remainingTime / 60) - (this.hours*60));
      this.minutes = Math.floor(this.remainingTime / 60);
      this.seconds = Math.floor(this.remainingTime - this.minutes * 60);
      // this.timerVal = String(this.hours).padStart(2,'0')+" : "
      //                 + String(this.showMin).padStart(2,'0')+ " : "
      //                 + String(this.seconds).padStart(2,'0');
    }
    
    this.timeVal = this.totalDuration - this.minutes;
    this.timeVal = this.timeVal>-1 ? this.timeVal : 0;
    let sendTimeVal = [this.timeVal, this.remainingTime];
    this.getTime.next(sendTimeVal);

    this.userQuestions.myval = btoa(String(this.minutes)+":"+String(this.seconds));
    localStorage.setItem('userQuestions',JSON.stringify(this.userQuestions));

    this.ref.markForCheck();

    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }  
}
