import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ms-content-mp4',
  templateUrl: './ms-content-mp4.component.html',
  styleUrls: ['./ms-content-mp4.component.css']
})
export class MsContentMp4Component implements OnInit {

  @Input() url;
  constructor(
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }
  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
     }


}
