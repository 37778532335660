import { Component, OnInit, ViewChild } from '@angular/core';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { BackendService } from 'src/app/_services/backend.service';
import { SchoolService } from 'src/app/school/school.service';
import { ReportService } from '../report.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import { isValid, parse } from 'date-fns';
import { writeFile } from 'xlsx';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-day-wise-user-report',
  templateUrl: './day-wise-user-report.component.html',
  styleUrls: ['./day-wise-user-report.component.css']
})
export class DayWiseUserReportComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = "Day Wise User's Analytics";
  searchFilter: any = {
    // name: '',
    // email: '',
    // contactNumber: '',
    // subject_label: [],
    class_id: '',
    school_id: '',
    from_date: '',
    to_date: ''
  }
  currentDate = new Date();
  currentUser: any;
  customFilters: any = {};
  dataSource: any;
  schoolList: [];
  classList: [];
  loading: boolean = false;
  liveUrl = environment.liveUrl;
  schoolName: any;
  listData: any;
  selectedSchoolName: string;
  displaySchoolName: boolean = false;
  disableDownload: boolean = true;
  subscription: Subscription;
  from_date: any;
  to_date: any;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private schoolService: SchoolService,
    private _backendService: BackendService,
    private reportService: ReportService,
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.schoolService.getSuggestSchools({}).subscribe((schoolList: any) => {
      this.schoolList = schoolList.data;
    })
    let classObj = {
      board_id: 1
    }
    this._backendService.getClasses(classObj).subscribe((data: any) => {
      // console.log(data);
      this.classList = data.data;
    });

    // this.getAppUsage();
  }



  reset() {
    //Stop Searching and reset the table

    this.loading = false;
    this.disableDownload = true;
    this.displaySchoolName = false;
    this.searchFilter = {
      // name: '',
      // email: '',
      // contactNumber: '',
      // subject_label: [],
      class_id: '',
      school_id: '',
      from_date: '',
      to_date: ''
    };
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.openSnackBar('Reset Done! Please select all the required fields. ', 'Close');
    //this.subscription.unsubscribe();
    //this.searchUserData(this.searchFilter);
  }

  searchUserData(filter) {
    this.loading = true;
    this.searchFilter = filter;
    this.disableDownload = true;
    this.displaySchoolName = false;

    // Set from_date
    if (this.searchFilter.from_date !== '') {
      const timestamp_from_date = new Date(this.searchFilter.from_date).getTime();
      const adjustedFromDate = new Date(timestamp_from_date);
      const month = adjustedFromDate.getMonth() + 1;
      const day = adjustedFromDate.getDate();
      const year = adjustedFromDate.getFullYear();
      const isoStringFromDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
      this.from_date = isoStringFromDate;
    } else {
      this.from_date = '';
    }

    // Set to_date
    if (this.searchFilter.to_date !== '') {
      const timestamp_to_date = new Date(this.searchFilter.to_date).getTime();
      const adjustedToDate = new Date(timestamp_to_date);
      const month = adjustedToDate.getMonth() + 1;
      const day = adjustedToDate.getDate();
      const year = adjustedToDate.getFullYear();
      const isoStringToDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
      this.to_date = isoStringToDate;
    } else {
      this.to_date = '';
    }

    const obj = {
      school_id: filter.school_id,
      class_id: filter.class_id,
      from_date: this.from_date,
      to_date: this.to_date
    };


     console.log(this.searchFilter)
    if ((this.searchFilter.class_id == '' || this.searchFilter.subject_id == '' || this.searchFilter.from_date == '' || this.searchFilter.to_date == '')) {
      this.loading = false;
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.openSnackBar('Please select all the required fields. ', 'Close');
    } else {
       console.log('obj =' + JSON.stringify(obj));
      this.subscription = this.reportService.getDayWiseUsage(obj).subscribe((data: any) => {
        //  console.log(data)
        this.listData = data;
        this.loading = false;
        this.disableDownload = false;
        this.displaySchoolName = true;
        if (data.length == 0) {
          this.disableDownload = true;
          this.displaySchoolName = false;
          this.subscription.unsubscribe();
          this.openSnackBar('Data is not available for the selected school & class. ', 'Close');
        }

      },
        (error) => {
          this.loading = false;
          this.openSnackBar('An error occurred.', 'Close');
          console.error('An error occurred:', error);
        }
      )
    }
  }

  onSchoolSelected(id) {
    // console.log(id)
    const selectedSchool = this.schoolList.find((school: any) => school.id === id) as { id: number, school_name: string };
    this.selectedSchoolName = selectedSchool ? selectedSchool.school_name : '';
    //console.log(this.selectedSchoolName)
  }



  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  resetPageIndex() {
    // reset the paginator
    // this.paginator.pageIndex = 0;
  }
  resetSearchFilter() {
    this.resetPageIndex();
    //searchPanel.toggle();
    this.searchFilter = {
      // name: '',
      // email: '',
      // contactNumber: '',
      // subject_label: [],
      class_id: '',
      school_id: '',
      from_date: '',
      to_date: ''
    };
    this.disableDownload = true;
    this.displaySchoolName = false;
    this.loading = false;
    //this.subscription.unsubscribe();
  }



  exportExcel() {
    const data = this.listData
    const worksheetData: any[] = [];
    const headers = ['Name', 'Email', 'User_Id'];

    // Extract unique dates from the data
    const dates = new Set<string>();
    for (const key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((entry: any) => {
          dates.add(entry.date);
        });
      }
    }

    // Sort the dates in ascending order
    const sortedDates = Array.from(dates).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    // Prepare worksheet data
    for (const key in data) {
      if (Array.isArray(data[key]) && data[key].length > 0) {
        const user = data[key][0];
        const userData = [user.name, user.email, user.user_id];
        const userEntries: Record<string, number[]> = {};

        data[key].forEach((entry: any) => {
          if (!userEntries[entry.date]) {
            userEntries[entry.date] = [];
          }
          userEntries[entry.date].push(entry.timeSpent);
        });

        sortedDates.forEach(date => {
          userData.push(...(userEntries[date] || ['']));
        });

        // Calculate the sum for each user's dates
        const userSum = sortedDates.reduce((sum, date) => {
          const timeSpent = userEntries[date] ? userEntries[date].reduce((total, value) => total + value, 0) : 0;
          return sum + timeSpent;
        }, 0);

        userData.push(userSum); // Add the sum to the user's data

        worksheetData.push(userData);
      }
    }

    // Create additional columns for each date entry
    const dateHeaders = sortedDates.map(date => this.formatDate(date));
    headers.push(...dateHeaders, 'Grand Total (In Minutes)');

    // Create a new workbook and worksheet
    const workbook = { SheetNames: [], Sheets: {} };
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...worksheetData]);

    // Add the worksheet to the workbook
    let sheetName = 'Grade ' + this.searchFilter.class_id;
    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = worksheet;

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Save the Excel file
    const file = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(file, this.selectedSchoolName + '_Day_Usage_Report.xlsx');
  }

  // Helper function to format the date as "dd/mm/yyyy"
  formatDate(date: string): string {
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }


}
