import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-user-subscription-invoice',
  templateUrl: './user-subscription-invoice.component.html',
  styleUrls: ['./user-subscription-invoice.component.css']
})
export class UserSubscriptionInvoiceComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'User Subscriptions';
  displayedColumns: string[] = ['Sno', 'order_id', 'class_id', 'startDate', 'endDate', 'paidAmount', 'id'];
  errorMessage: any;
  currentUser: any;

  loginUserId: number;
  type_order: any;
  user_id: any;
  subscribeData: any;
  dataSource: any = null;
  totalSub: number;
  customFilters: any = null;
  limit = 10;
  offset = 0;
  name: string;
  quiz_user_id: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private subscriptionService: SubscriptionService,
    private backendService: BackendService) {
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.user_id = this.currentUser.id;
    if (this.currentUser) {
      this.loginUserId = this.currentUser.adminUser[0].id;
      this.quiz_user_id = this.currentUser.adminUser[0].quiz_user_id
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
      this.customFilters = {
        limit: this.paginator.pageSize,
        offset: this.paginator.pageIndex * this.paginator.pageSize
      }
    }

    this.route.params.subscribe(params => {
      this.user_id = params['user_id'];
      this.getSubscription();
    });

    this.backendService.getUser(this.user_id).subscribe((data: any) => {
      // console.log(data);
      this.name = data.firstName + ' ' + data.lastName;
    })
  }

  getSubscription() {
    this.customFilters = {
      user_id: this.user_id,
      quiz_user_id: this.quiz_user_id,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
    // console.log(this.quiz_user_id);
    this.subscriptionService.getUserSubscription(this.customFilters).subscribe((data: any) => {
      // console.log(data);
      this.subscribeData = data.data;
      this.totalSub = data.totalSub
      this.dataSource = new MatTableDataSource(this.subscribeData);
    })
  }

  reset() {
    this.getSubscription();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          // this.sortBy = data.action;
          // this.sortDirection = data.direction;
          this.getSubscription();
        })
      ).subscribe();
  }

}