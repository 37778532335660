import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paper-set-school',
  templateUrl: './paper-set-school.component.html',
  styleUrls: ['./paper-set-school.component.css']
})
export class PaperSetSchoolComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
