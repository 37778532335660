import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsContentService } from '../ms-content.service';

@Component({
  selector: 'app-ms-content-map-lessons',
  templateUrl: './ms-content-map-lessons.component.html',
  styleUrls: ['./ms-content-map-lessons.component.css']
})
export class MsContentMapLessonsComponent implements OnInit {

  country_lesson_id: number;
  mapLessonList: any;
  lessonName: string;
  class_id: number;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: MsContentService) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.class_id = parseInt(params.class_id);
      this.country_lesson_id = parseInt(params.country_lesson_id);
    });

    console.log(this.country_lesson_id);
    this.getCountryMapLessons(this.country_lesson_id);
  }

  getCountryMapLessons(strVal, defaultVal = 0) {
    const paramsVal = { country_lesson_id: [strVal] };
    this.contentService.getCountryMapLessons(paramsVal).subscribe((result: any) => {
      this.mapLessonList = result.data;
      this.lessonName = this.mapLessonList[0].country_map_lessons.country_lesson_name;
      console.log(this.mapLessonList);
    });
  }

  openContentData(lessonId){
    this.router.navigate(['/content-items/',this.class_id,lessonId])
  }

}
