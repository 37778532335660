import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableDataSource } from '@angular/material';
import { QuizSetService } from 'src/app/_services/quiz-set.service';
//import { AttemptedTestHistoryComponent } from '../attempted-test-history/attempted-test-history.component';

@Component({
  selector: 'app-topic-wise-result',
  templateUrl: './topic-wise-result.component.html',
  styleUrls: ['./topic-wise-result.component.css']
})
export class TopicWiseResultComponent implements OnInit {

  @Input() quiz_set_id;
  @Input() quiz_user_id;
  displayedColumns = ['topic_name', 'total_attempted', 'total_correct', 
                      'total_incorrect', 'total_skipped', 'percentage', 'review']; //, 'id'
  quizSetData: any;
  dataSource: any;
  
  constructor(
    private quizSetService: QuizSetService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getTopicWiseResult();
  }

  getTopicWiseResult(){
    let paramsVal = {quiz_set_id: this.quiz_set_id, quiz_user_id: this.quiz_user_id};
    //console.log('paramsVal',paramsVal);
    this.quizSetService.getTopicWiseResult(paramsVal)
      .subscribe(result => {
        //console.log('result',result);
        this.quizSetData = result;
        this.quizSetData = this.quizSetData.data;
        this.freshDataList(this.quizSetData);
      });
  }

  freshDataList(quizData) {
    this.quizSetData = quizData;
    this.dataSource = new MatTableDataSource(this.quizSetData);
    //this.dataSource.paginator = this.paginator;
   // this.dataSource.sort = this.sort;
  }
  /*
  openDialog(quiz_set_id, set_id, topic_id) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.height = '600px';
    dialogConfig.width = '800px';
    
    dialogConfig.data = {
      quiz_set_id: quiz_set_id,
      set_id: set_id,
      topic_id: topic_id
    };
    const dialogRef = this.dialog.open(AttemptedTestHistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe();
  }
  */
}
