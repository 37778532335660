import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teacher-dashboard',
  templateUrl: './teacher-dashboard.component.html',
  styleUrls: ['./teacher-dashboard.component.css']
})
export class TeacherDashboardComponent implements OnInit {

  @Input() currentUser;
  @Input() userClasses;
  cardClass = 'dash-card';
  mediaQueryList: any = null;
  mediaQueryMin: any = null;
  isMobile = false;
  colNum: number = 4;
  rowHeight = '300px';
  colSpan: number = 2;
  // userClasses = [];
  currentSelSchoolId: number;
  subGridRowHeight = '350px';
  subGridColNum: number = 3;
  subGridCol: number = 2;
  chartGridWidth = '300px';

  constructor(
    breakpointObserver: BreakpointObserver,
    mediaMatcher: MediaMatcher,
    private router: Router
  ) { 
    this.mediaQueryList = mediaMatcher.matchMedia('(min-width: 640px)');
    this.mediaQueryMin = mediaMatcher.matchMedia('(min-width: 1000px)');

    breakpointObserver.observe([
      // Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
        this.colNum = this.mediaQueryList.matches ? 2 : 1;
        this.colSpan = this.mediaQueryList.matches ? 2 : 1;
        this.cardClass = 'dash-card-inline';
        this.subGridColNum = this.mediaQueryList.matches ? 3 : 1;
        this.subGridCol = this.mediaQueryList.matches ? 2 : 1;
        this.subGridRowHeight = '500px';
        this.chartGridWidth = '250px';
        
      }
    });
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      if (result.matches) {
        this.isMobile = true;
        this.colNum = this.mediaQueryList.matches ? 2 : 1;
        this.colSpan = this.mediaQueryList.matches ? 2 : 1;
        this.cardClass = 'dash-card-inline';
        this.subGridColNum = this.mediaQueryList.matches ? 3 : 1;
        this.subGridCol = this.mediaQueryList.matches ? 2 : 1;
        this.subGridRowHeight = '350px';
        this.chartGridWidth = '300px';
        
      }
    });
  }

  ngOnInit() {
    // this.userClasses = this.currentUser.userData;
    //console.log(this.currentUser)
    //console.log(this.userClasses)
    this.currentSelSchoolId = this.currentUser.currentSelSchoolId;
    // this.userClasses = this.userClasses.filter(data => data.school_id == this.currentSelSchoolId);
    // console.log(this.userClasses)
  }

  openQuizSets(school_id, class_id, section_id){
   // localStorage.setItem("maind","1");
    this.router.navigate(['/quiz_dashboard',`${school_id}`,`${class_id}`,`${section_id}`])
  }
  openPrePostTests(school_id, class_id, section_id){
    //localStorage.setItem("maind","1");
    this.router.navigate(['/post_quiz_dashboard',`${school_id}`,`${class_id}`,`${section_id}`],{queryParams:{postTest: 1}})
  }
}
