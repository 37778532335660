import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { HomeworkService } from '../homework.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-tutor-student',
  templateUrl: './tutor-student.component.html',
  styleUrls: ['./tutor-student.component.css']
})
export class TutorStudentComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'Tutor Students';
  displayedColumns: string[] = ['Sno', 'name', 'email', 'contact', 'class', 'id'];

  dataSource: any;

  currentUser: any;
  user_id: number;
  userData: any;
  classArr = [];
  sectionArr = [];
  schoolList: any;
  school_id = [];
  limit = 10;
  offset = 0;
  totalStudent: number;

  constructor(
    private homeworkService: HomeworkService
    ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = this.currentUser.adminUser[0].id;

    this.userData = this.currentUser.userData;
    this.userData.forEach(userVal => {
      if (this.classArr.indexOf(userVal.class.id) == -1) {
        this.classArr.push(userVal.class.id);
      }
      if (this.sectionArr.indexOf(userVal.section_id) == -1) {
        this.sectionArr.push(userVal.section_id);
      }
    });
    this.schoolList = this.currentUser.userSchools
    this.schoolList.forEach(school => {
      this.school_id.push(school.id);
    });
    this.getStudentList();
  }

  getStudentList(){
    let obj = {
      school_id: this.school_id,
      class_id: this.classArr,
      section_id: this.sectionArr,
      limit: this.paginator.pageSize,
      offset: this.paginator.pageIndex * this.paginator.pageSize
    }
    // console.log(obj);
    this.homeworkService.getTutorStudent(obj).subscribe((data: any)=> {
      console.log(data);
      this.totalStudent = data.totalCount;
      this.dataSource = new MatTableDataSource(data.data);
    })
  }

  reset() {
    this.getStudentList();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          // this.sortBy = data.action;
          // this.sortDirection = data.direction;
          this.getStudentList();
        })
      ).subscribe();
  }

}
