import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControlName, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { IUser } from '../user';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { Observable, Subscription } from 'rxjs';
import { GenericValidator } from '../../shared/generic-validator';
import { MatSnackBar, MatOption } from '@angular/material';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  @ViewChild('allSelectedSection') private allSelectedSection: MatOption;
  @ViewChild('allSelectedSchool') private allSelectedSchool: MatOption;
  @ViewChild('allSelectedClasses') private allSelectedClasses: MatOption;
  @ViewChild('allSelectedSubject') private allSelectedSubject: MatOption;

  pageTitle = 'Add User';
  errorMessage: any;
  userForm: FormGroup;
  user: any;
  status = { 1: 'Active', 2: 'Inactive' };
  //  status = [{id: 1, statusVal: 'Active'}, {id: 2, statusVal: 'Inactive'}];
  displayMessage: { [key: string]: string } = {};
  currentUser: any;
  token: string;
  formSubmit = false;
  userTypes: any;
  departments: any;
  userId: string;
  department: string;
  users: any;
  salesUsers: any;
  schoolList: any;
  classList: any;
  sectionList: any;
  subjectList: any;
  id: any;
  type_order: any;
  classArr = [];
  sectionArr = [];
  sectionIdArr = [];
  assignedUser: any;
  countryList = [];
  userType: number;
  userObj: any;
  countryCode: string;
  // expand: boolean = false;
  loading = false;
  liveUrl = environment.liveUrl;
  expand: boolean = true;

  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  private sub: Subscription;

  userCredForm: FormGroup;
  userDetailForm: FormGroup;
  classMangtForm: FormGroup;
  suggestEmail: any;
  filteredSchools: [] = [];
  multiSearchSchool: FormControl = new FormControl();


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private _backendService: BackendService,
    private userService: UserService) {
    this.validationMessages = {
      firstName: {
        required: 'Fist name is required.'
      },
      username: {
        required: 'Username is required.'
      },
      status: {
        required: 'Status is required.'
      },
      contactNumber: {
        maxlength: 'Contact number is required.'
      },
      altNumber: {
        maxlength: 'Alternate contact number cannot exceed 10 characters.'
      },
      email: {
        pattern: 'Invalid email address.'
      },
      country_id: {
        required: 'Country is required.'
      },
      user_type_id: {
        required: 'User type is required.'
      },
      assigned_to: {
        required: 'Assign user is required.'
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.token = this.currentUser.token;
      // this.userId = this.currentUser.id;
      // this.type_order = this.currentUser.user_Type.type_order;
      this.userId = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    }

    this.userCredForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', ''],
      email: ['', [Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]]
    });

    this.userDetailForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', ''],
      country_id: ['', [Validators.required]],
      city: ['', ''],
      rollNumber: ['', ''],
      contactNumber: ['', [Validators.required]],
      altNumber: ['', ''],
      user_type_id: ['', [Validators.required]],
      assigned_to: ['', '']
    });

    this.classMangtForm = this.fb.group({
      school_id: ['', ''],
      class_id: ['', ''],
      section_id: ['', ''],
      subject_id: ['', ''],
      zoom_id: ['', ''],
      zoom_pass: ['', ''],
      status: ['', [Validators.required]],
    });

    this.userService.getUserTypes(this.userId).subscribe((data) => {
      this.userTypes = data;
    });
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      // if(this.id){ this.expand = true }
      // else{ this.expand = false }
    });
    this.sub.add(null);

    if (this.type_order == 1 || this.type_order == 2) {
      this.userService.getActiveSchools(this.userId).subscribe((data: any) => {
        this.schoolList = data.data;
        if (this.id) {
          this.getUser(this.id);
          this.pageTitle = `Update User`;
        }
        // this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
      });
    } else {
      let obj = { user_id: this.userId }
      this.userService.userSchools(obj).subscribe((data: any) => {
        this.classArr = data.userClass;
        this.sectionArr = data.userSection;
        // console.log("here section assign>>>>>>>>>>>>>>>>>>>>>>>>>");
        this.schoolList = data.data;
        if (this.schoolList.length == 1) {
          this.classMangtForm.patchValue({
            school_id: [this.schoolList[0].id]
          });
        }
        let classObj = {
          board_id: 1,
          class_id: this.classArr
        }
        this.userService.getClasses(classObj).subscribe((data: any) => {
          this.classList = data.data;
        });
        if (this.id) {
          this.getUser(this.id);
          this.pageTitle = `Update User`;
        }
      });
    }


    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.userService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
    this.userService.getCountries().subscribe((data: any) => {
      this.countryList = data.data;
    })

    // Read the user Id from the route parameter

  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    // Observable.merge(this.userForm.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
    //   this.displayMessage = this.genericValidator.processMessages(this.userForm);
    // });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  getUser(id: string): void {
    this.userService.getUser(id).subscribe(
      (user: any) => {
        this.onUserRetrieved(user);
      },
      (error: any) => this.errorMessage = error as any
    );
  }

  onUserRetrieved(user: any): void {

    this.user = user;
    //console.log(this.user)
    //console.log('user1 = '+JSON.stringify(this.user));

    let createdBy = this.currentUser.adminUser[0].id;
    let userClassArr = [];
    if (this.user.quizUser && this.user.quizUser.schoolUserData.length >= 1) {
      userClassArr = this.user.quizUser.schoolUserData[0].userDataSchool;
    }

    let country_id = [];
    let school_id = [];
    let class_id = [];
    let section_id = [];
    let subject_id = [];
    if (userClassArr.length > 0) {
      userClassArr.forEach(element => {
        school_id.push(element.school_id);
        class_id.push(element.class_id);
        // if(element.section_id !=0 || userClassArr.length != 1)
        section_id.push(element.section_id);
        subject_id.push(element.subject_id)
      });
      school_id = school_id.filter((v, i, a) => a.indexOf(v) === i);
      class_id = class_id.filter((v, i, a) => a.indexOf(v) === i);
      section_id = section_id.filter((v, i, a) => a.indexOf(v) === i);
      subject_id = subject_id.filter((v, i, a) => a.indexOf(v) === i);
    }
    this.changeClass(class_id);
    this.user.user_countries.forEach((country: any) => {
      country_id.push(country.country_id);
    })
    this.changeCountry(country_id);
    //  this.changeClass(class_id);
    this.changeUserType(this.user.user_type_id);

    //console.log('user = '+JSON.stringify(this.user));

    this.userCredForm.patchValue({
      username: this.user.username,
      password: this.user.password,
      email: this.user.email,
    })
    let cityVal = '';
    let rollNumber = '';
    let zoomId = '';
    let zoomPass = '';
    if (this.user.quizUser) {
      if (this.user.quizUser.schoolUserData.length >= 1) {
        cityVal = this.user.quizUser.schoolUserData[0].city;
        rollNumber = this.user.quizUser.schoolUserData[0].rollNumber;
      }
      zoomId = this.user.quizUser.zoom_id;
      zoomPass = this.user.quizUser.zoom_pass;
    }
    this.userDetailForm.patchValue({
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      contactNumber: this.user.contactNumber.slice(4),
      altNumber: this.user.altNumber,
      country_id: country_id,
      city: cityVal,
      rollNumber: rollNumber,
      user_type_id: this.user.user_type_id,
      assigned_to: this.user.assigned_to,
      createdBy: createdBy
    })

    this.classMangtForm.patchValue({
      school_id: school_id,
      class_id: class_id,
      section_id: section_id,
      subject_id: subject_id,
      zoom_id: zoomId,
      zoom_pass: zoomPass,
      status: this.user.status.toString()
    })


    if (class_id.length === this.classList.length) {
      this.allSelectedClasses.select();
    } else {
      this.allSelectedClasses.deselect();
    }
    //console.log(school_id);
    //console.log(this.schoolList)
    
    let newSchoolID = school_id.filter((id: number) => id !== 0);
   // console.log(newSchoolID.length === this.schoolList.length)
    if (newSchoolID.length === this.schoolList.length) {
      this.allSelectedSchool.select();
    } else {
      this.allSelectedSchool.deselect();
    }

  }


  onSaveComplete(): void {
    this.openSnackBar('User has been saved successfully. ', 'Close');
    this.errorMessage = { "message": 'User data saved successfully.' };
    this.router.navigate(['/user'], { queryParamsHandling: 'preserve' });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  emailFilter(filterValue: string) {
    //this.errorMessage = {};
    if (filterValue.length > 4) {
      let obj = { email: filterValue };
      this.userService.getSuggestData(obj).subscribe((emailData: any) => {
        //console.log(emailData);
        this.suggestEmail = emailData.data;
        // console.log(filterValue.indexOf('@'),  this.suggestData.length);
        // if (filterValue.indexOf('@') > 0 && this.suggestEmail.length <= 0) {
        //   this.errorMessage.message = "User is not registered"
        // }
      });
    }
  }

  fillData(suggest) {
    this.userCredForm.patchValue({
      email: suggest.email,
      username: suggest.username
    });
    this.userDetailForm.patchValue({
      contactNumber: suggest.contactNumber.slice(4),
      firstName: suggest.firstName,
      lastName: suggest.lastName
    })
  }

  onFormSubmit(): void {
    this.formSubmit = true;
  }

  // changeClass(class_id) {
  //   this.sectionIdArr = class_id;
  //   let classObj = { class_id: this.sectionIdArr, section_id: this.sectionArr }
  //   this.userService.getClassSections(classObj).subscribe((result: any) => {
  //     this.sectionList = result.data;
  //   })
  //   this.userService.getSubjects(classObj).subscribe((result: any) => {
  //     this.subjectList = result.data;
  //   });
  // }

  changeUserType(user_type_id) {
    let typeObj = {
      "user_type_id": user_type_id,
      "user_id": parseInt(this.id)
    }
    // console.log(typeObj);
    // this.userService.getTypeUsers(typeObj).subscribe((result: any) => {
    //   // console.log(result);
    //   this.assignedUser = result.data;
    // })
    if (user_type_id == 8) {
      this.userType = 0
    } else { this.userType = 1 }
  }

  changeCountry(strVal) {
    let params = { country_id: strVal }
    this.countryList.forEach(country => {
      if (country.id == strVal[0]) {
        this.countryCode = country.countryCode;
      }
    });
  }

  schoolFilter(keyword: string) {
    // Convert the keyword to lowercase for case-insensitive matching
    const lowercaseKeyword = keyword.toLowerCase();

    // Filter the schoolList based on the keyword
    const filteredSchools = this.schoolList.filter(school =>
      school.school_name.toLowerCase().includes(lowercaseKeyword)
    );

    // Sort the filtered schools to show the matching ones on top
    filteredSchools.sort((a, b) => {
      const aMatches = a.school_name.toLowerCase().includes(lowercaseKeyword);
      const bMatches = b.school_name.toLowerCase().includes(lowercaseKeyword);
      if (aMatches && !bMatches) {
        return -1; // a comes before b
      } else if (!aMatches && bMatches) {
        return 1; // b comes before a
      } else {
        return 0; // preserve the original order
      }
    });

    // Concatenate the filtered schools with the remaining schools
    this.filteredSchools = filteredSchools.concat(
      this.schoolList.filter(school => !filteredSchools.includes(school))
    );
  }


  selectAllSchool() {
    if (this.allSelectedSchool.selected) {
      this.classMangtForm.controls.school_id.patchValue([...this.schoolList.map(item => item.id), 0]);
    } else {
      this.classMangtForm.controls.school_id.patchValue([]);
    }
  }

  tosslePerSchool() {
    const selectedSchools = this.classMangtForm.controls.school_id.value;
    const allSelected = selectedSchools.length === this.schoolList.length;

    if (allSelected) {
      this.allSelectedSchool.select();
    } else {
      this.allSelectedSchool.deselect();
    }

    if (selectedSchools.includes(0) && selectedSchools.length > 1) {
      this.allSelectedSchool.deselect();
    }
  }

  selectAllClasses() {
    if (this.allSelectedClasses.selected) {
      this.classMangtForm.controls.class_id.patchValue([...this.classList.map(item => item.id), 0]);
    } else {
      if (!this.classMangtForm.controls.class_id.value.includes(0)) {
        this.classMangtForm.controls.class_id.patchValue([]);
      }
    }
    this.changeClass(this.classMangtForm.controls.class_id.value);
  }

  tosslePerClass() {
    const selectedClasses = this.classMangtForm.controls.class_id.value;
    const allSelected = selectedClasses.length === this.classList.length;

    if (allSelected) {
      this.allSelectedClasses.select();
    } else {
      this.allSelectedClasses.deselect();
    }

    if (selectedClasses.includes(0) && selectedClasses.length > 1) {
      this.allSelectedClasses.deselect();
    }

    this.changeClass(selectedClasses);
  }


  changeClass(class_id) {
     //console.log(class_id);
     this.subjectList = []
     this.sectionList = []
    this.sectionIdArr = class_id;
    let classObj = { class_id: this.sectionIdArr, section_id: this.sectionArr };

    this.userService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
      if(this.sectionList && this.sectionList.length > 0){
        this.updateSectionSelection();
      }else{
        this.allSelectedSection.deselect();
      }
      
    });

    this.userService.getSubjects(classObj).subscribe((result: any) => {
      this.subjectList = result.data;
      if(this.subjectList && this.subjectList.length > 0){
        this.updateSubjectSelection();
      }else{
        this.allSelectedSubject.deselect();
      }
    });

  }

  updateSectionSelection() {
   // console.log('kd')
    if (this.sectionList.length > 0) {
      const selectedSections = this.classMangtForm.controls.section_id.value;
      const allSelected = selectedSections.length === this.sectionList.length;
     // console.log(allSelected)
      if (allSelected) {
        const sectionIds = this.sectionList.map(section => section.id);
        if (!sectionIds.includes(0)) {
          sectionIds.push(0);
        }
        this.classMangtForm.controls.section_id.patchValue(sectionIds);
      } else {
         // console.log(selectedSections)
          let newSelectedSections = selectedSections.filter( id => id !== 0)
          //console.log(newSelectedSections)
          // if( newSelectedSections.length === this.sectionList.length){
            if(this.sectionList.every(section => newSelectedSections.includes(section.id))){
           // console.log('hii')
            const sectionIds = this.sectionList.map(section => section.id);
            if (!sectionIds.includes(0)) {
              sectionIds.push(0);
            }
            this.classMangtForm.controls.section_id.patchValue(sectionIds);
          } else {
         //  console.log('hello')
            this.allSelectedSection.deselect();
            this.classMangtForm.controls.section_id.patchValue(newSelectedSections);
          }
         
       
      }

    }
  }

  updateSubjectSelection() {
   // console.log('kd')
    if (this.subjectList.length > 0) {
      // const selectedSubjects = this.classMangtForm.controls.subject_id.value;
      const selectedSubjects = this.classMangtForm.controls.subject_id.value.filter(subjectId =>
        this.subjectList.some(subject => subject.id === subjectId)
      );
      // console.log(this.subjectList);
      // console.log(this.classMangtForm.controls.subject_id.value)
      // console.log(selectedSubjects)
      const allSelected = selectedSubjects.length === this.subjectList.length;
      //console.log(allSelected)
      if (allSelected) {
        const subjectIds = this.subjectList.map(subject => subject.id);
        if (!subjectIds.includes(0)) {
          subjectIds.push(0);
        }
        this.classMangtForm.controls.subject_id.patchValue(subjectIds);
      } else {
         // console.log(selectedSubjects)
          let newSelectedSubjects = selectedSubjects.filter( id => id !== 0)
          //console.log(newSelectedSubjects)
          // if( newSelectedSections.length === this.sectionList.length){
            if(this.subjectList.every(subject => newSelectedSubjects.includes(subject.id))){
            //console.log('hii')
            const subjectIds = this.subjectList.map(subject => subject.id);
            if (!subjectIds.includes(0)) {
              subjectIds.push(0);
            }
            this.classMangtForm.controls.subject_id.patchValue(subjectIds);
          } else {
           //console.log('hello')
            this.allSelectedSubject.deselect();
            this.classMangtForm.controls.subject_id.patchValue(newSelectedSubjects);
          }
         
       
      }

    }
  }


  selectAllSection() {
    let sectionVal = [0];
    if (this.allSelectedSection.selected) {
      this.classMangtForm.controls.section_id
        .patchValue([...this.sectionList.map(item => item.id), 0]);
      sectionVal = this.classMangtForm.controls.section_id.value;
    } else {
      this.classMangtForm.controls.section_id.patchValue([]);
    }
  }

  tosslePerSection() {
    if (this.allSelectedSection.selected) {
      this.allSelectedSection.deselect();
      return false;
    }
    if (this.classMangtForm.controls.section_id.value.length === this.sectionList.length) {
      this.allSelectedSection.select();
    }
  }

  selectAllSubject() {
    if (this.allSelectedSubject.selected) {
      this.classMangtForm.controls.subject_id.patchValue([...this.subjectList.map(item => item.id), 0]);
    } else {
      this.classMangtForm.controls.subject_id.patchValue([]);
    }
  }

  tosslePerSubject() {
    const selectedSubjects = this.classMangtForm.controls.subject_id.value;
    const allSelected = selectedSubjects.length === this.subjectList.length;

    if (allSelected) {
      this.allSelectedSubject.select();
    } else {
      this.allSelectedSubject.deselect();
    }

    if (selectedSubjects.includes(0) && selectedSubjects.length > 1) {
      this.allSelectedSubject.deselect();
    }
  }

  allFormSubmit() {
    this.loading = true;
    this.userDetailForm.value.contactNumber = this.countryCode + '-' + this.userDetailForm.value.contactNumber;
    this.userDetailForm.value.assigned_to = this.userId;
    let filteredClassMangtFormValue = this.classMangtForm.value;
     filteredClassMangtFormValue.school_id = filteredClassMangtFormValue.school_id.filter((id: number) => id !== 0);
     filteredClassMangtFormValue.class_id = filteredClassMangtFormValue.class_id.filter((id: number) => id !== 0);
     filteredClassMangtFormValue.section_id = filteredClassMangtFormValue.section_id.filter((id: number) => id !== 0);
     filteredClassMangtFormValue.subject_id = filteredClassMangtFormValue.subject_id.filter((id: number) => id !== 0);

    this.userObj = Object.assign({},
      {
        id: this.id,
        userType: this.userType,
        referredBy: '',
        packageName: 'com.oksedu.marksharks.cbse.g07',
        fcm_id: "jhg76tb768",
        created_by: this.userId,
        modified_by: this.userId,
      },
      this.userCredForm.value,
      this.userDetailForm.value,
      filteredClassMangtFormValue);
     // console.log(this.userObj);

    if (this.id) {
      this.userObj.created_by = undefined
      this.adminUser();
    } else {
      this.userService.chkUserExists({ username: this.userObj.username }).subscribe((chkData: any) => {
        // console.log(chkData)
        if (chkData.status) {
          this.adminUser();
        } else {
          this._backendService.appsignup(this.userObj).subscribe((appData: any) => {
            if (appData.status == false) {
              this.errorMessage = appData;
              this.loading = false;
            } else {
              this.userObj.quiz_user_id = appData.data.userId
              // this.userObj.referredBy = undefined;
              this._backendService.updateUser(appData.data.userId, this.userObj).subscribe((userData: any) => {
                // console.log(userData);
                if (userData.status) {
                  this.adminUser();
                } else {
                  this.errorMessage = userData;
                  this.loading = false;
                }
              })
            }
          })
        }
      });
    }
  }

  adminUser() {
    //console.log(this.userObj);
    this.userService.createUser(this.userObj).subscribe((result: any) => {
      //console.log(result)
      if (result.status) {
        this.classMangtUser();
      } else {
        this.errorMessage = result;
        this.loading = false;
      }
    });
  }

  classMangtUser() {

    if (this.id) {
      let obj = Object.assign({}, {
        id: this.user.quizUser.schoolUserData[0].id,
        modified_by: this.userId,
        studentName: this.userDetailForm.value.firstName + ' ' + this.userDetailForm.value.lastName,
        rollNumber: this.userDetailForm.value.rollNumber,
        email: this.userCredForm.value.email,
        studentContactNo: this.userDetailForm.value.contactNumber,
        city: this.userDetailForm.value.city,
        user_type: this.userType
      },
        this.classMangtForm.value);
      this.userService.editUsersData(obj).subscribe((result: any) => {
        if (result.status) {
          this.onSaveComplete();
          this.openSnackBar(result.message, 'Close');
        } else {
          this.errorMessage = result;
          this.loading = false;
        }
      })
    } else {
      let obj = Object.assign({}, {
        created_by: this.userId,
        modified_by: this.userId,
        studentName: this.userDetailForm.value.firstName + ' ' + this.userDetailForm.value.lastName,
        rollNumber: this.userDetailForm.value.rollNumber,
        email: this.userCredForm.value.email,
        studentContactNo: this.userDetailForm.value.contactNumber,
        city: this.userDetailForm.value.city,
        user_type: this.userType
      },
        this.classMangtForm.value);
      this.userService.addUsersData(obj).subscribe((result: any) => {
        // console.log(result);
        if (result.status) {
          this.onSaveComplete();
        } else {
          this.errorMessage = result;
          this.loading = false;
          this.openSnackBar(result.message, 'Close');
        }
      });
    }
  }

  handleIconClick(event: MouseEvent | FocusEvent) {
   // console.log('jj');
    const target = event.target as HTMLElement;
    if (target.classList.contains('clear-icon') || event instanceof FocusEvent) {
      let searchInput = '';
      // Call the schoolFilter function if needed
      this.schoolFilter(searchInput);
    }
  }

  
  

}
