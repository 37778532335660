import { Component, OnInit, ViewChild } from '@angular/core';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { QuizSetService } from 'src/app/_services/quiz-set.service';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator, MatSort, PageEvent, MatTableDataSource, MatDialogConfig, MatDialog } from '@angular/material';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PostTestService } from '../post-test.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { BackendService } from 'src/app/_services/backend.service';
import { AttemptedTestHistoryComponent } from './attempted-test-history/attempted-test-history.component';
import { DashboardService } from 'src/app/dashboard/dashboard.service';

@Component({
  selector: 'app-post-test-list',
  templateUrl: './post-test-list.component.html',
  styleUrls: ['./post-test-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PostTestListComponent implements OnInit {

  pageTitle = 'Post Test Reports';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageEvent: PageEvent;
  expandedElement: any;

  private apiUrl = environment.apiUrl;
  displayedColumns = ['set_name', 'total_attempted', 'total_correct', 
                      'total_incorrect', 'total_skipped', 'percentage', 'attemptedOn', 'test_type',
                      'id'
                    ];
  searchFilter: any = {
    setName: '',
    created_by: '',
    limit: 0,
    offset: 0
  };
  customFilters: any = null;
  quizSets: any;
  quizSetData: any;
  errorMessage: string;
  dataSource: any;
  userId: number;
  token: string;
  currentUser: any;
  user_type_order: any;
  limit = 10;
  offset = 0;
  total_count: number;
  schoolId: number;
  classId: number;
  sectionId: number;
  email: string;
  lessonId: number;
  sno = 0;
  lessonData: any;
  lesson_name: any;
  
  constructor(
    private http: HttpClient,
    private postTestService: PostTestService,
    private route: ActivatedRoute,
    private backendService: BackendService,
    private dialog: MatDialog,
    private dashboardService: DashboardService,
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      //console.log(this.currentUser)
      this.schoolId = this.currentUser.currentSelSchoolId;
      //this.classId = this.currentUser.userData[0].class_id;
      this.sectionId = this.currentUser.userData[0].section_id;
      this.email = this.currentUser.userData[0].email;
      this.userId = this.currentUser.id;
      this.token = this.currentUser.token;
      this.customFilters = { 
        created_by: this.userId,
        offset: this.paginator.pageIndex * this.paginator.pageSize,
        limit: this.paginator.pageSize
      };
      // if(this)
      
      this.route.params.subscribe(
        params => {
          this.lessonId = +params['id'];        
          this.getLesson();  
        }
      );
        //console.log('queryParams = ',this.route.queryParams);
      this.route.queryParams.subscribe( parmasData => {
        this.classId = parmasData.class_id;
        //console.log('parmasData = ',parmasData);
      });
      //console.log('class = ',this.classId);
      this.getQuizSets();
    }
  }


  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
    tap(() => {
        this.getQuizSets();
        })
    )
    .subscribe();
}


  getQuizSets() {
    this.customFilters = { 
      school_id: this.schoolId,
      class_id: this.classId,
      user_id: this.userId,
      quiz_user_id: this.userId,
      lesson_id: this.lessonId,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    };
      //console.log(this.customFilters)
      this.dashboardService.getUserLessonPerformance(this.customFilters).subscribe((lessonTestData: any) => {
        //console.log('lessonTestData = ',lessonTestData);
        let filteredTestData = lessonTestData.pre_post_test;
        this.lesson_name = filteredTestData[0].lesson_name;
        const filteredData = lessonTestData.pre_post_test.filter(entry => entry.pre_quiz_set_id !== 0);

        if (filteredData.length > 0) {
          const newEntry = {
            name: filteredData[0].name,
            subject_id: filteredData[0].subject_id,
            lesson_id: filteredData[0].lesson_id,
            lesson_name: filteredData[0].lesson_name,
            pre_quiz_set_id: filteredData[0].pre_quiz_set_id,
            pre_attempted_set_id: filteredData[0].pre_attempted_set_id,
            pre_total_attempted: filteredData[0].pre_total_attempted,
            pre_total_correct: filteredData[0].pre_total_correct,
            pre_total_incorrect: filteredData[0].pre_total_incorrect,
            pre_total_skipped: filteredData[0].pre_total_skipped,
            pre_time_spent: filteredData[0].pre_time_spent,
            pre_attempted_on: filteredData[0].pre_attempted_on,
            pre_score: filteredData[0].pre_score,
            post_quiz_set_id: filteredData[0].pre_quiz_set_id,
            post_attempted_set_id: filteredData[0].pre_attempted_set_id,
            post_total_attempted: filteredData[0].pre_total_attempted,
            post_total_correct: filteredData[0].pre_total_correct,
            post_total_incorrect: filteredData[0].pre_total_incorrect,
            post_total_skipped: filteredData[0].pre_total_skipped,
            post_time_spent: filteredData[0].pre_time_spent,
            post_attempted_on: filteredData[0].pre_attempted_on,
            post_score: filteredData[0].pre_score,
            usage: 0,
            progress: 0,
            preTest: true,
          };

       
        filteredTestData.push(newEntry); // Insert the new entry at the end of the list
        const finalData = filteredTestData.filter(item => item.post_attempted_set_id)
       // console.log(finalData)

        let lessonDataList = finalData.map((lessonData, index) => {
          return {
            ...lessonData,
            attempt_no: index + 1
          }
        });
       // console.log(lessonDataList);

        lessonDataList.map((value,index)=>{
                 lessonDataList[index].attemptVal = index;
              });
        this.freshDataList(lessonDataList);
       //this.dataSource = new MatTableDataSource(lessonDataList);
        }

      },
      error => this.errorMessage = error as any
      );
    
    // this.postTestService.getAttemptedQuizSets(this.customFilters)
    //   .subscribe(quizSets => {
    //     this.quizSetData = quizSets;
    //     this.expandedElement = this.quizSetData;
    //     this.total_count = this.quizSetData.total_count;
    //     this.quizSetData = this.quizSetData.data;
    //     this.quizSetData.map((value,index)=>{
    //       this.quizSetData[index].attemptVal = index;
    //     });
    //     this.freshDataList(this.quizSetData);
    //     return quizSets;
    //   },
    //     error => this.errorMessage = error as any);
  }

  searchQuizSets(filters: any) {
    this.resetPageIndex();
    if (filters) {
      filters.created_by = this.userId;
      filters.limit = this.paginator.pageSize
      filters.offset = this.paginator.pageIndex * this.paginator.pageSize;
      this.customFilters = filters;
      this.getQuizSets();
    }

  }

  getLesson(){
    this.backendService.getLesson(this.lessonId)
    .subscribe(data => {
      this.lessonData = data;
      this.lessonData = this.lessonData.data;
    });
  }

  freshDataList(quizData) {
    this.quizSetData = quizData;
   // console.log(this.quizSetData)
    this.dataSource = new MatTableDataSource(this.quizSetData);
    this.dataSource.paginator = this.paginator;
   // this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  openDialog(attempted_set_id, set_id) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.height = '600px';
    dialogConfig.width = '800px';
    
    dialogConfig.data = {
      attempted_set_id: attempted_set_id,
      set_id: set_id,
      topic_id: ""
    };
    const dialogRef = this.dialog.open(AttemptedTestHistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe();
  }

  reset() {
    this.resetPageIndex();
    this.searchFilter = {};
    this.customFilters = { 
      created_by: this.userId,
      limit: this.limit,
      offset: this.offset
    };
    this.getQuizSets();
  }

  resetPageIndex() {
    // reset the paginator
    this.paginator.pageIndex = 0;
  }

  resetSearchFilter(searchPanel: any) {
    this.resetPageIndex();
    searchPanel.toggle();
    this.searchFilter = {};
    this.customFilters = { created_by: this.userId };
    this.getQuizSets();
  }

}
