import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ShowOnlineScheduleService } from '../show-online-schedule.service';
import { MatTableDataSource, MatPaginator, MatSort, MatDialogConfig, MatDialog } from '@angular/material';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from '../../_services/backend.service';
import { OnlineScheduleLessonComponent } from '../online-schedule-lesson/online-schedule-lesson.component';

@Component({
  selector: 'app-search-class',
  templateUrl: './search-class.component.html',
  styleUrls: ['./search-class.component.css']
})
export class SearchClassComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() searchData;

  displayedColumns: string[] = ['Sno', 'class', 'subject', 'lessonNum', 'lesson', 'classLevel', 
  'startDate', 'endDate', 'day', 'id'];
  currentUser: any;
  customFilters: any = null;
  dataSource: any;
  total_schedule: number;
  schoolList: any;
  classList: any;
  sectionList: any;
  scheduleData = [];
  userId: number;
  schoolId: number;
  userType: number;
  // scheduleArr 
  limit = 10;
  offset = 0;
  schoolArr = [];
  classArr = [];
  sectionArr = [];
  teacherId: number;
  sortBy = '';
  sortDirection = '';

  searchFilter: any = {
    start_date: '',
    end_date: '',
    school_id: [],
    class_id: [],
    section_id: [],
    user_id: '',
    limit: 10,
    offset: 0
  };
  constructor(
    private onlineScheduleService: ShowOnlineScheduleService,
    private backendService: BackendService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    // console.log(this.searchData)
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.currentUser.id;
    this.schoolId = this.currentUser.currentSelSchoolId;
    this.userType = this.currentUser.userData[0].user_type;

    if(this.userType == 1){
      this.teacherId = this.userId;
    }

    this.customFilters = this.searchData;
    // console.log(this.customFilters);
    this.searchFilter = this.customFilters;
    this.sectionArr = this.customFilters.section_id
    if (this.customFilters.class_id != '') {
      this.changeClass(this.customFilters.class_id);
    }
    this.offset = this.paginator.pageIndex = (this.customFilters.offset / this.customFilters.limit);
    this.limit = this.paginator.pageSize = this.customFilters.limit;

    // this.route.queryParams.subscribe((params: any) => {
    //   if (params.customFilters) {
    //     this.customFilters = JSON.parse(params.customFilters);
    //     this.searchFilter = this.customFilters;
    //     this.sectionArr = this.customFilters.section_id
    //     if (this.customFilters.class_id != '') {
    //       this.changeClass(this.customFilters.class_id);
    //     }
    //     this.offset = this.paginator.pageIndex = (this.customFilters.offset / this.customFilters.limit);
    //     this.limit = this.paginator.pageSize = this.customFilters.limit;
    //   } else {
    //     this.customFilters = {};
    //   }
    // })
    let obj = {user_id: this.userId}
    this.backendService.userSchools(obj).subscribe((data: any) => {
      this.schoolList = data.data;
      // this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
      this.schoolArr.push(this.schoolId);
      this.classArr = data.userClass;
      this.sectionArr = data.userSection;
      this.getUserClasses();

      if(Object.keys(this.customFilters).length === 0){
        this.customFilters = {
          school_id: this.schoolArr,
          class_id: this.classArr,
          section_id: this.sectionArr,
          user_id: this.teacherId,
          limit: this.paginator.pageSize,
          offset: this.paginator.pageIndex * this.paginator.pageSize
        }
      }
      this.getSchedule();
    });
    let classObj = {
      board_id: 1
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  getSchedule() {
    this.customFilters = {
      search: true,
      start_date: this.customFilters.start_date,
      end_date: this.customFilters.end_date,
      school_id: this.customFilters.school_id,
      class_id: this.customFilters.class_id,
      section_id: this.customFilters.section_id,
      user_id: this.customFilters.user_id,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection
    }
    // console.log(this.customFilters);
    this.onlineScheduleService.getSchedule(this.customFilters).subscribe((scheduleList: any) => {
      // console.log(scheduleList);
      this.scheduleData = [];
      scheduleList.data.forEach(sl => {
        let date = new Date(sl.end_date);
        let hours = date.getHours();
        sl.ampm = hours >= 12 ? 'PM' : 'AM';
        this.scheduleData.push(sl);
      });
      // console.log(this.scheduleData)
      this.dataSource = new MatTableDataSource (this.scheduleData);
      this.total_schedule = scheduleList.totalSchedule;
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    })
  }

  reset(){
    this.getSchedule();
  }

  searchUserData(filters: any) {
    this.resetPageIndex();
    if (filters) {
      if (!filters.school_id || filters.school_id.length <= 0) {
        filters.school_id = this.schoolArr;
      }
      if (!filters.class_id || filters.class_id.length <= 0) {
        filters.class_id = this.classArr;
      }
      if (!filters.section_id || filters.section_id.length <= 0) {
        filters.section_id = this.sectionArr;
      }
      filters.user_id = this.teacherId;
      filters.limit = this.paginator.pageSize;
      filters.offset = this.paginator.pageIndex * this.paginator.pageSize;
      this.customFilters = filters;
      this.getSchedule();
    }
  }

  resetSearchFilter(searchPanel: any) {
    this.resetPageIndex();
    searchPanel.toggle();
    this.searchFilter = {};
    this.sectionArr = null;
    this.customFilters = {
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr,
      user_id: this.teacherId,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
    this.getSchedule();
  }

  resetPageIndex() {
    this.paginator.pageIndex = 0;
  }

  changeClass(class_id) {
    // this.sectionIdArr = [class_id];
    let classObj = { class_id: class_id, section_id: this.sectionArr }
    // console.log(classObj)
    this.backendService.getClassSections(classObj).subscribe((result: any) => {
      // console.log(result)
      this.sectionList = result.data;
    })
  }

  getUserClasses(){
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  lessonSchedule(schedule_id){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      schedule_id: schedule_id,
    };
    this.dialog.open(OnlineScheduleLessonComponent, dialogConfig);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          this.sortBy = data.action;
          this.sortDirection = data.direction;
          this.getSchedule();
        })
      ).subscribe();
  }


}