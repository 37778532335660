import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-latest-atten-teacher',
  templateUrl: './latest-atten-teacher.component.html',
  styleUrls: ['./latest-atten-teacher.component.css']
})
export class LatestAttenTeacherComponent implements OnInit {

  @Input() teacherAttendance;
  
  constructor() { }

  ngOnInit() {
  }

  

}
