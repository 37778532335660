import { Component, OnInit, ViewChild } from '@angular/core';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { BackendService } from 'src/app/_services/backend.service';
import { SchoolService } from 'src/app/school/school.service';
import { ReportService } from '../report.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import { isValid, parse } from 'date-fns';

@Component({
  selector: 'app-ms-report',
  templateUrl: './ms-report.component.html',
  styleUrls: ['./ms-report.component.css']
})
export class MsReportComponent implements OnInit {


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'MS Report';
  displayedColumns: string[] = ['name', 'email', 'phone', 'totalTimeSpent', 'mathTimeSpent', 'scienceTimeSpent'];
  searchFilter: any = {
    name: '',
    email: '',
    contactNumber: '',
    subject_label: [],
    class_id: '',
    school_id: '',
    from_date: '',
    to_date: ''
  }
  currentDate = new Date();
  currentUser: any;
  customFilters: any = {};
  dataSource: any;
  schoolList: [];
  classList: [];
  loading: boolean = false;
  liveUrl = environment.liveUrl;
  schoolName: any;
  listData: any;
  selectedSchoolName: string;
  displaySchoolName: boolean = false;
  disableDownload: boolean = true;
  subscription: Subscription;
  from_date: any;
  to_date: any;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private schoolService: SchoolService,
    private _backendService: BackendService,
    private reportService: ReportService,
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.schoolService.getSuggestSchools({}).subscribe((schoolList: any) => {
      this.schoolList = schoolList.data;
    })
    let classObj = {
      board_id: 1
    }
    this._backendService.getClasses(classObj).subscribe((data: any) => {
      // console.log(data);
      this.classList = data.data;
    });

    // this.getAppUsage();
  }

  // getAppUsage() {
  //   // this.customFilters = {
  //   //   offset: this.paginator.pageIndex * this.paginator.pageSize,
  //   //   limit: this.paginator.pageSize
  //   // }
  //   this.reportService.getAppUsage(this.customFilters).subscribe((userList: any) => {
  //     console.log(userList);
  //     this.dataSource = new MatTableDataSource(userList.data);
  //   })
  // }

  reset() {
    //Stop Searching and reset the table
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.loading = false;
    this.disableDownload = true;
    this.displaySchoolName = false;
    this.searchFilter = {
      name: '',
      email: '',
      contactNumber: '',
      subject_label: [],
      class_id: '',
      school_id: '',
      from_date: '',
      to_date: ''
    };
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.openSnackBar('Reset Done! Please Select Class & School. ', 'Close');
    //this.subscription.unsubscribe();
    //this.searchUserData(this.searchFilter);
  }

  searchUserData(filter) {
    this.loading = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.searchFilter = filter;
    this.disableDownload = true;
    this.displaySchoolName = false;


    // set from_date 
    if (this.searchFilter.from_date != '') {

      let timestamp_from_date = new Date(this.searchFilter.from_date).getTime();
      // add one day
      timestamp_from_date += 24 * 60 * 60 * 1000;
      // convert the adjusted timestamp back to a Date object
      let adjustedFromDate = new Date(timestamp_from_date);

      // convert the adjusted date to an ISO 8601 string without the time component
      let isoStringFromDate = adjustedFromDate.toISOString().slice(0, 10);

      // convert the adjusted timestamp back to a Date object
      this.from_date = isoStringFromDate;

    } else {
      this.from_date = '';
    }

    // set to_date 
    if (this.searchFilter.to_date != '') {

      let timestamp_to_date = new Date(this.searchFilter.to_date).getTime();
      timestamp_to_date += 24 * 60 * 60 * 1000;
      let adjustedToDate = new Date(timestamp_to_date);
      let isoStringToDate = adjustedToDate.toISOString().slice(0, 10);
      this.to_date = isoStringToDate;

    } else {
      this.to_date = '';
    }

    let obj = {
      name: filter.name,
      email: filter.email,
      contactNumber: filter.contactNumber,
      subject_label: filter.subject_label,
      school_ids: [filter.school_id],
      class_ids: [filter.class_id],
      from_date: this.from_date,
      to_date: this.to_date
    }
    // console.log(this.searchFilter)
    if ((this.searchFilter.class_id == '' || this.searchFilter.subject_id == '')) {
      this.loading = false;
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.openSnackBar('Please Select Class & School. ', 'Close');
    } else {
      //console.log('obj =' + JSON.stringify(obj));
      this.subscription = this.reportService.getAppUsage(obj).subscribe((data: any) => {
        // console.log(data)
        this.listData = data;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.loading = false;
        this.disableDownload = false;
        this.displaySchoolName = true;
        if (data.length == 0) {
          this.disableDownload = true;
          this.displaySchoolName = false;
          this.subscription.unsubscribe();
          this.openSnackBar('Data is not available for the selected school & class. ', 'Close');
        }

      },
        (error) => {
          this.loading = false;
          this.openSnackBar('An error occurred.', 'Close');
          console.error('An error occurred:', error);
        }
      )
    }
  }

  onSchoolSelected(id) {
    // console.log(id)
    const selectedSchool = this.schoolList.find((school: any) => school.id === id) as { id: number, school_name: string };
    this.selectedSchoolName = selectedSchool ? selectedSchool.school_name : '';
    //console.log(this.selectedSchoolName)
  }



  exportExcel() {
    // create a new workbook
    const workbook = XLSX.utils.book_new();

    // // create the app usage sheet
    // const appUsageSheet = XLSX.utils.json_to_sheet(this.listData.map(user => ({
    //   'Name': user.first_name + ' ' + user.last_name,
    //   'Email': user.email,
    //   'Grand Total (In Minutes)': user.totalTimeSpent,
    //   'Maths (In Minutes)': user.mathTimeSpent,
    //   'Science (In Minutes)': user.scienceTimeSpent
    // })));

    // // Set the width of columns A, B and C
    // const ws = XLSX.utils.sheet_add_aoa(appUsageSheet, [], { origin: "A1" });
    // ws['!cols'] = [{ width: 20 }, { width: 30 }, { width: 20 }, { width: 15 }, { width: 15 }];

    // XLSX.utils.book_append_sheet(workbook, appUsageSheet, 'App Usage');

    const appUsageSheet = XLSX.utils.json_to_sheet(this.listData.map(user => {
      const row = {
        'Name': user.first_name + ' ' + user.last_name,
        'Email': user.email,
        'Grand Total (In Minutes)': user.totalTimeSpent,
      };
      if (this.searchFilter.subject_label.length === 0) {
        row['Maths (In Minutes)'] = user.mathTimeSpent;
        row['Science (In Minutes)'] = user.scienceTimeSpent;
      } else {
        if (this.searchFilter.subject_label.length !== 0 && this.searchFilter.subject_label === '1') {
          row['Maths (In Minutes)'] = user.mathTimeSpent;
        }
        if (this.searchFilter.subject_label.length !== 0 && this.searchFilter.subject_label === '2') {
          row['Science (In Minutes)'] = user.scienceTimeSpent;
        }
      }
      return row;
    }));
    //console.log('subject_label:', this.searchFilter.subject_label);
    // Set the width of columns A, B and C
    const ws = XLSX.utils.sheet_add_aoa(appUsageSheet, [], { origin: "A1" });
    ws['!cols'] = [{ width: 20 }, { width: 30 }, { width: 20 }, { width: 15 }, { width: 15 }];

    XLSX.utils.book_append_sheet(workbook, appUsageSheet, 'App Usage');

    // create the mathData sheet
    const mathData = this.listData.map(user => {
      const mathLessons = user.mathData.map(lesson => ({
        'Name': user.first_name + ' ' + user.last_name,
        'Email': user.email,
        'Lesson Name': lesson.lesson_name,
        'Pre Test Completion Time (In Minutes)': lesson.pre_test_time,
        'Pre Test Score': lesson.pre_test_score,
        'Post Test Completion Time (In Minutes)': lesson.post_test_time,
        'Post Test Score': lesson.post_test_score,
        'Usage (In Minutes)': lesson.usage,
      }));
      return mathLessons;
    }).flat();

    // Check if mathData available then generate CHAPTERWISE MATHS sheet
    if (mathData.length > 0) {
      const mathDataSheet = XLSX.utils.json_to_sheet(mathData);

      // Set the width of columns in mathDataSheet
      mathDataSheet["!cols"] = [
        { width: 20 }, // Name column width
        { width: 30 }, // Email column width
        { width: 30 }, // Lesson Name column width
        { width: 15 }, // Pre Test Time column width
        { width: 15 }, // Pre Test Score column width
        { width: 15 }, // Post Test Time column width
        { width: 15 }, // Post Test Score column width
        { width: 10 }  // Usage column width
      ];

      XLSX.utils.book_append_sheet(workbook, mathDataSheet, 'CHAPTERWISE MATHS');

    }

    // create the scienceData sheet
    const scienceData = this.listData.map(user => {
      const scienceLessons = user.scienceData.map(lesson => ({
        'Name': user.first_name + ' ' + user.last_name,
        'Email': user.email,
        'Lesson Name': lesson.lesson_name,
        'Pre Test Completion Time (In Minutes)': lesson.pre_test_time,
        'Pre Test Score': lesson.pre_test_score,
        'Post Test Completion Time (In Minutes)': lesson.post_test_time,
        'Post Test Score': lesson.post_test_score,
        'Usage (In Minutes)': lesson.usage,
      }));
      return scienceLessons;
    }).flat();

    //check if scienceData available the generate CHAPTERWISE SCIENCE sheet
    if (scienceData.length > 0) {
      const scienceDataSheet = XLSX.utils.json_to_sheet(scienceData);

      // Set the width of columns in scienceDataSheet
      scienceDataSheet["!cols"] = [
        { width: 20 }, // Name column width
        { width: 30 }, // Email column width
        { width: 30 }, // Lesson Name column width
        { width: 15 }, // Pre Test Time column width
        { width: 15 }, // Pre Test Score column width
        { width: 15 }, // Post Test Time column width
        { width: 15 }, // Post Test Score column width
        { width: 10 }  // Usage column width
      ];

      XLSX.utils.book_append_sheet(workbook, scienceDataSheet, 'CHAPTERWISE SCIENCE');

    }

    // export the workbook as an Excel file
    XLSX.writeFile(workbook, 'MS_Report_' + this.selectedSchoolName + '.xlsx');
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  resetPageIndex() {
    // reset the paginator
    this.paginator.pageIndex = 0;
  }
  resetSearchFilter() {
    this.resetPageIndex();
    //searchPanel.toggle();
    this.searchFilter = {
      name: '',
      email: '',
      contactNumber: '',
      subject_label: [],
      class_id: '',
      school_id: '',
      from_date: '',
      to_date: ''
    };
    this.disableDownload = true;
    this.displaySchoolName = false;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.loading = false;
    //this.subscription.unsubscribe();
  }


  onFromDateChange(event: any) {
    const newDate = event.target.value;
    // Check if the entered value is a valid date in the "MM/DD/YYYY" format
    if (newDate && !/^\d{2}\/\d{2}\/\d{4}$/.test(newDate)) {
      // If not, set searchFilter.from_date to ''
      this.searchFilter.from_date = '';
    } else if (newDate) {
      const parsedDate = parse(newDate, 'MM/dd/yyyy', new Date());
      if (!isValid(parsedDate)) {
        // If the entered date is not a valid date, set searchFilter.from_date to ''
        this.searchFilter.from_date = '';
      } else {
        // Set the searchFilter.from_date to the parsed date value
        this.searchFilter.from_date = parsedDate.toISOString().slice(0, 10);
      }
    } else {
      // If the entered value is empty, set searchFilter.from_date to empty string
      this.searchFilter.from_date = '';
    }
  }

  onToDateChange(event: any) {
    const newDate = event.target.value;
    // Check if the entered value is a valid date in the "MM/DD/YYYY" format
    if (newDate && !/^\d{2}\/\d{2}\/\d{4}$/.test(newDate)) {
      // If not, set searchFilter.to_date to ''
      this.searchFilter.to_date = '';
    } else if (newDate) {
      const parsedDate = parse(newDate, 'MM/dd/yyyy', new Date());
      if (!isValid(parsedDate)) {
        // If the entered date is not a valid date, set searchFilter.to_date to ''
        this.searchFilter.to_date = '';
      } else {
        // Set the searchFilter.to_date to the parsed date value
        this.searchFilter.to_date = parsedDate.toISOString().slice(0, 10);
      }
    } else {
      // If the entered value is empty, set searchFilter.to_date to empty string
      this.searchFilter.to_date = '';
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          // this.sortBy = data.action;
          // this.sortDirection = data.direction;
          //this.get
        })
      ).subscribe();
  }

}

