import { Component, OnInit } from '@angular/core';
import { QuizSetService } from 'src/app/_services/quiz-set.service';

@Component({
  selector: 'app-my-attempted-quiz',
  templateUrl: './my-attempted-quiz.component.html',
  styleUrls: ['./my-attempted-quiz.component.css']
})
export class MyAttemptedQuizComponent implements OnInit {

  currentUser: any;
  currentSelSchoolId: number;
  userId: number;
  attemptQuiz: any;

  constructor(
    private quizSetService: QuizSetService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.userId = this.currentUser.id;
      this.currentSelSchoolId = this.currentUser.currentSelSchoolId;
    }
    this.getAttemptQuizSets();
  }

  getAttemptQuizSets() {
    let obj = { 
      school_id: this.currentSelSchoolId,
      user_id: this.userId
    };

    this.quizSetService.getattemptquizsetsLimit(obj)
      .subscribe((quizSets: any) => {
        this.attemptQuiz = quizSets.data;
      });
  }
}
