import { Component,  OnInit } from '@angular/core';
import {  MatDialog, MatTableDataSource } from '@angular/material'
import { ActivatedRoute, Router } from '@angular/router';
import { tr } from 'date-fns/locale';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { environment } from 'src/environments/environment';
import { PrePostTestsDataComponent } from '../../post-user-list-dash/pre-post-tests-data/pre-post-tests-data.component';
import { PostLessonStudentTestsComponent } from '../post-lesson-student-tests/post-lesson-student-tests.component';

@Component({
  selector: 'app-post-lesson-student-list',
  templateUrl: './post-lesson-student-list.component.html',
  styleUrls: ['./post-lesson-student-list.component.css']
})
export class PostLessonStudentListComponent implements OnInit {

  displayedColumns = ['student_name', 'pre_test_completion_time', 'pre_test_score', 'post_test_completion_time', 'post_test_score', 'usage', 'progress'];
  dataSource: any;
  school_id: number;
  class_id: number;
  section_id: number;
  lesson_id: number;
  lesson_name: any;
  lessonDataList: any;
  subject_id: number;
  tempObj: { school_id: number; class_id: number; section_id: number; };
  school_name: any;
  class_name: any;
  section_name: any;
  liveUrl = environment.liveUrl;
  loading: boolean = false;
  defaultTab = 1;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dashboardService : DashboardService,
    private dialog: MatDialog
   
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
        this.subject_id = +params['subject_id']
        this.lesson_id = +params['lesson_id']
      }
    ); 
    //console.log(this.section_id)
    this.dashboardService.getLessonById(this.lesson_id).subscribe((result: any) => {
      //console.log(result)
      this.lesson_name = result.data.lesson_name;
    })
    this.getLessonStudentData();
    this.breadcrumbs();
  }

  breadcrumbs(){
    this.tempObj = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id
    }

    // get School Name
    this.dashboardService.getSchoolById(this.tempObj).subscribe((schoolData: any) => {
       //console.log(schoolData);
      this.school_name = schoolData.data.school_name;
    })

    // get Class Name
    this.dashboardService.getClass(this.class_id).subscribe((classData: any) => {
      // console.log(classData);
      this.class_name = classData.data.class_name;
    })

      // get Section Name
    this.dashboardService.getSection(this.tempObj).subscribe((sectionData: any) => {
      // console.log(sectionData);
      this.section_name = sectionData.data.class_section.section_name;
    })

  }



  getLessonStudentData(){
    this.loading = true;
    let obj ={
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      subject_id: this.subject_id,
      lesson_id: this.lesson_id
    }
    //console.log(JSON.stringify(obj))
    this.dashboardService.getLessonUserPerformance(obj).subscribe((lessonData: any) => {
     // console.log(lessonData);
      this.lessonDataList = lessonData.pre_post_test;
      this.dataSource = new MatTableDataSource(this.lessonDataList);
      this.loading = false;
    })
  }
  
  
  openPreLessonTests(user_id: number){
    let obj ={
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      quiz_user_id: user_id,
      subject_id: this.subject_id,
      lesson_id: this.lesson_id
    }
   //console.log(obj)
    let preTest = true;
    this.dialog.open(PrePostTestsDataComponent, { data: { obj, preTest } });
    //this.dialog.open(PostLessonStudentTestsComponent, {data:{obj, preTest}}); 
  }

  openPostLessonTests(user_id: number){
    let obj ={
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      quiz_user_id: user_id,
      subject_id: this.subject_id,
      lesson_id: this.lesson_id
    }
    let preTest = false;
    this.dialog.open(PrePostTestsDataComponent, { data: { obj, preTest } });
    //this.dialog.open(PostLessonStudentTestsComponent, {data:{obj, preTest}}); 
  }

}
