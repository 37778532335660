import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SubscriptionService } from '../../subscription.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BackendService } from 'src/app/_services/backend.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-school-subscription',
  templateUrl: './school-subscription.component.html',
  styleUrls: ['./school-subscription.component.css']
})
export class SchoolSubscriptionComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'School Subscriptions';
  displayedColumns: string[] = ['school', 'class', 'frequencyType', 'amount', 'totalAmount', 'id'];
  schoolSubscription: any;
  dataSource: any = null;
  currentUser: any;
  customFilters: any = null;
  limit = 10;
  offset = 0;
  totalSubscribeSchool: number;
  user_id: number;
  type_order: number;
  schoolList: any;
  schoolArr = [];
  userData: any;
  classList: any;
  classArr = [];

  searchFilter: any = {
    school_id: [],
    class_id: [],
    status: 0,
    limit: 10,
    offset: 0
  };

  constructor(
    private subscriptionService: SubscriptionService,
    private backendServicce: BackendService,
    private route: ActivatedRoute,) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (this.currentUser) {
      this.user_id = this.currentUser.id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
      this.customFilters = {
        limit: this.paginator.pageSize,
        offset: this.paginator.pageIndex * this.paginator.pageSize
      }
    }
   
    this.route.queryParams.subscribe((params: any) => {
      if (params.customFilters) {
        this.customFilters = JSON.parse(params.customFilters);
        // console.log(this.customFilters);
        this.searchFilter = this.customFilters;
        if (this.customFilters.class_id != '') {
          this.getUserClasses();
 
        }
        // this.getTeacher();
        this.offset = this.paginator.pageIndex = (this.customFilters.offset / this.customFilters.limit);
        this.limit = this.paginator.pageSize = this.customFilters.limit;
      } else {
        this.customFilters = {};
      }
    })

    if (this.type_order == 1 || this.type_order == 2) {
    //  console.log('1')
      this.backendServicce.getActiveSchools(this.user_id).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { 
          this.schoolArr.push(element.id) 
          this.schoolArr.push(element.id);
        });
       // console.log(this.schoolArr)
        
        if (Object.keys(this.customFilters).length === 0) {
          this.customFilters = {
            school_id: this.schoolArr,
            class_id: this.classArr
          }
        }

        this.getUserClasses();
        this.getSubscriptionSchool();
      });
    } else {

      this.userData = this.currentUser.userData;
      this.userData.forEach(userVal => {
        if (this.classArr.indexOf(userVal.class.id) == -1) {
          this.classArr.push(userVal.class.id);
         // this.classes.push({ id: userVal.class.id, className: userVal.class.class_name })
        }
      });
      this.getUserClasses();
      this.schoolList = this.currentUser.userSchools
      this.currentUser.userSchools.forEach(school => {
        this.schoolArr.push(school.id);
      });


      // let obj = { user_id: this.user_id }
      // this.userService.userSchools(obj).subscribe((data: any) => {
      //   this.schoolList = data.data;
      //   this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
      //   this.classArr = data.userClass;

      // this.changeSchool(this.schoolArr);
      if (Object.keys(this.customFilters).length === 0) {
        this.customFilters = {
          school_id: this.schoolArr,
          class_id: this.classArr
        }
      }
      this.getSubscriptionSchool();
      // });
    }


  }

  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.backendServicce.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  getSubscriptionSchool(){
    this.customFilters = {
      school_id: this.customFilters.school_id,
      class_id: this.customFilters.class_id,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
   // console.log(this.customFilters);
      this.subscriptionService.getSubscriptionMSList(this.customFilters).subscribe((data: any) => {
        //console.log(data);
        this.totalSubscribeSchool = data.totalSubscription;
        this.schoolSubscription = data.data;
        this.dataSource = new MatTableDataSource(this.schoolSubscription);
      })
  }

  searchUserData(filters: any) {
    this.resetPageIndex();
    if (filters) {
      if (!filters.school_id || filters.school_id.length <= 0) {
        filters.school_id = this.schoolArr;
      }
      if (!filters.class_id || filters.class_id.length <= 0) {
        filters.class_id = this.classArr;
      }
      filters.limit = this.paginator.pageSize;
      filters.offset = this.paginator.pageIndex * this.paginator.pageSize;
      this.customFilters = filters;
      this.getSubscriptionSchool();
    }
  }

  resetSearchFilter(searchPanel: any) {
    this.resetPageIndex();
   // searchPanel.toggle();
    this.searchFilter = {};

    // this.customFilters = {
    //   school_id: this.schoolArr,
    //   class_id: this.classArr,
    //   offset: this.paginator.pageIndex * this.paginator.pageSize,
    //   limit: this.paginator.pageSize
    // }
    // this.getSubscriptionSchool();
  }

  resetPageIndex() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
  }

  reset() {
    this.resetPageIndex();
    this.customFilters = {}
    this.searchFilter = {}
    if (this.type_order == 1 || this.type_order == 2) {
      if (Object.keys(this.customFilters).length === 0) {
        this.customFilters = {
          school_id: this.schoolArr,
        }
      }
    } else {
      if (Object.keys(this.customFilters).length === 0) {
        this.customFilters = {
          school_id: this.schoolArr,
          class_id: this.classArr
        }
      }
    }
     
    this.getSubscriptionSchool();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          // this.sortBy = data.action;
          // this.sortDirection = data.direction;
          this.getSubscriptionSchool();
        })
      ).subscribe();
  }

}
