import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionService } from '../subscription.service';
import { MatDialog } from '@angular/material';
import { PaymentCouponComponent } from './payment-coupon/payment-coupon.component';
import { UserService } from 'src/app/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-subscription',
  templateUrl: './payment-subscription.component.html',
  styleUrls: ['./payment-subscription.component.css']
})
export class PaymentSubscriptionComponent implements OnInit {

  currentUser: any;
  user_id: any;
  userData: any;
  schoolList: any;
  schoolArr = [];
  classArr = [];
  schoolSubscription = [];
  totalSubscribeSchool: number;
  totalAmount: number;
  marksharksSubscription: [];
  showTab: boolean = false;
  defaultTab = 0;
  user_country_id: any;
  errorMessage: any;
  productName = environment.productName;

  constructor(
    public subscriptionService: SubscriptionService,
    private router: Router,
    private userService: UserService,
    public dialog: MatDialog) {  
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
   // console.log(this.currentUser)
    this.user_id = this.currentUser.adminUser[0].id;

    this.userData = this.currentUser.userData;
    this.userData.forEach(userVal => {
      if (this.classArr.indexOf(userVal.class.id) == -1) {
        this.classArr.push(userVal.class.id);
      }
    });
    this.schoolList = this.currentUser.userSchools
    this.schoolList.forEach(school => {
      this.schoolArr.push(school.id);
    });

    this.getUser();
    //this.getSubscriptionList();
  }
 
  // getSubscriptionListOld() {
  //   let obj = {
  //     school_id: this.schoolArr,
  //     class_id: this.classArr
  //   } 
  //  // console.log(obj)
  //    this.subscriptionService.getSubscriptionMSList(obj).subscribe((data: any) => {
  //      // console.log(data);
  //      this.totalSubscribeSchool = data.totalSubscription;
  //      if (this.totalSubscribeSchool > 0) {
  //        this.schoolSubscription = data.data;
  //      } else {
  //       this.subscriptionService.getSubFromUser(obj).subscribe((data: any) => {
  //        // console.log(data);
  //         this.totalSubscribeSchool = data.totalSubscription;
  //         if (this.totalSubscribeSchool > 0) {
  //           this.schoolSubscription = data.data;
  //         } else {
  //           let classObj = {
  //             class_id : this.classArr
  //           }
  //          // console.log(classObj)
  //           this.subscriptionService.getSchoolSubOfOKS(classObj).subscribe((data: any) => {
  //             // console.log(data);
  //             // if(data.data){
  //             //   this.totalSubscribeSchool = 1;
  //             //   this.schoolSubscription = [data.data];
  //             // }
  //           //  console.log(data);
  //             this.totalSubscribeSchool = data.totalSubscription;
  //             if (this.totalSubscribeSchool > 0) {
  //               this.schoolSubscription = data.data;

  //             }
  //           });
  //         }
  //       });
  //     }
  //   });
  // }

  getUser(){
    this.userService.getUser(this.user_id).subscribe((user: any) => {
      this.user_country_id = user.user_countries[0].country_id;
      //console.log('ci = '+this.user_country_id)
      if(this.user_country_id != undefined){
        this. getSubscriptionList()
      }
    },
      (error: any) => this.errorMessage = error as any
    );
  }

  getSubscriptionList() {
    let obj = {
      school_id: this.schoolArr,
      class_id: this.classArr,
      country_id: this.user_country_id,
    } 
   // console.log(obj)
     this.subscriptionService.getSubscriptionMSListNew(obj).subscribe((data: any) => {
       // console.log(data);
        this.schoolSubscription = data.school_package;
        this.totalSubscribeSchool = data.school_package.length
        //console.log(this.totalSubscribeSchool)
        this.marksharksSubscription = data.marksharks_package;
        if(this.totalSubscribeSchool > 0){
          this.showTab = true;
          this.defaultTab = 0;
          //console.log(this.showTab)
        }
       
    });
  }

  openDialog(subscription){
    //console.log(subscription)
    this.dialog.open(PaymentCouponComponent, {data:{subscription}});
  }
}
