import { Component, OnInit, ViewChild } from '@angular/core';
import { ShowOnlineScheduleService } from '../show-online-schedule.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from '../../_services/backend.service';

@Component({
  selector: 'app-past-class',
  templateUrl: './past-class.component.html',
  styleUrls: ['./past-class.component.css']
})
export class PastClassComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['Sno', 'class', 'subject', 'lessonNum', 'lesson', 'classLevel',
    'startDate', 'endDate', 'day', 'id'];
  currentUser: any;
  customFilters: any = {};
  dataSource: any;
  total_schedule: number;
  schoolList: any;
  classList: any;
  sectionList: any;
  scheduleData = [];
  userId: number;
  schoolId: number;
  userType: number;
  showTable: boolean = false;
  // scheduleArr 
  limit = 10;
  offset = 0;
  schoolArr = [];
  classArr = [];
  sectionArr = [];
  teacherId: number;

  searchFilter: any = {
    start_date: '',
    end_date: '',
    school_id: [],
    class_id: [],
    section_id: [],
    user_id: '',
    limit: 10,
    offset: 0
  };
  constructor(
    private onlineScheduleService: ShowOnlineScheduleService,
    private backendService: BackendService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.currentUser.id;
    this.schoolId = this.currentUser.currentSelSchoolId;
    this.userType = this.currentUser.userData[0].user_type;

    if (this.userType == 1) {
      this.teacherId = this.userId;
    }
    this.route.queryParams.subscribe((params: any) => {
      if (params.customFilters) {
        this.customFilters = JSON.parse(params.customFilters);
        this.searchFilter = this.customFilters;
        this.sectionArr = this.customFilters.section_id
        if (this.customFilters.class_id != '') {
          this.changeClass(this.customFilters.class_id);
        }
        if(!this.customFilters.limit){
          this.customFilters.limit = this.limit
          this.customFilters.offset = this.offset
        }
      } else {
        // console.log("qwsertyhuiopasdfghjk");
        // this.customFilters = {
        //   school_id: this.schoolArr,
        //   class_id: this.classArr,
        //   section_id: this.sectionArr,
        //   limit: this.paginator.pageSize,
        //   offset: this.paginator.pageIndex * this.paginator.pageSize
        // }
        this.customFilters = {};
      }
    })
    let obj = { user_id: this.userId }
    this.backendService.userSchools(obj).subscribe((data: any) => {
      this.schoolList = data.data;
      // this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
      this.schoolArr.push(this.schoolId);
      this.classArr = data.userClass;
      this.sectionArr = data.userSection;
      this.getUserClasses();

      if (Object.keys(this.customFilters).length === 0) {
        this.customFilters = {
          school_id: this.schoolArr,
          class_id: this.classArr,
          section_id: this.sectionArr,
          user_id: this.teacherId,
          offset: this.offset,
          limit: this.limit
        }
      }
      this.getSchedule();
    });
    let classObj = {
      board_id: 1
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  getSchedule() {
    // this.customFilters = {
    //   scheduleType: 'past',
    //   start_date: this.customFilters.start_date,
    //   end_date: this.customFilters.end_date,
    //   school_id: this.customFilters.school_id,
    //   class_id: this.customFilters.class_id,
    //   section_id: this.customFilters.section_id,
    //   user_id: this.customFilters.user_id,
    //   offset: this.paginator.pageIndex * this.paginator.pageSize,
    //   limit: this.paginator.pageSize
    // }
    this.showTable = true;
    // console.log(this.customFilters);
    // this.onlineScheduleService.getSchedule(this.customFilters).subscribe((scheduleList: any) => {
    //   this.scheduleData = [];
    //   scheduleList.data.forEach(sl => {
    //     let date = new Date(sl.end_date);
    //     let hours = date.getHours();
    //     sl.ampm = hours >= 12 ? 'PM' : 'AM';
    //     this.scheduleData.push(sl);
    //   });
    //   this.dataSource = new MatTableDataSource (this.scheduleData);
    //   this.total_schedule = scheduleList.totalSchedule;
    //   // this.dataSource.paginator = this.paginator;
    //   // this.dataSource.sort = this.sort;
    // })
  }

  reset() {
    this.getSchedule();
  }

  searchUserData(filters: any) {
    this.resetPageIndex();
    if (filters) {
      if (!filters.school_id || filters.school_id.length <= 0) {
        filters.school_id = this.schoolArr;
      }
      if (!filters.class_id || filters.class_id.length <= 0) {
        filters.class_id = this.classArr;
      }
      if (!filters.section_id || filters.section_id.length <= 0) {
        filters.section_id = this.sectionArr;
      }
      filters.user_id = this.teacherId;
      filters.limit = this.paginator.pageSize;
      filters.offset = this.paginator.pageIndex * this.paginator.pageSize;
      this.customFilters = filters;
      this.getSchedule();
    }
  }

  resetSearchFilter(searchPanel: any) {
    this.resetPageIndex();
    searchPanel.toggle();
    this.searchFilter = {};
    this.sectionArr = null;
    this.customFilters = {
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr,
      user_id: this.teacherId,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
    this.getSchedule();
  }

  resetPageIndex() {
    this.paginator.pageIndex = 0;
  }

  changeClass(class_id) {
    // this.sectionIdArr = [class_id];
    let classObj = { class_id: class_id, section_id: this.sectionArr }
    // console.log(classObj)
    this.backendService.getClassSections(classObj).subscribe((result: any) => {
      // console.log(result)
      this.sectionList = result.data;

    })
  }

  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  // ngAfterViewInit() {
  //   this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
  //   merge(this.sort.sortChange, this.paginator.page)
  //     .pipe(
  //       tap((data: any) => {
  //         this.getSchedule();
  //       })
  //     ).subscribe();
  // }


}
