import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';
import { MsContentService } from '../ms-content.service';

@Component({
  selector: 'app-content-topic',
  templateUrl: './content-topic.component.html',
  styleUrls: ['./content-topic.component.css']
})
export class ContentTopicComponent implements OnInit {

  currentUser: any;
  class_id: number;
  lesson_id: any;
  userData: any;
  defaultTab = 0;
  contentData: any;
  lessonName: string;
  className: string;
  country_id: number;
  school_id: number;
  user_id: number;
  schoolArr = [];
  classArr = [];
  sectionArr = [];
  contentUrl = environment.contentUrl;
  country_lesson_id: number;
  contentType: string;

  constructor(
    private backendService: BackendService,
    private router: Router,
    private route: ActivatedRoute,
    private contentService: MsContentService,
    private snackBar: MatSnackBar,
    private _backendService: BackendService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = this.currentUser.id;
    if(this.currentUser){ 
      this.school_id = this.currentUser.currentSelSchoolId;
     }
     this._backendService.getSchoolById({school_id:this.school_id}).subscribe((schoolData: any)=> {
      //  console.log(schoolData);
       this.country_id = schoolData.data.schoolCountry.id;
       if(this.country_id == 2){
        this.contentService.getMapCountryLessons({lesson_id: this.lesson_id}).subscribe((data: any)=> {
          this.country_lesson_id = data.data.country_lesson_id;
          console.log(this.country_lesson_id);
        })
      }
     })
    this.route.params.subscribe(params => {
      this.class_id = parseInt(params.class_id);
      this.lesson_id = parseInt(params.lesson_id);
    });
    this.route.queryParams.subscribe(paramsData =>{
      this.contentType = paramsData.contentType;
      // console.log(this.prwCheck)
    })
    this._backendService.getClass(this.class_id).subscribe((className: any)=> {
      this.className = className.data.class_name;
    })
    let obj = {user_id: this.user_id}
    this._backendService.userSchools(obj).subscribe((data: any) => {
      // console.log(data);
      data.data.forEach(element => { this.schoolArr.push(element.id) });
      this.classArr = data.userClass;
      this.sectionArr = data.userSection;
      if(this.contentType){
        this.getTextbookContent()
      }else{
        this.getContent();
      }
    });
  }

  getContent(){
    let obj = {
      country_id: this.country_id,
      lesson_id: this.lesson_id,
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr
    }
    this.contentService.getLessonContent(obj).subscribe((contentData: any) => {
      this.contentData = contentData.data;
      this.lessonName = this.contentData[0].content_lesson.lesson_name;
      // console.log(this.contentData);
      this.contentData = this.contentData.filter((element, index, self) =>
        index === self.findIndex((t) => (
          t.topic_id === element.topic_id
        ))
      );
      // this.contentType = this.contentData.content_type;
    });
  }

  getTextbookContent(){
    let obj = {
      country_id: this.country_id,
      lesson_id: this.lesson_id,
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr
    }
    this.contentService.getTextbookLessonContent(obj).subscribe((contentData: any) => {
      this.contentData = contentData.data;
      this.lessonName = this.contentData[0].content_lesson.lesson_name;
      // console.log(this.contentData);
      this.contentData = this.contentData.filter((element, index, self) =>
        index === self.findIndex((t) => (
          t.topic_id === element.topic_id
        ))
      );
      // this.contentType = this.contentData.content_type;
    });
  }

  openData(topicId){
    if(this.contentType){
      this.openTextbookContentData(topicId);
    }else{
      this.openContentData(topicId);
    }
  }

  openContentData(topicId){
    let obj = {
      country_id: this.country_id,
      topic_id: topicId
    }
    this.contentService.getTopicContent(obj).subscribe((contentData: any) => {
      // console.log(contentData);
      if(contentData.data.length ==1 && contentData.data[0].content_type == 6){
        window.open(contentData.data[0].path);
      }else if(contentData.data.length ==1 && contentData.data[0].content_type != 6){
        this.router.navigate(['/content-data/',this.class_id,contentData.data[0].id, this.lesson_id],{
          queryParams: { lesson_id:this.lesson_id}
        })
      }
      else if(contentData.data.length >1){
        this.router.navigate(['/content-title/',this.class_id,this.lesson_id,topicId])
      }else if(contentData.data.length==0){
        this.openSnackBar("Content Not Available", 'Close');
      }
    });
  }

  openTextbookContentData(topicId){
    let obj = {
      country_id: this.country_id,
      topic_id: topicId
    }
    this.contentService.getTextbookTopicContent(obj).subscribe((contentData: any) => {
      // console.log(contentData);
      if(contentData.data.length ==1 ){
        this.router.navigate(['/content-textbook/',this.class_id,contentData.data[0].id, this.lesson_id],{
          queryParams: { lesson_id:this.lesson_id}
        })
      }
      else if(contentData.data.length >1){
        this.router.navigate(['/content-title/',this.class_id,this.lesson_id,topicId], {
          queryParams: { contentType: 'textbook' }
        })
      }else if(contentData.data.length==0){
        this.openSnackBar("Content Not Available", 'Close');
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}
