import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MatOption, MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-invite',
  templateUrl: './user-invite.component.html',
  styleUrls: ['./user-invite.component.css']
})

export class UserInviteComponent implements OnInit {


  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  @ViewChild('allSelectedSection') private allSelectedSection: MatOption;

  
  displayMessage: { [key: string]: string } = {};
  inviteForm: FormGroup;
  currentUser: any;
  pageTitle = 'Send Sign-up Invite';
  countryList = [];
  boards: any;
  classList = [];
  sectionList: any;
  sectionArr: any;
  subjectList: any;
  country_id: any;
  userId: number;
  userData: any;
  user_assigned_subjects: any;
  classIds = [];
  subjectIds = [];
  sectionIds = [];
  schoolId: number;
  emailstring: string;
  schoolCode: string;
  classCode: any;
  COUNTRY_CODES: any;
  SCHOOL_IDS: any
  BOARD_IDS: any;
  CLASS_IDS: any;
  SECTION_IDS: any
  SUBJECT_IDS: any;
  invitationLink: string;
  userReferralCode: string;
  liveUrl = environment.liveUrl;
  whatsAppUrl: string;
  shareText: string;

  private validationMessages: { [key: string]: { [key: string]: string } };  
  private genericValidator: GenericValidator;

  constructor(private fb: FormBuilder,
    private _backendService: BackendService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) { 
    this.validationMessages = {
      user_type: {
        required: 'User Type is required.'
      },
      board_id: {
        required: 'board is required.'
      },
      class_id: {
        required: 'class is required.'
      },
      section_id: {
        required: 'section is required.'
      },
      subject_id: {
        required: 'subject is required.'
      },
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
   }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    Observable.merge(this.inviteForm.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.inviteForm);
    });
  }

  ngOnInit() {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.currentUser.id;
    this.schoolId = this.currentUser.currentSelSchoolId;
    this.userData = this.currentUser.userData;
    this.user_assigned_subjects = this.currentUser.user_assigned_subjects;

    this.userData.forEach(userVal => {
      if (this.classIds.indexOf(userVal.class.id) == -1) {
        this.classIds.push(userVal.class.id);
      }
      if (this.sectionIds.indexOf(userVal.section_id) == -1) {
        this.sectionIds.push(userVal.section_id);
      }
    });
    console.log(this.classIds, this.sectionIds);
    this.user_assigned_subjects.forEach(sub => {
      this.subjectIds.push(sub.subject_id);
    });

    let schoolObj = {school_id: this.schoolId}
    this._backendService.getSchoolById(schoolObj).subscribe((data: any)=> {
      // console.log(data);
      this.country_id = data.data.schoolCountry.id;
      this.schoolCode = data.data.school_code;
    })

    this.inviteForm = this.fb.group({
      board_id: ['', Validators.required],
      class_id: ['', Validators.required],
      section_id: ['', Validators.required],
      subject_id: ['', Validators.required],
      user_type: ['', Validators.required],
    });
    this.getUserClasses();
    this._backendService.getBoards().subscribe((data: any) => {
      this.boards = data.data;
      this.inviteForm.patchValue({board_id: this.boards[0].id});
    });
  }



  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classIds
    }
    this._backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  // changeBoard(strVal, defaultVal = 0) {
  //   let paramsVal = { board_id: strVal };
  //   this._backendService.getClasses(paramsVal).subscribe((result: any) => {
  //     this.classList = result.data;
  //   });
  // }

  selectAllSection() {
    let sectionVal = [0];
    if (this.allSelectedSection.selected) {
      this.inviteForm.controls.section_id
        .patchValue([...this.sectionList.map(item => item.id), 0]);
      sectionVal = this.inviteForm.controls.section_id.value;
    } else {
      this.inviteForm.controls.section_id.patchValue([]);
    }
  }

  tosslePerSection() {
    if (this.allSelectedSection.selected) {
      this.allSelectedSection.deselect();
      return false;
    }
    if (this.inviteForm.controls.section_id.value.length === this.sectionList.length) {
      this.allSelectedSection.select();
    }
  }

  changeClass(class_id) {
    let classObj = { class_id: class_id, section_id: this.sectionIds }
    this._backendService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
    })
    let subjectObj = {class_id: class_id, subject_id: this.subjectIds}
    this._backendService.getSubjects(subjectObj).subscribe((result: any) => {
      this.subjectList = result.data;
    });
  }


  sendMail(){
    this.createCode();
    this.emailstring= `mailto:?Subject=Invitation to register&body=${this.shareText}`;
    window.location.href = this.emailstring;
  }

  // onSubmit() {
  //   console.log(this.inviteForm.value);
  // }

  createCode(){
    this.classCode = this.schoolCode+'/'+this.country_id+'/'+this.schoolId+'/1/'
    console.log(this.schoolCode, this.country_id);
    console.log(this.inviteForm.value);
    this.CLASS_IDS = this.inviteForm.value.class_id;
    this.SECTION_IDS = this.inviteForm.value.section_id;
    this.SUBJECT_IDS = this.inviteForm.value.subject_id;
    this.CLASS_IDS.forEach(element => { this.classCode = this.classCode+element+'-' });
    this.classCode = this.classCode.slice(0, -1) + '/';
    this.SECTION_IDS.forEach(element => { this.classCode = this.classCode+element+'-' });
    this.classCode = this.classCode.slice(0, -1) + '/';
    this.SUBJECT_IDS.forEach(element => { this.classCode = this.classCode+element+'-' });
    this.classCode = this.classCode.slice(0, -1);
    console.log(this.classCode);
    let signupType = this.inviteForm.value.user_type;
    
    // http://localhost:4200/#/teacherSignUp?signupType=2
    this._backendService.getStudentData({student_id: this.userId}).subscribe((user: any)=> {
      console.log(user);
      this.userReferralCode = user.data.userReferralCode;
      this.invitationLink = `${this.liveUrl}/#/teacherSignUp?signupType=${signupType}&classCode=${this.classCode}&referredby=${this.userReferralCode}`;
      this.shareText = `Invitation to register. Registration URL: ${this.invitationLink}`;
      this.shareText = encodeURIComponent(this.shareText);
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.whatsAppUrl = `whatsapp://send?text=${this.shareText}`;
      }
      else{
        this.whatsAppUrl = `https://web.whatsapp.com/send?text=${this.shareText}`;
      }
    })
  }

  copyClipboard(){
    const selBox = document.createElement('textarea');
    selBox.value = this.invitationLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    console.log(this.invitationLink);
    this.openSnackBar('Invitation link Copied. ', 'Close');
  }

  reset(){
    this.inviteForm.reset();
    this.invitationLink = '';
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}
