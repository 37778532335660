import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services';

@Injectable()
export class AuthGuard implements CanActivate {

    menuItems = [];
    allowedUrls: any;
    type_order: any;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            if(this.chkPermissions(state.url)){
                this.router.navigate(['/forbidden']);
            }
            this.isSubscribed(); //Check user subscription
            
            // logged in so return true    
            return true;
        }

        if(state.url.includes('download-report') 
            || state.url.includes('user-verification')){
            return true;
        }

        if(state.url.includes('signup')){
            console.log("testing")
            return true;
        }
        if(state.url.includes('teacherSignUp')){
            return true;
        }
        if(state.url.includes('verification')){
            return true;
        }

        if(state.url.includes('forbidden')){
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
        return false;
    }

    chkPermissions(url){
         //console.log(url)
        let allowStatus = [];
        this.menuItems = JSON.parse(localStorage.getItem('menus')); //All user assigned permissions
        if(this.menuItems){
          allowStatus = this.menuItems.filter(data => 
            {
                if(data.link == null || data.link == ''){
                    return false;
                } else { 
                    data.link = data.link;
                    if(url.includes(data.link)){
                        return true;
                    } else {
                        return false;
                    }
                } 
            });
        }
    
        // this.allowedUrls = JSON.parse(localStorage.getItem('allowed')); //All common urls
        // let allowedUrl = [];
        // if(this.allowedUrls){
        //   allowedUrl = this.allowedUrls.filter(data => '/'+data.link.includes(url));
        // }
        //  console.log(allowedUrl); 
        // if(allowStatus.length<1 && url != '/' && allowedUrl.length<1){
        
        if(allowStatus.length<1){
        //   this.router.navigate(['/forbidden']);
            return true;
        }
        return false;
    }

    isSubscribed(){
        let user = JSON.parse(localStorage.getItem('currentUser'));
        this.type_order = user.adminUser[0].user_Type.type_order;
        let obj = {
            user_id: user.adminUser[0].id,
            //school_id: user.currentSelSchoolId,
            class_id: user.currentClassId,
            quiz_user_id : user.adminUser[0].quiz_user_id
        }
        //console.log(obj);
        if(this.type_order == 1 || this.type_order == 2 || user.currentClassId != 6){//
            return true;
        }else{
            return this.authenticationService.isSubscribed(obj).subscribe((subVal: any)=>{
                //console.log(subVal);
                if(subVal.data[0].totalCount>0){
                    return true;
                } else {
                    /*
                    //Start - checking user subscription on liferay
                    let objLiferay = {
                        user_id: user.adminUser[0].id,
                        class_id: user.currentClassId,
                        quiz_user_id : user.adminUser[0].quiz_user_id,
                        headerVal: user.headerVal
                    }
                    console.log("objLiferay = "+JSON.stringify(objLiferay));
                    return false;
                    return this.authenticationService.isSubscribedLiferay(objLiferay).subscribe((subVal: any)=>{
                        //console.log(subVal);
                        if(subVal.data[0].totalCount>0){
                            return true;
                        } else {
                            this.router.navigate(['/paymentSubscription']);
                        }            
                        return false;
                    });
                    //End - checking user subscription on liferay
                    */
                   this.router.navigate(['/paymentSubscription']);
                   return false;
                }            
                return false;
            });
        }
        
    }
}