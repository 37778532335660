import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import {  ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from 'src/app/user/user.service';
import { environment } from 'src/environments/environment';
import { ReportService } from 'src/app/report/report.service';
import { MatTableExporterModule } from 'mat-table-exporter';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/_services/backend.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-student-topic-usage',
  templateUrl: './student-topic-usage.component.html',
  styleUrls: ['./student-topic-usage.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class StudentTopicUsageComponent implements OnInit {
  //@Input() filterData;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['userName', 'email', 'contact_no', 'lesson_usage']; //, 'user_last_login', 'total_quiz_attempt', 'total_attempted', 'total_correct', 'total_incorrect', 'total_skipped', 'percentage', 'total_usage'
  pageTitle = 'Student Topic Usage Report';
  dataSource: any;
  school_id: any;
  class_id: any;
  section_id: any;
  subject_id: any;
  lesson_id: any;
  topic_id: any;
  old_lesson_id: any;
  old_topic_id: any;
  screen_no: any;
  limit = 12;
  offset = 0;
  customFilters = {}
  studentList: any;
  section_name: string;
  student_id: any;
  currentUser: any;
  userId: any;
  type_order: any;
  schoolList: any;
  classList: any;
  schoolArr = [];
  classArr = [];
  sectionArr: [];
  classObjArr = [];
  sectionList: any;
  subjectList: any;
  lessonList: any;
  topicList: any;
  screenList: any;
  searchFilter: any = {
    phone: '',
    email: '',
    school_id: '',
    class_id: '',
    section_id: '',
    subject_id: '',
    lesson_id: '',
    topic_id: '',
    old_lesson_id: '',
    old_topic_id: '',
    screen_no: '',
    user_type_id: '',
    from_date: '',
    to_date: ''
  };
  suggestEmail: any;
  errorMessage: any;
  loading: boolean = false;
  liveUrl = environment.liveUrl;
  customFilter: any;
  userTypes: void;
  disableDownload: boolean = true;
  subscription: Subscription;
  school_disable : boolean = false
  class_disable: boolean = false;
  section_disable: boolean = false;
  dash: any;
  user_type_disable: boolean = false;
  selectedSchoolName: string;
  displaySchoolName: boolean = false;
  from_date: any;
  to_date: any;
  currentDate = new Date();
  
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private backendService: BackendService,
    private reportService: ReportService,
    private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

   //.log(this.currentUser)
    //console.log(this.filterData)
    
    if (this.currentUser) {
      this.userId = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    }
    if (this.type_order == 1 || this.type_order == 2) {
      this.userService.getActiveSchools(this.userId).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.getUserClasses();
      });
    } else {
      let obj = { user_id: this.userId }
      this.userService.userSchools(obj).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.classArr = data.userClass;
        this.sectionArr = data.userSection;
        this.getUserClasses();
      });
    }

    this.userService.getUserTypes(this.userId).subscribe((data) => {
      this.userTypes = data;
     // console.log(this.userTypes)
    });

    this.route.queryParams.subscribe((params: any) => {
     // console.log(params)
      if (params.searchFilter) {
        this.loading = true;
        this.customFilter = JSON.parse(params.searchFilter);
       //console.log('customFilter',this.customFilter)
        if (this.customFilter.school_id && this.customFilter.class_id == '') {
          this.searchFilter.school_id = this.customFilter.school_id;
          this.getUserList();
        }
        if (this.customFilter.class_id != '' && this.customFilter.school_id != '') {
          this.searchFilter.school_id = this.customFilter.school_id;
          this.changeClass(this.customFilter.class_id, () => {
            this.searchFilter.class_id = this.customFilter.class_id;
           // console.log(this.searchFilter.class_id)
            this.searchFilter.section_id = this.customFilter.section_id;
            this.searchFilter.subject_id = this.customFilter.subject_id;
            this.searchFilter.lesson_id = this.customFilter.lesson_id;
            this.searchFilter.topic_id = this.customFilter.topic_id;
            this.searchFilter.old_lesson_id = this.customFilter.old_lesson_id;
            this.searchFilter.old_topic_id = this.customFilter.old_topic_id;
            this.searchFilter.screen_no = this.customFilter.screen_no;
            this.searchFilter.user_type_id = this.customFilter.user_type_id,
            this.searchFilter.email = this.customFilter.email,
            this.searchFilter.phone = this.customFilter.phone,
            this.searchFilter.from_date = this.customFilter.from_date,
            this.searchFilter.to_date = this.customFilter.to_date

            this.getUserList();
          });
        } else {
          this.customFilters = {};
        }
      } else {
        this.customFilters = {};
      }
    })
  }

  getUserList(){

     // Set from_date
     if (this.searchFilter.from_date !== '') {
      const timestamp_from_date = new Date(this.searchFilter.from_date).getTime();
      const adjustedFromDate = new Date(timestamp_from_date);
      const month = adjustedFromDate.getMonth() + 1;
      const day = adjustedFromDate.getDate();
      const year = adjustedFromDate.getFullYear();
      const isoStringFromDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
      this.from_date = isoStringFromDate;
    } else {
      this.from_date = '';
    }

    // Set to_date
    if (this.searchFilter.to_date !== '') {
      const timestamp_to_date = new Date(this.searchFilter.to_date).getTime();
      const adjustedToDate = new Date(timestamp_to_date);
      const month = adjustedToDate.getMonth() + 1;
      const day = adjustedToDate.getDate();
      const year = adjustedToDate.getFullYear();
      const isoStringToDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
      this.to_date = isoStringToDate;
    } else {
      this.to_date = '';
    }
//console.log('searchFilter',this.searchFilter);
    let obj = {
      school_ids: [this.searchFilter.school_id],
      class_ids: [this.searchFilter.class_id],
      section_ids: [this.searchFilter.section_id],
      subject_ids: [this.searchFilter.subject_id],
      lesson_ids: [this.searchFilter.old_lesson_id],
      topic_ids: [this.searchFilter.old_topic_id],
      screen_nos: [this.searchFilter.screen_no],
      email: this.searchFilter.email,
      mobile: this.searchFilter.phone,
      user_type_id: this.searchFilter.user_type_id,
      start_date: this.from_date,
      end_date: this.to_date
    }
   //console.log(obj);//return false;
    this.subscription = this.reportService.getUserUsage(obj).subscribe((data:any) => {
      //console.log(data); //return;
      let listStudent = data;
      //if( data.status !== false){
        if(listStudent.length != 0){
          this.onSchoolSelected(this.searchFilter.school_id);
          this.studentList = listStudent.filter(item => item !== null);
          this.dataSource = new MatTableDataSource(this.studentList);
          this.dataSource.paginator = this.paginator;
          this.displaySchoolName = true;
          this.disableDownload = false;
          //this.dataSource.sort = this.sort;
          
        } else {
          this.displaySchoolName = false;
          this.disableDownload = true;
          this.dataSource = new MatTableDataSource();
          this.errorMessage = data.message;
          this.openSnackBar('Record not found. Search again with different criteria !!!', 'Close');
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
        }   
      /*} else {
        this.errorMessage = data.message;
        this.displaySchoolName = false;
        this.disableDownload = true;
        this.openSnackBar(this.errorMessage, 'Close');
        this.dataSource = new MatTableDataSource();
        this.dataSource.paginator = this.paginator;
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      }*/
      this.loading = false;
    },
    (error) => {
      this.loading = false;
      this.openSnackBar('An error occurred.', 'Close');
      console.error('An error occurred:', error);
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    })
  }
  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2500,
    });
  }

  onSchoolSelected(id) {
    // console.log(id)
    const selectedSchool = this.schoolList.find((school: any) => school.id === id) as { id: number, school_name: string };
    this.selectedSchoolName = selectedSchool ? selectedSchool.school_name : '';
   // console.log(this.selectedSchoolName)
  }

  openStudentLessonList(school_id, class_id, section_id, user_id) {
    this.student_id = user_id;
    //console.log(this.student_id)

    // this.router.navigate(['/post_student_lesson_list', `${school_id}`, `${class_id}`, `${section_id}`, `${this.student_id}`], 
    // {queryParams: {usage_report: 'Yes', dash: this.dash, searchFilter: JSON.stringify(this.searchFilter)}});
    this.router.navigate(['/post_student_lesson_list', school_id, class_id, section_id, this.student_id], {
      queryParams: { usage_report: 'Yes', dash: this.dash, searchFilter: JSON.stringify(this.searchFilter) }
    });
  }

  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.userService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  // changeClass(class_id) {
  //   this.searchFilter.section_id = '';
  //   let classObj = { class_id: [class_id], section_id: this.sectionArr }
  //   //console.log(classObj)
  //   this.userService.getClassSections(classObj).subscribe((result: any) => {
  //     this.sectionList = result.data;
  //     //console.log(this.sectionList)
  //   })
  // }

  /*changeClass(class_id, callback) {
   // console.log(class_id)
    this.searchFilter.section_id = '';
    let classObj = { class_id: [class_id], section_id: this.sectionArr };
    this.userService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
      if (callback) {
        callback();
      }
    });
  }*/

  changeClass(class_id, callback) {
    let classObj = { class_id: [class_id], section_id: this.sectionArr }
    this.backendService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
    });
    this.backendService.getSubjects(classObj).subscribe((result: any) => {
      this.subjectList = result.data;
    });
  }

  changeSubject(subject_id) {
    let subjectObj = { subject_id: [subject_id] }
    //console.log('subjectObj',subjectObj);
    this.backendService.getLessons(subjectObj).subscribe((result: any) => {
      this.lessonList = result.data;
    });
  }

  changeLesson(subject_id, lesson_id) {
    let lessonObj = { subject_id: [subject_id], lesson_id: [lesson_id] }
    this.backendService.getLessons(lessonObj).subscribe((result: any) => {
      this.old_lesson_id = result.data[0].old_record_id;
    });

    this.backendService.getTopics(lessonObj).subscribe((result: any) => {
      this.topicList = result.data;
    });
  }

  changeTopic(topic_id) {
    let screenObj = { topic_id: [topic_id] };
    let topicObj = { topic_id: topic_id };
    this.backendService.getTopics(topicObj).subscribe((result: any) => {
      //console.log('resultdata',result.data[0]);
      this.old_topic_id = result.data[0].old_record_id;
    });

    this.backendService.getScreens(screenObj).subscribe((result: any) => {
      this.screenList = result.data;
    });
  }
  

  searchUserData(filters: any) {
    this.searchFilter = filters;
    this.disableDownload = true;
   //console.log('searchFilter11',this.searchFilter)
   this.loading = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.getUserList();
  }

  reset() {
    this.loading = false;
    this.searchFilter = {
      phone: '',
      email: '',
      school_id: '',
      class_id: '',
      section_id: '',
      subject_id: '',
      lesson_id: '',
      topic_id: '',
      old_lesson_id: '',
      old_topic_id: '',
      screen_no: '',
      user_type_id: '',
      from_date: '',
      to_date: ''
    };
    this.disableDownload = true;
    this.displaySchoolName = false;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.sectionList = [];
    // if(this.filterData){
    //   this.searchFilter.school_id = this.filterData.school_id;
    //   this.searchFilter.class_id = this.filterData.class_id;
    //   this.changeClass(this.searchFilter.class_id, '');
    //   this.searchFilter.section_id = this.filterData.section_id;
    //   this.searchFilter.user_type_id = 8;
    //   this.searchFilter.email = '';
    //   this.searchFilter.phone = '';
    // } else {
    //   this.searchFilter = {};
    // }
    //this.getUserList();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  emailFilter(filterValue: string) {
    //this.errorMessage = {};
    if (filterValue.length > 4) {
      let obj = { email: filterValue};
      //console.log(obj)
      this.userService.getSuggestData(obj).subscribe((emailData: any) => {
       // console.log(emailData);
        this.suggestEmail = emailData.data;
        // console.log(filterValue.indexOf('@'),  this.suggestData.length);
        // if (filterValue.indexOf('@') > 0 && this.suggestEmail.length <= 0) {
        //   this.errorMessage.message = "User is not registered"
        // }
      });
    }
  }
  
  fillData(suggest) {
    this.searchFilter.email = suggest.email;
  } 

  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }

}
