import { Component, OnInit, ViewChild } from '@angular/core';
import { from, merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { BackendService } from 'src/app/_services/backend.service';
import { SchoolService } from 'src/app/school/school.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { Subscription } from 'rxjs';
import { isValid, parse } from 'date-fns';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionService } from 'src/app/subscription/subscription.service';

@Component({
  selector: 'app-user-invoice-list',
  templateUrl: './user-invoice-list.component.html',
  styleUrls: ['./user-invoice-list.component.css']
})
export class UserInvoiceListComponent implements OnInit {

 
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'User Invoices';
  displayedColumns: string[] = ['name', 'email', 'class', 'payment_type', 'amount', 'invoice_date', 'id'];

  searchFilter: any = {
    country_id: '',
    name: '',
    email: '',
    order_no: '',
    invoice_no: '',
    from_date: '',
    to_date: ''
  }
  backToPage: number;

  currentUser: any;
  customFilters: any = {};
  dataSource: any;
  loading: boolean = false;
  liveUrl = environment.liveUrl;
  listData: any;
  subscription: Subscription;
  backToIndex: number;

  currentPageIndex = 2;
  from_date: any;
  to_date: any;
  currentDate = new Date();
  isCountrySelected: boolean = false;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private schoolService: SchoolService,
    private _backendService: BackendService,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.isCountrySelected = false;
    
    this.route.queryParams.subscribe((params: any) => {
      this.backToIndex = +params.backToIndex || 0;
      if (params.customFilters) {
        this.searchFilter = JSON.parse(params.customFilters);
        if(params.customFilters.country_id != ''){
          this.isCountrySelected = true;
        }
        this.searchUserData(this.searchFilter)
      } else {
        this.searchFilter = {
          country_id: '',
          name: '',
          email: '',
          order_no: '',
          invoice_no: '',
          from_date: '',
           to_date: ''
        }
        this.isCountrySelected = false;
      }
    })
  }


  getPaginatorPageIndex(): number {
    return this.backToIndex;
  }

  reset() {
    //Stop Searching and reset the table
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.loading = false;
    this.searchFilter = {
      country_id: '',
      name: '',
      email: '',
      order_no: '',
      invoice_no: '',
      from_date: '',
      to_date: ''
    };
    this.isCountrySelected = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.openSnackBar('Reset Done!', 'Close');
  }

  searchUserData(filter) {
    this.loading = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.searchFilter = filter;

       // Set from_date
       if (this.searchFilter.from_date !== '') {
        const timestamp_from_date = new Date(this.searchFilter.from_date).getTime();
        const adjustedFromDate = new Date(timestamp_from_date);
        const month = adjustedFromDate.getMonth() + 1;
        const day = adjustedFromDate.getDate();
        const year = adjustedFromDate.getFullYear();
        const isoStringFromDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
        this.from_date = isoStringFromDate;
      } else {
        this.from_date = '';
      }
  
      // Set to_date
      if (this.searchFilter.to_date !== '') {
        const timestamp_to_date = new Date(this.searchFilter.to_date).getTime();
        const adjustedToDate = new Date(timestamp_to_date);
        const month = adjustedToDate.getMonth() + 1;
        const day = adjustedToDate.getDate();
        const year = adjustedToDate.getFullYear();
        const isoStringToDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
        this.to_date = isoStringToDate;
      } else {
        this.to_date = '';
      }

    let obj = {
      country_id: Number(filter.country_id),
      name: filter.name && filter.name.trim(),
      email: filter.email && filter.email.trim(),
      order_id: filter.order_no && filter.order_no.trim(),
      invoice_id: filter.invoice_no && filter.invoice_no.trim(),
      from_date: this.from_date,
      to_date: this.to_date
    }
    //console.log(obj)
     //console.log(this.searchFilter)
    
    if ((this.searchFilter.name.trim() === '' && this.searchFilter.email.trim() === ''  &&
     this.searchFilter.order_no.trim() === ''  && this.searchFilter.invoice_no.trim() === '' &&
     this.searchFilter.from_date === '' && this.searchFilter.to_date === '')) {
      this.loading = false;
      //console.log(obj)
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.openSnackBar('Please search with valid data. ', 'Close');
    } else {
      //console.log(obj)
      this.dataSource = new MatTableDataSource(this.listData);
      this.dataSource.paginator = this.paginator;
      //console.log('obj =' + JSON.stringify(obj));
      this.subscription = this.subscriptionService.getInvoice(obj).subscribe((data: any) => {
       // console.log(data)
        this.listData = data;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.loading = false;
        if (data.length == 0) {
          this.subscription.unsubscribe();
          this.openSnackBar('Data is not available for the selected data ', 'Close');
        }
      },
        (error) => {
          this.loading = false;
          this.openSnackBar('An error occurred.', 'Close');
          console.error('An error occurred:', error);
        }
      )
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  resetPageIndex() {
    // reset the paginator
    this.paginator.pageIndex = 0;
  }

  onCountrySelect() {
    this.isCountrySelected = !!this.searchFilter.country_id;
  }

  resetSearchFilter() {
    this.resetPageIndex();
    //searchPanel.toggle();
    this.searchFilter = {
      country_id: '',
      name: '',
      email: '',
      order_no: '',
      invoice_no: '',
      from_date: '',
      to_date: ''
    };
    this.isCountrySelected = false;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.loading = false;
    //this.subscription.unsubscribe();
  }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          // this.sortBy = data.action;
          // this.sortDirection = data.direction;
          //this.get
        })
      ).subscribe();
  }

}

