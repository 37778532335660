import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-success',
  template: `
    <h3>
      Subscription updated successfully. 
      <span 
      [ngClass]="['cursorPointer','textVal']"
      (click)="redirectDash()">Click</span> here to redirect to dashboard.
    </h3>
  `,
  styles: [`
    .cursorPointer {
      cursor: Pointer
    }
    .textVal {
      color: blue
    }
  `]
})
export class PaymentSuccessComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  redirectDash() {
    this.router.navigate(['/dashboard']);
  }
}
