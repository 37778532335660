import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/_services/backend.service';
import { PostTestService } from '../post-test.service';

@Component({
  selector: 'app-start-test',
  templateUrl: './start-test.component.html',
  styleUrls: ['./start-test.component.css']
})
export class StartTestComponent implements OnInit {

  sub: Subscription;
  pageTitle: string;
  currentUser: any;
  postTestForm: any;
  userId: number;
  userTestQuestions: any;
  postTestQuestions: any;
  postTestQuestion: any;
  quesIndex: number;
  topicData: any;
  lessonId: number;
  totalQues: number;
  topicId = [];
  quesArrStatus = [];
  rightQuesCount = 0;
  questionNumber = 1;
  questionValue: string;
  rightAnsCount = 0;
  correct = 0;
  incorrect = 0;
  skipped = 0;
  totalAttempted = 0;
  schoolId: number;
  classId: number;
  sectionId: number;
  subjectId: number;
  subjectName: string;
  quesInc =  1;
  topicQuesInc = 1;
  quizRes: any;
  quizSetId: number;
  quesArr = [];
  postQuesArr = [];  
  backStatus = false;
  disbBtn = false;
  liferayHeader: string;
  startTime: number;
  endTime: number;
  timeSpentInSeconds: number;
  testCondition: any;
  quesOrder = 1;

  constructor(
    private backendService: BackendService,
    private postTestService: PostTestService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private location: PlatformLocation
  ) { 
    location.onPopState(()=>{
      this.backStatus = true;
    });
  }


  ngOnInit() {

    this.route.queryParams.subscribe( parmasData => {
      this.classId = parmasData.class_id;
      //console.log('parmasData = ',parmasData);
    });
    this.testCondition = JSON.parse(localStorage.getItem('testCondition'));
    //console.log(this.testCondition)
    this.quesArr = [];
    this.quesArrStatus = [];
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.userId = this.currentUser.id;
      this.schoolId = this.currentUser.currentSelSchoolId;
      //this.classId = this.currentUser.userData[0].class_id;
      this.sectionId = this.currentUser.userData[0].section_id;
      this.liferayHeader = this.currentUser.headerVal;
    }
    this.userTestQuestions = JSON.parse(localStorage.getItem('userTestQuestions'));
    //console.log('userTestQuestions = ',this.userTestQuestions.quiz_questions);

    //console.log(this.userTestQuestions.quiz_questions);
    this.postTestForm = this.fb.group({
      user_id: ["",[Validators.required]],
      total_attempted: ['',''],
      total_correct:['',''],
      questions: this.fb.array([])
    });
    this.pageTitle = 'Post Test - '+this.userTestQuestions.lesson_name;

    this.sub = this.route.params.subscribe((params)=>{
      this.quesIndex = +params['id'];
      this.postTestQuestions = this.userTestQuestions.quiz_questions;
      this.postTestQuestion = this.userTestQuestions.quiz_questions[this.quesIndex];
      this.lessonId = this.userTestQuestions.lessonId;
      this.subjectId = this.userTestQuestions.subjectId;
      this.subjectName = this.userTestQuestions.subjectName;
      this.questionNumber = this.userTestQuestions.questionNumber;
      this.topicQuesInc = this.userTestQuestions.topicQuesInc;
      this.quesInc = this.userTestQuestions.quesInc;
      this.questionValue = this.postTestQuestion.syllabus_questions.question;
      this.getTopics();
    });
    this.startTime = Date.now();
    //console.log('postTestQuestions',this.postTestQuestions);
  }

  ngOnDestroy(){
    // this.sub.unsubscribe();
  }

  quesAttempt(quesIndex, ansId, ansOption='Single'){

    this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.attempted = true;
    let questionId = this.userTestQuestions.quiz_questions[this.quesIndex].question_id;
    let answer_id = this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.answer_id;
    if(ansOption=='Multiple'){
      if(answer_id.indexOf(ansId)>-1){
        ansId = new RegExp(","+ansId,"g");
        this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.answer_id = answer_id.replace(ansId,'');
      } else {
        this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.answer_id = (answer_id != 0) ? answer_id + ',' + ansId : ansId.toString();
      }
    } else {
      this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.answer_id = ansId;
    }
    //console.log(this.userTestQuestions);
    localStorage.setItem('userTestQuestions',JSON.stringify(this.userTestQuestions));
    
    let answerIds = this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.answer_id;
    this.quesAnswerStatus(this.quesIndex, questionId, answerIds);
    // this.getQuizSetQuesDetails(quesIndex, questionId, answerIds);    
  }

  updateLocalQuestion(){
    this.userTestQuestions.questionNumber = this.questionNumber;
    this.userTestQuestions.topicQuesInc = this.topicQuesInc;
    this.userTestQuestions.quesInc = this.quesInc;
    this.postTestQuestion = this.userTestQuestions.quiz_questions[this.quesIndex];
    this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.visited = true;
    this.userTestQuestions.quiz_questions[this.quesIndex].visited = true;
    let answer_id = this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.answer_id;
    if(!answer_id){
      this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.answer_id = "0";
    }
    localStorage.setItem('userTestQuestions',JSON.stringify(this.userTestQuestions));
    this.userTestQuestions = JSON.parse(localStorage.getItem('userTestQuestions'));
  }

  openQuestion(quesIndex){
    //this.userTestQuestions = JSON.parse(localStorage.getItem('userTestQuestions'));
    //console.log('userTestQuestionsTotal = ',this.userTestQuestions.quiz_questions);
    //console.log('userTestQuestions = ',this.userTestQuestions.quiz_questions[quesIndex]);
    this.quesInc++;
    //console.log('quesIndex = '+quesIndex);
    //console.log('quesInc = '+this.quesInc);
    // this.userTestQuestions.questionNumber = this.questionNumber;
    // this.postTestQuestion = this.userTestQuestions.quiz_questions[this.quesIndex];
    // this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.visited = true;
    // let answer_id = this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.answer_id;
    // if(!answer_id){
    //   this.userTestQuestions.quiz_questions[this.quesIndex].syllabus_questions.answer_id = "0";
    // }
    // localStorage.setItem('userTestQuestions',JSON.stringify(this.userTestQuestions));
    // this.userTestQuestions = JSON.parse(localStorage.getItem('userTestQuestions'));
    // this.router.navigate([`/start-post-test/${quesIndex}`]);
    this.chkQuestionLevel(quesIndex);
    this.questionNumber++;    
    this.topicQuesInc++;
    //console.log('questionNumber = '+this.questionNumber);
    //console.log('topicQuesInc = '+this.topicQuesInc);
    this.updateLocalQuestion();
  }

  chkQuestionLevel(quesIndex){ 
    if((this.topicQuesInc)%6 === 0){
      this.quesInc = 1;
      // console.log("rightandcount1",this.rightQuesCount);
      // console.log("topicQuesInc",this.topicQuesInc);
      if((this.topicQuesInc)%6 === 0 && ((quesIndex)%9==0)){
        // quesIndex = quesIndex + 3;
        // console.log("6th",quesIndex);
        // console.log("topic1",this.topicQuesInc)
        // this.questionNumber++;
        // this.updateLocalQuestion();
        // this.router.navigate([`/start-post-test/${quesIndex}`]);
      } else if((this.topicQuesInc)%6 === 0){
        quesIndex = quesIndex + 3;
        // console.log("6th1",quesIndex);
        
        // this.questionNumber++;
        // this.updateLocalQuestion();
        // this.router.navigate([`/start-post-test/${quesIndex}`]);
      }
    } else if((this.quesInc)%4 === 0){
      // console.log("4th",this.quesInc)
      // console.log("rightandcount",this.rightQuesCount);
      this.quesInc = 1;
      if(this.rightQuesCount > 1){
        quesIndex = quesIndex + 3;
         //console.log("3rd = ",quesIndex);
        this.rightQuesCount = 0;
        // this.router.navigate([`/start-post-test/${quesIndex}`]);
      }
    }
    // console.log("1st",quesIndex);
   
    this.router.navigate([`/start-post-test/${quesIndex}`], {queryParams : {
      class_id : this.classId
    }});
  }

  quesAnswerStatus(questionNumber, questionId, answerIds){    
    answerIds = String(answerIds);
    let attemptAnsIds = answerIds.split(',');
    let rightAnsIds = [];
    let attempt_status;
    this.userTestQuestions.quiz_questions[questionNumber].syllabus_questions.rightansoption.forEach((rVal)=>{
      rightAnsIds.push(rVal.answer_id);
    });

    attemptAnsIds.sort();          
    rightAnsIds.sort();

    // this.rightAnsCount = 0;
    // this.rightQuesCount = 0;
          // console.log("rightAnsIds",rightAnsIds);
          // console.log("attemptAnsIds",attemptAnsIds);
    //Parse all the attempted answer ids of a question
    //console.log('attemptAnsIds',attemptAnsIds)
    this.quesArrStatus[questionId] = [];
    if(attemptAnsIds.toString() == rightAnsIds.toString()) {
      attempt_status = 1; //For correct Answer
      this.rightQuesCount++;
    } else {
      attempt_status = 2; //For incorrect Answer
    }
    this.quesArrStatus[questionId].push({question_id:questionId,answer_id : attemptAnsIds.toString(),attempt_status:attempt_status});
    //console.log('quesArrStatus',this.quesArrStatus);
    /*
    attemptAnsIds.forEach((element) =>{
      let ansId = Number(element); 
      if(rightAnsIds.includes(ansId) && ansId>0){
        attempt_status = 1;
        this.quesArrStatus[questionId].push({question_id:questionId,answer_id : ansId,attempt_status:attempt_status}); //For correct Answer
        this.rightQuesCount++;
      } else if(!rightAnsIds.includes(ansId) && ansId>0){
        attempt_status = 2;
        this.quesArrStatus[questionId].push({question_id:questionId,answer_id : ansId,attempt_status:attempt_status}); //For incorrect Answer
      } else {
        this.quesArrStatus[questionId].push({question_id:questionId,answer_id : ansId,attempt_status:attempt_status}); //For skipped question
      }
    });*/
    // this.updateQuizQuesValue();
  }

  getTestDetails(){
    this.updateLocalQuestion();
    this.disbBtn = true;
    this.totalAttempted = this.totalQues;
    this.correct = 0;
    this.incorrect = 0;
    this.skipped = 0;
    this.quesOrder = 0;
    //console.log(this.userTestQuestions.quiz_questions);
    this.userTestQuestions.quiz_questions.forEach((quesVal,index) => {
      let attemptAnsIds = [];
      let rightAnsIds = [];
      let ansId = quesVal.syllabus_questions.answer_id.toString();                
      let attempt_status;

      // if(ansId=="0"){ //If user has not attempted a question
      if(quesVal.syllabus_questions.visited){        
        attempt_status = 0;
        this.skipped++;
      }
      
      if(ansId.indexOf(',')){  //Split in case of multiple answers
        attemptAnsIds = ansId.split(',');
      } else {
        attemptAnsIds.push(Number(quesVal.syllabus_questions.answer_id));
      }

      //Get all right answer ids of a question
      this.userTestQuestions.quiz_questions[index].syllabus_questions.rightansoption.forEach((rVal)=>{
        rightAnsIds.push(rVal.answer_id);
      });

      attemptAnsIds.sort();          
      rightAnsIds.sort();

      this.rightAnsCount = 0;
      //let rightCount = 0;          
      //Parse all the attempted answer ids of a question
      this.quesArrStatus[quesVal.question_id] = [];

      if(attemptAnsIds.length > 0 && attemptAnsIds.toString() == rightAnsIds.toString()) {
        attempt_status = 1; //For correct Answer
        this.correct++;
        //rightCount++;
      } else if(attemptAnsIds.length > 0 && attemptAnsIds[0] != 0 && attemptAnsIds.toString() != rightAnsIds.toString()) {
        attempt_status = 2; //For incorrect Answer
        this.incorrect++;
      }
      if(attempt_status >= 0) {
        this.quesOrder++;
      }
      this.quesArrStatus[quesVal.question_id].push({question_id:quesVal.question_id,answer_id : attemptAnsIds.toString(),attempt_status:attempt_status,ques_order:this.quesOrder});

      //console.log('quesArrStatus',this.quesArrStatus);

      /*
      attemptAnsIds.forEach((element) =>{
        let ansId = Number(element);            
        if(rightAnsIds.includes(ansId) && ansId>0){
          attempt_status = 1;
          // this.quesArr[index] = {question_id:quesVal.question_id,answer_id : ansId,attempt_status:attempt_status};
          this.quesArrStatus[quesVal.question_id].push({question_id:quesVal.question_id,answer_id : ansId,attempt_status:attempt_status}); //For correct Answer          
          rightCount++;
        } else if(!rightAnsIds.includes(ansId) && ansId>0){
          attempt_status = 2;
          // this.quesArr[index] = {question_id:quesVal.question_id,answer_id : ansId,attempt_status:attempt_status};
          this.quesArrStatus[quesVal.question_id].push({question_id:quesVal.question_id,answer_id : ansId,attempt_status:attempt_status}); //For incorrect Answer
        } else if(attempt_status!=undefined) {
          // this.quesArr[index] = {question_id:quesVal.question_id,answer_id : ansId,attempt_status:attempt_status};
          this.quesArrStatus[quesVal.question_id].push({question_id:quesVal.question_id,answer_id : ansId,attempt_status:attempt_status}); //For skipped question
        }
      });
      */
      /*
      //if(this.quesArr.length < this.totalAttempted) {
        if(rightCount == rightAnsIds.length){
          attempt_status = 1;
          this.correct++;
        } else if(ansId!="0"){ //If the question is not skipped and wrong answer given by a user
          attempt_status = 2;
          this.incorrect++;
        }*/ 
        //console.log(this.quesArr.length+" <= "+this.totalAttempted);
        if(attempt_status >= 0){ 
          if(!this.quesArr.includes(quesVal.question_id)){
            this.quesArr.push(quesVal.question_id);
          }        
        }
        //console.log('quesArr',this.quesArr);
      //}
    });
    this.endTime = Date.now();
    this.timeSpentInSeconds = this.endTime - this.startTime;
    //console.log('Time spent in component: ' + this.timeSpentInSeconds + ' ms');
    
    this.saveTest();
  }

  saveTest(){
    // this.quesArr = [];
    this.postQuesArr = [];
    //console.log('quesArrStatus1',this.quesArrStatus);

    this.quesArrStatus.forEach((qVal)=>{
      //console.log(qVal)
      qVal.forEach((quesVal)=>{
        if(quesVal.attempt_status >= 0){
          this.postQuesArr.push(quesVal);
          // this.quesArr.push(quesVal.question_id);
        }
        // if(quesVal.attempt_status >= 0 
        //     && quesVal.answer_id>0 
        //     && !this.quesArr.includes(quesVal.question_id)){
        //   this.quesArr.push(quesVal.question_id);
        // }
      });
      
    });

    this.skipped = this.totalAttempted - (this.correct + this.incorrect);
    let quesParams = {
      quiz_from: 'web',
      set_name: 'quiz_'+this.userId+'_'+new Date().getTime(),
      num_ques: this.totalAttempted,
      duration: this.timeSpentInSeconds/1000,
      set_type: 0,
      created_by: 2,
      modified_by: 2,
      status: 1,
      board_id: 1,
      school_id: this.schoolId,
      class_id: [this.classId],
      section_id: [this.sectionId],
      subject_id: [this.subjectId],
      lesson_id: [this.lessonId],
      topic_id: this.topicId,      
      user_id: this.userId,
      questions: this.quesArr,
      testName: "Post-Test",
      testType: 1,
      timeSpent: this.timeSpentInSeconds,
      //score: (this.correct + this.incorrect + this.skipped)/this.totalAttempted,
      score: 0, //Math.round((this.correct / (this.correct+ this.incorrect + this.skipped))*100),
      headerVal: this.liferayHeader
    }

    //console.log('quesParams',quesParams);
    this.postTestService.createQuizSet(quesParams).subscribe(
      quizResult => {
        this.quizRes = quizResult;
        
        if(this.quizRes.status){
          this.quizSetId = this.quizRes.data.id;
          this.savePostTest();
          // this.removelocalStorage();
          // this.router.navigate(['/quiz-list']);          
        } else {
          this.disbBtn = false;
          this.openSnackBar("Quiz message"+this.quizRes.message,'close');
        }
      }
    );
  }
  

  savePostTest(){
    this.skipped = this.totalAttempted - (this.correct + this.incorrect);
    let quesParams = {
      user_id: this.userId,
      quiz_set_id: this.quizSetId,
      total_attempted: this.totalAttempted,
      total_correct: this.correct,
      total_incorrect: this.incorrect,
      total_skipped: this.skipped,
      time_spent: this.timeSpentInSeconds,
      questions: this.postQuesArr
    }
  //console.log('quesParams1',quesParams);
    this.postTestService.submitPostTest(quesParams).subscribe(
      quizResult => {
        this.quizRes = quizResult;
        this.openSnackBar(this.quizRes.message,'close');
        if(this.quizRes.status){
          this.removelocalStorage();
          this.router.navigate(['/post-test-items',this.lessonId], {queryParamsHandling: 'preserve'});
        } else {
          this.disbBtn = false;
        }
      }
    );
  }

  getTopics(){
    let params = {lesson_id:[this.lessonId]};
    this.backendService.getTopics(params)
    .subscribe((data)=>{
      this.topicData = data;
      if(this.topicData.status){     
        if(this.topicData.data){
          this.topicData = this.topicData.data;
          this.topicData.forEach(topicVal => {
            this.topicId.push(topicVal.id);
          });
          /*if(this.subjectName == 'Science'){
            this.totalQues = 12;
          } else {*/
            this.totalQues = this.topicData.length * 2 * 3;
          //}          
        } else {          
          this.invalidValue();
        }
      } else {
        this.invalidValue();
      }
    });
  }

  openSnackBar(message: string, action: string, duration=2500){
    this.snackBar.open(message, action, {
      duration: duration
    });
  }

  invalidValue(){
    this.openSnackBar("Invalid Value. Please try again.",'close');
    this.router.navigate(['post-test']);
  }

  removelocalStorage(){
    localStorage.removeItem('userTestQuestions');
  }

  canDeactivate(){
    if(this.backStatus){
      return false;
    }
    return true;
  }
}
