import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from 'src/app/user/user.service';
import { environment } from 'src/environments/environment';
import { MatTableExporterModule } from 'mat-table-exporter';
import { Subscription } from 'rxjs';
import { ReportService } from '../report.service';
import { QuizUsersListDataComponent } from '../quiz-users-list-data/quiz-users-list-data.component';
import { DashboardService } from '../../dashboard/dashboard.service';

@Component({
  selector: 'app-quiz-performance-report',
  templateUrl: './quiz-performance-report.component.html',
  styleUrls: ['./quiz-performance-report.component.css']
})
export class QuizPerformanceReportComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['setName', 'class_id', 'section_id', 'student_attempted', 'no_student_attempted', 'below_40', 'between_40_60', 'between_61_80', 'above_80', 'avg_perc'];
  pageTitle = 'Quiz Performance Report';
  dataSource: any;
  school_id: any;
  class_id: any;
  section_id: any;
  limit = 12;
  offset = 0;
  customFilters = {}
  studentList: any;
  section_name: string;
  student_id: any;
  currentUser: any;
  userId: any;
  type_order: any;
  schoolList: any;
  classList: any;
  schoolArr = [];
  classArr = [];
  sectionArr: [];
  classObjArr = [];
  sectionList: any;
  searchFilter: any = {
    set_name: '',
    school_id: [],
    class_id: [],
    section_id: [],
    from_date: '',
    to_date: ''
  };
  suggestEmail: any;
  errorMessage: any;
  loading: boolean = false;
  liveUrl = environment.liveUrl;
  customFilter: any;
  userTypes: void;
  disableDownload: boolean = true;
  subscription: Subscription;
  school_disable: boolean = false
  class_disable: boolean = false;
  section_disable: boolean = false;
  dash: any;
  user_type_disable: boolean = false;
  selectedSchoolName: string;
  displaySchoolName: boolean = false;
  from_date: any;
  to_date: any;
  currentDate = new Date();

  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (this.currentUser) {
      this.userId = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    }
    if (this.type_order == 1 || this.type_order == 2) {
      this.userService.getActiveSchools(this.userId).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.getUserClasses();
      });
    } else {
      let obj = { user_id: this.userId }
      this.userService.userSchools(obj).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.classArr = data.userClass;
        this.sectionArr = data.userSection;
        this.getUserClasses();
      });
    }


    this.route.queryParams.subscribe((params: any) => {
      // console.log(params)
      if (params.customFilters) {
        // console.log(params);
        this.customFilter = JSON.parse(params.customFilters);
        let sectionId = this.customFilter.section_id;
        this.searchFilter = this.customFilter;
        this.changeClass(this.searchFilter.class_id, () => {
          this.searchFilter.section_id = sectionId;
          // console.log(this.searchFilter);
          this.getUserList()
        });
      } else {
        this.customFilters = {};
      }
    })



  }

  getUserList() {

    // Set from_date
    if (this.searchFilter.from_date !== '') {
      const timestamp_from_date = new Date(this.searchFilter.from_date).getTime();
      const adjustedFromDate = new Date(timestamp_from_date);
      const month = adjustedFromDate.getMonth() + 1;
      const day = adjustedFromDate.getDate();
      const year = adjustedFromDate.getFullYear();
      const isoStringFromDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
      this.from_date = isoStringFromDate;
    } else {
      this.from_date = '';
    }

    // Set to_date
    if (this.searchFilter.to_date !== '') {
      const timestamp_to_date = new Date(this.searchFilter.to_date).getTime();
      const adjustedToDate = new Date(timestamp_to_date);
      const month = adjustedToDate.getMonth() + 1;
      const day = adjustedToDate.getDate();
      const year = adjustedToDate.getFullYear();
      const isoStringToDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`;
      this.to_date = isoStringToDate;
    } else {
      this.to_date = '';
    }

    let obj = {
      set_name: this.searchFilter.set_name.trim(),
      school_id: this.searchFilter.school_id,
      class_id: this.searchFilter.class_id,
      section_id: this.searchFilter.section_id,
      start_date: this.from_date,
      end_date: this.to_date
    }
    // console.log(obj);
    this.subscription = this.reportService.getQuizPerformance(obj).subscribe((data: any) => {
      // console.log(data);
      let listStudent = data;
      if (data.status !== false) {
        if (listStudent.quiz_performance.length != 0) {
          // console.log('j')
          this.onSchoolSelected(this.searchFilter.school_id);
          this.studentList = listStudent.quiz_performance;
          this.studentList.forEach(student => {
            const matchingSection = this.sectionList.find(section => section.id === student.section_id);
            if (matchingSection) {
              student.section_name = matchingSection.class_section.section_name;
            } else {
              student.section_name = 'NA';
            }
          });
          //  console.log(this.studentList)
          this.dataSource = new MatTableDataSource(this.studentList);
          this.dataSource.paginator = this.paginator;
          this.displaySchoolName = true;
          this.disableDownload = false;
          //this.dataSource.sort = this.sort;

        } else {
          this.displaySchoolName = false;
          this.disableDownload = true;
          this.dataSource = new MatTableDataSource();
          this.errorMessage = data.message;
          this.openSnackBar('Data not found. Search again with different criteria !!!', 'Close');
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
        }
      } else {
        this.errorMessage = data.message;
        this.displaySchoolName = false;
        this.disableDownload = true;
        this.openSnackBar(this.errorMessage, 'Close');
        this.dataSource = new MatTableDataSource();
        this.dataSource.paginator = this.paginator;
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      }
      this.loading = false;
    },
      (error) => {
        this.loading = false;
        this.openSnackBar('An error occurred.', 'Close');
        console.error('An error occurred:', error);
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      }
    )
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2500,
    });
  }

  onSchoolSelected(id) {
    // console.log(id)
    const selectedSchool = this.schoolList.find((school: any) => school.id === id) as { id: number, school_name: string };
    this.selectedSchoolName = selectedSchool ? selectedSchool.school_name : '';
    // console.log(this.selectedSchoolName)
  }


  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.userService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }


  changeClass(class_id, callback) {
    // console.log(class_id)
    this.searchFilter.section_id = '';
    let classObj = { class_id: [class_id], section_id: this.sectionArr };
    this.userService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
      if (callback) {
        callback();
      }
    });
  }


  searchUserData(filters: any) {
    this.searchFilter = filters;
    this.disableDownload = true;
    // console.log(this.searchFilter)
    this.loading = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.getUserList();
  }

  reset() {
    this.loading = false;
    this.searchFilter = {
      school_id: [],
      class_id: [],
      section_id: [],
      set_name: '',
      from_date: '',
      to_date: ''
    };
    this.disableDownload = true;
    this.displaySchoolName = false;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.sectionList = [];
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  openUserList(user_ids: any, tabType: any, quizSetName: any) {
    //console.log(user_ids)

    this.dialog.open(QuizUsersListDataComponent, { data: { user_ids, tabType, quizSetName } });
  }


  sendReport() {
    let obj = {
      set_name: this.searchFilter.set_name.trim(),
      school_id: this.searchFilter.school_id,
      class_id: this.searchFilter.class_id,
      section_id: this.searchFilter.section_id,
      start_date: this.from_date,
      end_date: this.to_date
    }
    // console.log(obj)
    this.dashboardService.sendQuizReport(obj).subscribe((data: any) => {
      // console.log(data)
      if (data.status == true) {
        this.openSnackBar('Mail Sent Successfully.', 'Close');
      } else {
        this.openSnackBar('Mail not sent retry.', 'Close');
      }
    },
      (error) => {
        console.error('An error occurred:', error);
      })
  }


}
