import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';
import { MatSnackBar, MatTooltipModule } from '@angular/material';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DashboardService } from './dashboard.service';
import { BackendService } from '../_services/backend.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  currentUser: any;
  user_id: number;
  token: string;
  tokenStatus  =false;
  userType: number;
  userTypeName: string;
  schools = [];
  currentSelSchoolId: number;
  userClasses = [];
  classes = [];
  classObj = [];
  sections = [];
  sectionObj = [];
  curClassId: number;
  curSectionId: number;
  currDashSel: any;
  studentData: any;
  schoolCode: string;
  trialUser: boolean = false;
  defaultTab = 0;
 newSchoolId : number;

  constructor(private authService: AuthenticationService,
    private dashboardService: DashboardService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private backendService: BackendService) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    //console.log(this.currentUser)
    if(this.currentUser){  
      this.user_id = this.currentUser.id;
      this.token = this.currentUser.token;
      if (this.currentUser.adminUser[0].user_Type.type_order == '1') {
        // Check if schools are already available
        if (this.currentUser.activeSchools) {
          this.schools = this.currentUser.activeSchools;
        } else {
          this.backendService.getActiveSchools({}).subscribe((data: any) => {
           // console.log(data);
            let listSchool = data.data; 
            this.schools = listSchool;
  
            // Store schools in currentUser for future use
            this.currentUser.activeSchools = listSchool;
            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          });
        }
      } else {
        this.schools = this.currentUser.userSchools;
      }
  
      
      // this.userType = this.currentUser.userData[0].user_type;
      this.userType = this.currentUser.adminUser[0].user_type_id;
      this.userTypeName = this.currentUser.adminUser[0].user_Type.type_name;
      this.currentSelSchoolId = this.currentUser.currentSelSchoolId; 
      this.currentUser.newSchoolId = [this.currentSelSchoolId] 
      localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
      this.userClasses = this.currentUser.userData;   
      this.userClasses = this.userClasses.filter(data => data.school_id == this.currentSelSchoolId);
      //console.log(this.userClasses)
      this.userClasses.forEach((data)=> {
        if(!this.classes.includes(data.class.id)){
          this.classes.push(data.class.id);  
          // let classObj = {class:data.class};  
          this.classObj.push({class:data.class});
        }

        if(!this.sections.includes(data.section.class_section.id)){      
          this.sections.push(data.section.class_section.id);  
          // let sectionObj = {sections:data.section.class_section};  
          this.sectionObj.push({section:data.section.class_section});
        }
      });

      this.currDashSel = JSON.parse(localStorage.getItem('currDashSel'));
      if(this.currDashSel){
        this.curClassId = this.currDashSel.class_id;
        this.curSectionId = this.currDashSel.section_id;
        this.changeClass(this.curClassId);
        this.changeSection(this.curSectionId);
      }
    //  console.log(this.schools)
    if(this.schools.length > 0){
      this.schoolCode = this.schools[0].school_code
    }
     
      if(this.schoolCode == "MRKSTRIAL"){
        this.trialUser = true;
      }else{
        this.trialUser = false;
      }
    } else {
      this.router.navigate(['/login']);
    }
    this.route.queryParams.subscribe((paramsData: any) => {
      if(paramsData.tabType == '1'){
        this.defaultTab = 1;
      }
    })

    this.route.queryParams.subscribe((paramsData: any) => {
      if(paramsData.tabType == '0'){
        this.defaultTab = 0;
      }
    })

    this.route.queryParams.subscribe((paramsData: any) => {
      if(paramsData.tabType == '2'){
        this.defaultTab = 2;
      }
    })
  }

  chkUserToken(){    
    let paramsVal = {user_id: this.user_id, token: this.token};
    return this.authService.chkUserToken(paramsVal).subscribe(
      data =>{
        this.tokenStatus = data.status;
        if(!this.tokenStatus){
          this.openSnackBar("You've been logged in different Computer", 'Close');
          this.authService.logout();
        }
      }
    )
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  // changeSchool(){
  //   this.currentUser.currentSelSchoolId = this.currentSelSchoolId;
  //   localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
  //   let currentUrl = this.router.url;
  //   this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
  //       this.router.navigate([currentUrl]);
  //       // console.log(currentUrl);
  //   });
  // }

  changeSchool() {
   // console.log(this.newSchoolId )
    //console.log(this.currentSelSchoolId)
    this.currentUser.currentSelSchoolId = this.currentSelSchoolId;
    this.currentUser.newSchoolId = [this.currentSelSchoolId];
    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    
    // Get the current URL without query parameters
    const currentUrl = this.router.url.split('?')[0];
    
    // Get the current query parameters
    const queryParams = this.route.snapshot.queryParams;
    

    // Navigate to the current URL with preserved query parameters
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
           this.router.navigate([currentUrl], {queryParams});
            // console.log(currentUrl);
      });
    
  }

  changeClass(class_val){
    if(this.userTypeName == 'teacher'){
      this.getChangeClass(class_val);
    } else {
      this.getClassSectionUsers(class_val);
    }    
  }

  changeSection(section_val){
    if(this.userTypeName == 'teacher'){
      this.getChangeSection(section_val);
    } else if(this.userTypeName == 'Private Tutor'){
      this.getClassSectionUsers(0, section_val);
    } 
  }

  getChangeClass(class_val){
    this.curClassId = class_val;
    localStorage.setItem('currDashSel',
        JSON.stringify({class_id: this.curClassId, section_id: this.curSectionId})
    );
    if(this.curClassId){      
      if(this.curSectionId){
        this.userClasses = this.currentUser.userData.filter(data => data.school_id == this.currentSelSchoolId 
          && data.class_id == this.curClassId
          && data.section.class_section.section_name == this.curSectionId);
      } else {
        this.userClasses = this.currentUser.userData.filter(data => data.school_id == this.currentSelSchoolId 
          && data.class_id == this.curClassId);
        }
    } else {
      if(this.curSectionId){
        this.userClasses = this.currentUser.userData.filter(data => data.school_id == this.currentSelSchoolId 
          && data.section.class_section.section_name == this.curSectionId);
      } else {
        this.userClasses = this.currentUser.userData.filter(data => data.school_id == this.currentSelSchoolId );
      }
    }
  }

  getChangeSection(section_val){
    this.curSectionId = section_val;
    localStorage.setItem('currDashSel',
        JSON.stringify({class_id: this.curClassId, section_id: this.curSectionId})
    );
    if(this.curSectionId){      
      if(this.curClassId){
        this.userClasses = this.currentUser.userData.filter(data => data.school_id == this.currentSelSchoolId 
          && data.class_id == this.curClassId
          && data.section.class_section.section_name == this.curSectionId);
      } else {
        this.userClasses = this.currentUser.userData.filter(data => data.school_id == this.currentSelSchoolId 
          && data.section.class_section.section_name == this.curSectionId);
      }
    } else {
      if(this.curClassId){
        this.userClasses = this.currentUser.userData.filter(data => data.school_id == this.currentSelSchoolId 
          && data.class_id == this.curClassId);
      } else {
        this.userClasses = this.currentUser.userData.filter(data => data.school_id == this.currentSelSchoolId );
      }
    }
  }

  getClassSectionUsers(class_id=0, section_id=0){
    if(class_id == 0 && this.curClassId){
      class_id = this.curClassId
    }
    if(section_id == 0 && this.curSectionId){
      section_id = this.curSectionId
    }
    let params = {
      school_id: [this.currentSelSchoolId],
      class_id: class_id,
      section_name: section_id
    }
    this.dashboardService.getClassStudents(params)
      .subscribe((userData:any) => {
        // console.log(userData);
        if(userData.status){
          this.studentData = userData.data;
        }        
      });
  }
}