import { Component, OnInit, NgZone } from '@angular/core';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-indo-pay-resp',
  templateUrl: './indo-pay-resp.component.html',
  styleUrls: ['./indo-pay-resp.component.css']
})
export class IndoPayRespComponent implements OnInit {

  loading: boolean = false;
  errorMessage: any;
  currentUser: any;
  frequencyTypeList: any;
  indoPayData: any;
  product: string;
  sku: string;
  invoice_no_param: string;
  frequency_type_value: any;
  constructor(
    private subscriptionService: SubscriptionService,
    private zone: NgZone,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) { 

    this.route.queryParams.subscribe(params => {
      this.invoice_no_param = params['invoice_no'];
      //this.getUser()
      // this.userSubForm.controls.user_id.setValue(this.user_id);

    });
  }


  ngOnInit() { 
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let indoPayRespObj = {"invoice_no": this.invoice_no_param};
    this.subscriptionService.getIndoPayResp(indoPayRespObj).subscribe(subData => {
      if (subData) {
        console.log('subData',subData[0]);
        let indoPayResp = JSON.parse(subData[0].payment_resp);
        let payment_status = '';
        if(indoPayResp == undefined || indoPayResp == '') {
            payment_status = 'Payment Pending';
        } else {
            payment_status = indoPayResp.transaction.status;
        }
        let mess = '';
        
        if(payment_status === 'SUCCESS') {
          mess = "User Subscription & Invoice has been added successfully";
        } else if(payment_status === 'FAILED') {
          mess = "Payment Failed";
        } else {
          mess = "Payment Pending"
        }

        this.router.navigate(['/invoiceUser', subData[0].user_id]);
        this.openSnackBar(mess, 'Close');
      } else {
        this.zone.run(() => {
          this.loading = false;
          this.errorMessage = 'There is problem in invoice entry';
        })

      }
    });  
  }
  /*
  ngOnInit() { 
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    console.log('currentUser',this.currentUser);
    
    
    this.subscriptionService.getAllFrequencyType({}).subscribe((data: any) => {
      // console.log(data);
      this.frequencyTypeList = data.data;
      localStorage.setItem('frequencyTypeList', JSON.stringify(this.frequencyTypeList));
    });
    console.log('invoice_no_param',this.invoice_no_param);
    let indoPayDataObj = {"invoice_no": this.invoice_no_param};
    this.subscriptionService.getIndoPayData(indoPayDataObj).subscribe((dataIndo: any) => {
      //console.log('getindopaydata', data);
      this.indoPayData = dataIndo;
      //console.log('thisindoPayData111', this.indoPayData);
      //localStorage.setItem('indoPayData', this.indoPayData);
    //});

    //console.log('thisindoPayData222', this.indoPayData);
    let frequencyTypeList = JSON.parse(localStorage.getItem('frequencyTypeList'));
    
    let indoPayData = JSON.parse(this.indoPayData); //JSON.parse(localStorage.getItem('indoPayData'));
    console.log('indoPayData',indoPayData);
    console.log('invoice_number',indoPayData.invoice_number);
    let indoPayRespObj = {"invoice_no": indoPayData.invoice_number};
    console.log('indoPayRespObj',indoPayRespObj);
    
    this.subscriptionService.getIndoPayResp(indoPayRespObj).subscribe(indoPayRespData => {
      
      console.log('indoPayRespData',indoPayRespData);
      //console.log('indoPayRespData_payment_resp',indoPayRespData[0].payment_resp);
      //console.log('indoPayRespData',indoPayRespData);
      if(indoPayRespData) {
        console.log('frequencyTypeList',frequencyTypeList);
        if (frequencyTypeList) {
          let freq_type_id = indoPayData.freq_type_id;
          const selectedDataProd = frequencyTypeList.find(item => item.id === freq_type_id);
          console.log('selectedDataProd',selectedDataProd);
          if (selectedDataProd) {
            const { frequency, frequency_value } = selectedDataProd;
            this.frequency_type_value = frequency_value;
            //console.log('frequency_type_value',this.frequency_type_value);
            if (frequency_value === 1) {
              this.product = frequency_value + ' Month Plan';
            } else {
              this.product = frequency_value + ' Months Plan';
            }
            this.sku = 'G' + indoPayData.class_id + '_CBSE_' + frequency_value + 'M'
          }
        }
        let subscriptionObj = {
          "email": indoPayData.email,
          "firstName": indoPayData.firstName,
          "user_id": indoPayData.user_id,
          "subscription_id": indoPayData.subscription_id,
          "school_id": indoPayData.school_id,
          "class_id": indoPayData.class_id,
          "amount": indoPayData.amount,
          "discount_amt": indoPayData.discount_amt,
          "paid_amount": indoPayData.checkout_amount,
          "gst_amount": indoPayData.gst_amount,
          "freq_type_id": indoPayData.freq_type_id,
          "frequency_value": this.frequency_type_value,
          "payment_type": indoPayData.payment_type,
          "payment_response_id": indoPayData.invoice_number,
          "status": 1
        };

        let liferaySubscriptionObj = {
          "product": this.product,
          "class_id": indoPayData.class_id,
          "email": indoPayData.email
          //"start_date": "2023-07-17T14:02:00.000Z",
          //"end_date": "2024-07-17T14:02:00.000Z"
        };

        let invoiceObj = {
          "user_id": indoPayData.user_id,
          "school_id": indoPayData.school_id,
          "class_id": indoPayData.class_id,
          "firstName": indoPayData.firstName,
          "lastName": indoPayData.lastName,
          "address": indoPayData.address,
          "city": indoPayData.city,
          "state": indoPayData.state,
          "country_id": indoPayData.country_id,
          "pincode": indoPayData.pincode,
          "email": indoPayData.email,
          "contactNumber": indoPayData.contactNumber,
          "payment_type": indoPayData.payment_type,
          "price": indoPayData.amount,
          "discount_amount": indoPayData.discount_amt,
          "tax_amount": indoPayData.gst_amount,
          "quantity": 1,
          "total_amount": indoPayData.checkout_amount,
          "payment_response_id": indoPayData.invoice_number,
          "received_by": indoPayData.received_by,
          "product": this.product,
          "sku": this.sku,
          "created_by": this.currentUser.adminUser[0].id,
          "gst_no": indoPayData.gst_no,
          "state_id": indoPayData.state_id,
          "emi_amount": indoPayData.emi_amount,
          "no_of_installment": indoPayData.no_of_installment,
          "down_payment": indoPayData.down_payment,
          "down_payment_type": indoPayData.down_payment_type
        };
        let subObj = {
          "subscription": subscriptionObj,
          "liferaySubscription": liferaySubscriptionObj,
          "invoice": invoiceObj
        };
        console.log('subObj',subObj);
        this.subscriptionService.paymentSubscriptionInvoice(subObj).subscribe(subData => {
          if (subData) {
            let obj = {}
            this.subscriptionService.setSubPaymentFromData(obj);
            // this.router.navigate(['/user']);
            console.log('user_id = '+indoPayData.user_id);
            this.router.navigate(['/invoiceUser', indoPayData.user_id]);
            this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');


            // this.router.navigate(['/invoiceUser', this.user_id]);
            //this.openSnackBar('User Subscription & Invoice has been added successfully. ', 'Close');

          } else {
            this.zone.run(() => {
              this.loading = false;
              this.errorMessage = 'There is problem in invoice entry';
            })

          }
        });
      }
        
  
    });
  });  
  }
  */

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }
}
