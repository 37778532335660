import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { HomeworkService } from '../homework.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tutor-homework',
  templateUrl: './tutor-homework.component.html',
  styleUrls: ['./tutor-homework.component.css']
})
export class TutorHomeworkComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'Tutor Students';
  displayedColumns: string[] = ['Sno', 'title', 'description', 'id'];

  dataSource: any;

  currentUser: any;
  customFilters: any;
  user_id: number;
  class_id: number;
  section_id: number;
  school_id: number;
  limit = 10;
  offset = 0;
  totalHW: number;
  student_id: number;

  constructor(
    private homeworkService: HomeworkService,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = this.currentUser.adminUser[0].id;

    this.route.queryParams.subscribe((params: any) => {
      if (params.customFilters) {
        this.customFilters = JSON.parse(params.customFilters);
        this.school_id= this.customFilters.school_id;
        this.class_id = this.customFilters.class_id;
        this.section_id = this.customFilters.section_id;
        this.student_id = this.customFilters.student_id;
        console.log(this.customFilters);
      }
      this.getStudentList();
    })
  }

  getStudentList(){
    let obj = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      submitted_by: this.student_id,
      created_by: this.user_id,
      limit: this.paginator.pageSize,
      offset: this.paginator.pageIndex * this.paginator.pageSize
    }
    console.log(obj);
    this.homeworkService.submissionsToTutor(obj).subscribe((data: any)=> {
      console.log(data);
      this.totalHW = data.totalHW;
      this.dataSource = new MatTableDataSource(data.data);
    })
  }

  reset() {
    this.getStudentList();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          this.getStudentList();
        })
      ).subscribe();
  }

}
