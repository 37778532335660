import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import {  MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import {  Observable } from 'rxjs';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { BackendService } from 'src/app/_services/backend.service';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-add-user-subscription',
  templateUrl: './add-user-subscription.component.html',
  styleUrls: ['./add-user-subscription.component.css']
})
export class AddUserSubscriptionComponent implements OnInit {

  
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  userSubForm: FormGroup;
  displayMessage: { [key: string]: string } = {};
  pageTitle = "Add User Subscription";
  boards: any;
  errorMessage: any;
  schoolSubForm: FormGroup;
  currentUser: any;
  classList: any;
  schoolList: any;
  loginUser_id: number;
  user_id: number;
  type_order: any;
  schoolArr = [];
  classArr = [];
  userData: any;
  frequencyTypeList: any;
  totalAmount: number;
  school_id: number;
  class_id: number;
  email : string;



  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  suggestEmail: any;
  firstName: any;
  freq_Value: any;

  constructor(
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private backendService: BackendService
  ) {
    this.validationMessages = {
      email: {
        required: 'email is required.'
      },
      school_id: {
        required: 'school is required.'
      },
      class_id: {
        required: 'Class is required.'
      },
      freq_type_id: {
        required: 'Frequency Type is required.'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    Observable.merge(this.userSubForm.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.userSubForm);
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      //console.log(this.currentUser);
      this.loginUser_id = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    }

    this.userSubForm = this.fb.group({
      email: ['', ''],
      firstName: ['', ''],
      user_id: ['', [Validators.required]],
      school_id: ['', [Validators.required]],
      class_id: ['', [Validators.required]],
      freq_type_id: ['', [Validators.required]],
      amount: ['', ''],
      discount_amt: ['', ''],
      total_amount: ['', ''],
      status: ['', '']
    });

    this.subscriptionService.getAllFrequencyType({}).subscribe((data: any) => {
      //console.log(data);
      this.frequencyTypeList = data.data;
    })

    let classObj = {
      board_id: 1
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });

    if (this.type_order == 1 || this.type_order == 2) {
      this.backendService.getActiveSchools({}).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
      });
    } else {
      this.userData = this.currentUser.userData;
      this.userData.forEach(userVal => {
        if (this.classArr.indexOf(userVal.class.id) == -1) {
          this.classArr.push(userVal.class.id);
          // this.classes.push({ id: userVal.class.id, className: userVal.class.class_name })
        }
      });
      this.currentUser.userSchools.forEach(school => {
        this.schoolArr.push(school.id);
      });

      let classObj = {
        board_id: 1,
        class_id: this.classArr
      }
      this.backendService.getClasses(classObj).subscribe((data: any) => {
        this.classList = data.data;
      });
    }
  }

  emailFilter(filterValue: string) {
    //this.errorMessage = {};
    if (filterValue.length > 4) {
      let obj = { email: filterValue };
      this.userService.getSuggestData(obj).subscribe((emailData: any) => {
        //console.log(emailData);
        this.suggestEmail = emailData.data;
        // console.log(filterValue.indexOf('@'),  this.suggestData.length);
        // if (filterValue.indexOf('@') > 0 && this.suggestEmail.length <= 0) {
        //   this.errorMessage.message = "User is not registered"
        // }
      });
    }
}

  fillData(suggest) {
    this.email = suggest.email;
    this.backendService.getuserdetails({ username: this.email }).subscribe((data: any) => {
     // console.log(data)
      this.user_id = data.data.adminUser[0].id;
      this.firstName = data.data.firstName;
      this.class_id = data.data.userData[0].class_id;
      this.school_id = data.data.userData[0].school_id;
      this.userSubForm.patchValue({
        email: this.email,
        user_id: this.user_id,
        firstName: this.firstName,
        class_id: this.class_id,
        school_id: this.school_id
      })
    })
  }
 
  createSubscription(){
    let subscriptionObj = {
      email: this.userSubForm.value.email,
      firstName: this.userSubForm.value.firstName,
      user_id: this.userSubForm.value.user_id,
      subscription_id: 6,
      school_id: this.userSubForm.value.school_id,
      class_id: this.userSubForm.value.class_id,
      amount: 0,
      discount_amt: 0,
      paid_amount: 0,
      freq_type_id: this.userSubForm.value.freq_type_id,
      frequency_value: this.freq_Value,
      status: 1,
      payment_response_id: 'adminSubscription'
    }
   /// console.log(subscriptionObj)
    this.subscriptionService.createSubscription(subscriptionObj).subscribe((data: any)=>{
      //console.log(data)
      if(data.status == true){
        this.openSnackBar('Subscription has been updated successfully. ', 'Close');
        this.router.navigate(['/userSubscription']);
      } else {
        this.errorMessage = data.message;
      }
    });
  }

  onFreqTypeChange(freq_type_id: number) {

    let freqency = this.frequencyTypeList.find(item => item.id === freq_type_id );
    this.freq_Value = freqency.frequency_value;
    //console.log(this.freq_Value)

  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}

