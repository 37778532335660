import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { CommonModule } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-pre-post-tests-data',
  templateUrl: './pre-post-tests-data.component.html',
  styleUrls: ['./pre-post-tests-data.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PrePostTestsDataComponent implements OnInit {

  displayedPreColumns = ['Sno', 'total_attempted', 'total_correct', 'total_incorrect', 'total_skipped', 'pre_test_completion_time', 'pre_test_score', 'id'];
  displayedPostColumns = ['Sno', 'total_attempted', 'total_correct', 'total_incorrect', 'total_skipped', 'post_test_completion_time', 'post_test_score', 'id'];
  dataSource: any;
  lessonDataList: any;
  lessonName: string;
  preTest: boolean;
  start = 0;


  constructor(
    @Inject(MAT_DIALOG_DATA) public lessonData: any,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
  ) { }

  ngOnInit() {
    this.preTest = this.lessonData.preTest;
    //console.log(this.preTest);
    if (this.preTest) {
      this.getPrelessonTestData();
    } else {
      this.getPostlessonTestData();
    }
  }

  // getPrelessonTestData(){
  //   let obj = this.lessonData.obj;
  //   this.dashboardService.getUserLessonPerformance(obj).subscribe((lessonTestData: any) => {
  //     //console.log(lessonTestData);
  //      this.lessonDataList = lessonTestData.pre_post_test;
  //     // console.log(this.lessonDataList);
  //      this.lessonName = this.lessonDataList[0].lesson_name;
  //      let filteredLessonDataList = this.lessonDataList.filter(item => item.pre_time_spent !== 'NA' || item.pre_score !== 'NA');
  //      //console.log(filteredLessonDataList);
  //     this.dataSource = new MatTableDataSource(filteredLessonDataList);
  //   });
  // }


  // getPostlessonTestData(){
  //   let obj = this.lessonData.obj;
  //   //console.log(JSON.stringify(obj))
  //   this.dashboardService.getUserLessonPerformance(obj).subscribe((lessonTestData: any) => {
  //     console.log(lessonTestData);
  //      this.lessonDataList = lessonTestData.pre_post_test;
  //      this.lessonName = this.lessonDataList[0].lesson_name;
  //     this.dataSource = new MatTableDataSource(this.lessonDataList);
  //   });
  // }


  getPostlessonTestData() {
    let obj = this.lessonData.obj;
    this.dashboardService.getUserLessonPerformance(obj).subscribe((lessonTestData: any) => {
      //console.log(lessonTestData);
      this.lessonDataList = lessonTestData.pre_post_test.map((lessonData, index) => {
        return {
          ...lessonData,
          attempt_no: index + 1
        }
      });
      // console.log(this.lessonDataList);
      this.lessonName = this.lessonDataList[0].lesson_name;
      this.dataSource = new MatTableDataSource(this.lessonDataList);
    });
  }

  getPrelessonTestData() {
    let obj = this.lessonData.obj;
    this.dashboardService.getUserLessonPerformance(obj).subscribe((lessonTestData: any) => {
      //console.log(lessonTestData);
      this.lessonDataList = lessonTestData.pre_post_test.map((lessonData, index) => {
        return {
          ...lessonData,
          attempt_no: index + 1
        }
      });
      //console.log(this.lessonDataList);
      this.lessonName = this.lessonDataList[0].lesson_name;
      let filteredLessonDataList = this.lessonDataList.filter(item => item.pre_time_spent !== 'NA' || item.pre_score !== 'NA');
      //console.log(filteredLessonDataList);
      this.dataSource = new MatTableDataSource(filteredLessonDataList);
    });
  }

}
