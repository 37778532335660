import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css']
})
export class StudentDashboardComponent implements OnInit {

  @Input() currentUser;
  cardClass = 'dash-card';
  mediaQueryList: any = null;
  mediaQueryMin: any = null;
  isMobile = false;
  colNum: number = 4;
  rowHeight = '320px';
  colSpan: number = 2;

  constructor(
    breakpointObserver: BreakpointObserver,
    mediaMatcher: MediaMatcher
  ) { 
    this.mediaQueryList = mediaMatcher.matchMedia('(min-width: 640px)');
    this.mediaQueryMin = mediaMatcher.matchMedia('(min-width: 1000px)');

    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      // console.log(result)
      if (result.matches) {
        this.isMobile = true;
        this.colNum = this.mediaQueryList.matches ? 2 : 1;
        this.colSpan = this.mediaQueryList.matches ? 2 : 1;
        this.cardClass = 'dash-card-inline';
      }
    });
  }

  ngOnInit() {
  }

}
