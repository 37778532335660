import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styles: [
    `
    Html, body{
      height:100%;
        margin:0px;
        padding:0px;
    }

    .container {
      height:100%;
        margin: 0 auto;
        padding:0px;
        position: relative;
        top: 200px;
    }
    .grandParentContaniner{
            display:table;
            height:100%;
            margin: 0 auto;
    }
    .parentContainer{
        display:table-cell;
        vertical-align:middle;
    }
    .parentContainer .loginForm{
        padding:10px;
        background: #fff;
        border: 1px solid #ddd;
        width:400px;  /*  your login form width */    display:table-cell;
        display:table-cell;
        vertical-align:middle;

    }
    .footer {
      max-height: 18px;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1rem;
      background-color: #efefef;
      text-align: center;
    }

    .btn-pad {
      margin-left: 10px;
    }
    `
  ]
})
export class UserVerificationComponent implements OnInit {

  user_id: number;
  email: string;
  firstName: string;
  userData: any;
  errorMessage: any;
  model: any = {};
  trialUser: boolean = false;
  forgotPass: boolean = false;
  paramsData: any;
  productName = environment.productName;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private _backendService: BackendService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.model.otp_received = '';
    this.route.queryParams.subscribe((params: any) => {
      this.paramsData = params;
      //console.log(params);
      if(params.user_id){
        this.user_id = Number(atob(decodeURIComponent(params.user_id)));
        // console.log(params);
        // this.user_id = params.user_id;
        //console.log(this.user_id);
      }
      if(params.forgotPassword == "Yes"){
        this.forgotPass = true;
      }else{
        this.forgotPass = false;
      }
      if(params.trialUser == "Yes"){
        this.trialUser = true;
      }else{
        this.trialUser = false;
      }
      // if (Object.keys(params).length > 0) {
      //   Object.keys(params).forEach((pKey) => {
      //     params = pKey;
      //   });
      //   params = atob(decodeURIComponent(params));
      //   params = atob(decodeURIComponent(params));
      //   params = params.split('&');
      //   this.user_id = (params ? params[0].split('=') : '');
      //   this.user_id = +(this.user_id ? this.user_id[1] : '');
      //   this.email = (params ? params[2].split('=') : '');
      //   this.email = (this.email ? this.email[1] : '');
      //   this.firstName = (params ? params[3].split('=') : '');
      //   this.firstName = (params ? params[3].split('=') : '');
      // }
    });

    // this.route.params.subscribe(
    //   (params: any) => {
    //     this.user_id = Number(atob(params['id']));
    //     console.log(this.user_id);
    //     this.model.user_id = this.user_id;
    //   }
    // )
  }

  validate() {
   if(this.forgotPass == true){
    this.userData = {
      user_id: this.user_id,
      otp: this.model.otp_received
    }
    //console.log(this.userData);
    this._backendService.verifyOTP(this.userData).subscribe(
      data => {
        //console.log(data)
        if (data.status == false) {
          this.router.navigate(['/user-verification'],{queryParamsHandling: 'preserve'});
          this.errorMessage = data.message;
        } else {
          this.openSnackBar(data.message, 'Close');
          this.router.navigate(['/updatePassword'], {
            queryParams: { 
              user_id: this.paramsData.user_id
            }
          });
          this.errorMessage = data.message;
        }
      },
      error => {
      }
    );
   }else{
    this.userData = {
      user_id: this.user_id,
      trialUser: this.trialUser,
      otp_received: this.model.otp_received
    }
    //console.log(this.userData);
    this.authenticationService.userVerification(this.userData).subscribe(
      data => {
        //console.log(data)
        if (data.status == false) {
          this.router.navigate(['/user-verification'],{queryParamsHandling: 'preserve'});
          this.errorMessage = data.message;
        } else {
          this.router.navigate(['/login']);
          this.openSnackBar('Registered Successfully. Please login.', 'Close');
          //this.errorMessage = data.message;
        }
      },
      error => {
      }
    );
   }
  }

  resendOtp(){
    //console.log("hi, im clicked");
    if(this.forgotPass){
      let obj = {
        user_id: this.user_id,
      }
      this._backendService.generateOtpFP(obj).subscribe((data: any) => {
        //console.log(data);
        this.openSnackBar(data.message, 'Close');
      })

    } else{
      let obj = {
        user_id: this.user_id,
      }
      this._backendService.generateOtp(obj).subscribe((data: any) => {
        //console.log(data);
        this.openSnackBar(data.message, 'Close');
      })
    }
  }

  reset(){
    this.model.otp_received = '';
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}
