import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-navigation',
  templateUrl: './common-navigation.component.html',
  styleUrls: ['./common-navigation.component.css']
})
export class CommonNavigationComponent implements OnInit {

  @Input() currentUser;
  userTypeName: string;
  
  constructor() { }

  ngOnInit() {    
    this.userTypeName = this.currentUser.adminUser[0].user_Type.type_name;
  }

}
