// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  productName: "Marksharks",
  productUrl: "https://www.marksharks.com",
  production: true,
  // apiUrl: 'http://localhost:4001/api',
  // apiUrl: 'http://35.200.242.77:4001/api'
  apiUrl: "https://support.marksharks.com:4004/api",
  onlineClassUrl: "https://marksharks.com/online_class/index.php",
  liveUrl: "https://support.marksharks.com",
  imgUrl: "https://storage.googleapis.com/lms-app",
  adminUrl: "https://support.marksharks.com:4004",
  msUrl: "https://storage.googleapis.com/marksharks-content",
  contentUrl:
    "https://storage.googleapis.com/marksharks-content/msplus-content",
  appContentUrl:
    "https://storage.googleapis.com/marksharks-content/app-content",
  API_URL: "https://www.googleapis.com/youtube/v3/search",
  API_TOKEN: "AIzaSyDmNbqZfOxBYq3rxE-hy5qIwqhGyqGX_dg",
  quesUploadUrl:
    "http://ec2-3-95-207-0.compute-1.amazonaws.com/LMS/convert_html.php",
  crmUrlApi: "https://mcrm.marksharks.com:3005/api",
  //razorPayKey: 'rzp_test_h6gvOZC5j1bCuu'
  razorPayKey: "rzp_live_b0ReEkQTkGbYek",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
