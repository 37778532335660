import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MatOption, MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-class-code',
  templateUrl: './class-code.component.html',
  styleUrls: ['./class-code.component.css']
})
export class ClassCodeComponent implements OnInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  displayMessage: { [key: string]: string } = {};
  classCode: FormGroup;
  currentUser: any;
  pageTitle = 'Request for account upgrade';
  errorMessage: any;
  countryList: any;
  schoolList: any;
  classList: any;
  sectionList: any;
  sectionArr: any;
  subjectList: any;
  backTo: boolean = false;
  firstName = '';
  lastName = '';
  contactNumber = '';
  email = '';
  countryCode = '';

  private validationMessages: { [key: string]: { [key: string]: string } };  
  private genericValidator: GenericValidator;

  constructor(private fb: FormBuilder,
    private _backendService: BackendService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) { 
    this.validationMessages = {
      firstName: {
        required: 'firstName is required.'
      },
      lastName: {
        required: 'lastName is required.'
      },
      email: {
        required: 'email is required.'
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
   }

   ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    Observable.merge(this.classCode.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.classCode);
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.firstName = this.currentUser.adminUser[0].firstName;
    this.lastName = this.currentUser.adminUser[0].lastName;
    this.email = this.currentUser.adminUser[0].email;
    this.contactNumber = this.currentUser.adminUser[0].contactNumber.split('-');
    this.countryCode = this.contactNumber[0];
    this.contactNumber = this.contactNumber[1];
    
    this.classCode = this.fb.group({
      firstName: [this.firstName, Validators.required],
      lastName: [this.lastName, ''],
      email: [this.email, [Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'), Validators.required]],
      contactNumber: [this.contactNumber, ''],
      country_id: [this.countryCode, '']
    });

    this.route.queryParams.subscribe((params: any) => {
      // console.log(params);
      if(params.backTo){
        this.backTo = true;
        // console.log(params);
        // this.user_id = params.user_id;
      }
      
    });

    this._backendService.getCountries().subscribe((data: any) => {
      this.countryList = data.data;
    })
  }


  reset(){
    this.classCode.reset();
  }

  SentMail(){
    // if(this.classCode.value.contactNumber && !this.classCode.value.country_id){
    //   this.errorMessage = "Please provide country";
    //   return;
    // }else{
    //   this.errorMessage = '';
    //   console.log(this.classCode.value);
      this._backendService.classCodeMail(this.classCode.value).subscribe((data: any) => {
        console.log(data);
        this.openSnackBar("Mail sent successfully", 'Close');
      });
    // }
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3500,
    });
  }

}
