import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { PostTestService } from '../../post-test.service';

@Component({
  selector: 'app-attempted-test-history',
  templateUrl: './attempted-test-history.component.html',
  styleUrls: ['./attempted-test-history.component.css']
})
export class AttemptedTestHistoryComponent implements OnInit {

  pageTitle = 'Questions';
  currentUser
  quizParams: any;
  sub: Subscription;
  quizVal: any;
  quizData: any;
  setId: number;
  topicId: number;
  tempObj = {};
  set_name: string;
  attemptedSetId: number;
  topicName: string;
  inputData: any;

  constructor(
    private postTestService: PostTestService,
    private dialogRef: MatDialogRef<AttemptedTestHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.inputData = data;
    this.attemptedSetId = this.inputData.attempted_set_id;
    this.topicId = this.inputData.topic_id;
    this.setId = this.inputData.set_id;
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));  
    this.getQuizSetData();
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  getQuizSetData() {
    this.quizParams = {attempted_set_id: this.attemptedSetId, set_id: this.setId, topic_id: this.topicId };
    this.sub = this.postTestService.getTopicWiseDetails(this.quizParams)
      .subscribe(quizData => {
        this.quizVal = quizData;
        this.topicName = this.quizVal.topic_name;
        this.quizData = this.quizVal.data;
      }
    );    
  }

  close() {
    this.dialogRef.close();
  }
}
