import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { QuizSetService } from 'src/app/_services/quiz-set.service';

@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrls: ['./leader-board.component.css']
})

export class LeaderBoardComponent implements OnInit {

  pageTitle = 'Leaderboard';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['userName', 'class_section', 'total_attempted', 'total_correct', 'total_incorrect', 'total_skipped', 'time_spent', 'percentage', 'attemptedOn'];

  customFilters: any = null;
  quizSets: any;
  quizSetData: any;
  quizSetValue: any;
  errorMessage: string;
  dataSource: any;
  quizSetId: number;
  getTotalCorrect: any;
  getTotalIncorrect: any;
  getTotalSkipped: any;
  getTotalBelowAvg: any;
  getTotalAvg: any;
  getTotalAboveAvg: any;
  getFirstAvg: any;
  colNum = 1;
  classArr = [];
  sectionArr = [];
  quizClassInfo: any;
  quizSectionInfo: any;
  rowHeight = '400px';
  class_id = [];
  section_id = [];
  filterClassId = [];
  filterSectionId = [];

  constructor(
    private quizSetService: QuizSetService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.quizSetId = +params['quiz_id'];
      }
    );
    this.customFilters = {
      quiz_set_id: this.quizSetId
    };
    this.getQuizSets();
  }

  getQuizSets() {
    this.customFilters = {
      quiz_set_id: this.customFilters.quiz_set_id
    };
    this.quizSetService.getQuizSetsData(this.customFilters).subscribe(quizSets => {
        this.quizSetData = quizSets;
        // this.quizSetData.sort((a, b) => a.percentage > b.percentage ? -1 : (a.percentage < b.percentage ? 1 : 0))
        this.freshDataList(this.quizSetData);
      },
        error => this.errorMessage = error as any);
  }

  freshDataList(quizData) {
    this.quizSetData = quizData;
    this.dataSource = new MatTableDataSource(this.quizSetData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }






}
