import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { ReportService } from '../report.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quiz-users-list-data',
  templateUrl: './quiz-users-list-data.component.html',
  styleUrls: ['./quiz-users-list-data.component.css']
})
export class QuizUsersListDataComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = ['Sno', 'username', 'email',];
  dataSource: any;
  pageTitle: any;
  user_id: any;
  subscription: Subscription;


  constructor(
    @Inject(MAT_DIALOG_DATA) public userData: any,
    public dialog: MatDialog,
    private reportService: ReportService,) { }

  ngOnInit() {
    //console.log(this.userData);
    this.pageTitle = this.userData.quizSetName + ' - ' + this.userData.tabType;
    this.user_id = this.userData.user_ids;

    this.getUserList(this.user_id);
  }

  getUserList(user_id: any) {
    // console.log(user_id);
    let obj = {
      user_id: user_id
    }
    // console.log(obj)
    this.subscription = this.reportService.getAllActiveUsers(obj).subscribe((data: any) => {
      //  console.log(data);
      if (data.length > 0) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        console.log('Data Not Found')
      }

    }, (error) => {
      console.error('An error occurred:', error);
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
