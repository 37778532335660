import { Component, OnInit, ViewChild } from '@angular/core';
import { Http } from '@angular/http';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/user/user.service';
import { environment } from 'src/environments/environment';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'app-coupon-subscription',
  templateUrl: './coupon-subscription.component.html',
  styleUrls: ['./coupon-subscription.component.css']
})
export class CouponSubscriptionComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private apiUrl = environment.apiUrl; r
  pageTitle = 'User Coupon Subscription';
  displayedColumns: string[] = ['id', 'name', 'school_name', 'class', 'email', 'phone', 'referral_code', 'created_on'];
  users: any;
  currentUser: any;
  userId: string;
  dataSource: any = null;
  errorMessage: string;
  type_order: any;
  schoolList: any;
  classList: any;
  schoolArr = [];
  classArr = [];
  user_type_id: any;
  searchFilter: any = {
    refferal_code: '',
    email: '',
    school_id: [],
    class_id: [],
    start_date: '',
    end_date: ''
  };
  currentDate = new Date();
  loading: boolean = false;
  liveUrl = environment.liveUrl;
  disableDownload: boolean = true;
  subscription: Subscription;
  start_date: any;
  end_date: any;

  constructor(private http: Http,
    private userService: UserService,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (this.currentUser) {
      this.userId = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    }

    if (this.type_order == 1 || this.type_order == 2) {
      this.userService.getActiveSchools(this.userId).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.getUserClasses();
        //this.getUsers();
      });
    } else {
      let obj = { user_id: this.userId }
      this.userService.userSchools(obj).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.classArr = data.userClass;
        this.getUserClasses();
        //this.getUsers();
      });
    }
  }

  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.userService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }


  searchUserData(filter: any) {
    this.loading = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.searchFilter = filter;
    this.disableDownload = true;

    if (this.searchFilter.start_date != '') {
      // set start_date 
      let timestamp_start_date = new Date(this.searchFilter.start_date).getTime();
      // add one day
      timestamp_start_date += 24 * 60 * 60 * 1000;
      // convert the adjusted timestamp back to a Date object
      let adjustedStartDate = new Date(timestamp_start_date);

      // convert the adjusted date to an ISO 8601 string without the time component
      let isoStringStart = adjustedStartDate.toISOString().slice(0, 10);

      // convert the adjusted timestamp back to a Date object
      this.start_date = isoStringStart;
    } else {
      this.start_date = '';
    }
    // set end_date 
    if (this.searchFilter.end_date != '') {
      let timestamp_end_date = new Date(this.searchFilter.end_date).getTime();
      timestamp_end_date += 24 * 60 * 60 * 1000;
      let adjustedStartDate = new Date(timestamp_end_date);
      let isoStringEnd = adjustedStartDate.toISOString().slice(0, 10);
      this.end_date = isoStringEnd;
    } else {
      this.end_date = ''
    }

    let obj = {
      email: filter.email,
      refferal_code: filter.refferal_code,
      school_id: filter.school_id,
      class_id: filter.class_id,
      start_date: this.start_date,
      end_date: this.end_date
    }
  //  console.log(this.searchFilter)
    if ((this.searchFilter.class_id == '')) {
      this.loading = false;
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.openSnackBar('Please Select Class. ', 'Close');
    } else {
      //console.log('obj =' + JSON.stringify(obj));
      this.subscription = this.subscriptionService.getCouponRegisteredUsers(obj).subscribe((data: any) => {
       // console.log(data)
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.loading = false;
        this.disableDownload = false;
        if (data.length == 0) {
          this.disableDownload = true;
          this.subscription.unsubscribe();
          this.openSnackBar('Users not found. ', 'Close');
        }
      },
        (error) => {
          this.loading = false;
          this.disableDownload = true;
          this.openSnackBar('An error occurred.', 'Close');
          console.error('An error occurred:', error);
        }
      )
    }
  }

  resetSearchFilter() {
    //searchPanel.toggle();
    this.searchFilter = {
      email: '',
      refferal_code: '',
      school_id: '',
      class_id: '',
      start_date: '',
      end_date: ''
    };
    this.disableDownload = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.loading = false;
    //this.subscription.unsubscribe();
  }

  reset() {
    //Stop Searching and reset the table
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.loading = false;
    this.disableDownload = true;
    this.searchFilter = {
      email: '',
      refferal_code: '',
      school_id: '',
      class_id: '',
      start_date: '',
      end_date: ''
    };
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.openSnackBar('Reset Done! Please Select Class. ', 'Close');

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }


  getUsers() {
    let obj = {
      "class_id": this.searchFilter.class_id
    }
    this.subscriptionService.getCouponRegisteredUsers(obj).subscribe((data: any) => {
      //console.log(data);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    })
  }


  changeClass(class_id) {
    //console.log(class_id)
  }

}

