import { Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MatOption, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
// import { GenericValidator } from 'src/app/shared';
import { SchoolService } from '../school.service';
import { Observable } from 'rxjs';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { AppContentService } from '../../app-content/app-content.service';
import { element } from 'protractor';
@Component({
  selector: 'app-add-edit-coupon',
  templateUrl: './add-edit-coupon.component.html',
  styleUrls: ['./add-edit-coupon.component.css']
})

export class AddEditCouponComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  @ViewChild('allSelectedLessons') private allSelectedLessons: MatOption;
  couponForm: FormGroup;
  displayMessage: { [key: string]: string } = {};
  coupon_id: number;
  pageTitle: string;
  couponData: any;
  schoolList = [];
  expireType: number;
  errorMessage: any;
  todayDate:Date = new Date();
  customFilters: any = {};
  boards: any;
  classes: any;
  subjects: any;
  lessons: any;
  board_id = [];
  class_id = [];
  subject_id = [];
  lesson_id = [];

  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private schoolService: SchoolService,
    private router: Router,
    private snackBar: MatSnackBar,
    private contentService: AppContentService
  ) { 
    this.validationMessages = {
      couponCode: {
          required: 'Coupon Code is required.'
      },
      expiration_type: {
        required: 'Expiration Type is  required.'
      },
      num_attempts: {
        required: 'Number of attempts is  required.'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
   }

  ngOnInit() {

    this.route.params.subscribe(params => {
          this.coupon_id = params['coupon_id'];
          if(this.coupon_id){
            this.pageTitle = `Update Coupon`;
            this.getCoupon();
          }else{
            this.pageTitle = 'Add Coupon';
          }
      });
      this.schoolService.getSuggestSchools({}).subscribe((schoolList: any) => {
        this.schoolList = schoolList.data;
      })
    this.couponForm = this.fb.group({
      couponCode: ['', [Validators.required]],
      school_id: ['', ''],
      expiration_type: ['',[Validators.required]],
      expiration_date: ['', ''],
      num_days: ['', ''],
      num_attempts: ['', [Validators.required]],
      board_id: ['', ''],
      class_id: ['', ''],
      subject_id: ['', ''],
      lesson_id: ['', '']
    });
    this.getBoards();
    this.changeBoard(1);
    this.couponForm.patchValue({
      board_id: 1
    })
  }

  ngAfterViewInit(): void {
    const controlBlurs: Observable<any>[] = this.formInputElements
        .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    Observable.merge(this.couponForm.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
        this.displayMessage = this.genericValidator.processMessages(this.couponForm);
    });
  }

  schoolFilter(school_name: string) {
    // console.log(filterValue);
      let obj = { school_name: school_name };
      this.schoolService.getSuggestSchools(obj).subscribe((schoolData: any) => {
        this.schoolList = schoolData.data;
      });
  }

  onFocusOut(){
    this.schoolService.getSuggestSchools({}).subscribe((schoolList: any) => {
      this.schoolList = schoolList.data;
    })
  }


  saveSchool(){
    this.errorMessage = null;
    if(this.coupon_id){
      this.editCoupn();
    }else{
      this.createCoupon();
    }
  }

  createCoupon(){
    let formData = this.couponForm.value;
    let school_ids = []; 
    let class_ids = [];
    school_ids.push(formData.school_id);
    class_ids.push(formData.class_id);
    formData.school_id = school_ids;
    formData.class_id = class_ids;
    this.schoolService.createCoupon(formData).subscribe((response:any) => {
      if(response.status){
        this.router.navigate(['/coupon']);
        this.openSnackBar('Coupon has been created successfully. ', 'Close');
      }else{
        this.errorMessage = response;
      }
    });
  }

  editCoupn(){
    let obj = Object.assign({}, {coupon_id: this.coupon_id}, this.couponForm.value);
    //console.log(obj);
    this.schoolService.editCoupon(obj).subscribe((response:any) => {
      if(response.status){
        this.router.navigate(['/coupon']);
        this.openSnackBar('Coupon has been edited successfully. ', 'Close');
      }else{
        console.log(response);
        this.errorMessage = response;
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
        duration: 1500,
    });
  }

  getCoupon(){
    let obj = {
      coupon_id: this.coupon_id
    }
    this.schoolService.getCouponsById(obj).subscribe((couponData: any) => {
      this.couponData = couponData.data;
      //console.log(this.couponData);
      let schoolArrId = []
      if(this.couponData.coupon_School.length > 0){
        schoolArrId = this.couponData.coupon_School[0].school_id;
      }
      // this.couponData.coupon_School.forEach(element => {
      //   schoolArr.push(element.school_id);
      // });
      let class_idArr = [];
      let subjectIdArr = [];
      let lessonIdArr= [];
      if(this.couponData.coupon_detail.length != 0){
        class_idArr = this.couponData.coupon_detail[0].class_id,
        this.couponData.coupon_detail.forEach(element => {
          subjectIdArr.push(element.subject_id);
          lessonIdArr.push(element.lesson_id);
        })
      }
      this.changeClass(class_idArr);
      this.changeSubject(subjectIdArr);
      this.changeLesson(lessonIdArr);
      this.couponForm.patchValue({
        couponCode: this.couponData.couponCode,
        school_id: schoolArrId,
        expiration_type: this.couponData.expiration_type,
        expiration_date: this.couponData.expiration_date,
        num_days: this.couponData.num_days,
        num_attempts: this.couponData.num_attempts,
        class_id: class_idArr,
        subject_id: subjectIdArr,
        lesson_id: lessonIdArr
      });
      this.expireType = this.couponData.expiration_type;
      this.couponForm.controls.school_id.disable();
      this.couponForm.controls.board_id.disable();
      this.couponForm.controls.class_id.disable();
      this.couponForm.controls.subject_id.disable();
      this.couponForm.controls.lesson_id.disable();
    });
  }

  chkExpireType(typeVal){
    this.expireType = typeVal
  }

  getBoards(nonAcademicVal = 0) {
    const paramsVal = { non_academic: nonAcademicVal };
    this.contentService.getBoards(paramsVal).subscribe((result: any) => {
      this.boards = result.data;
    });
  }

  changeBoard(strVal, defaultVal = 0) {
    const paramsVal = { board_id: strVal };
    this.contentService.getClasses(paramsVal).subscribe((result: any) => {
      this.classes = result.data;
    });
  }

  changeClass(strVal, defaultVal = 0) {
    let class_ids = [];
    class_ids.push(strVal);
    const paramsVal = { class_id: class_ids };
   console.log(paramsVal);
    this.contentService.getSubjects(paramsVal).subscribe((result: any) => {
      this.subjects = result.data;
    });
    this.customFilters = {
      class_id: strVal,
    };
  }

  changeSubject(strVal, defaultVal = 0) {
    const paramsVal = { subject_id: strVal };

    this.contentService.getLessons(paramsVal).subscribe((result: any) => {
      this.lessons = result.data;
    });
    this.customFilters = {
      subject_id: strVal,
    };
   }

  changeLesson(strVal, defaultVal = 0) {
    this.customFilters = {
      lesson_id: strVal,
    };
  }

  tosslePerLesson() {
    if (this.allSelectedLessons.selected) {
      this.allSelectedLessons.deselect();
      return false;
    }
    if (this.couponForm.controls.lesson_id.value.length === this.lessons.length) {
      this.allSelectedLessons.select();
    }
  }

  selectAllLessons() {
    let lessonVal = [0];
    if (this.allSelectedLessons.selected) {
      this.couponForm.controls.lesson_id
        .patchValue([...this.lessons.map(item => item.id), 0]);
      lessonVal = this.couponForm.controls.lesson_id.value;
    } else {
      this.couponForm.controls.lesson_id.patchValue([]);
    }
    // console.log(this.contentForm.value.lesson_id);
    this.changeLesson(lessonVal);
  }

  generateCouponCode() {
    if(this.couponForm.value.school_id != '') {
      let class_id = (this.couponForm.value.class_id != '') ? this.couponForm.value.class_id : '';
      let obj = {school_id: this.couponForm.value.school_id};
      this.schoolService.getSchoolById(obj).subscribe((result: any) => {
        //console.log(result.data.school_code);
        this.couponForm.controls.couponCode.patchValue(result.data.school_code+class_id);
      });
    } else {
      this.couponForm.controls.couponCode.patchValue('');
    }
  }

}