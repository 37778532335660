import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styles: [
    `
    Html, body{
      height:100%;
        margin:0px;
        padding:0px;
    }

    .container {
      height:100%;
        margin: 0 auto;
        padding:0px;
        position: relative;
        top: 200px;
    }
    .grandParentContaniner{
            display:table;
            height:100%;
            margin: 0 auto;
    }
    .parentContainer{
        display:table-cell;
        vertical-align:middle;
    }
    .parentContainer .loginForm{
        padding:10px;
        background: #fff;
        border: 1px solid #ddd;
        width:400px;  /*  your login form width */    display:table-cell;
        display:table-cell;
        vertical-align:middle;

    }
    .footer {
      max-height: 18px;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1rem;
      background-color: #efefef;
      text-align: center;
    }

    .btn-pad {
      margin-left: 10px;
    }
    `
  ]
})
export class UpdatePasswordComponent implements OnInit {

  errorMessage: any;
  model: any = {};
  user_id: number;
  changePassword: boolean = false;
  productName = environment.productName;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _backendService: BackendService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params: any) => {
      //console.log(params);
      if(params.user_id){
        this.user_id = Number(atob(decodeURIComponent(params.user_id)));
        console.log(this.user_id);
      }
      if(params.changePassword){
        this.changePassword = true;
      }
    });
  }

  updatePass(){
    let password = this.model.password;
    let confirmPassword = this.model.confirmPassword;

    if(password != confirmPassword){
      this.errorMessage = "Password and Confirm Password does not match"
    } else{
      let obj ={
        user_id: this.user_id,
        password: password,
        confirmPassword:confirmPassword
      }
      console.log(obj)
      if(this.changePassword) {
      this._backendService.updatePassword(obj).subscribe(
        data => {
          console.log(data)
          if (data.status == false) {
            this.router.navigate(['/updatePassword'],{queryParamsHandling: 'preserve'});
            this.errorMessage = data.message;
          } else {
            this.openSnackBar(data.message, 'Close');
            this.router.navigate(['/dashboard']);
            this.errorMessage = data.message;
          }
        },
        error => {
        }
      );
      } else {
        this._backendService.updatePassword(obj).subscribe(
          data => {
            console.log(data)
            if (data.status == false) {
              this.router.navigate(['/updatePassword'],{queryParamsHandling: 'preserve'});
              this.errorMessage = data.message;
            } else {
              this.openSnackBar(data.message, 'Close');
              this.router.navigate(['/login']);
              this.errorMessage = data.message;
            }
          },
          error => {
          }
        );
      }
    }

    
  }


  reset(){
    this.model.password = '';
    this.model.confirmPassword = '';
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


}
