import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { StartTestComponent } from '../post-test/start-test/start-test.component';

@Injectable({
  providedIn: 'root'
})
export class StarttestGuard implements CanDeactivate<StartTestComponent>  {
  
  canDeactivate(component: StartTestComponent): boolean {
    let eventVal = component.canDeactivate();
      if(!eventVal){
        component.backStatus = false;
      }
      return eventVal;
  }
}
