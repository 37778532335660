import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-latest-atten-student',
  templateUrl: './latest-atten-student.component.html',
  styleUrls: ['./latest-atten-student.component.css']
})
export class LatestAttenStudentComponent implements OnInit {

  @Input() studentAttendance;

  constructor() { }

  ngOnInit() {
  }

}
