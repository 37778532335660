import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http, Response } from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionService {

  private apiUrl = environment.apiUrl;
  private searchFilterSubject = new BehaviorSubject<any>({});
  constructor(
    private http: Http
   ) { }

  setSubPaymentFromData(searchFilter: any) {
    this.searchFilterSubject.next(searchFilter);
  }

  getSubPaymentFromData() {
    return this.searchFilterSubject.asObservable();
  }

  getSubscriptionMSList(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_master_schools/getSubscriptionMSList`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getSubscriptionMSListNew(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_master_schools/getSchoolSubscriptions`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  createSubscriptionMS(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_master_schools/createSubscriptionMS`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getAllFrequencyType(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_freq_types/getAllFrequencyType`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getSchoolSubById(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_master_schools/getSchoolSubById`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  createSubscription(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/createSubscription`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  createCouponSubscription(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/createCouponSubscription`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserSubscription(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/getUserSubscription`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserInvoices(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/invoices/getUserInvoices`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserInvoicesIndonesia(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/invoices_indonesia/getUserInvoices`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  createSubscriptionLiferay(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/createSubscriptionLiferay`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getSubDetails(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/getSubscriptionDetail`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserInvoiceDetails(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/invoices/getUserInvoice`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserInvoiceDetailsIndonesia(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/invoices_indonesia/getUserInvoice`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  addInvoice(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/invoices/addInvoice`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  addInvoiceIndonesia(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/invoices_indonesia/addInvoice`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  subPayment(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/subPayment`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getInvoice(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/invoices/getInvoice`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserSubscriptionData(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_details/getUserSubscriptionData`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }
  
  getCouponRegisteredUsers(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_users/getRegisteredUser`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
     
    }

  getUserSubById(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_master_users/getUserSubById`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  createUserSubscription(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_master_users/createUserSubscription`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  } 

  getSchoolSubForUser(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_master_schools/getSchoolSubForUser`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  updateSchoolClass(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/school_user_classes/updateTrialSchool`, paramsVal)
    .map((response: Response) => {
      return response.json();
    })
    .catch(this.handleError);
  }

  getSchoolSubOfOKS(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_master_schools/getSchoolSubOfOKS`, paramsVal )
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  updateUserClass(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/user_classes/updateTrialSchool`, paramsVal)
    .map((response: Response) => {
      return response.json();
    })
    .catch(this.handleError);
  }
  
  getSubFromUser(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscription_master_users/getSubFromUser`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);  
  }
  
  redeemUserCoupon(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/coupon_users/couponUser`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  private handleError(error: Response): Observable<any> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error.json());
    return Observable.throw(error.json().error || 'Server error');
  }

  getSignature(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/indonesiaPayment`, paramsVal)
      .map((response: Response) => {
        return response.json();
        
      })
      .catch(this.handleError);
  }

  paymentSubscriptionInvoice(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/subPayment`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getIndoPayResp(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/getIndoPayResp`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getIndoPayData(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subscriptions/getIndoPayData`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }
  
}