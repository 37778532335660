import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';


@Injectable({
  providedIn: 'root'
})
export class BackendService {

  private onlineClassUrl = environment.onlineClassUrl;
  private apiUrl = environment.apiUrl;
  currentUser:any;
  token:string;
  
  constructor(private http: Http) { 
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  if (this.currentUser) {
      this.token = this.currentUser.token;
  }
   }

  initiateOnlineClass(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.onlineClassUrl}`,paramsVal)
    .map((response: Response)=>{
      return response.json();
    })
    .catch(this.handleError);
  }

  getAttendanceList(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/attendances/getAttendanceList`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getUserAttendance(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/attendances/getUserAttendance`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getStudentAttendance(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/attendances/getStudentAttendance`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getSchoolUserData(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/school_user_classes/getSchoolUserData`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getZoomMeeting(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/zoom_class_meetings/getZoomMeeting`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  userSchools(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/school_user_classes/userSchools`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getClasses(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/classes/getclasses`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getClass(class_id: number): Observable<any[]> {
    return this.http.get(`${this.apiUrl}/classes/getClass/${class_id}`)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getClassSections(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/class_sections/getClassSections`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getSection(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/class_sections/getSection`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getPollQuestions(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/poll_questions/getPollQuestions`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  addUserAnswer(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/poll_user_answers/addUserAnswer`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getActiveSchools(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/schools/getschools`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getSubjects(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subjects/getsubjects`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getLessons(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/lessons/getlessons`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getLesson(lesson_id: number): Observable<any[]> {
    return this.http.get(`${this.apiUrl}/lessons/getLesson/${lesson_id}`)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getCountryLessons(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/country_lessons/getlessons`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getCountryMapLessons(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/country_lesson_mappings/getlessons`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getTopics(paramsVal: any): Observable<any[]> {
    //console.log('paramsVal',paramsVal);
    return this.http.post(`${this.apiUrl}/topics/gettopics`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getScreens(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/screens/getScreens`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getHwTeacher(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/online_schedules/getScheduleTeacher`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

    getStudentReport(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getStudentReport`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getStudentPostReport(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/post_attempted_quiz_sets/getStudentPostReport`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  schoolQuizReport(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/user_data/schoolQuizReport`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  schoolPostQuizReport(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/user_data/schoolPostQuizReport`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getSchoolById(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/schools/getSchoolById`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getSchoolByCode(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/schools/getSchoolByCode`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getCountries(paramsVal: any = {}): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/countries/getCountries`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getCountrySchool(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/schools/getCountrySchool`, paramsVal)
      .map((response: Response) => {
        return response.json();
      });
  }

  appsignup(paramsVal: any): Observable<any[]> {
    if(paramsVal.class_id.length > 1 || (paramsVal.class_id.length == 1 && paramsVal.class_id[0] > 6)) {
      return this.http.post(`${this.apiUrl}/quiz_users/appsignuptemp`, paramsVal)
      .map((response: Response) => {
        return response.json();
      });
    } else {
      return this.http.post(`${this.apiUrl}/quiz_users/appsignup`, paramsVal)
        .map((response: Response) => {
          return response.json();
        });
    }
  }

  getuserdetails(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/quiz_users/getuserdetails`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getStudentData(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/quiz_users/getStudentData`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  updateUser(app_user_id, paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_users/updateUser/${app_user_id}`, paramsVal)
      .map((response: Response) => {
        return response.json();
      });
  }

  chkAdminUser(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/users/chkAdminUser`, paramsVal)
      .map((response: Response) => {
        return response.json();
      });
  }

  createUser(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/users/createUser`, paramsVal)
      .map((response: Response) => {
        return response.json();
      });
  }
  
  generateOtp(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/quiz_users/generateOtp`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getrandomnum(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/countries/getrandomnum`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  addUsersData(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/user_data/addUsersData`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  tutorCreatSchool(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/schools/tutorCreatSchool`, paramsVal)
      .map((response: Response) => {
        return response.json();
      });
  }

  getBoards(): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/boards/getboards`, "")
      .map((response: Response) => {
        return response.json();
      });
  }

  teacherAttendanceReport(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/online_schedules/teacherAttendanceReport`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getTeacherQuizSets(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_sets/getallquizsets`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getTotalQuiz(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/quiz_sets/getTotalQuiz`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getQuizDataReport(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getQuizDataReport`, paramsVal)
    .map((response: Response) => {
      return response.json();
    })
    .catch(this.handleError);
  }

  getPollResult(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/poll_user_answers/getPollResult`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUser(id: string): Observable<any[]> {
    
    let getUserDetails = `${this.apiUrl}/users/getUser/${id}?access_token=${this.token}`;
    return this.http.get(getUserDetails).map((response: Response) => {
        return response.json();
      }).catch(this.handleError);
  }

  classCodeMail(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/users/classCodeMail`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  trialUserInfo(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/users/trialUserInfo`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getSchoolCouponDetails(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/coupons/getSchoolCouponDetails`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  private handleError(error: Response): Observable<any> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error.json());
    return Observable.throw(error.json().error || 'Server error');
  }

  generateOtpFP(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/quiz_users/generateOtpFP`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  
  verifyOTP(paramsVal: any): Observable<any>{
    return this.http.post(`${this.apiUrl}/quiz_users/verifyOtp`,paramsVal)
    .map((response: Response)=>{
      return response.json();
    })
    .catch(this.handleError);
  }

  updatePassword(paramsVal: any): Observable<any>{
    return this.http.post(`${this.apiUrl}/quiz_users/updatePassword`,paramsVal)
    .map((response: Response)=>{
      return response.json();
    })
    .catch(this.handleError);
  }
}
