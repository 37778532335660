import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableDataSource } from '@angular/material';
import { ReportService } from 'src/app/report/report.service';

@Component({
  selector: 'app-screen-wise-usage',
  templateUrl: './screen-wise-usage.component.html',
  styleUrls: ['./screen-wise-usage.component.css']
})
export class ScreenWiseUsageComponent implements OnInit {

  @Input() lesson_id;
  @Input() topic_id;
  @Input() quiz_user_id;
  displayedColumns = ['screen_no', 'usage'];
  usageData: any;
  dataSource: any;
  
  constructor(
    private reportService: ReportService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getUserUsage();
  }

  getUserUsage(){
    let paramsVal = {lesson_ids: this.lesson_id, topic_ids: this.topic_id, quiz_user_id: this.quiz_user_id};
    //console.log('paramsVal',paramsVal);
    this.reportService.getUserUsage(paramsVal)
      .subscribe(result => {
        //console.log('result',result);
        this.usageData = result;
        this.freshDataList(this.usageData);
      });
  }

  freshDataList(screenUsageData) {
    this.usageData = screenUsageData;
    this.dataSource = new MatTableDataSource(this.usageData);
    //this.dataSource.paginator = this.paginator;
   // this.dataSource.sort = this.sort;
  }
  /*
  openDialog(topic_id, set_id, topic_id) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.height = '600px';
    dialogConfig.width = '800px';
    
    dialogConfig.data = {
      topic_id: topic_id,
      set_id: set_id,
      topic_id: topic_id
    };
    const dialogRef = this.dialog.open(AttemptedTestHistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe();
  }
  */
}
