import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '../_services/backend.service';
import { MsContentService } from './ms-content.service';

@Component({
  selector: 'app-ms-content',
  templateUrl: './ms-content.component.html',
  styleUrls: ['./ms-content.component.css']
})
export class MsContentComponent implements OnInit {
  currentUser: any;
  userId: number;
  user_id: number;
  classArr = [];
  subjects = [];
  lessons: any;
  topics: any;
  userData: any;
  userType: number;
  selClassId: number;
  defaultTab = 0;
  user_assigned_subjects: any;
  classSubject = [];
  class_id: number;
  classIds = [];
  country_id: number;
  school_id: number;
  countryLessonList: any;
  couponDetail: any;
  schoolCouponDetails: any;
  //classIdsCoupon = [];
  
  showCountryLesson: boolean = false;

  constructor(
    private backendService: BackendService,
    private router: Router,
    private route: ActivatedRoute,
    private contentService: MsContentService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.currentUser.id;
    this.user_id = this.currentUser.adminUser[0].id;
    this.userData = this.currentUser.userData;
    this.userType = this.currentUser.userData[0].user_type;
    this.user_assigned_subjects = this.currentUser.user_assigned_subjects;
    this.country_id = this.currentUser.countryDetail.id;
    this.school_id = this.currentUser.currentSelSchoolId;

    this.userData.forEach(userVal => {
      if(this.classIds.indexOf(userVal.class.id) == -1){
        this.classIds.push(userVal.class.id);
        this.classArr.push({id:userVal.class.id,className: userVal.class.class_name})
      }
    });
    this.user_assigned_subjects.forEach(sub => {
      this.classSubject.push(sub.subject);      
    });
    
    this.selClassId = this.classArr[0].id;
    this.getSubjects(this.selClassId);

    this.route.queryParams.subscribe(queryParams => {
      if(queryParams.class_id){
        this.selClassId = parseInt(queryParams.class_id);
        this.getSubjects(this.selClassId);
      }
    });

    this.getSchoolCouponDetails(this.school_id, this.user_id, this.userId, this.classIds);
    //console.log(this.classIdsCoupon);
  }

  getSubjects(classId){
    // classId = [classId];
    // let params = {class_id: classId};
    // this.backendService.getSubjects(params).subscribe(subData =>{
    //   this.subjects = subData;
    //   this.subjects = this.subjects.data;
    //   this.getLessons(this.subjects[0].id);
    // });    
    // console.log(classId, this.classSubject);
    this.class_id = classId
    this.subjects = [];
    this.classSubject.forEach((e)=> {
      if(e.class_id == classId){
        this.subjects.push(e);
      }
    })
    if(this.country_id == 2){
      this.showCountryLesson = true;
      this.getCountryLessons(this.subjects[0].id)
    }else{
      this.getLessons(this.subjects[0].id);
    }
  }

  changeLessons(subjectId){
    if(this.country_id == 2){
      this.showCountryLesson = true;
      this.getCountryLessons(subjectId)
    }else{
      this.getLessons(subjectId);
    }
  }

  getLessons(subjectId){
    subjectId = [subjectId];
    let params = {subject_id: subjectId};
    this.backendService.getLessons(params).subscribe(lessonData =>{
      this.lessons = lessonData;
      this.lessons = this.lessons.data;
      //console.log(this.lessons);
    });    
  }

  getSchoolCouponDetails(school_id, user_id, quiz_user_id, class_id){
    let params = {school_id: school_id, user_id: user_id, quiz_user_id: quiz_user_id, class_id: class_id};
    this.backendService.getSchoolCouponDetails(params).subscribe(couponDetails =>{
      this.couponDetail = couponDetails;
     // console.log(this.couponDetail);
      //console.log(this.classIdsCoupon);
    });    
  }

  getCountryLessons(strVal, defaultVal = 0) {
    const paramsVal = { subject_id: [strVal] };
    this.contentService.getCountryLessons(paramsVal).subscribe((result: any) => {
      this.countryLessonList = result.data;
      //console.log(this.countryLessonList);
    });
  }

  // openContentData(lessonId){
  //   let obj = {
  //     lesson_id: lessonId
  //   }
  //   this.contentService.getLessonContent(obj).subscribe((contentData: any) => {
  //     // console.log(contentData);
  //     if(contentData.data.length ==1 && contentData.data[0].content_type == 6){
  //       window.open(contentData.data[0].path);
  //     }else if(contentData.data.length ==1 && contentData.data[0].content_type != 6){
  //       this.router.navigate(['/content-data/',contentData.data[0].id])
  //     }
  //     else if(contentData.data.length >1){
  //       this.router.navigate(['/content-topic/',lessonId])
  //     }else if(contentData.data.length==0){
  //       this.openSnackBar("Content Not Available", 'Close');
  //     }
  //   });
  // }

  openContentData(lessonId){
    //console.log('Mabood '+this.couponDetail);
    let flag = false;
    if(this.class_id != 6) {
      flag = true;
    } else {
      this.couponDetail.forEach((couponDet)=> {
        //console.log(couponDet.class_id+" == "+this.class_id+" && ("+couponDet.lesson_id+" == 0 || "+couponDet.lesson_id+" == "+lessonId+")");
        if(couponDet.class_id == this.class_id && (couponDet.lesson_id == 0 || couponDet.lesson_id == lessonId)) {
          flag = true;
          return false;
        }
      });
    }
    //console.log(flag);
    flag = true; //currently enable all content. we will remove this line in future
    if(flag) {
      this.router.navigate(['/content-items/',this.class_id,lessonId])
    } else {
      alert('You are not authorized for this lesson.');
      return false;
    }
  }

  openMapLessons(lessonId){
    this.router.navigate(['/content-mapLesson/',this.class_id,lessonId])
  }

  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}