import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator } from '@angular/material';
import { ActivatedRoute } from '@angular/router';

import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DashboardService } from '../../dashboard.service';
import { PostDashboardService } from '../../post-dashboard.service';
import { QuizInfoDashboardComponent } from '../../dash-quiz-set/quiz-info-dashboard/quiz-info-dashboard.component';

@Component({
  selector: 'app-post-lesson-dash',
  templateUrl: './post-lesson-dash.component.html',
  styleUrls: ['./post-lesson-dash.component.css']
})
export class PostLessonDashComponent implements OnInit, AfterViewInit {


  @Input() inputData;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  title = 'Lesson Wise Performance'
  section_name: any;
  school_id: number;
  class_id: number;
  section_id: number;
  currentUser: any;
  limit = 6;
  offset = 0;
  customFilters = {}
  quizSetLength: number;
  total_count: number;
  rowHeight: string;
  quizSetData: any;
  tempObj: { school_id: number; class_id: number; section_id: number; };
  school_name: any;
  class_name: any;
  componentToDisplay: string;
  quiz_set_id: [];
  school_quiz_set_id: any;
  class_quiz_set_id: any;
  section_quiz_set_id: any;
  
  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private postDashboardService: PostDashboardService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
     //console.log('hii')
    this.section_name = this.inputData.section_name;

    // this.route.params.subscribe(
    //   params => {
    //     this.school_id = +params['school_id'];
    //     this.class_id = +params['class_id'];
    //     this.section_id = +params['section_id'];
    //   }
    // );

    this.route.params.subscribe(params => {
      if (params.school_id && params.class_id && params.section_id) {
        this.school_id = +params.school_id;
        this.class_id = +params.class_id;
        this.section_id = +params.section_id;
      } else {
        this.route.queryParams.subscribe(queryParams => {
          this.school_id = +queryParams.school_id;
          this.class_id = +queryParams.class_id;
          this.section_id = +queryParams.section_id;
        });
      }
    });
    
 
    this.route.queryParams.subscribe((paramsData: any) => {
      if (paramsData.quiz_set_id) {
        this.quiz_set_id = paramsData.quiz_set_id;
      }
      
    })

    this.route.queryParamMap.subscribe(params => {
      const listView = params.get('listView');
      if (listView === 'Yes') {
        this.componentToDisplay = 'user-list-dashboard';
        this.title = 'Student Performance List';      }
    });

    this.route.queryParamMap.subscribe(params => {
      const studentDash = params.get('studentDash');
      if (studentDash === 'Yes') {
        this.componentToDisplay = 'user-dashboard';
        this.title = 'Student Wise Performance';      }
    });

    this.route.queryParamMap.subscribe(params => {
      const lessonWiseTab = params.get('lessonWiseTab');
      if (lessonWiseTab === 'Yes') {
        this.componentToDisplay = 'lesson-wise-student';
        this.title = 'Lesson Wise Student Performance';      }
    });

    


    this.getPrePostSchoolQuizId();
    this.breadcrumbs();
    //this.getQuizSets();
    
  }



  getPrePostSchoolQuizId() {
    //New API FOR SCHOOL DASH IN PRE/POST
    let customFiltersPosT = {
      school_id: [this.school_id],
    }
   // console.log(customFiltersPosT)
    this.dashboardService.getPrePostSchoolsList(customFiltersPosT).subscribe((schoolsList: any) => {
     // console.log(schoolsList);
      let schoolData : any = Object.values(schoolsList)
      this.school_quiz_set_id = schoolData[0].quiz_set_id
      this.getPrePostClassQuizId(this.school_quiz_set_id)
    });
    
  }


  getPrePostClassQuizId(school_quiz_set_id) {
    //New API FOR Class DASH IN PRE/POST
    let customFiltersPosT = {
      school_id: [this.school_id],
      class_id: [this.class_id],
      quiz_set_id: [this.school_quiz_set_id],
    }
   //  console.log(JSON.stringify(customFiltersPosT))
    this.dashboardService.getPrePostClassList(customFiltersPosT).subscribe((classListData: any) => {
    //  console.log(classListData);
      let classData: any = classListData
      //console.log(this.schoolData)
      this.class_quiz_set_id = classData.quiz_set_id
      this.getPrePostSectionQuizId(this.class_quiz_set_id)
    });
  }

  getPrePostSectionQuizId(class_quiz_set_id){
    let customFiltersPosT = {
      user_id: this.currentUser.adminUser[0].id,
      school_id: [this.school_id],
      class_id: [this.class_id],
      section_id: [this.section_id],
      quiz_set_id: [this.class_quiz_set_id],
    }
  //  console.log('section'+JSON.stringify(customFiltersPosT))
    this.dashboardService.getPrePostSectionList(customFiltersPosT).subscribe((sectionListData: any) => {
     // console.log(sectionListData);
      let sectionData : any = sectionListData
      //console.log(this.schoolData)
      this.section_quiz_set_id = sectionData.quiz_set_id
      this.getQuizSets()
    });

  }
  getQuizSets(){

    let customFiltersLesson = {
      // user_id: this.currentUser.adminUser[0].id,
       school_id: [this.school_id],
       class_id: [this.class_id],
       section_id: [this.section_id],
       quiz_set_id: [this.section_quiz_set_id]
      // limit: this.limit,
      // offset: this.offset
     }
   // console.log(customFiltersLesson)
    this.postDashboardService.getLessonPerfDash(customFiltersLesson).subscribe((quizSets: any) => {
     ///console.log(quizSets)
      this.quizSetData = Object.values(quizSets);      
      this.total_count = this.quizSetData.length;
      //console.log(this.total_count);
      this.quizSetLength = this.total_count;
        if(this.quizSetLength > 2){
          this.rowHeight = "480px";
          this.quizSetLength = 3;
        }
   });
  }

  breadcrumbs(){
    this.tempObj = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id
    }

    // get School Name
    this.dashboardService.getSchoolById(this.tempObj).subscribe((schoolData: any) => {
      // console.log(schoolData);
      this.school_name = schoolData.data.school_name;
    })

    // get Class Name
    // this.dashboardService.getClass(this.class_id).subscribe((classData: any) => {
    //   //console.log(classData);
    //   this.class_name = classData.data.class_name;
    // })

    this.class_name = 'Grade '+this.class_id  ;

      // get Section Name
    this.dashboardService.getSection(this.tempObj).subscribe((sectionData: any) => {
      // console.log(sectionData);
      this.section_name = sectionData.data.class_section.section_name;
    })

  }


  openDialog(quizSet) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '500px';
  dialogConfig.height = '400px';
  dialogConfig.maxHeight = '80vh'; 
    dialogConfig.data = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      quiz_set_id: quizSet.quiz_set_id,
      lesson_id: quizSet.lesson_id,
      dashType: 'postTest'
    };
    this.dialog.open(QuizInfoDashboardComponent, dialogConfig);
  }


  onPageChange(event) {
    this.offset = event.pageIndex * event.pageSize;
    this.limit = event.pageSize;
    this.getQuizSets();
  }

  ngAfterViewInit() {
    merge(this.paginator.page)
      .pipe(
        tap(() => {
          this.onPageChange(this.paginator);
        })
      )
      .subscribe();
  }

}
