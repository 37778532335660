import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../user.service';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import html2pdf from 'html2pdf.js';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-user-invoice-details',
  templateUrl: './user-invoice-details.component.html',
  styleUrls: ['./user-invoice-details.component.css']
})
export class UserInvoiceDetailsComponent implements OnInit {
  @ViewChild('invoiceContainer') invoiceContainer!: ElementRef;

  Id: any;
  user_id: any;
  userData: any;
  user_name: string;
  errorMessage: any;
  exc_amount: any;
  disableDownload: boolean = false;
  nonIGSTAmount: any;
  invoicesList: boolean = false;
  searchCountryId: any;
  productName = environment.productName;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.Id = params['id'];
    });

    this.route.params.subscribe(params => {
      this.user_id = params['user_id'];
    });

    this.route.queryParams.subscribe(params => {
      if (params.backTo === '0') {
        //console.log(params.backTo);
        this.invoicesList = true
      } else {
        //console.log(params.backTo)
        this.invoicesList = false
      }
    })

    this.route.queryParams.subscribe(params => {
      console.log(params)
      if (params) {
        const customFiltersObj = JSON.parse(params.customFilters);
        this.searchCountryId = customFiltersObj.country_id;
      }

      //console.log(this.searchCountryId);
    })

    this.getUser(this.user_id)
    this.getInvoiceDetails(this.Id)
  }

  getUser(user_id: string): void {
    this.userService.getUser(this.user_id).subscribe((user: any) => {
      //console.log(user)
      this.user_name = user.firstName + ' ' + user.lastName;
      localStorage.setItem('invoiceuser_country_id', user.user_countries[0].country_id);
      //console.log(this.user_name)

    },
      (error: any) => this.errorMessage = error as any
    );
  }

  getInvoiceDetails(Id) {
    let obj = {
      id: Id
    }
    this.disableDownload = true;
    let invoiceuser_country_id = parseInt(localStorage.getItem('invoiceuser_country_id'));
    //console.log(this.searchCountryId);
    //console.log(invoiceuser_country_id)
    if (this.searchCountryId != '' && this.searchCountryId != undefined && this.searchCountryId != null) {
      if (this.searchCountryId == 2) {
        this.subscriptionService.getUserInvoiceDetailsIndonesia(obj).subscribe((data: any) => {
          // console.log(data);
          let invData = data;
          this.exc_amount = invData.total_amount - invData.tax_amount;
          let exc_value = parseFloat(this.exc_amount).toFixed(2);
          let checkAmount = parseFloat(invData.total_amount).toFixed(2);
          let checkPrice = parseFloat(invData.price).toFixed(2);
          let checkDiscountAmount = parseFloat(invData.discount_amount).toFixed(2);
          let checkTaxAmount = parseFloat(invData.tax_amount).toFixed(2);
          this.nonIGSTAmount = invData.tax_amount / 2;
          let sgst = parseFloat(this.nonIGSTAmount).toFixed(2)



          this.userData = Object.assign(
            {}, invData,
            { exc_amount: exc_value },
            { total_amount: checkAmount },
            { price: checkPrice },
            { discount_amount: checkDiscountAmount },
            { tax_amount: checkTaxAmount },
            { sgst: sgst }
          );
          //console.log(this.userData);
          if (data) {
            this.disableDownload = false;
          }
        })
      } else {
        this.subscriptionService.getUserInvoiceDetails(obj).subscribe((data: any) => {
          // console.log(data);
          let invData = data;
          this.exc_amount = invData.total_amount - invData.tax_amount;
          let exc_value = parseFloat(this.exc_amount).toFixed(2);
          let checkAmount = parseFloat(invData.total_amount).toFixed(2);
          let checkPrice = parseFloat(invData.price).toFixed(2);
          let checkDiscountAmount = parseFloat(invData.discount_amount).toFixed(2);
          let checkTaxAmount = parseFloat(invData.tax_amount).toFixed(2);
          this.nonIGSTAmount = invData.tax_amount / 2;
          let sgst = parseFloat(this.nonIGSTAmount).toFixed(2)



          this.userData = Object.assign(
            {}, invData,
            { exc_amount: exc_value },
            { total_amount: checkAmount },
            { price: checkPrice },
            { discount_amount: checkDiscountAmount },
            { tax_amount: checkTaxAmount },
            { sgst: sgst }
          );
          //console.log(this.userData);
          if (data) {
            this.disableDownload = false;
          }
        })
      }
    } else {
      if (invoiceuser_country_id == 2) {
        this.subscriptionService.getUserInvoiceDetailsIndonesia(obj).subscribe((data: any) => {
          // console.log(data);
          let invData = data;
          this.exc_amount = invData.total_amount - invData.tax_amount;
          let exc_value = parseFloat(this.exc_amount).toFixed(2);
          let checkAmount = parseFloat(invData.total_amount).toFixed(2);
          let checkPrice = parseFloat(invData.price).toFixed(2);
          let checkDiscountAmount = parseFloat(invData.discount_amount).toFixed(2);
          let checkTaxAmount = parseFloat(invData.tax_amount).toFixed(2);
          this.nonIGSTAmount = invData.tax_amount / 2;
          let sgst = parseFloat(this.nonIGSTAmount).toFixed(2)



          this.userData = Object.assign(
            {}, invData,
            { exc_amount: exc_value },
            { total_amount: checkAmount },
            { price: checkPrice },
            { discount_amount: checkDiscountAmount },
            { tax_amount: checkTaxAmount },
            { sgst: sgst }
          );
          //console.log(this.userData);
          if (data) {
            this.disableDownload = false;
          }
        })
      } else {
        this.subscriptionService.getUserInvoiceDetails(obj).subscribe((data: any) => {
          // console.log(data);
          let invData = data;
          this.exc_amount = invData.total_amount - invData.tax_amount;
          let exc_value = parseFloat(this.exc_amount).toFixed(2);
          let checkAmount = parseFloat(invData.total_amount).toFixed(2);
          let checkPrice = parseFloat(invData.price).toFixed(2);
          let checkDiscountAmount = parseFloat(invData.discount_amount).toFixed(2);
          let checkTaxAmount = parseFloat(invData.tax_amount).toFixed(2);
          this.nonIGSTAmount = invData.tax_amount / 2;
          let sgst = parseFloat(this.nonIGSTAmount).toFixed(2)



          this.userData = Object.assign(
            {}, invData,
            { exc_amount: exc_value },
            { total_amount: checkAmount },
            { price: checkPrice },
            { discount_amount: checkDiscountAmount },
            { tax_amount: checkTaxAmount },
            { sgst: sgst }
          );
          //console.log(this.userData);
          if (data) {
            this.disableDownload = false;
          }
        })
      }

    }

  }

  reset() {
    this.getUser(this.user_id);
    this.getInvoiceDetails(this.Id);
  }

  exportToPDF(): void {

    const orderID = this.userData.order_id;
    const createdOn = new Date(this.userData.created_on);
    const datePipe = new DatePipe('en-GB');
    const formattedDate = datePipe.transform(createdOn, 'dd-MM-yyyy');
    const fileName = `invoice_${orderID}_${formattedDate}.pdf`;
    const options = {
      margin: 2,
      filename: fileName,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    const element = this.invoiceContainer.nativeElement;
    html2pdf().from(element).set(options).save();
  }


  isPincodeInRange(pincode: string): boolean {
    return pincode >= '110000' && pincode <= '110110';
  }


}
