import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from 'src/app/_services/backend.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-school-report',
  template: `
  <div id="pdfTable" #pdfTable>
  <p>
    <label>School Name: </label> <strong> {{schoolName}}</strong>
  </p>
  <p>Report Date: {{from_date}} to {{to_date}}</p>
  <!--<h6>{{name}}</h6>-->
  <div align="right"> 
  <button mat-raised-button class=" mat-primary" 
    (click)="exportAsPDF()">Download As PDF</button>
</div>
  <table class="report-table" border="1" cellpadding="8">
    <tr>
      <th>Student Name</th>
      <th>Class/Section</th>
      <th>Email</th>
      <th>Total Attempted Quiz</th>
      <th>Average Score</th>
    </tr>
    <tr *ngFor="let quizData of quizSetData">
      <td>{{quizData.studentName}}</td>
      <td>{{quizData.class_name}} - {{quizData.section_name}}</td>
      <td>{{quizData.email}}</td>
      <td>{{quizData.totalQuiz}}</td>
      <td>{{quizData.percentage}}%</td>
    </tr>
  </table>
</div>
<br>
<div> <button  (click)="exportAsPDF()">Download To PDF</button></div>
  `,
  styles: [
    ` table.report-table {   border-spacing: 30px;   border: 1px solid #000000;}

      th td{
        border: 1px solid #000000;
        width: 50px;
      }
    `
  ]
})
export class SchoolMsReportComponent implements OnInit {

  percent: any;
  quizSetData: any;
  schoolName: string;
  email: string;
  report_type: string;
  currentUser: any;
  from_date;
  to_date;
  school_id: number;

  constructor(
    private route: ActivatedRoute,
    private backendService: BackendService
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe((params: any) => {
      this.report_type = params.report_type;
      this.from_date = params.from_date;
      this.to_date = params.to_date;
      this.school_id = params.school_id
    });

    this.route.queryParams.subscribe((params: any) => {
      if (Object.keys(params).length > 0) {
        Object.keys(params).forEach((pKey) => {
          params = pKey;
        });
        params = atob(decodeURIComponent(params));
        params = params.split('&');
        this.from_date = (params ? params[2].split('=') : '');
        this.from_date = (this.from_date ? this.from_date[1] : '');
        this.to_date = (params ? params[3].split('=') : '');
        this.to_date = (this.to_date ? this.to_date[1] : '');
        this.school_id = (params ? params[6].split('=') : '');
        this.school_id = (this.school_id ? this.school_id[1] : '');
        this.report_type = (params ? params[4].split('=') : '');
        this.report_type = (this.report_type ? this.report_type[1] : '');
      }
    });

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(this.currentUser);
    this.getSchool();
    if(this.report_type == 'posttest'){
      this.getSchoolPostReport();
    }else{
      this.getSchoolReport();
    }
  }

  getSchool(){
    let obj = {school_id: this.school_id}
    this.backendService.getSchoolById(obj).subscribe((data: any) => {
      console.log(data);
      this.schoolName = data.data.school_name;
    })
  }

  getSchoolReport() {
    let obj = {
      school_id: this.school_id,
      from_date: this.from_date,
      to_date: this.to_date,
      // school_id: 3,
    }
    this.backendService.schoolQuizReport(obj).subscribe((data: any) => {
      this.quizSetData = data.data;
      let temp = [];
      this.quizSetData.forEach(element => {
        element.percentage = element.percentage.toFixed(2);
        temp.push(element);
      });
      this.quizSetData = temp;
      // console.log(data);
      // this.exportAsPDF();
    })
  }

  getSchoolPostReport() {
    let obj = {
      school_id: this.school_id,
      from_date: this.from_date,
      to_date: this.to_date,
    }
    this.backendService.schoolPostQuizReport(obj).subscribe((data: any) => {
      this.quizSetData = data.data;
      let temp = [];
      this.quizSetData.forEach(element => {
        element.percentage = element.percentage.toFixed(2);
        temp.push(element);
      });
      this.quizSetData = temp;
      // console.log(data);
      // this.exportAsPDF();
    })
  }

  // exportAsPDF() {
  //   setTimeout(() => {
  //     let data = document.getElementById('pdfTable');
  //     html2canvas(data).then(canvas => {
  //       const contentDataURL = canvas.toDataURL('image/png')
  //       let pdf = new jspdf.jsPDF(); //Generates PDF in landscape mode
  //       pdf.addImage(contentDataURL, 0, 0, 0, 0);
  //       pdf.save('schoolReport.pdf');
  //     });
  //   }, 0);

  // }

  exportAsPDF() {
    let data = document.getElementById('pdfTable');
    console.log(data);
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jspdf.jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      }
      doc.save( 'file.pdf');
    });
  }

}