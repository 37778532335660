
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {

  currentUser: any;
  userid: number;
  userType: number;

  ngOnInit(){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
        this.userid = this.currentUser.id;
        this.userType = this.currentUser.userData[0].user_type;
    }
  }

}
