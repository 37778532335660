import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-add-school-subscription',
  templateUrl: './add-school-subscription.component.html',
  styleUrls: ['./add-school-subscription.component.css']
})

export class AddSchoolSubscriptionComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  boards: any;
  pageTitle = 'Add School Subscription';
  errorMessage: any;
  schoolSubForm: FormGroup;
  currentUser: any;
  classList: any;
  schoolList: any;
  user_id: number;
  type_order: any;
  schoolArr = [];
  classArr = [];
  customFilters: any;
  school_id = [];
  class_id = [];
  userData: any;
  frequencyTypeList: any;
  totalAmount: number;
  schoolSubId: number;
  schoolSubData: any;

  constructor(
    private subscriptionService: SubscriptionService,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    // private userService: UserService,
    private backendService: BackendService
  ) {
    this.validationMessages = {
      school_id: {
        required: 'school is required.'
      },
      class_id: {
        required: 'Class is required.'
      },
      freq_type_id: {
        required: 'Frequency Type is required.'
      },
      amount: {
        required: 'Amount is required.'
      },
      total_amount: {
        required: 'Total amount is required.'
      },
      status: {
        required: 'Status is required.'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    Observable.merge(this.schoolSubForm.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.schoolSubForm);
    });
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.user_id = this.currentUser.id;
    if (this.currentUser) {
      // this.user_id = this.currentUser.id;
      // this.type_order = this.currentUser.user_Type.type_order;
      // this.countryList = this.currentUser.user_countries;

      this.user_id = this.currentUser.adminUser[0].id;
      this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
      // this.countryList = this.currentUser.user_countries;
    }

    this.schoolSubForm = this.fb.group({
      school_id: ['', [Validators.required]],
      class_id: ['', [Validators.required]],
      freq_type_id: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      discount_amt: ['', ''],
      total_amount: ['', [Validators.required]],
      status: ['', [Validators.required]]
    });

    this.subscriptionService.getAllFrequencyType({}).subscribe((data: any)=> {
    //  console.log(data);
      this.frequencyTypeList = data.data;
    })

    let classObj = {
      board_id: 1
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });

    if (this.type_order == 1 || this.type_order == 2) {
      this.backendService.getActiveSchools({}).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.school_id.push(element.id) });
      });
    } else {
      this.userData = this.currentUser.userData;
      this.userData.forEach(userVal => {
        if (this.classArr.indexOf(userVal.class.id) == -1) {
          this.classArr.push(userVal.class.id);
          // this.classes.push({ id: userVal.class.id, className: userVal.class.class_name })
        }
      });
      this.currentUser.userSchools.forEach(school => {
        this.school_id.push(school.id);
      });
  
      let classObj = {
        board_id: 1,
        class_id: this.classArr
      }
      this.backendService.getClasses(classObj).subscribe((data: any) => {
        this.classList = data.data;
      });
    }

    this.route.params.subscribe(params => {
      this.schoolSubId = params['schoolSubId'];
      if (this.schoolSubId) {
        this.getschoolSub();
        this.pageTitle = `Update School Subscription`;
      } else {
        this.pageTitle = 'Add School Subscription';
      }
    });
  }

  getschoolSub(){
    this.subscriptionService.getSchoolSubById({schoolSubId: this.schoolSubId})
    .subscribe((data: any)=> {
      //console.log(data);
      this.schoolSubData = data.data;
      this.schoolSubForm.patchValue({
        school_id: this.schoolSubData.school_id,
        class_id: this.schoolSubData.class_id,
        freq_type_id: this.schoolSubData.freq_type_id,
        amount: this.schoolSubData.amount,
        discount_amt: this.schoolSubData.discount_amt,
        total_amount: this.schoolSubData.total_amount,
        status: this.schoolSubData.status
      })
    })
  }

  amountFunc(amount){
  //  console.log(amount);
    this.schoolSubForm.patchValue({
      total_amount: amount
    });
  }

  discountAmtFunc(discountAmount){
    if(discountAmount > this.schoolSubForm.value.amount){
      // console.log("here is discount cancel");
      this.errorMessage = "Discount amount should not be more than amount";
      this.schoolSubForm.controls.discount_amt.setValue(undefined);
      this.schoolSubForm.patchValue({
        total_amount: this.schoolSubForm.value.amount
      });
      return;
    }else{
      this.errorMessage = "";
    }
    this.totalAmount = this.schoolSubForm.value.amount - discountAmount
    this.schoolSubForm.patchValue({
      total_amount: this.totalAmount
    })
  }


  savecontent() {
    // if(this.schoolSubForm.value.discount_amt && this.schoolSubForm.value.discount_amt > this.schoolSubForm.value.amount){
    //   console.log(this.schoolSubForm.value);
    //   console.log("here is discount cancel");
    //   this.errorMessage = "Discount amount should not be more than amount";
    //   return;
    // }else{
    //   this.errorMessage = "";
    // }
    let obj = {};
    if(this.schoolSubId){
      obj = Object.assign({},{schoolSubId: this.schoolSubId}, this.schoolSubForm.value);
     // console.log(obj);
      this.subscriptionService.createSubscriptionMS(obj).subscribe((data: any) => {
        //console.log(data);
        if (data.status == true) {
          this.openSnackBar('Subscription has been updated successfully. ', 'Close');
          this.router.navigate(['/schoolSubscription'], { queryParamsHandling: 'preserve' });
        } else {
          this.errorMessage = data.message;
        }
      })
    }else{
      obj = Object.assign({}, this.schoolSubForm.value);
    //  console.log(obj);
      this.subscriptionService.createSubscriptionMS(obj).subscribe((data: any) => {
    //    console.log(data);
        if (data.status == true) {
          this.openSnackBar('Subscription has been created successfully. ', 'Close');
          this.router.navigate(['/schoolSubscription'], { queryParamsHandling: 'preserve' });
        } else {
          this.errorMessage = data.message;
        }
      })
    }
    
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}
