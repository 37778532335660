import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PostDashboardService } from 'src/app/dashboard/post-dashboard.service';

@Component({
  selector: 'app-post-lesson-topic-dash',
  templateUrl: './post-lesson-topic-dash.component.html',
  styleUrls: ['./post-lesson-topic-dash.component.css']
})
export class PostLessonTopicDashComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentUser: any;
  section_name: any;
  school_id: number;
  class_id: number;
  section_id: number;
  lesson_id: number;
  tempObj: {};
  school_name: any;
  class_name: any;
  total_count: any;
  quizSetLength: any;
  rowHeight: string;
  limit = 6;
  offset = 0;


  lesson_name: any;
  quizSetData: any;
  customFilters: {};
  quiz_set_id = [];
  defaultTab =  0;
  ques_dash: boolean = false;
  school_quiz_set_id: any;
  class_quiz_set_id: any;
  main_dash: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private postDashboardService: PostDashboardService,
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    

    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
        this.lesson_id = +params['lesson_id'];
        //this.quiz_set_id.push(params['quiz_set_id']);
      }
    );
    this.route.queryParams.subscribe(params => {
      if (params.quiz_set_id) {
        this.quiz_set_id = params.quiz_set_id;
      }
      if( params.postTest == '1'){
        this.defaultTab = 1
      }
      if(params.ques_dash == 'ques_dash'){
        this.ques_dash = true;
      }
      if(params.backTo == 'quiz_dash'){
        this.main_dash = true;
      }
      
    });

    //console.log(this.quiz_set_id)
    this.customFilters = {
       school_id: [this.school_id],
       class_id: [this.class_id],
       section_id: [this.section_id],
       lesson_id: [this.lesson_id],
       quiz_set_id: [this.quiz_set_id]
     }

     //this.getPrePostSchoolQuizId();
    this.breadcrumbs();

    this.getQuizSets()
  }




  // getPrePostSchoolQuizId() {
  //   //New API FOR SCHOOL DASH IN PRE/POST
  //   let customFiltersPosT = {
  //     school_id: [this.school_id],
  //   }
  //   // console.log(customFiltersPosT)
  //   this.dashboardService.getPrePostSchoolsList(customFiltersPosT).subscribe((schoolsList: any) => {
  //     //console.log(schoolsList);
  //     let schoolData : any = Object.values(schoolsList)
  //     this.school_quiz_set_id = schoolData[0].quiz_set_id
  //   });
  //   this.getPrePostClassQuizId(this.school_quiz_set_id)
  // }


  // getPrePostClassQuizId(school_quiz_set_id) {
  //   //New API FOR Class DASH IN PRE/POST
  //   let customFiltersPosT = {
  //     user_id: this.currentUser.adminUser[0].id,
  //     school_id: [this.school_id],
  //     class_id: [this.class_id],
  //     quiz_set_id: [this.school_quiz_set_id],
  //   }
  //    console.log(JSON.stringify(customFiltersPosT))
  //   this.dashboardService.getPrePostClassList(customFiltersPosT).subscribe((classListData: any) => {
  //     console.log(classListData);
  //     let classData: any = classListData
  //     //console.log(this.schoolData)
  //     this.class_quiz_set_id = classData.quiz_set_id
  //   });
  //   this.getPrePostSectionQuizId(this.class_quiz_set_id)
  // }


  // getPrePostSectionQuizId(class_quiz_set_id) {
  //   //New API FOR Section DASH IN PRE/POST
  //   let customFiltersPosT = {
  //     user_id: this.currentUser.adminUser[0].id,
  //     school_id: [this.school_id],
  //     class_id: [this.class_id],
  //     section_id: [this.section_id],
  //     quiz_set_id: [this.quiz_set_id],
  //   }
  //   console.log(JSON.stringify(customFiltersPosT))
  //   this.dashboardService.getPrePostSectionList(customFiltersPosT).subscribe((sectionListData: any) => {
  //     console.log(sectionListData);
  //     let sectionData: any = sectionListData
  //     //console.log(this.schoolData)
  //     this.school_quiz_set_id =  sectionData.quiz_set_id
  //   });
  // }

  
  getQuizSets(){
   // aconsole.log(this.customFilters)
     this.postDashboardService.getTopicPerfDash(this.customFilters).subscribe((quizSets: any) => {
       //console.log(quizSets)
       this.quizSetData = Object.values(quizSets);      
       this.total_count = this.quizSetData.length;
       //console.log(this.total_count);
       this.quizSetLength = this.total_count;
         if(this.quizSetLength > 2){
           this.rowHeight = "480px";
           this.quizSetLength = 3;
         }
    });
   }
 
   onPageChange(event) {
     this.offset = event.pageIndex * event.pageSize;
     this.limit = event.pageSize;
     this.getQuizSets();
   }
 
   ngAfterViewInit() {
     merge(this.paginator.page)
       .pipe(
         tap(() => {
           this.onPageChange(this.paginator);
         })
       )
       .subscribe();
   }
 
 
 

  breadcrumbs(){
    this.tempObj = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      lesson_id : this.lesson_id
    }

    // get School Name
    this.dashboardService.getSchoolById(this.tempObj).subscribe((schoolData: any) => {
      // console.log(schoolData);
      this.school_name = schoolData.data.school_name;
    })

    // get Class Name
    this.dashboardService.getClass(this.class_id).subscribe((classData: any) => {
      // console.log(classData);
      this.class_name = classData.data.class_name;
    })

      // get Section Name
    this.dashboardService.getSection(this.tempObj).subscribe((sectionData: any) => {
      // console.log(sectionData);
      this.section_name = sectionData.data.class_section.section_name;
    })

    this.dashboardService.getLessonById(this.lesson_id).subscribe((lesson : any ) => {
      //console.log(lesson);
      this.lesson_name = lesson.data.lesson_name;
    })

  }


}
