import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BackendService } from '../_services/backend.service';

@Component({
  selector: 'app-ms-user',
  templateUrl: './ms-user.component.html',
  styleUrls: ['./ms-user.component.css']
})
export class MsUserComponent implements OnInit {

  userForm: FormGroup;
  currentUser: any;
  user_id: number;
  type_order: number;
  name: string;
  email: string;
  contactNumber: string;
  userType: string;
  school_id = [];
  class_id = [];
  section_id = [];
  subject_id = [];
  user_class = [];
  schoolList = [];
  classList = [];
  sectionList = [];
  subjectList = [];
  user_subjects = [];

  constructor(private fb: FormBuilder,
    private backendService: BackendService) { }

  // ngOnInit() {
  //   this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  //   this.user_id = this.currentUser.id;
  //   // this.type_order = this.currentUser.user_Type.type_order;
  //   this.name = this.currentUser.userData[0].studentName0;
  //   this.email = this.currentUser.userData[0].email;
  //   this.contactNumber = this.currentUser.userData[0].studentContactNo;
  //   this.userType = this.currentUser.userData[0].user_type;
  // }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = this.currentUser.id;
        this.name = this.currentUser.userData[0].studentName;
    this.email = this.currentUser.userData[0].email;
    this.contactNumber = this.currentUser.userData[0].studentContactNo;
    this.userType = this.currentUser.userData[0].user_type;
    this.user_subjects = this.currentUser.user_assigned_subjects;
      this.user_subjects.forEach(sub => {
        this.subject_id.push(sub.subject_id);
      });

    this.userForm = this.fb.group({
      name: ['', ''],
      email: ['', ''],
      contactNumber: ['', ''],
      userType: ['', ''],
      school_id: ['', ''],
      class_id: ['', ''],
      section_id: ['', ''],
      subject_id: ['', '']
    });

      let obj = { user_id: this.user_id }
      this.backendService.userSchools(obj).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(sc => {
          this.school_id.push(sc.id);
        })
        this.class_id = data.userClass;
        this.section_id = data.userSection;
        this.getClasses();
        this.getSections();
        this.getSubjects();
        this.patchData();
      });
  }

  getClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.class_id
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  getSections() {
    let sectionObj = { class_id: this.class_id, section_id: this.section_id }
    this.backendService.getClassSections(sectionObj).subscribe((result: any) => {
      this.sectionList = result.data;
    })
  }

  getSubjects(){
    let classObj = { class_id: this.class_id, subject_id: this.subject_id }
    this.backendService.getSubjects(classObj).subscribe((result: any) => {
      this.subjectList = result.data;
    });
  }

  patchData(){
    this.userForm.patchValue({
      name: this.name,
      email: this.email,
      contactNumber: this.contactNumber,
      userType: this.userType,
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      subject_id: this.subject_id
    });
  }

}
