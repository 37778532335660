import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { SubscriptionService } from '../../subscription.service';

@Component({
  selector: 'app-payment-coupon',
  templateUrl: './payment-coupon.component.html',
  styleUrls: ['./payment-coupon.component.css']
})
export class PaymentCouponComponent implements OnInit {
  searchFilter: any = {
    couponCode: '',
    user_id : ''
  };
  couponData: any;
  errorMessage: any;
  currentUser: any;
  user_id: any;
  firstName: any;
  email: any;
  end_date: any;
  school_id: any;
  class_id: any;
  subscription: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) public Subscription: any,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = this.currentUser.adminUser[0].id;
    this.firstName = this.currentUser.adminUser[0].firstName;
    this.email = this.currentUser.adminUser[0].email;
    this.subscription = this.Subscription.subscription;
    this.school_id = this.subscription.school_id;
    this.class_id = this.subscription.class_id;
    //console.log(this.user_id)
  }
  
  redeemCoupon(){
      let obj ={
        couponCode : this.searchFilter.couponCode,
        user_id: this.user_id,
        school_id : this.school_id
      }
     // console.log(obj);
    this.subscriptionService.redeemUserCoupon(obj).subscribe((data: any) =>{
    //  console.log(data);
      this.couponData = data.couponDetail; 
        if(data.status == true){
          if(this.couponData.expiration_type == 1){
          this.end_date = this.couponData.expiration_date;
        } else {
          let days = this.couponData.num_days;
          let end_Date = new Date();
          end_Date.setDate(end_Date.getDate() + days);
          end_Date = new Date(end_Date);
          this.end_date = end_Date.getFullYear() + '-' + (end_Date.getMonth() + 1) + '-' + end_Date.getDate() + ' ' + end_Date.getHours() + ":" + end_Date.getMinutes();
          //console.log(this.end_date);
        } 
       // console.log(this.subscription)
        let subscriptionObj = {
          email: this.email,
          firstName: this.firstName,
          user_id: this.user_id,
          subscription_id: this.subscription.id,
          freq_type_id: this.subscription.freq_type_id,
          school_id: this.school_id,
          class_id: this.class_id,
          end_date: this.end_date,
          status: 1,
          payment_response_id: this.searchFilter.couponCode
        }
        //console.log(subscriptionObj);
        this.subscriptionService.createCouponSubscription(subscriptionObj).subscribe((data: any)=>{
          //console.log(data);
          if(data.status == true){
            this.dialog.closeAll();
            this.router.navigate(['/paymentSuccess']);
            this.openSnackBar('Coupon has been redeemed successfully. ', 'Close');
          } else {
            this.router.navigate(['/paymentError']);
          }
        });  
        this.errorMessage = data.message
        //console.log(this.errorMessage)
      }else{
        this.errorMessage = data.message;
        //console.log(this.errorMessage)
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
