import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  model: any = {};
  errorMessage: string;
  user_id: any;
  trialUser: string;
  loading: boolean;
  email: any;
  forgotPassword: string;

  constructor(private _backendService: BackendService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router){ }

  ngOnInit() {
  }

 forgotPass(){
    this.email = this.model.email;
    console.log(this.model.email);
    let obj ={
      username : this.model.email
    }
    this._backendService.getuserdetails(obj).subscribe((data: any) => {
      //console.log(data);
      if(data.status == false){
        this.errorMessage = "No user found please enter a valid e-mail"
      } else {
        this.user_id = data.data.id;
        this.forgotPassword = "Yes"
        let otpObj = {
          user_id: this.user_id
        }
        this._backendService.generateOtpFP(otpObj).subscribe((data: any) =>{
          //console.log(data)
          if(data){
            this.openSnackBar(data.message, 'Close');
            this.user_id = this.user_id;
            this.user_id = btoa(this.user_id);
            this.router.navigate(['user-verification'],{
              queryParams: { 
                user_id: this.user_id,
                forgotPassword: this.forgotPassword
              }
            });
          }else{
            this.errorMessage = data.message
            this.loading = false;
          }
        })
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }
}
