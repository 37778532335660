import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { DashboardService } from '../../dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PostDashboardService } from '../../post-dashboard.service';

@Component({
  selector: 'app-post-section-dashboard',
  templateUrl: './post-section-dashboard.component.html',
  styleUrls: ['./post-section-dashboard.component.css']
})
export class PostSectionDashboardComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;

  currentUser: any;
  type_order: String;
  sectionObj: any = null;
  sectionList: any;
  school_id: number;
  class_id: number;
  limit = 12;
  offset = 0;
  heading = false;
  sectionLength: number;
  total_section: number;
  school_name: string;
  class_name: string;
  rowHeight = '650px';
  searchFilter: any = {
    start_date: '',
    end_date: '',
    subject_label: [],
  };
  currentDate = new Date();
  subject_label: any;
  start_date: Date;
  end_date: Date;
  defaultTab= 1;

  constructor(private postDashboardService: PostDashboardService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute) {  }

  ngOnInit() {

    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
      }
    );
    
    this.route.queryParams.subscribe((paramsData: any) => {
      if(paramsData.tabType == '2'){
        this.defaultTab = 2;
      } else {
        this.defaultTab = 1;
      }
    })


    //this.breadcrumbs();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.type_order = this.currentUser.adminUser[0].user_Type.type_order;
    //this.refreshData();
    // if(this.type_order == '1' || this.type_order == '2'){
      
    //   this.sectionObj = {
    //     class_id: this.class_id,
    //     offset: this.offset,
    //     limit: this.limit
    //   }
    //   this.dashboardService.getSectionList(this.sectionObj).subscribe((sectionList: any) => {
    //     // console.log(sectionList);
    //     this.total_section = sectionList.total_section;
    //     // console.log(this.total_section);
    //     this.sectionList = sectionList.data;
    //     this.sectionLength = this.total_section;
    //     if(this.sectionLength > 2){
    //       this.rowHeight = '480px';
    //       this.sectionLength = 3;
    //       this.heading = true;
    //     }
    //   });
    // }else{
    //   this.sectionObj = {
    //     user_id: this.currentUser.adminUser[0].id,
    //     school_id: this.school_id,
    //     class_id: this.class_id,
    //     offset: this.offset,
    //     limit: this.limit
    //   }
    //   this.dashboardService.getUserSection(this.sectionObj).subscribe((sectionList: any) => {
    //     this.total_section = sectionList.total_section;
    //     this.sectionList = sectionList.data;
    //     this.sectionLength = this.total_section;
    //     if(this.sectionLength > 2){
    //       this.rowHeight = '480px';
    //       this.sectionLength = 3;
    //       this.heading = true;
    //     }
    //   });
    // }
    this.dashboardService.getSearchFilter().subscribe(searchFilterData => {
      //console.log(searchFilterData);
      this.searchFilter.subject_label = searchFilterData.subject_label;
      this.searchFilter.start_date = searchFilterData.start_date;
      this.searchFilter.end_date = searchFilterData.end_date;
    });
  }

  breadcrumbs(){
    this.sectionObj = {
      school_id: this.school_id,
      class_id: this.class_id
    }

    // get School Name
    this.dashboardService.getSchoolById(this.sectionObj).subscribe((schoolData: any) => {
      // console.log(schoolData);
      this.school_name = schoolData.data.school_name;
    })

    // get Class Name
    this.dashboardService.getClass(this.class_id).subscribe((classData: any) => {
      // console.log(classData);
      this.class_name = classData.data.class_name;
    })

  }
  filterData(searchFilter){
    //console.log(searchFilter)
    this.subject_label = searchFilter.subject_label;
    this.start_date = searchFilter.start_date;
    this.end_date = searchFilter.end_date;
    let searchFilterData = {
      subject_label: this.subject_label,
      end_date: this.end_date,
      start_date: this.start_date
    }
    this.dashboardService.setSearchFilter(searchFilterData);
    this.refreshData();
  }
   
  resetSearchFilter(searchPanel: any) {
    this.searchFilter = {};
    this.end_date = null;
    this.start_date = null;
    this.subject_label = null;
    let searchFilterData = {
      subject_label: '',
      end_date: '',
      start_date: '',
    }
    this.dashboardService.setSearchFilter(searchFilterData);
    this.refreshData();
  }

  refreshData(){
    if(this.type_order == '1' || this.type_order == '2'){
      
      this.sectionObj = {
        class_id: this.class_id,
        offset: this.offset,
        limit: this.limit
      }
      this.dashboardService.getSectionList(this.sectionObj).subscribe((sectionList: any) => {
        // console.log(sectionList);
        this.total_section = sectionList.total_section;
        // console.log(this.total_section);
        this.sectionList = sectionList.data;
        this.sectionLength = this.total_section;
        if(this.sectionLength > 2){
          this.rowHeight = '510px';
          this.sectionLength = 3;
          this.heading = true;
        }
      });
    }else{
      this.sectionObj = {
        user_id: this.currentUser.adminUser[0].id,
        school_id: this.school_id,
        class_id: this.class_id,
        offset: this.offset,
        limit: this.limit
      }
      this.dashboardService.getUserSection(this.sectionObj).subscribe((sectionList: any) => {
        this.total_section = sectionList.total_section;
        this.sectionList = sectionList.data;
        this.sectionLength = this.total_section;
        if(this.sectionLength > 2){
          this.rowHeight = '510px';
          this.sectionLength = 3;
          this.heading = true;
        }
      });
    }
  }

//   ngAfterViewInit() {

//     merge( this.paginator.page)
//     .pipe(
//     tap(() => {
//         this.offset = this.paginator.pageIndex * this.paginator.pageSize;
//         this.limit = this.paginator.pageSize;
//         this.ngOnInit();
//         })
//     )
//     .subscribe();
// }

}
