import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Http, Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})

export class HomeworkService {

  private apiUrl = environment.apiUrl;

  constructor(private http: Http) { }

  getHWTeacher(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homeworks/getHWTeacher`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  addHomeWork(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homeworks/addHomeWork`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getHomeWorkById(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homeworks/getHomeWorkById`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  editHomeWork(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homeworks/editHomeWork`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getHWStudent(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homeworks/getHWStudent`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  homeWorkSubmit(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homework_submissions/homeWorkSubmit`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }


  homeWorkUsers(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/user_data/homeWorkUsers`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }


  getStudentHWFile(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homeworks/getStudentHWFile`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }


  teacherRemark(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homework_remarks/teacherRemark`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  editTeacherRemark(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homework_remarks/editTeacherRemark`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }


  getHWDetails(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homeworks/getHWDetails`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  deleteHomework(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homeworks/deleteHomework`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  activeHomework(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homeworks/activeHomework`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  edithomeWorkSubmit(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homework_submissions/edithomeWorkSubmit`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  deleteHWFile(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homework_files/deleteHWFile`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  removeHWfile(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/containers/removeHWfile`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  getTutorStudent(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/user_data/getTutorStudent`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }

  submissionsToTutor(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/homeworks/submissionsToTutor`, paramsVal)
      .map((response: Response) => {
        return response.json();
    })
    .catch(this.handleError);
  }


  private handleError(error: Response): Observable<any> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error.json());
    return Observable.throw(error.json().error || 'Server error');
  }
}