import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { PostDashboardService } from '../../post-dashboard.service';
import { MatTableExporterModule } from 'mat-table-exporter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-post-user-list-dash',
  templateUrl: './post-user-list-dash.component.html',
  styleUrls: ['./post-user-list-dash.component.css']
})
export class PostUserListDashComponent implements OnInit {

  @Input() inputData;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = ['userName', 'user_last_login', 'total_quiz_attempt', 'total_attempted', 'total_correct', 'total_incorrect', 'total_skipped', 'percentage', 'total_usage', 'lesson_usage'];

  dataSource: any;
  school_id: any;
  class_id: any;
  section_id: any;
  limit = 12;
  offset = 0;
  customFilters = {}
  studentList: any;
  section_name: string;
  listView: any;
  student_id : any;
  searchFilter: any = {
    start_date: '',
    end_date: '',
    subject_label: [],
  };
  currentDate = new Date();
  subject_label: any;
  start_date: Date;
  end_date: Date;
  disableDownload: boolean = true;
  liveUrl = environment.liveUrl;
  loading: boolean = false;
  
  constructor(private postDashboardService: PostDashboardService,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private router: Router) { }

  ngOnInit() {

    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
      }
    );
    this.section_name = this.inputData.section_name;
    this.getAttemptUserList();
  }


  getAttemptUserList(){
    this.loading = true;
    this.disableDownload = true;
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dashboardService.getSearchFilter().subscribe(searchFilterData => {
      // console.log(searchFilterData);
       this.searchFilter.subject_label = searchFilterData.subject_label;
       this.searchFilter.start_date = searchFilterData.start_date;
       this.searchFilter.end_date = searchFilterData.end_date;
    });
    this.customFilters = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      limit: this.limit,
      offset: this.offset,
      student_id: this.student_id,
      user_type_id: 8, //send user_type_id to list student user only 
      subject_label: this.searchFilter.subject_label,
      start_date: this.searchFilter.start_date,
      end_date: this.searchFilter.end_date
    }
    //console.log(JSON.stringify(this.customFilters));
    this.postDashboardService.getAttemptUserList(this.customFilters).subscribe((student: any) => {
      //console.log(student);
      this.studentList = student;
    this.dataSource = new MatTableDataSource(this.studentList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loading = false;
    this.disableDownload = false;
    })
  }

  openStudentLessonList(id){
    this.student_id = id;
    //console.log(this.student_id)
    this.router.navigate(['/post_student_lesson_list', `${this.school_id}`, `${this.class_id}`, `${this.section_id}`, `${this.student_id}`], {
      queryParams: {
        postTest: 1
      }
    });
  }

  graphView(){
    this.router.navigate(['/quiz_dashboard', `${this.school_id}`, `${this.class_id}`, `${this.section_id}`]);
  }

  
  filterData(searchFilter){
    // console.log(searchFilter)
     this.subject_label = searchFilter.subject_label;
     this.start_date = searchFilter.start_date;
     this.end_date = searchFilter.end_date;
     let searchFilterData = {
       subject_label: this.subject_label,
       end_date: this.end_date,
       start_date: this.start_date
     }
     this.dashboardService.setSearchFilter(searchFilterData);
     this.getAttemptUserList();
   }
    
   resetSearchFilter(searchPanel: any) {
     this.searchFilter = {};
     this.end_date = null;
     this.start_date = null;
     this.subject_label = null;
     let searchFilterData = {
       subject_label: '',
       end_date: '',
       start_date: '',
     }
     this.dashboardService.setSearchFilter(searchFilterData);
     this.getAttemptUserList();
   }
 
}
