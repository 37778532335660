import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from '../../_services/backend.service';

@Component({
  selector: 'app-attendance-detail',
  templateUrl: './attendance-detail.component.html',
  styleUrls: ['./attendance-detail.component.css']
})
export class AttendanceDetailComponent implements OnInit {

  currentUser: any;
  userId: number;
  studentUserId: number;
  attendanceDate: any;
  userData: any;
  attendanceId: number;
  schedule_id: number;

  constructor(
    private route: ActivatedRoute,
    private backendService: BackendService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.currentUser.id;

    this.route.params.subscribe(params => {
      this.attendanceId = +params['id'];
      this.schedule_id = +params['schedule_id'];
    });
    this.route.queryParams.subscribe(queryParams => {
      this.attendanceDate = queryParams.attendanceDate;
    });
    this.getattendance();
  }

  
  getattendance(){
    let obj = {
      attendanceId: this.attendanceId,
      attendanceDate: this.attendanceDate
    }
    this.backendService.getUserAttendance(obj).subscribe((result: any) => {
      this.userData = result.data;
    //  this.userData.startTime = new Date(this.userData.startTime).toUTCString();
      // this.userData.endTime = new Date(this.userData.endTime).toUTCString();
    })
  }

}
