import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notfoundpage',
  template: `
  <div>
    <h1>404</h1>
    <h2>The page which you are looking for was not found on this server. </h2>
  </div>
  `,
  styles: []
})
export class NotfoundpageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
