import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { HomeworkService } from '../homework.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-student-homework',
  templateUrl: './student-homework.component.html',
  styleUrls: ['./student-homework.component.css']
})

export class StudentHomeworkComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'Student Homework';
  displayedColumns: string[] = ['Sno', 'title', 'subject', 'assign_on', 'given_by', 'submissions', 'id'];

  dataSource: any;
  currentUser: any;
  schoolId: number;
  classId: number;
  sectionId: number;
  subjectId = [];
  customFilters: any = null;
  limit = 10;
  offset = 0;
  totalHw: number;
  user_id: number;
  subjectList = [];
  teacherList = [];
  user_subjects = [];
  searchFilter: any = {
    title: '',
    // date: '',
    start_date: '',
    end_date: '',
    subject_id: [],
    created_by: [],
    limit: 10,
    offset: 0
  };

  constructor(
    private homeworkService: HomeworkService,
    private backendService: BackendService
  ) { }

  ngOnInit() {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.schoolId = this.currentUser.currentSelSchoolId;
      this.classId = this.currentUser.userData[0].class_id;
      this.sectionId = this.currentUser.userData[0].section_id;
      this.user_id = this.currentUser.id;
      // this.user_id = this.currentUser.adminUser[0].id;
      this.user_subjects = this.currentUser.user_assigned_subjects;
      this.user_subjects.forEach(sub => {
        this.subjectId.push(sub.subject_id);
      });

      this.customFilters = {
        school_id: this.schoolId,
        class_id: this.classId,
        section_id: this.sectionId,
        subject_id: this.subjectId,
        limit: this.paginator.pageSize,
        offset: this.paginator.pageIndex * this.paginator.pageSize
      }
      this.getSubjects();
      this.getHomework();
      this.getTeacher();
    }
    
  }

  getHomework() {
    this.customFilters = {
      school_id: this.customFilters.school_id,
      class_id: this.customFilters.class_id,
      section_id: this.customFilters.section_id,
      subject_id: this.customFilters.subject_id,
      created_by: this.customFilters.created_by,
      // date: this.customFilters.date,
      start_date: this.customFilters.start_date,
      end_date: this.customFilters.end_date,
      title: this.customFilters.title,
      user_id: this.user_id,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
    // console.log(this.customFilters);
    this.homeworkService.getHWStudent(this.customFilters).subscribe((data: any) => {
      // console.log(data);
      this.totalHw = data.totalHW;
      this.dataSource = new MatTableDataSource(data.data);
    })
  }

  reset() {
    this.getHomework();
  }


  searchUserData(filters: any) {
    // this.resetPageIndex();
    if (filters) {
      if (!filters.school_id || filters.school_id.length <= 0) {
        filters.school_id = this.schoolId;
      }
      if (!filters.class_id || filters.class_id.length <= 0) {
        filters.class_id = this.classId;
      }
      if (!filters.section_id || filters.section_id.length <= 0) {
        filters.section_id = this.sectionId;
      }
      if (!filters.subject_id || filters.subject_id.length <= 0) {
        filters.subject_id = this.subjectId;
      }
      filters.limit = this.paginator.pageSize;
      filters.offset = this.paginator.pageIndex * this.paginator.pageSize;
      this.customFilters = filters;
      this.getHomework();
    }
  }

  resetSearchFilter(searchPanel: any) {
    this.resetPageIndex();
    searchPanel.toggle();
    this.searchFilter = {};
    this.customFilters = {
      school_id: this.schoolId,
      class_id: this.classId,
      section_id: this.sectionId,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    }
    this.getHomework();
  }

  resetPageIndex() {
    this.paginator.pageIndex = 0;
  }

  getSubjects(){
    let classObj = { class_id: [this.classId], subject_id: this.subjectId }
    this.backendService.getSubjects(classObj).subscribe((result: any) => {
      this.subjectList = result.data;
    });
  }

  getTeacher(){
    let Obj = {
      school_id: [this.schoolId],
      class_id: [this.classId],
      section_id: [this.sectionId]
    }
    this.backendService.getHwTeacher(Obj).subscribe((result: any) => {
      this.teacherList = result.data;
    })
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap((data: any) => {
          // this.sortBy = data.action;
          // this.sortDirection = data.direction;
          this.getHomework();
        })
      ).subscribe();
  }

}
