import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableDataSource } from '@angular/material';
import { PostTestService } from '../../post-test.service';
import { AttemptedTestHistoryComponent } from '../attempted-test-history/attempted-test-history.component';

@Component({
  selector: 'app-topic-wise-result',
  templateUrl: './topic-wise-result.component.html',
  styleUrls: ['./topic-wise-result.component.css']
})
export class TopicWiseResultComponent implements OnInit {

  @Input() attempted_set_id;
  displayedColumns = ['topic_name', 'total_attempted', 'total_correct', 
                      'total_incorrect', 'total_skipped', 'percentage', 'id'];
  quizSetData: any;
  dataSource: any;
  
  constructor(
    private postTestService: PostTestService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getTopicWiseResult();
  }

  getTopicWiseResult(){
    let paramsVal = {attempted_set_id: this.attempted_set_id};
    this.postTestService.getTopicWiseResult(paramsVal)
      .subscribe(result => {
        this.quizSetData = result;
        this.quizSetData = this.quizSetData.data;
        this.freshDataList(this.quizSetData);
      });
  }

  freshDataList(quizData) {
    this.quizSetData = quizData;
    this.dataSource = new MatTableDataSource(this.quizSetData);
    //this.dataSource.paginator = this.paginator;
   // this.dataSource.sort = this.sort;
  }

  openDialog(attempted_set_id, set_id, topic_id) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.height = '600px';
    dialogConfig.width = '800px';
    
    dialogConfig.data = {
      attempted_set_id: attempted_set_id,
      set_id: set_id,
      topic_id: topic_id
    };
    const dialogRef = this.dialog.open(AttemptedTestHistoryComponent, dialogConfig);
    dialogRef.afterClosed().subscribe();
  }
}
