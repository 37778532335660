import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { OnlineScheduleService } from 'src/app/online-schedule/online-schedule.service';
import { AuthenticationService } from 'src/app/_services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upcoming-schedule',
  templateUrl: './upcoming-schedule.component.html',
  styleUrls: ['./upcoming-schedule.component.css']
})
export class UpcomingScheduleComponent implements OnInit {

  @Output() joinClass = new Subject();
  currentUser: any;
  dataSource: any;
  displayedColumns: string[] = ['Sno', 'class', 'subject', 'lesson', 'startDate', 'join'];
  userid: number;
  userData: any;
  currentSelSchoolId: number;
  classIds = [];
  classes = [];
  sectionIds = [];
  sections = [];
  scheduleData: any;
  userType: number;
  token: string;
  tokenStatus  =false;
  onlineClassUrl = environment.onlineClassUrl;
  productName = environment.productName;
  userDataVal: any;
  userName: string;
  userTypeVal: any;
  schoolId: number;
  classId: number;
  sectionId: number;
  subjectId: number;
  teacherId: number;

  constructor(
    private onlineScheduleService: OnlineScheduleService,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.userid = this.currentUser.id;
      this.currentSelSchoolId = this.currentUser.currentSelSchoolId;
      this.userData = this.currentUser.userData;
      this.token = this.currentUser.token;
  
      this.userData.forEach(userVal => {
        if(this.classIds.indexOf(userVal.class.id) == -1){
          this.classIds.push(userVal.class.id);
          this.classes.push({id:userVal.class.id,className: userVal.class.class_name})
        }
        if (this.sectionIds.indexOf(userVal.section_id) == -1) {
          this.sectionIds.push(userVal.section_id);
          this.sections.push(userVal.section);
        }
      });
    }

    if(this.userType == 1){
      this.teacherId = this.userid;
    }

    this.getSchedule();
  }

  getSchedule() {
    let obj = {
      scheduleType: 'current',
      school_id: [this.currentSelSchoolId],
      class_id: this.classIds,
      section_id: this.sectionIds,
      user_id: this.teacherId,
      offset: 0,
      limit: 3
    }
    // console.log(obj);
    this.onlineScheduleService.getSchedule(obj).subscribe((scheduleList: any) => {
      // console.log(scheduleList);
      this.scheduleData = scheduleList.data;
      // console.log(this.scheduleData)
      // this.total_schedule = scheduleList.totalSchedule;
    })
  }

  openClass(schedule){
    this.userType = this.currentUser.userData[0].user_type;
    this.userName = this.currentUser.userData[0].studentName;
    this.schoolId = schedule.school_id;
    this.classId = schedule.class_id;
    this.sectionId = schedule.section_id;
    this.subjectId = schedule.subject_id;
    this.userDataVal =  "user_id="+this.userid+"&user_type="+this.userType+"&school_id="+this.schoolId+"&class_id="+this.classId+"&section_id="+this.sectionId+"&subject_id="+this.subjectId+"&userName="+this.userName;
    this.userDataVal = btoa(this.userDataVal);
    // console.log(this.userDataVal);
    let paramsVal = {user_id: this.userid, token: this.token};
    // console.log(paramsVal);
    return this.authService.chkUserToken(paramsVal).subscribe(
      data =>{
        this.tokenStatus = data.status;
        if(!this.tokenStatus){
          this.openSnackBar("You've been logged in different Computer", 'Close');
          this.authService.logout();
          //return false;
        } else {
          window.open(this.onlineClassUrl+'?'+this.userDataVal,this.productName+" Online Class",'height=650,width=700');
        }
        this.joinClass.next();
      }
    )    
  }

  /*
  openSnackBar(arg0: string, arg1: string) {
    throw new Error('Method not implemented.');
  }*/
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

}
