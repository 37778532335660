import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TopicQuesUserListComponent } from '../topic-ques-user-list/topic-ques-user-list.component';

@Component({
  selector: 'app-topic-ques-chart',
  template: `
  <mat-grid-list [cols]="colNum" [rowHeight]="rowHeight">
  <mat-grid-tile>
    <mat-card>
    <div>
    <p style="margin-left: 50px">Total Attempts : {{totalAttempts}}</p>
    </div>
      <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
        [options]="pieChartOptions" [colors]="pieChartColors" (chartClick)="chartClicked($event)"></canvas>
    </mat-card>

  </mat-grid-tile>


</mat-grid-list>
  `,
  styles: []
})
export class TopicQuesChartComponent implements OnInit {

  @Input() questionId;
  @Input() quesData;
  @Input() paramsData;

  getTotalCorrect: any;
  getTotalIncorrect: any;
  getTotalSkipped: any;
  
  colNum = '1';
  rowHeight = '200px';

  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartType = 'pie';
  public pieChartLabels: Array<string> = ['Correct', 'Incorrect', 'Skipped'];
  public pieChartData: Array<number> = [1, 1, 1];
  public pieChartColors: Array<any> = [
    { // all colors in order
      backgroundColor: ['green', 'red', 'grey'],
      color:['white', 'white', 'white']
    }
  ];

  public pieChartOptions: any = {
    pieceLabel: {
      render: function (args) {
        const label = args.label,
              value = args.value;
        return value;
      },
      fontColor: '#fff'
    },
    legend: { position: 'right' }
  }
  totalAttempts: number;
  studentObj: any;

  constructor(
    private dialog: MatDialog
  ) { }

 
  ngOnInit() {
   // console.log(this.paramsData);
   // console.log(this.questionId);
    //console.log(this.quesData);
    this.totalAttempts =  this.questionId.total_attempt;
    this.getTotalCorrect = this.questionId.total_correct;
    this.getTotalIncorrect = this.questionId.total_incorrect;
    this.getTotalSkipped = this.questionId.total_skipped;
    this.pieChartData = [this.getTotalCorrect, this.getTotalIncorrect, this.getTotalSkipped];
    //this.totalAttempts = this.getTotalCorrect+this.getTotalIncorrect+this.getTotalSkipped;
   /// console.log(this.totalAttempts)
  }


  public chartClicked(e: any): void {
   
    this.dialog.open( TopicQuesUserListComponent, { data: {paramsData: this.paramsData, questionId: this.questionId.question_id } });
  }

}