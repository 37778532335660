import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { StartQuizComponent } from '../student-quiz-set/attempt-quiz/start-quiz/start-quiz.component';

@Injectable({
  providedIn: 'root'
})
export class StartquizGuard implements CanDeactivate<StartQuizComponent> {
  canDeactivate(component: StartQuizComponent): boolean  {
      let eventVal = component.canDeactivate();
      if(!eventVal){
        component.backStatus = false;
      }
      return eventVal;
    }
}
