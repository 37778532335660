import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/_services/backend.service';
import { PostTestService } from '../post-test.service';

@Component({
  selector: 'app-attempt-test',
  templateUrl: './attempt-test.component.html',
  styleUrls: ['./attempt-test.component.css']
})
export class AttemptTestComponent implements OnInit {

  currentUser: any;
  userId: number;
  sub: Subscription;  
  lessonData: any;
  lessonId: number;  
  lessonName: string;
  userTestQuestions: any = {};
  postTestQuestions: any;
  postTestData: any;
  subjectId: number;
  subjectName: string;
  classId: any;
  testCondtion: void;

  constructor(
    private backendService: BackendService,
    private postTestService: PostTestService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe( parmasData => {
      this.classId = parmasData.class_id;
      //console.log('parmasData = ',parmasData);
    });

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.userId = this.currentUser.id;
    }
    this.sub = this.route.params.subscribe(
      params => {
        this.lessonId = +params['id'];
      }
    );

    if(!this.lessonId){
      this.invalidLesson();
    }
    this.getLesson(this.lessonId);    
  }

  getPostTestQuestions(){
    let paramsVal = {
      lesson_id: this.lessonId,
      user_id: this.userId,
      subject_id: this.subjectId
    };
    return this.postTestService.getPostTestQuestions(paramsVal)
      .subscribe((data)=> {  
        //console.log(data);  
        this.postTestData = data; 
        //console.log('postTestData = ',this.postTestData);
        if(this.postTestData.status){
          this.testCondtion  = localStorage.setItem('testCondition', JSON.stringify(data));  
          this.subjectName = this.postTestData.subject_name;
          this.postTestData = this.postTestData.data;
          if(!this.postTestData){
            this.openSnackBar("Invalid Value! Please try again.",'close');
            this.invalidQuiz();
          }    
          
          /*if(this.subjectName == 'Science'){
            this.postTestQuestions = this.postTestData;
            this.saveQuiz();     
          } else {*/
            this.getUserPostTestQuestions();
          //}
        } else {
          this.openSnackBar(this.postTestData.message,'close');
          this.invalidQuiz();
      } 
      });
  }

  getUserPostTestQuestions(){
    let paramsVal = {
      lesson_id: this.lessonId,
      user_id: this.userId,
      subject_id: this.subjectId
    };
    return this.postTestService.getUserPostTestQuestions(paramsVal)
      .subscribe((data)=> {    
        //console.log('getUserPostTestQuestions = ', data);
        this.postTestData = data; 
        if(this.postTestData.status){       
          this.subjectName = this.postTestData.subject_name;
          this.postTestData = this.postTestData.data;
          if(!this.postTestData){
            this.openSnackBar("Invalid Value! Please try again.",'close');
            this.invalidQuiz();
          }  
          this.postTestQuestions = this.postTestData;
          this.saveQuiz();        
        } else {
          this.openSnackBar(this.postTestData.message,'close');
          this.invalidQuiz();
      } 
      });
  }

  saveQuiz(){
    this.userTestQuestions.lesson_name = this.lessonName;
    this.userTestQuestions.lessonId = this.lessonId;
    this.userTestQuestions.subjectId = this.subjectId;
    this.userTestQuestions.subjectName = this.subjectName;
    this.userTestQuestions.questionNumber = 1;
    this.userTestQuestions.quesInc = 1;
    this.userTestQuestions.topicQuesInc = 1;
    this.userTestQuestions.quiz_questions = this.postTestQuestions;

    let keepFieldsArr = ['question_id','question','ansoptions','answer_id','attempt_status'];
    this.userTestQuestions.quiz_questions.map((value,key)=>{
      let answer_id = this.userTestQuestions.quiz_questions[key].syllabus_questions.answer_id;
      if(!answer_id){
        this.userTestQuestions.quiz_questions[key].syllabus_questions.answer_id = "0";
      }
      this.userTestQuestions.quiz_questions[key].syllabus_questions.rightAnsOptionCount = value.syllabus_questions.rightansoption.length;
       let objKeys = Object.keys(value.syllabus_questions);
       
       objKeys.forEach((keyVal)=>{
         if(!keepFieldsArr.includes(keyVal)){
          // delete this.userTestQuestions.quiz_questions[key].syllabus_questions[keyVal];
         }
       })
      //  this.userTestQuestions.quiz_questions[key].quiz_question.question = value.quiz_question.question;
    });
    this.userTestQuestions = localStorage.setItem('userTestQuestions',JSON.stringify(this.userTestQuestions));
    this.openQuiz();
  }

  getLesson(lesson_id){
    this.backendService.getLesson(lesson_id)
    .subscribe((data)=>{
      this.lessonData = data;
      if(this.lessonData.status){     
        if(this.lessonData.data){
          this.lessonData = this.lessonData.data;
          this.lessonName = this.lessonData.lesson_name;
          this.subjectId = this.lessonData.subject_id;
          this.getPostTestQuestions();
        } else {          
          this.invalidLesson();
        }
      } else {
        this.invalidLesson();
      }
    })
  }

  openQuiz(){
    this.router.navigate([`/start-post-test/0`], {queryParamsHandling: 'preserve'});
  }

  invalidQuiz(){
    this.router.navigate(['/post-test-items',this.lessonId], {queryParamsHandling: 'preserve'});
  }

  invalidLesson(){
    this.openSnackBar("Invalid Value. Please try again.",'close');
    this.router.navigate(['post-test'], {queryParamsHandling: 'preserve'});
  }

  openSnackBar(message: string, action: string, duration=2500){
    this.snackBar.open(message, action, {
      duration: duration
    });
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

}
