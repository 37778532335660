import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { WizenozeService } from './wizenoze.service';

@Component({
  selector: 'app-wizenoze-content',
  templateUrl: './wizenoze-content.component.html',
  styleUrls: ['./wizenoze-content.component.css']
})
export class WizenozeContentComponent implements OnInit {

  boardList: any;
  board_id: any;
  classList: any;
  class_id = 7482;
  showSub: boolean = false;
  constructor(
    private http: HttpClient,
    private wizenozeService: WizenozeService
  ) { }

  ngOnInit() {
    this.wizenozeService.getWizenozeboard().subscribe((data: any) => {
      console.log(data);
      this.boardList = data;
      this.board_id = this.boardList[0].id
      this.changeCurriculum(this.boardList[0].id);
      this.changeClass(this.class_id);
    })
    // this.http.get(`https://api.wizenoze.com/v4/curriculum?userUUID=123456&sessionUUID=123456&userType=teacher`, {
    //   headers: {"Authorization": "6bc9c661-cd70-4d40-961a-de65192d379d"}
    // })
    //   .subscribe((data: any) => {
    //     console.log(data);
    //     this.boardList = data;
    //     this.board_id = this.boardList[1].id
    //     this.changeCurriculum(this.boardList[1].id);
    //     this.changeClass(this.class_id);
    //   });
  }

  changeCurriculum(board_id){
    // console.log(board_id);

    this.wizenozeService.getWizenozeClass(board_id).subscribe((data: any) => {
      console.log(data);
      this.classList = data;
    })

    // this.http.get(`https://api.wizenoze.com/v4/curriculum/${board_id}/layers/GRADE?userUUID=123456&sessionUUID=123456&userType=teacher`, {
    //   headers: {"Authorization": "6bc9c661-cd70-4d40-961a-de65192d379d"}
    // })
    //   .subscribe((data: any) => {
    //     console.log(data);
    //     this.classList = data;
    //   });
  }

  changeClass(class_id){
    this.showSub = true;
    this.class_id = class_id;
    this.wizenozeService.emitdata(this.class_id);
    console.log(class_id);
  }

  // sendData(x){
  //   this.wizenozeService.emitdata(x);
  //   }

}
