import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { QuizSetService } from 'src/app/_services/quiz-set.service';
import { MatSnackBar } from '@angular/material';
import { PlatformLocation } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-start-quiz',
  templateUrl: './start-quiz.component.html',
  styleUrls: ['./start-quiz.component.css']
})
export class StartQuizComponent implements OnInit {

  userQuestions: any;
  quizQuestions: any;
  quizQuestion: any;
  quesIndex = 0;
  sub: Subscription;
  quizForm: any;
  pageTitle = '';
  quesSub: Subscription;
  currentUser: any;
  schoolId: number;
  userId: number;
  quizSetId: number;
  quizData: any;
  rightAnsCount = 0;
  totalAttempted = 0;
  correct = 0;
  incorrect = 0;
  skipped = 0;
  quesArr = [];
  quizRes: any;
  timeSpent = 0;
  minDuration: number;
  secDuration: number;
  quizDuration: number;
  quesArrStatus = [];
  encQuizSetId: string;
  backStatus = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private quizSetService: QuizSetService,
    private snackBar: MatSnackBar,
    private location: PlatformLocation
    ) { 
      location.onPopState(()=>{
        this.backStatus = true;
      });
    }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.schoolId = this.currentUser.currentSelSchoolId;
      this.userId = this.currentUser.id;
    }
    this.userQuestions = JSON.parse(localStorage.getItem('userQuestions'));
    this.quizForm = this.fb.group({
      user_id: ["",[Validators.required]],
      quiz_set_id: ['',[Validators.required]],
      total_attempted: ['',''],
      total_correct:['',''],
      questions: this.fb.array([])
    });
    if(this.userQuestions === null
      || this.userQuestions === 'null'){
      // this.invalidQuiz();
      window.location.href = environment.liveUrl;
    }
    this.pageTitle = this.userQuestions.set_name;
    
    this.sub = this.route.params.subscribe(
      params => {
        this.encQuizSetId = params['quiz_set_id'];
        this.quizSetId = Number(atob(params['quiz_set_id']));
        this.quesIndex = +params['id'];
        this.quizQuestions = this.userQuestions.quiz_questions;
        this.quizQuestion = this.userQuestions.quiz_questions[this.quesIndex];
        let myVal = atob(this.userQuestions.myval);
        this.quizDuration = Number(atob(this.userQuestions.myquizval));
        let durationArr = myVal.split(':');
        this.minDuration = Number(durationArr[0]);
        this.secDuration = Number(durationArr[1]);
        if((this.minDuration < 1 && this.secDuration < 1)
            && this.quizDuration>0) {
          this.invalidQuiz();
        }
      }
    );
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  getQuizSetDetails(){
    let paramsVal = {
      set_id: this.quizSetId,
      school_id: this.schoolId
    };
    this.skipped = 0;
    this.correct = 0;
    this.incorrect = 0;
    return this.quizSetService.getQuizSetDetails(paramsVal)
      .subscribe((data)=> {        
        this.quizData = data;
        this.quizData = this.quizData.data;
        if(!this.quizData){
          this.invalidQuiz();
        }   
        this.totalAttempted = this.userQuestions.quiz_questions.length;
        this.userQuestions.quiz_questions.forEach((quesVal,index) => {
          let attemptAnsIds = [];
          let rightAnsIds = [];
          let ansId = quesVal.quiz_question.answer_id.toString();                
          let attempt_status;

          if(ansId=="0"){ //If user has not attempted a question
            attempt_status = 0;
            this.skipped++;
          }
          
          if(ansId.indexOf(',')){  //Split in case of multiple answers
            attemptAnsIds = ansId.split(',');
          } else {
            attemptAnsIds.push(Number(quesVal.quiz_question.answer_id));
          }

          //Get all right answer ids of a question
          this.quizData.quiz_questions[index].quiz_question.rightansoption.forEach((rVal)=>{
            rightAnsIds.push(rVal.answer_id);
          });

          attemptAnsIds.sort();          
          rightAnsIds.sort();

          this.rightAnsCount = 0;
          let rightCount = 0;          

          //Parse all the attempted answer ids of a question
          attemptAnsIds.forEach((element) =>{
            let ansId = Number(element);            
            if(rightAnsIds.includes(ansId) && ansId>0){
              attempt_status = 1;
              this.quesArr[index] = {question_id:quesVal.question_id,answer_id : ansId,attempt_status:attempt_status}; //For correct Answer
              rightCount++;
            } else if(!rightAnsIds.includes(ansId) && ansId>0){
              attempt_status = 2;
              this.quesArr[index] = {question_id:quesVal.question_id,answer_id : ansId,attempt_status:attempt_status}; //For incorrect Answer
            } else {
              this.quesArr[index] = {question_id:quesVal.question_id,answer_id : ansId,attempt_status:attempt_status}; //For skipped question
            }
          });
          
          if(rightCount == rightAnsIds.length){
            attempt_status = 1;
            this.correct++;
          } else if(ansId!="0"){ //If the question is not skipped and wrong answer given by a user
            attempt_status = 2;
            this.incorrect++;
          }        
        });
        // console.log("correct",this.correct);
        // console.log("incorrect",this.incorrect);
        // console.log("skipped ",this.skipped);
        this.saveQuiz(); //Save the attempted quiz set
      }
    );
  }

  openQuestion(quesIndex){
    this.userQuestions.quiz_questions[this.quesIndex].quiz_question.visited = true;
    let answer_id = this.userQuestions.quiz_questions[this.quesIndex].quiz_question.answer_id;
    if(!answer_id){
      this.userQuestions.quiz_questions[this.quesIndex].quiz_question.answer_id = "0";
    }
    localStorage.setItem('userQuestions',JSON.stringify(this.userQuestions));
    this.userQuestions = JSON.parse(localStorage.getItem('userQuestions'));
    this.router.navigate([`/start-quiz/${this.encQuizSetId}/${quesIndex}`]);
  }

  quesAttempt(quesIndex, ansId, ansOption='Single'){
    this.userQuestions.quiz_questions[this.quesIndex].quiz_question.attempted = true;
    let questionId = this.userQuestions.quiz_questions[this.quesIndex].question_id;
    let answer_id = this.userQuestions.quiz_questions[this.quesIndex].quiz_question.answer_id;
    if(ansOption=='Multiple'){
      if(answer_id.indexOf(ansId)>-1){
        ansId = new RegExp(","+ansId,"g");
        this.userQuestions.quiz_questions[this.quesIndex].quiz_question.answer_id = answer_id.replace(ansId,'');
      } else {
        this.userQuestions.quiz_questions[this.quesIndex].quiz_question.answer_id = answer_id + ',' + ansId;
      }
    } else {
      this.userQuestions.quiz_questions[this.quesIndex].quiz_question.answer_id = ansId;
    }
    localStorage.setItem('userQuestions',JSON.stringify(this.userQuestions));
    
    let answerIds = this.userQuestions.quiz_questions[this.quesIndex].quiz_question.answer_id;
    this.getQuizSetQuesDetails(quesIndex, questionId, answerIds);    
  }

  saveQuiz(){
      this.saveQuizValue();
  }

  saveQuestions() {
    // this.quizSetId = this.userQuestions.quiz_set_id;
    var result = confirm("Are you sure, you want to submit the quiz?");
    if (result) {
      this.getQuizSetDetails();
    }
  }

  invalidQuiz(){
    this.router.navigate(['/quiz-list']);
  }

  openSnackBar(message: string, action: string, duration = 2500) {
    this.snackBar.open(message, action, {
        duration: duration,
    });
  }

  saveQuizValue(){
    this.skipped = this.totalAttempted - (this.correct + this.incorrect);
    if(this.totalAttempted == (this.correct + this.incorrect + this.skipped)) {
      this.getAttemptedQuizSetData(); //Get data from server if total attempted is not equal to sum of correct, incorrect and skipped
    }
    
    let quesParams = {
      user_id: this.userId,
      quiz_set_id: this.quizSetId,
      total_attempted: this.totalAttempted,
      total_correct: this.correct,
      total_incorrect: this.incorrect,
      total_skipped: this.skipped,
      time_spent: this.timeSpent,
      questions: this.quesArr
    }
    this.quizSetService.updateQuizSet(quesParams).subscribe(
      quizResult => {
        this.quizRes = quizResult;
        this.openSnackBar(this.quizRes.message,'close');
        if(this.quizRes.status){
          this.removelocalStorage();
          this.router.navigate(['/quiz-list']);
        }
      }
    );
  }

  getTime(value){
    this.timeSpent = value[0];
    let remainingTime = value[1];
    if(remainingTime < 1){
      this.openSnackBar("Time Up!",'close', 3000)
      this.getQuizSetDetails();
    }
  }

  getQuizSetQuesDetails(questionNumber, questionId, answerIds){
    let paramsVal = {
      set_id: this.quizSetId,
      school_id: this.schoolId
    };
    this.quesArrStatus = [];
    this.skipped = 0;
    this.correct = 0;
    this.incorrect = 0;
    return this.quizSetService.getQuizSetDetails(paramsVal)
      .subscribe((data)=> {        
        this.quizData = data;
        this.quizData = this.quizData.data;
        if(!this.quizData){
          this.invalidQuiz();
        }   
        this.totalAttempted = this.userQuestions.quiz_questions.length;
        this.userQuestions.quiz_questions.forEach((quesVal,index) => {
          let attemptAnsIds = [];
          let rightAnsIds = [];
          let ansId = quesVal.quiz_question.answer_id.toString();                
          let attempt_status;

          if(ansId=="0"){ //If user has not attempted a question
            attempt_status = 0;
            this.skipped++;
          }
          
          if(ansId.indexOf(',')){  //Split in case of multiple answers
            attemptAnsIds = ansId.split(',');
          } else {
            attemptAnsIds.push(Number(quesVal.quiz_question.answer_id));
          }

          //Get all right answer ids of a question
          this.quizData.quiz_questions[index].quiz_question.rightansoption.forEach((rVal)=>{
            rightAnsIds.push(rVal.answer_id);
          });

          attemptAnsIds.sort();          
          rightAnsIds.sort();

          this.rightAnsCount = 0;
          let rightCount = 0;
          

          //Parse all the attempted answer ids of a question
          attemptAnsIds.forEach((element) =>{
            let ansId = Number(element);            
            if(rightAnsIds.includes(ansId) && ansId>0){             
              rightCount++;
            } 
          });
          
          if(rightCount == rightAnsIds.length){
            attempt_status = 1;
            this.correct++;
          } else if(ansId!="0"){ //If the question is not skipped and wrong answer given by a user
            attempt_status = 2;
            this.incorrect++;
          }        
        });
        // console.log("correct",this.correct);
        // console.log("incorrect",this.incorrect);
        // console.log("skipped ",this.skipped);
        this.quesAnswerStatus(questionNumber, questionId, answerIds);
      }
    );
  }

  quesAnswerStatus(questionNumber, questionId, answerIds){
    let paramsVal = {
      set_id: this.quizSetId,
      school_id: this.schoolId
    };
    
    answerIds = String(answerIds);
    let attemptAnsIds = answerIds.split(',');
    let rightAnsIds = [];
    let attempt_status;
    this.quizData.quiz_questions[questionNumber].quiz_question.rightansoption.forEach((rVal)=>{
      rightAnsIds.push(rVal.answer_id);
    });

    attemptAnsIds.sort();          
    rightAnsIds.sort();

    this.rightAnsCount = 0;
    let rightCount = 0;
          
    //Parse all the attempted answer ids of a question
    attemptAnsIds.forEach((element) =>{
      let ansId = Number(element);            
      if(rightAnsIds.includes(ansId) && ansId>0){
        attempt_status = 1;
        this.quesArrStatus.push({question_id:questionId,answer_id : ansId,attempt_status:attempt_status}); //For correct Answer
        rightCount++;
      } else if(!rightAnsIds.includes(ansId) && ansId>0){
        attempt_status = 2;
        this.quesArrStatus.push({question_id:questionId,answer_id : ansId,attempt_status:attempt_status}); //For incorrect Answer
      } else {
        this.quesArrStatus.push({question_id:questionId,answer_id : ansId,attempt_status:attempt_status}); //For skipped question
      }
    });
    this.updateQuizQuesValue();
  }

  updateQuizQuesValue(){
    this.skipped = this.totalAttempted - (this.correct + this.incorrect);
    let quesParams = {
      user_id: this.userId,
      quiz_set_id: this.quizSetId,
      total_attempted: this.totalAttempted,
      total_correct: this.correct,
      total_incorrect: this.incorrect,
      total_skipped: this.skipped,
      time_spent: this.timeSpent,
      questions: this.quesArrStatus
    }
    this.quizSetService.updateQuizSet(quesParams).subscribe(
      quizResult => {
       
      }
    );
  }

  removelocalStorage(){
    localStorage.removeItem('userQuestions');
  }

  canDeactivate(){
    if(this.backStatus){
      return false;
    }
    return true;
  }

  getAttemptedQuizSetData(){
    let paramsVal = {
      user_id: this.userId,
      quiz_set_id: this.quizSetId
    }
    this.quizSetService.getAttemptedQuizSetData(paramsVal).subscribe((data: any) => {
      console.log(data);
      this.correct = data.data[0].total_correct;
      this.incorrect = data.data[0].total_incorrect;
      this.skipped = data.data[0].total_skipped;
      console.log("correct", this.correct);
      console.log("incorrect",this.incorrect);
      console.log("skipped",this.skipped);
    });
  }
}
