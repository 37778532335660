import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashQuizSetComponent } from './dash-quiz-set/dash-quiz-set.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { RouterModule } from '@angular/router';
import { MathModule } from '../math/math.module';
import { HttpClientModule } from '@angular/common/http';
import { DashboardService } from './dashboard.service';
import { DashboardComponent } from './dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { SchoolDashboardComponent } from './dash-quiz-set/school-dashboard/school-dashboard.component';
import { QuizChartComponent } from './dash-quiz-set/quiz-chart/quiz-chart.component';
import { ClassDashboardComponent } from './dash-quiz-set/class-dashboard/class-dashboard.component';
import { SectionDashboardComponent } from './dash-quiz-set/section-dashboard/section-dashboard.component';
import { QuizDashboardComponent } from './dash-quiz-set/quiz-dashboard/quiz-dashboard.component';
import { UserQuizDashboardComponent } from './dash-quiz-set/user-quiz-dashboard/user-quiz-dashboard.component';
import { UserDashboardComponent } from './dash-quiz-set/user-dashboard/user-dashboard.component';
import { StudentQuizChartComponent } from './dash-quiz-set/student-quiz-chart/student-quiz-chart.component';
import { StudentQuizDashComponent } from './dash-quiz-set/student-quiz-dash/student-quiz-dash.component';
import { TopicDashboardComponent } from './dash-quiz-set/topic-dashboard/topic-dashboard.component';
import { TopicChartComponent } from './dash-quiz-set/topic-chart/topic-chart.component';
import { QuizInfoDashboardComponent } from './dash-quiz-set/quiz-info-dashboard/quiz-info-dashboard.component';
import { UserListDashboardComponent } from './dash-quiz-set/user-list-dashboard/user-list-dashboard.component';
import { DashQuizsetPostTestComponent } from './dash-quizset-post-test/dash-quizset-post-test.component';
import { PostSchoolDashboardComponent } from './dash-quizset-post-test/post-school-dashboard/post-school-dashboard.component';
import { PostClassDashboardComponent } from './dash-quizset-post-test/post-class-dashboard/post-class-dashboard.component';
import { PostQuizChartComponent } from './dash-quizset-post-test/post-quiz-chart/post-quiz-chart.component';
import { PostDashboardService } from './post-dashboard.service';
import { PostSectionDashboardComponent } from './dash-quizset-post-test/post-section-dashboard/post-section-dashboard.component';
import { PostUserQuizDashComponent } from './dash-quizset-post-test/post-user-quiz-dash/post-user-quiz-dash.component';
import { PostUserListDashComponent } from './dash-quizset-post-test/post-user-list-dash/post-user-list-dash.component';
import { PostUserDashComponent } from './dash-quizset-post-test/post-user-dash/post-user-dash.component';
import { PostQuizDashboardComponent } from './dash-quizset-post-test/post-quiz-dashboard/post-quiz-dashboard.component';
import { PostQuizInfoDashComponent } from './dash-quizset-post-test/post-quiz-info-dash/post-quiz-info-dash.component';
import { PostStudentQuizDashComponent } from './dash-quizset-post-test/post-student-quiz-dash/post-student-quiz-dash.component';
import { PostTopicDashComponent } from './dash-quizset-post-test/post-topic-dash/post-topic-dash.component';
import { PostTopicChartComponent } from './dash-quizset-post-test/post-topic-chart/post-topic-chart.component';
import { PostStudentQuizChatComponent } from './dash-quizset-post-test/post-student-quiz-chat/post-student-quiz-chat.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { TeacherDashboardComponent } from './teacher-dashboard/teacher-dashboard.component';
import { UpcomingScheduleComponent } from './upcoming-schedule/upcoming-schedule.component';
import { LatestQuizComponent } from './latest-quiz/latest-quiz.component';
import { LatestAttendanceComponent } from './latest-attendance/latest-attendance.component';
import { LatestHomeworkComponent } from './latest-homework/latest-homework.component';
import { LatestAttenTeacherComponent } from './latest-attendance/latest-atten-teacher/latest-atten-teacher.component';
import { LatestAttenStudentComponent } from './latest-attendance/latest-atten-student/latest-atten-student.component';
import { MyAttemptedQuizComponent } from './my-attempted-quiz/my-attempted-quiz.component';
import { TeacherQuizChartComponent } from './teacher-dashboard/teacher-quiz-chart/teacher-quiz-chart.component';
import { TutorDashboardComponent } from './tutor-dashboard/tutor-dashboard.component';
import { PostStudentLessonListComponent } from './dash-quizset-post-test/post-user-list-dash/post-student-lesson-list/post-student-lesson-list.component';
import { PostQuizLessonDashComponent } from './dash-quizset-post-test/post-quiz-lesson-dash/post-quiz-lesson-dash.component';
import { PostLessonStudentListComponent } from './dash-quizset-post-test/post-quiz-lesson-dash/post-lesson-student-list/post-lesson-student-list.component';
import { PrePostTestsDataComponent } from './dash-quizset-post-test/post-user-list-dash/pre-post-tests-data/pre-post-tests-data.component';
import { PostLessonStudentTestsComponent } from './dash-quizset-post-test/post-quiz-lesson-dash/post-lesson-student-tests/post-lesson-student-tests.component';
import { DashUsageReportComponent } from './dash-usage-report/dash-usage-report.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { TopicWiseTestsComponent } from './dash-quizset-post-test/post-user-list-dash/pre-post-tests-data/topic-wise-tests/topic-wise-tests.component';
import { AttemptedTestDataHistoryComponent } from './dash-quizset-post-test/post-user-list-dash/pre-post-tests-data/attempted-test-data-history/attempted-test-data-history.component';
import { AttemptedTestsQuesComponent } from './dash-quizset-post-test/post-user-list-dash/pre-post-tests-data/attempted-test-data-history/attempted-tests-ques/attempted-tests-ques.component';
import { AttemptedTestsQuesAnsComponent } from './dash-quizset-post-test/post-user-list-dash/pre-post-tests-data/attempted-test-data-history/attempted-tests-ques-ans/attempted-tests-ques-ans.component';
import { AttemptedTestsAnsComponent } from './dash-quizset-post-test/post-user-list-dash/pre-post-tests-data/attempted-test-data-history/attempted-tests-ans/attempted-tests-ans.component';
import { PostLessonDashComponent } from './dash-quizset-post-test/post-lesson-dash/post-lesson-dash.component';
import { PostLessonDashChartComponent } from './dash-quizset-post-test/post-lesson-dash-chart/post-lesson-dash-chart.component';
import { PostLessonTopicDashComponent } from './dash-quizset-post-test/post-lesson-dash/post-lesson-topic-dash/post-lesson-topic-dash.component';
import { PostLessonTopicQuesComponent } from './dash-quizset-post-test/post-lesson-dash/post-lesson-topic-ques/post-lesson-topic-ques.component';
import { TopicQuesDataComponent } from './dash-quizset-post-test/post-lesson-dash/post-lesson-topic-ques/topic-ques-data/topic-ques-data.component';
import { TopicQuesChartComponent } from './dash-quizset-post-test/post-lesson-dash/post-lesson-topic-ques/topic-ques-chart/topic-ques-chart.component';
import { TopicQuesUserListComponent } from './dash-quizset-post-test/post-lesson-dash/post-lesson-topic-ques/topic-ques-user-list/topic-ques-user-list.component';
import { PostClassDashDataComponent } from './dash-quizset-post-test/post-class-dashboard/post-class-dash-data/post-class-dash-data.component';
import { ClassDashDataComponent } from './dash-quiz-set/class-dashboard/class-dash-data/class-dash-data.component';
import { PostSectionDashDataComponent } from './dash-quizset-post-test/post-section-dashboard/post-section-dash-data/post-section-dash-data.component';
import { SectionDashDataComponent } from './dash-quiz-set/section-dashboard/section-dash-data/section-dash-data.component';
import { StudentQuizDashDataComponent } from './dash-quiz-set/student-quiz-dash/student-quiz-dash-data/student-quiz-dash-data.component';
import { AttemptedTestSolComponent } from './dash-quizset-post-test/post-user-list-dash/pre-post-tests-data/attempted-test-data-history/attempted-test-sol/attempted-test-sol.component';

const routes = [
  {
    path: 'dashQuizSet', component: DashQuizSetComponent,
  },
  // {
  //   path: 'school_dashboard',
  //   component: SchoolDashboardComponent
  // },
  {
    path: 'class_dashboard/:school_id',
    component: ClassDashboardComponent
  },
  {
    path: 'section_dashboard/:school_id/:class_id',
    component: SectionDashboardComponent
  },
  {
    path: 'quiz_dashboard/:school_id/:class_id/:section_id',
    component: UserQuizDashboardComponent
  },
  {
    path: 'topic_dashboard/:school_id/:class_id/:section_id/:quiz_id',
    component: TopicDashboardComponent
  },
  {
    path: 'student_quiz_dash/:school_id/:class_id/:section_id/:student_id',
    component: StudentQuizDashComponent
  },
  {
    path: 'post_class_dashboard/:school_id',
    component: PostClassDashboardComponent
  },
  {
    path: 'post_section_dashboard/:school_id/:class_id',
    component: PostSectionDashboardComponent
  },
  {
    path: 'post_quiz_dashboard/:school_id/:class_id/:section_id',
    component: PostUserQuizDashComponent
  },
  {
    path: 'post_student_quiz_dash/:school_id/:class_id/:section_id/:student_id',
    component: PostStudentQuizDashComponent
  },
  {
    path: 'post_topic_dashboard/:school_id/:class_id/:section_id/:quiz_id',
    component: PostTopicDashComponent
  },
  {
    path: 'post_student_lesson_list/:school_id/:class_id/:section_id/:student_id',
    component: PostStudentLessonListComponent
  },
  {
    path: 'post_quiz_lesson_dash',
    component: PostQuizChartComponent
  },
  {
    path: 'post_lesson_student_list/:school_id/:class_id/:section_id/:subject_id/:lesson_id',
    component: PostLessonStudentListComponent
  },
  {
    path: 'pre_post_test_data',
    component: PrePostTestsDataComponent
  },
  {
    path: 'post_lesson_student_tests',
    component: PostLessonStudentTestsComponent
  },
  {
    path: 'attempt_test_data_history',
    component: AttemptedTestDataHistoryComponent
  },
  {
    path: 'post-lesson-topic-dash/:school_id/:class_id/:section_id/:lesson_id',
    component: PostLessonTopicDashComponent
  },
  {
    path: 'post-lesson-topic-ques/:school_id/:class_id/:section_id/:lesson_id',
    component: PostLessonTopicQuesComponent
  },
  {
    path: 'topic_ques_user_list',
    component: TopicQuesUserListComponent
  }
  


];

@NgModule({
  declarations: [
    DashQuizSetComponent,
    DashboardComponent,
    SchoolDashboardComponent,
    QuizChartComponent,
    ClassDashboardComponent,
    SectionDashboardComponent,
    QuizDashboardComponent,
    UserQuizDashboardComponent,
    UserDashboardComponent,
    StudentQuizChartComponent,
    StudentQuizDashComponent,
    TopicDashboardComponent,
    TopicChartComponent,
    QuizInfoDashboardComponent,
    UserListDashboardComponent,
    DashQuizsetPostTestComponent,
    PostSchoolDashboardComponent,
    PostClassDashboardComponent,
    PostQuizChartComponent,
    PostSectionDashboardComponent,
    PostUserQuizDashComponent,
    PostUserListDashComponent,
    PostUserDashComponent,
    PostQuizDashboardComponent,
    PostQuizInfoDashComponent,
    PostStudentQuizDashComponent,
    PostTopicDashComponent,
    PostTopicChartComponent,
    PostStudentQuizChatComponent,
    TeacherDashboardComponent,
    StudentDashboardComponent,
    UpcomingScheduleComponent,
    LatestQuizComponent,
    LatestAttendanceComponent,
    LatestHomeworkComponent,
    LatestAttenTeacherComponent,
    LatestAttenStudentComponent,
    MyAttemptedQuizComponent,
    TeacherQuizChartComponent,
    TutorDashboardComponent,
    PostStudentLessonListComponent,
    PostQuizLessonDashComponent,
    PostLessonStudentListComponent,
    PrePostTestsDataComponent,
    PostLessonStudentTestsComponent,
    DashUsageReportComponent,
    TopicWiseTestsComponent,
    AttemptedTestDataHistoryComponent,
    AttemptedTestsQuesComponent,
    AttemptedTestsQuesAnsComponent,
    AttemptedTestsAnsComponent,
    PostLessonDashComponent,
    PostLessonDashChartComponent,
    PostLessonTopicDashComponent,
    PostLessonTopicQuesComponent,
    PostLessonTopicQuesComponent,
    TopicQuesDataComponent,
    TopicQuesChartComponent,
    TopicQuesUserListComponent,
    PostClassDashDataComponent,
    ClassDashDataComponent,
    PostSectionDashDataComponent,
    SectionDashDataComponent,
    StudentQuizDashDataComponent,
    AttemptedTestSolComponent
   


  ],
  imports: [
    CommonModule,
    FormsModule,
    ChartsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(routes),
    MathModule.forRoot(),
    HttpClientModule,
    MatTableExporterModule
  ],
  exports: [
    DashUsageReportComponent,
    PostLessonDashComponent,
    QuizDashboardComponent,
    PostSchoolDashboardComponent
  ],
  entryComponents: [QuizInfoDashboardComponent, PostQuizInfoDashComponent],
  providers: [DashboardService, PostDashboardService,],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardModule { }
