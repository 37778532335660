import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DashboardService {

  private apiUrl = environment.apiUrl;
  currentUser: any;
  token: string;
  private searchFilterSubject = new BehaviorSubject<any>({});
  constructor(private http: Http) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if (this.currentUser) {
      this.token = this.currentUser.token;
    }
  }

  setSearchFilter(searchFilter: any) {
    this.searchFilterSubject.next(searchFilter);
  }

  getSearchFilter() {
    return this.searchFilterSubject.asObservable();
  }


  getTotalQuiz(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_sets/getTotalQuiz`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getQuizDataReport(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getQuizDataReport`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserSchool(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/user_classes/getUserSchool`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getSchoolsList(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/schools/getSchoolsList`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }


  //New API For PREPOST School  
  getPrePostSchoolsList(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/post_quiz_sets/getSchoolPerfDash`, paramsVal)
         .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }


  getUserClass(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/user_classes/getUserClass`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getClassList(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/classes/getClassList`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  //API FOR PRE/POST CLASS DASH
  getPrePostClassList(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/post_quiz_sets/getClassPerfDash`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserSection(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/user_classes/getUserSection`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getSectionList(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/class_sections/getSectionList`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  //API for getting PrePost Section Dash
  getPrePostSectionList(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/post_quiz_sets/getSectionPerfDash`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getDasboardQuizSets(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_sets/getDasboardQuizSets`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getStudentList(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/user_data/getStudentList`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getSchoolById(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/schools/getSchoolById`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getClass(paramsVal: any): Observable<any[]> {
    return this.http.get(`${this.apiUrl}/classes/getClass/` + paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }


  getSection(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/class_sections/getSection`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }


  getStudentData(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_users/getStudentData`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getStudentDataInfo(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/user_data/getStudentDataInfo`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getUserLessonPerformance(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/post_quiz_sets/getUserLessonPerformance`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getLessonUserPerformance(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/post_quiz_sets/getLessonUserPerformance`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getQuizTopic(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_sets/getQuizTopic`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getTopicReport(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getTopicReport`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }


  getAttemptUserList(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/attempted_quiz_sets/getAttemptUserList`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getAttemptListUser(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/post_attempted_quiz_sets/getAttemptUserList`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }

  getClassStudents(paramsVal): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/users/getClassStudents`, paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }


  getSubjects(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/subjects/getsubjects`, paramsVal)
      .map((response: Response) => {
        return response.json();
      });
  }

  getLessons(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/lessons/getlessons`, paramsVal)
      .map((response: Response) => {
        return response.json();
      });
  }


  getLessonById(paramsVal: any): Observable<any[]> {
    return this.http.get(`${this.apiUrl}/lessons/getLesson/` + paramsVal)
      .map((response: Response) => {
        return response.json();
      })
      .catch(this.handleError);
  }


  sendQuizReport(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/quiz_sets/sendQuizReport`, paramsVal)
      .map((response: Response) => {
        return response.json();
      });
  }


  private handleError(error: Response): Observable<any> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    console.error(error.json());
    return Observable.throw(error.json().error || 'Server error');
  }


}
