import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostTestService {

  private apiUrl = environment.apiUrl;
  private errMessage;

  constructor(private http: HttpClient) { }

  getPostTestQuestions(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/syllabus_questions/getposttestques`, paramsVal)
      .map((response: Response) => {
        return response;
      })
      .catch(this.handleError);
  }

  getUserPostTestQuestions(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/syllabus_questions/getuserposttestques`, paramsVal)
      .map((response: Response) => {
        return response;
      })
      .catch(this.handleError);
  }

  createQuizSet(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/post_quiz_sets/create`, paramsVal)
      .map((response: Response) => {
        return response;
      })
      .catch(this.handleError);
  }

  submitPostTest(paramsVal: any): Observable<any[]> {
    return this.http.post(`${this.apiUrl}/post_attempted_quiz_sets/create`, paramsVal)
      .map((response: Response) => {
        return response;
      })
      .catch(this.handleError);
  }

  getAttemptedQuizSets(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/post_attempted_quiz_sets/getmytests`, paramsVal)
      .map((response: Response) => {
        return response;
      })
      .catch(this.handleError);
  }

  getTopicWiseResult(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/post_attempted_quiz_sets/getTopicWiseResult`, paramsVal)
      .map((response: Response) => {
        return response;
      })
      .catch(this.handleError);
  }

  getTopicWiseDetails(paramsVal: any): Observable<any[]>{
    return this.http.post(`${this.apiUrl}/post_quiz_syllabus_details/getTopicWiseDetails`, paramsVal)
      .map((response: Response) => {
        return response;
      })
      .catch(this.handleError);
  }

  private handleError(error: Response): Observable<any> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    this.errMessage = error;
    console.error(error);
    return Observable.throw(this.errMessage.message || 'Server error');
  }
}
