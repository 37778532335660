import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizenozeContentComponent } from './wizenoze-content.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { RouterModule } from '@angular/router';
import { MathModule } from '../math/math.module';
import { HttpClientModule } from '@angular/common/http';
import { WizenozeSubjectComponent } from './wizenoze-subject/wizenoze-subject.component';

const routes = [

  { path: 'wizenozeContent', component: WizenozeContentComponent }

];

@NgModule({
  declarations: [
    WizenozeContentComponent,
    WizenozeSubjectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(routes),
    MathModule.forRoot(),
    HttpClientModule,
  ]
})
export class WizenozeModule { }
