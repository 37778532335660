import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { AttemptedTestHistoryComponent } from 'src/app/post-test/post-test-list/attempted-test-history/attempted-test-history.component';
import { PostTestService } from 'src/app/post-test/post-test.service';

@Component({
  selector: 'app-attempted-test-data-history',
  templateUrl: './attempted-test-data-history.component.html',
  styleUrls: ['./attempted-test-data-history.component.css']
})
export class AttemptedTestDataHistoryComponent implements OnInit {
  pageTitle = 'Questions';
  currentUser
  quizParams: any;
  sub: Subscription;
  quizVal: any;
  quizData: any;
  setId: number;
  topicId: number;
  tempObj = {};
  set_name: string;
  attemptedSetId: number;
  topicName: string;
  inputData: any;


  constructor(
    private postTestService: PostTestService,
    private dialogRef: MatDialogRef<AttemptedTestHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.inputData = data;
    this.attemptedSetId = this.inputData.attempted_set_id;
    this.topicId = this.inputData.topic_id;
    this.setId = this.inputData.set_id;
  }
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));  
    this.getQuizSetData();
  }
  ngOnDestroy(){
    this.sub.unsubscribe();
  }

  getQuizSetData() {
    this.quizParams = {attempted_set_id: this.attemptedSetId, set_id: this.setId, topic_id: this.topicId };
    //this.quizParams = {attempted_set_id: 8280, set_id: 8336, topic_id: 63 };
    this.sub = this.postTestService.getTopicWiseDetails(this.quizParams)
      .subscribe(quizData => {
        this.quizVal = quizData;
        this.topicName = this.quizVal.topic_name;
        this.quizData = this.quizVal.data;
      }
    );    
  }

  close() {
    this.dialogRef.close();
  }

}
