import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeworkService } from '../homework.service';
import { MatTableDataSource, MatPaginator, MatSort, MatProgressSpinnerModule } from '@angular/material';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-users-homework',
  templateUrl: './users-homework.component.html',
  styleUrls: ['./users-homework.component.css']
})
export class UsersHomeworkComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageTitle = 'Students';
  displayedColumns: string[] = ['Sno', 'email', 'name', 'classSec', 'submitted', 'submitted_on', 'id', 'percentage'];
  currentUser: any;
  user_id: number;
  school_id = [];
  class_id = [];
  section_id = [];
  homework_id: number;
  dataSource: any;
  homeworkData: any;
  userData: any;
  userArray = [];
  classList = [];
  sectionList = [];
  customFilters: any;

  searchFilter: any = {
    class_id: [],
    section_id: []
  };

  constructor(
    private route: ActivatedRoute,
    private homeworkService: HomeworkService,
    private backendService: BackendService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = this.currentUser.id;

    this.route.params.subscribe(params => {
      this.homework_id = parseInt(params.homework_id);
      let obj = { homework_id: this.homework_id }
      this.homeworkService.getHomeWorkById(obj).subscribe((data: any) => {
        this.homeworkData = data.data;
        this.school_id = data.school_id;
        this.class_id = data.class_id;
        this.section_id = data.section_id;
        this.customFilters = {
          school_id: this.school_id,
          class_id: this.class_id,
          section_id: this.section_id
        }
        this.getUsers();
        this.getClass();
      });
    });
  }


  getUsers() {
    this.userArray = [];
    this.customFilters = {
      school_id: this.customFilters.school_id,
      class_id: this.customFilters.class_id,
      section_id: this.customFilters.section_id,
      homework_id: this.homework_id
    }
    this.homeworkService.homeWorkUsers(this.customFilters).subscribe((data: any) => {
      this.userData = data.data;
      // console.log(this.userData);
      this.userData.forEach(ud => {
        let userObj = {};
        if (ud.quiz_user_data && ud.quiz_user_data.homeWorkSubmit.length > 0) {
          if (ud.quiz_user_data.homeWorkSubmit[0].submitted_on > this.homeworkData.expiration_date) {
            userObj = Object.assign({}, { delay: true }, ud);
          } else {
            userObj = Object.assign({}, { delay: false }, ud);
          }
          this.userArray.push(userObj);
        } else {
          userObj = Object.assign({}, { delay: false }, ud);
          this.userArray.push(userObj);
        }
      });
      // console.log(this.userArray);
      this.dataSource = new MatTableDataSource(this.userArray);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      //console.log('thisdataSource',this.dataSource);
    })
  }

  getClass(){
    let classObj = {
      board_id: 1,
      class_id: this.class_id
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }

  changeClass(class_id, defaultVal = 0) {
    // this.classIdArr = class_id;
    let classObj = { class_id: class_id, section_id: this.section_id }
    this.backendService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
    })
  }

  searchUserData(filters: any) {
    if (filters) {
      if (!filters.school_id || filters.school_id.length <= 0) {
        filters.school_id = this.school_id;
      }
      if (!filters.class_id || filters.class_id.length <= 0) {
        filters.class_id = this.class_id;
      }
      if (!filters.section_id || filters.section_id.length <= 0) {
        filters.section_id = this.section_id;
      }
      this.customFilters = filters;
      this.getUsers();
    }
  }

  resetSearchFilter(searchPanel: any) {
    searchPanel.toggle();
    this.searchFilter = {};
    this.customFilters = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id
    }
    this.getUsers();
  }

  reset() {
    this.userArray = [];
    this.getUsers();
  }

}
