import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { flatMap } from 'rxjs/operators';
import { PostDashboardService } from 'src/app/dashboard/post-dashboard.service';

@Component({
  selector: 'app-topic-ques-user-list',
  templateUrl: './topic-ques-user-list.component.html',
  styleUrls: ['./topic-ques-user-list.component.css']
})
export class TopicQuesUserListComponent implements OnInit {

  displayedColumns = ['student_name', 'email', 'user_response'];
  dataSource: any;
  school_id: any;
  class_id: any;
  section_id: any;
  lesson_id: any;
  topic_id: any;
  question_id: any;
  userObj: any;
  studentList: any;
  quiz_set_id: any;
  title: string;
  quiz_title: boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public topicData: any,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<TopicQuesUserListComponent>,
    private postDashboardService: PostDashboardService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
   // console.log(this.topicData);
    if (this.topicData.quiz_dash == 'Yes') {
      this.question_id = this.topicData.questionId,
        this.route.queryParams.subscribe(queryParams => {
          this.school_id = +queryParams.school_id;
          this.class_id = +queryParams.class_id;
          this.section_id = +queryParams.section_id;
          this.topic_id = +queryParams.topic_id
        });

    } else if (this.topicData.main_quiz_dash == 'Yes'){
      this.quiz_title = true;
      this.school_id = this.topicData.paramsData.school_id,
      this.class_id = this.topicData.paramsData.class_id,
      this.section_id = this.topicData.paramsData.section_id,
      this.quiz_set_id = this.topicData.paramsData.quiz_set_id

    }
     else {
      this.school_id = this.topicData.paramsData.school_id,
        this.class_id = this.topicData.paramsData.class_id,
        this.section_id = this.topicData.paramsData.section_id,
        this.lesson_id = this.topicData.paramsData.lesson_id,
        this.topic_id = this.topicData.paramsData.topic_id,
        this.question_id = this.topicData.questionId

    }

    this.userObj = {
      school_id: [this.school_id],
      class_id: [this.class_id],
      section_id: [this.section_id],
      lesson_id: [this.lesson_id],
      topic_id: [this.topic_id],
      question_id: [this.question_id],
      quiz_set_id: [this.topicData.paramsData.quiz_set_id],
    }

    this.getUserList();
  }

  getUserList() {

    if (this.topicData.quiz_dash == 'Yes') {
      let obj = {
        school_id: [this.school_id],
        class_id: [this.class_id],
        section_id: [this.section_id],
        question_id: this.question_id,
        quiz_set_id: [this.topicData.paramsData[0].quiz_set_id],
        topic_id: [this.topic_id]
      }
    //  console.log(JSON.stringify(obj))
      this.postDashboardService.getAttemptQuizQuesUserList(obj).subscribe((student: any) => {
       // console.log(student);
        this.studentList = student;
        this.dataSource = new MatTableDataSource(this.studentList);
      })
    } else if (this.topicData.main_quiz_dash == 'Yes') {

      let obj = {
        school_id: [this.school_id],
        class_id: [this.class_id],
        section_id: [this.section_id],
        quiz_set_id: [this.quiz_set_id]
      }
      //console.log(JSON.stringify(obj))
      this.postDashboardService.getAttemptQuizQuesUserList(obj).subscribe((student: any) => {
        //console.log(student);
        this.studentList = student;
        this.dataSource = new MatTableDataSource(this.studentList);
      })

    } 
     else {
     // console.log(this.userObj)
      this.postDashboardService.getAttemptTopicQuesUserList(this.userObj).subscribe((student: any) => {
       // console.log(student);
        this.studentList = student;
        this.dataSource = new MatTableDataSource(this.studentList);
      })
    }

  }

  close() {
    this.dialogRef.close();
  }
}
