import { Component, OnInit } from '@angular/core';
import { QuizSetService } from 'src/app/quiz-set/quiz-set.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/_services/backend.service';
import { DashboardService } from 'src/app/dashboard/dashboard.service';

@Component({
  selector: 'app-user-quiz-history',
  templateUrl: './user-quiz-history.component.html',
  styleUrls: ['./user-quiz-history.component.css']
})
export class UserQuizHistoryComponent implements OnInit {
  pageTitle: string;
  quizParams: any;
  sub: Subscription;
  quizVal: any;
  quizData: any;
  attempted_set_id: number;
  setId: number;
  isDashboard: boolean = false;
  school_name: string;
  class_name: string;
  section_name: string;
  tempObj = {};
  set_name: string;
  student_name: string;

  school_id: number;
  class_id: number;
  section_id: number;
  student_id: number;
  postTest = 0;
  searchFilter: any = {
    start_date: '',
    end_date: '',
    subject_label: [],
  };
  currentDate = new Date();
  subject_label: any;
  start_date: Date;
  end_date: Date;
  postTestQuizParams = {};
  defaultTab = 0;
  quizTab: boolean = false;


  constructor(
    private quizSetService: QuizSetService,
    private backendService: BackendService,
    private route: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
        this.student_id = +params['student_id'];
        this.setId = +params['id'];
        this.attempted_set_id = +params['set_id'];
      }
    )

    this.route.queryParams.subscribe((paramsData: any) => {

      if (paramsData.quiz_Tab) {
        this.pageTitle = 'Quiz Result Details';
        this.quizTab = true;
      }
      if (paramsData.postTest) {
        this.defaultTab = 1;
        this.pageTitle = 'Post Test Result Details';
        this.postTest = paramsData.postTest;
        this.getQuizSetData();
      }
      else {
        this.pageTitle = 'Quiz Result Details';
        this.getQuizSetData();
      }
    });

    if (this.school_id) {
      this.isDashboard = true;
    }
    this.breadcrumbs();


  }

  getQuizSetData() {
    this.quizParams = { attempted_set_id: this.attempted_set_id };
    this.dashboardService.getSearchFilter().subscribe(searchFilterData => {
      this.searchFilter.subject_label = searchFilterData.subject_label;
      this.searchFilter.start_date = searchFilterData.start_date;
      this.searchFilter.end_date = searchFilterData.end_date;
    });
    this.postTestQuizParams = {
      attempted_set_id: this.attempted_set_id,
      subject_label: this.searchFilter.subject_label,
      start_date: this.searchFilter.start_date,
      end_date: this.searchFilter.end_date
    }
    //console.log(this.postTestQuizParams)
    // console.log(this.quizParams)
    if (this.postTest) {
      //console.log(this.postTestQuizParams)
      this.quizSetService.getPostUserQuizSetDetails(this.postTestQuizParams)
        .subscribe(quizData => {
          this.quizVal = quizData;
          this.quizData = this.quizVal.data.attempted_quiz_questions;
          // console.log('hii refreshed')
        });
    } else {
      this.quizSetService.getUserQuizSetDetails(this.quizParams)
        .subscribe(quizData => {
          this.quizVal = quizData;
          this.quizData = this.quizVal.data.attempted_quiz_questions;
        });
    }
  }

  breadcrumbs() {
    this.tempObj = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      set_id: this.setId,
      student_id: this.student_id
    }
    // get School Name
    this.backendService.getSchoolById(this.tempObj).subscribe((schoolData: any) => {
      // console.log(schoolData);
      this.school_name = schoolData.data.school_name;
    })

    // get Class Name
    this.backendService.getClass(this.class_id).subscribe((classData: any) => {
      // console.log(classData);
      this.class_name = classData.data.class_name;
    })

    // get Section Name
    this.backendService.getSection(this.tempObj).subscribe((sectionData: any) => {
      // console.log(sectionData);
      this.section_name = sectionData.data.class_section.section_name;
    });

    this.backendService.getStudentData(this.tempObj).subscribe((studentData: any) => {
      // console.log(studentData);
      this.student_name = studentData.data.firstName + ' ' + studentData.data.lastName;
    })
    if (this.postTest) {
      this.quizSetService.getPostQuizSetDetails(this.tempObj).subscribe((quizDetail: any) => {
        // console.log(quizDetail);
        this.set_name = quizDetail.data.set_name;
      })
    } else {
      this.quizSetService.getQuizSetDetails(this.tempObj).subscribe((quizDetail: any) => {
        // console.log(quizDetail);
        this.set_name = quizDetail.data.set_name;
      })
    }
  }

  filterData(searchFilter) {
    // console.log(searchFilter)
    this.subject_label = searchFilter.subject_label;
    this.start_date = searchFilter.start_date;
    this.end_date = searchFilter.end_date;
    let searchFilterData = {
      subject_label: this.subject_label,
      end_date: this.end_date,
      start_date: this.start_date
    }
    this.dashboardService.setSearchFilter(searchFilterData);
    this.getQuizSetData();
  }

  resetSearchFilter(searchFilter: any) {
    this.searchFilter = {};
    this.end_date = null;
    this.start_date = null;
    this.subject_label = null;
    let searchFilterData = {
      subject_label: '',
      end_date: '',
      start_date: '',
    }
    this.dashboardService.setSearchFilter(searchFilterData);
    this.getQuizSetData();
  }


}
