import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-content-youtube-player',
  templateUrl: './content-youtube-player.component.html',
  styleUrls: ['./content-youtube-player.component.css']
})
export class ContentYoutubePlayerComponent implements OnInit {

  @Input() url;
  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
