import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeacherHomeworkComponent } from './teacher-homework/teacher-homework.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HomeworkService } from './homework.service';
import { AddHomeworkComponent } from './add-homework/add-homework.component';
import { StudentHomeworkComponent } from './student-homework/student-homework.component';
import { MaterialModule } from '../shared/material.module';
import { HomeworkDetailsComponent } from './homework-details/homework-details.component';
import { UsersHomeworkComponent } from './users-homework/users-homework.component';
import { ViewHomeworkComponent } from './view-homework/view-homework.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { HomeworkComponent } from './homework.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DecodeHtmlComponent } from './decode-html.component';
import { AuthGuard } from '../_guard/auth.guard';
import { TutorStudentComponent } from './tutor-student/tutor-student.component';
import { TutorHomeworkComponent } from './tutor-homework/tutor-homework.component';

const routes = [
  {
    path: 'teacherHomework',
    component: TeacherHomeworkComponent, 
    canActivate:[AuthGuard]
  },
  {
    path: 'studentHomework',
    component: StudentHomeworkComponent, 
    canActivate:[AuthGuard]
  },
  {
    path: 'addHomework',
    component: AddHomeworkComponent, 
    canActivate:[AuthGuard]
  },
  {
    path: 'editHomework/:homework_id',
    component: AddHomeworkComponent, 
    canActivate:[AuthGuard]
  },
  {
    path: 'homeworkDetail/:homework_id/:teacher_id',
    component: HomeworkDetailsComponent, 
    canActivate:[AuthGuard]
  },
  {
    path: 'usersHomework/:homework_id',
    component: UsersHomeworkComponent, 
    canActivate:[AuthGuard]
  },
  {
    path: 'viewHomework/:homework_id/:student_id/:class_id',
    component: ViewHomeworkComponent, 
    canActivate:[AuthGuard]
  },
  {
    path: 'tutorStudent',
    component: TutorStudentComponent
  },
  {
    path: 'tutorHomework',
    component: TutorHomeworkComponent
  }
];

@NgModule({
  declarations: [
    HomeworkComponent,
    TeacherHomeworkComponent,
    AddHomeworkComponent,
    StudentHomeworkComponent,
    HomeworkDetailsComponent,
    UsersHomeworkComponent,
    ViewHomeworkComponent,
    DecodeHtmlComponent,
    TutorStudentComponent,
    TutorHomeworkComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(routes),
    CKEditorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
  ],
  providers: [HomeworkService]
})

export class HomeworkModule { }
