import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostTestComponent } from './post-test.component';
import { PostTestService } from './post-test.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { RouterModule } from '@angular/router';
import { AttemptTestComponent } from './attempt-test/attempt-test.component';
import { PostTestItemsComponent } from './post-test-items/post-test-items.component';
import { StartTestComponent } from './start-test/start-test.component';
import { MathModule } from '../math/math.module';
import {QuizQuestionComponent} from './start-test/quiz-question.component';
import {QuizQuesAnsComponent} from './start-test/quiz-ques-ans.component';
import { StarttestGuard } from '../_services/starttest.guard';
import { PostTestListComponent } from './post-test-list/post-test-list.component';
import { TopicWiseResultComponent } from './post-test-list/topic-wise-result/topic-wise-result.component';
import { AttemptedTestHistoryComponent } from './post-test-list/attempted-test-history/attempted-test-history.component';
import { PostTestQuesComponent } from './post-test-list/attempted-test-history/post-test-ques.component';
import { PostTestQuesAnsComponent } from './post-test-list/attempted-test-history/post-test-ques-ans.component';
import { PostTestQuesAnsAttemptedComponent } from './post-test-list/attempted-test-history/post-test-ques-ans-attempted.component';
import { AuthGuard } from '../_guard/auth.guard';
import { PostTestSolComponent } from './post-test-list/attempted-test-history/post-test-sol/post-test-sol.component';

const routes = [
  {
    path: 'post-test',
    component: PostTestComponent, 
    canActivate:[AuthGuard]
  },
  {
    path: 'post-test-items/:id',
    component: PostTestItemsComponent
  },
  {
    path: 'attempt-post-test/:id',
    component: AttemptTestComponent
  },
  {
    path: 'start-post-test/:id',
    component: StartTestComponent,
    canDeactivate: [StarttestGuard]
  },
  {
    path: 'post-test-list/:id',
    component: PostTestListComponent
  }
];

@NgModule({
  declarations: [
    PostTestComponent,
    AttemptTestComponent,
    PostTestItemsComponent,
    StartTestComponent,
    QuizQuestionComponent,
    QuizQuesAnsComponent,
    PostTestListComponent,
    TopicWiseResultComponent,
    AttemptedTestHistoryComponent,
    PostTestQuesComponent,
    PostTestQuesAnsComponent,
    PostTestQuesAnsAttemptedComponent,
    PostTestSolComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule.forChild(routes),
    MathModule.forRoot()
  ],
  providers:[
    PostTestService
  ],
  entryComponents:[
    AttemptedTestHistoryComponent
  ]
})
export class PostTestModule { }
