import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { WizenozeService } from '../wizenoze.service';

@Component({
  selector: 'app-wizenoze-subject',
  templateUrl: './wizenoze-subject.component.html',
  styleUrls: ['./wizenoze-subject.component.css']
})
export class WizenozeSubjectComponent implements OnInit {
  class_id: number;
  subjectList: any;
  rowHeight = '650px';
  carriculamData: any;
  subjectLength: number = 0;
  lessonList: any;
  showLesson: boolean = false;
  topicList: any;
  titleList: any;
  showTitle: boolean = false;
  currentUser: any;
  user_id: number;

  constructor(
    private http: HttpClient,
    private wizenozeService: WizenozeService
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.wizenozeService.subscribeData.subscribe((data) => {
      // console.log(data);
        this.class_id = data;
        this.user_id = this.currentUser.adminUser[0].id;

        this.wizenozeService.getWizenozeCurriculum(this.class_id, this.user_id).subscribe((data: any) => {
          // console.log(data);
          this.carriculamData = data;
          this.subjectList = this.carriculamData.children;
          this.subjectLength = this.subjectList.length;
          // console.log(this.subjectList);
          this.showLesson = false;
          this.showTitle = false;
        })


        // this.http.get(`https://api.wizenoze.com/v4/curriculum/node/${this.class_id}/layers/SUBJECT/UNIT/TOPIC/QUERY?userUUID=123456&sessionUUID=123456&userType=teacher`, {
        //   headers: {"Authorization": "6bc9c661-cd70-4d40-961a-de65192d379d"}
        // })
        //   .subscribe((data: any) => {
        //     this.carriculamData = data;
        //     this.subjectList = this.carriculamData.children;
        //     this.subjectLength = this.subjectList.length;
        //     console.log(this.subjectList);
        //     this.showLesson = false;
        //     this.showTitle = false;
        //   });
    });
  }

  changeLesons(lesson){
    // console.log(lesson);
    this.lessonList = lesson.children;
    this.showLesson = true;
  }

  changeTopic(topic){
    // console.log(topic);

    this.wizenozeService.getWizenozeTitle(topic.id).subscribe((data: any) => {
      // console.log(data);
      this.titleList = data.results;
      this.showLesson = false;
      this.showTitle = true;
    })

    // this.http.get(`https://api.wizenoze.com/v4/curriculum/node/query/${topic.id}/results?userUUID=123456&sessionUUID=123456&userType=teacher&resultSize=100`, {
    //       headers: {"Authorization": "6bc9c661-cd70-4d40-961a-de65192d379d"}
    //     })
    //       .subscribe((data: any) => {
    //         console.log(data);
    //         this.titleList = data.results;
    //         this.showLesson = false;
    //         this.showTitle = true;
    //       });
  }

  openTitle(title){
    // console.log(title);
    window.open(title.fullUrl, "_blank");
  }

  backFn(){
    if(this.showLesson && !this.showTitle){
      this.showLesson = false;
      this.showTitle = false;
    }else if(!this.showLesson && this.showTitle){
      this.showLesson = true;
      this.showTitle = false;
    }
  }

}
