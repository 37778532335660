
import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { Router } from '@angular/router';
import { PostDashboardService } from '../../post-dashboard.service';

@Component({
  selector: 'app-post-quiz-chart',
  templateUrl: './post-quiz-chart.component.html',
  styleUrls: ['./post-quiz-chart.component.css']
})
export class PostQuizChartComponent implements OnInit {

  @Input() chartData;
  @Input() chartTitle;
  @Input() chartInput;

  total_quizSets: number = 0;
  currentUser: any;
  customFilters: any = null;
  quizSetData: any;
  quizSetValue: any;
  errorMessage: string;
  getTotalBelowAvg: any;
  getTotalAvg: any;
  getTotalAboveAvg: any;
  getFirstAvg: any;
  colNum = 1;
  school_id: number;
  class_id: number;
  section_id: number;
  chartSize: number;
  chartGridWidth = '380px';
  chart_type: string;
  quiz_set_id: number;
  student_id: number;
  topic_id: number;
  totalQuiz: number;
  totalQuizAttempt: number;
  totalStudent: number;
  avgPercent: any;
  subject_label: any;
  start_date: Date;
  end_date: Date;

  public pieChartType = 'pie';
  public pieChartLabels: Array<string> = ['Above 80%', 'Betweeen 61% and 80%', 'Betweeen 40% and 60%', 'Below 40%'];
  public pieChartData: Array<number> = [0, 0, 0];
  public pieChartColors: Array<any> = [
    { // all colors in order
      backgroundColor: ['green', 'blue', 'orange', 'red'],
      color: ['white', 'white', 'white', 'white']
    }
  ];

  public pieChartOptions: any = {
    pieceLabel: {
      render: (args) => {
        const label = args.label,
          value = args.value;
        return value;
      },
      fontColor: '#fff'
    },
    legend: { position: 'right' }
  };
  schoolData: any;
  classData: any;
  sectionData: any;
  quizObj: {};
  school_quiz_set_id: any;
  class_quiz_set_id: any;


  constructor(private postDashboardService: PostDashboardService,
    private router: Router,
    private dashboardService: DashboardService) { }

  ngOnInit() {
    // console.log(this.chartData)
    //console.log(this.chartInput);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.school_id = this.chartData.school_id;
    this.class_id = this.chartData.class_id;
    this.section_id = this.chartData.section_id;
    this.chartSize = this.chartData.chartSize;
    this.chart_type = this.chartData.chart_type;
    this.quiz_set_id = this.chartData.quiz_set_id;
    this.student_id = this.chartData.student_id;
    this.topic_id = this.chartData.topic_id;
    this.subject_label = this.chartData.subject_label;
    this.start_date = this.chartData.start_date;
    this.end_date = this.chartData.end_date;



    this.customFilters = {
      user_id: this.currentUser.adminUser[0].id,
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      chart_type: this.chart_type,
      quiz_set_id: this.quiz_set_id,
      topic_id: this.topic_id,
      student_id: this.student_id,
      subject_label: this.subject_label,
      start_date: this.start_date,
      end_date: this.end_date
    }
    if (this.chartSize < 3) {
      this.chartGridWidth = '600px';
    }

    if (this.chartData.chartName == 'school') {
      // code for when chartName is 'school'
      // this.avgPercent = this.chartInput.averagePerc,
      //   this.totalStudent = this.chartInput.totalStudents,
      //   this.total_quizSets = this.chartInput.studentAttempted,
      //   this.pieChartData = [this.chartInput.above_80, this.chartInput.between_60_80, this.chartInput.between_40_60, this.chartInput.below_40];

      // console.log(this.chartInput);
      //("sdfsd");
      this.getPrePostSchool()

    } else if (this.chartData.chartName == 'class') {
      // code for when chartName is 'class'
     //('hii0562')
      //  this.avgPercent = this.chartInput.averagePerc,
      //  this.totalStudent = this.chartInput.totalStudents,
      //  this.total_quizSets = this.chartInput.studentAttempted,
      //  this.pieChartData = [this.chartInput.above_80, this.chartInput.between_60_80, this.chartInput.between_40_60, this.chartInput.below_40];
      this.getPrePostClass()

    } else if (this.chartData.chartName == 'section') {
      // code for when chartName is 'section'
     // console.log('Hiii Mabood Sir')
      this.getPrePostSection()

    } else {
      // code for when none of the above conditions are met
      this.getTotalQuiz();
      this.getQuizSets();
    }

    // this.getTotalQuiz();
    //this.getQuizSets();

  }

  public randomizeType(): void {
    this.pieChartType = this.pieChartType === 'doughnut' ? 'pie' : 'doughnut';
  }

  getPrePostSchool() {
    //New API FOR SCHOOL DASH IN PRE/POST
    let customFiltersPosT = {
      // user_id: this.currentUser.adminUser[0].id,
      school_id: [this.school_id],
      subject_label: this.subject_label,
      start_date: this.start_date,
      end_date: this.end_date
    }
   // console.log(customFiltersPosT)
    this.dashboardService.getPrePostSchoolsList(customFiltersPosT).subscribe((schoolsList: any) => {
      //console.log(schoolsList);
      this.schoolData = Object.values(schoolsList)
      //console.log(this.schoolData)
      this.avgPercent = this.schoolData[0].averagePerc,
        this.totalStudent = this.schoolData[0].totalStudents,
        this.total_quizSets = this.schoolData[0].studentAttempted
      this.pieChartData = [this.schoolData[0].above_80, this.schoolData[0].between_60_80, this.schoolData[0].between_40_60, this.schoolData[0].below_40];
      this.quiz_set_id = this.schoolData[0].quiz_set_id
    });
  }


  getPrePostClass() {
    //New API FOR Class DASH IN PRE/POST
    let obj = {
      school_id: [this.school_id]
    }
    this.dashboardService.getPrePostSchoolsList(obj).subscribe((schoolsList: any) => {
      //console.log(schoolsList);
      this.schoolData = Object.values(schoolsList)
      //console.log(this.schoolData)
      this.school_quiz_set_id = this.schoolData[0].quiz_set_id

      let customFiltersPosTClass = {
        //user_id: this.currentUser.adminUser[0].id,
        school_id: [this.school_id],
        class_id: [this.class_id],
        quiz_set_id: [this.school_quiz_set_id],
        subject_label: this.subject_label,
        start_date: this.start_date,
        end_date: this.end_date
      }

      if (this.school_quiz_set_id !== undefined || this.school_quiz_set_id !== null || this.school_quiz_set_id !== '') {
        //console.log(JSON.stringify(customFiltersPosTClass))
        this.dashboardService.getPrePostClassList(customFiltersPosTClass).subscribe((classListData: any) => {
          //console.log(classListData);
          this.classData = classListData
          //console.log(this.schoolData)
          this.avgPercent = this.classData.averagePerc,
            this.totalStudent = this.classData.totalStudents,
            this.total_quizSets = this.classData.studentAttempted
          this.pieChartData = [this.classData.above_80, this.classData.between_60_80, this.classData.between_40_60, this.classData.below_40];
          this.quiz_set_id = this.classData.quiz_set_id
        });
      }

    });

  }



  getPrePostSection() {
    //New API FOR Section DASH IN PRE/POST

    let obj = {
      school_id: [this.school_id]
    }
    this.dashboardService.getPrePostSchoolsList(obj).subscribe((schoolsList: any) => {
     // console.log(schoolsList);
      this.schoolData = Object.values(schoolsList)
      //console.log(this.schoolData)
      this.school_quiz_set_id = this.schoolData[0].quiz_set_id

      let customFiltersPostClass = {
       // user_id: this.currentUser.adminUser[0].id,
        school_id: [this.school_id],
        class_id: [this.class_id],
        quiz_set_id: [this.school_quiz_set_id],
        subject_label: this.subject_label,
        start_date: this.start_date,
        end_date: this.end_date
      }
//Now Hit APi for getting class_quiz_set_id
      if (this.school_quiz_set_id !== undefined || this.school_quiz_set_id !== null || this.school_quiz_set_id !== '') {
        //console.log('class'+JSON.stringify(customFiltersPostClass))
        this.dashboardService.getPrePostClassList(customFiltersPostClass).subscribe((classListData: any) => {
        //  console.log(classListData);
          this.classData = classListData
          //console.log(this.schoolData)
          this.class_quiz_set_id = this.classData.quiz_set_id;
//Now Hit API for Section Data from New Api
          if (this.class_quiz_set_id !== undefined || this.class_quiz_set_id !== null || this.class_quiz_set_id !== '') {
            let customFiltersPosT = {
             // user_id: this.currentUser.adminUser[0].id,
              school_id: [this.school_id],
              class_id: [this.class_id],
              section_id: [this.section_id],
              quiz_set_id: [this.class_quiz_set_id],
              subject_label: this.subject_label,
              start_date: this.start_date,
              end_date: this.end_date
            }
           // console.log('section'+JSON.stringify(customFiltersPosT))
            this.dashboardService.getPrePostSectionList(customFiltersPosT).subscribe((sectionListData: any) => {
             //console.log(sectionListData);
              this.sectionData = sectionListData
              //console.log(this.schoolData)
              this.avgPercent = this.sectionData.averagePerc,
                this.totalStudent = this.sectionData.totalStudents,
                this.total_quizSets = this.sectionData.studentAttempted
              this.pieChartData = [this.sectionData.above_80, this.sectionData.between_60_80, this.sectionData.between_40_60, this.sectionData.below_40];
              this.quiz_set_id = this.sectionData.quiz_set_id
            });
          }


        });
      }

    });


  }

  getTotalQuiz() {
    this.postDashboardService.getTotalQuiz(this.customFilters).subscribe((toatalQuizData: any) => {
      //console.log("toatalQuizData = "+JSON.stringify(toatalQuizData));
      this.totalQuiz = toatalQuizData.data.totalQuiz;
      this.totalQuizAttempt = toatalQuizData.data.totalQuizAttempt;
      this.totalStudent = toatalQuizData.data.totalStudent;
    })
  }


  getQuizSets() {
    // console.log(this.customFilters);
    this.postDashboardService.getQuizDataReport(this.customFilters).subscribe(quizSets => {
      //console.log(quizSets);
      this.quizSetData = quizSets;
      //this.total_quizSets = this.quizSetData.length;
      if (this.quizSetData.length > 0) {
        //console.log('if');
        this.quizSetValue = this.quizSetData[0].set_name;
      } else {
        //console.log('if');
        this.totalQuizAttempt = 0;
      }
      let users = [];
      this.quizSetData.map((quizVal, index) => {
        users.push(quizVal.user_id);
        //console.log('abc = '+quizVal.user_id);
        this.quizSetData[index].total_attempted = parseInt(quizVal.total_attempted);
        this.quizSetData[index].total_correct = parseInt(quizVal.total_correct);
        this.quizSetData[index].total_incorrect = parseInt(quizVal.total_incorrect);
        this.quizSetData[index].total_skipped = parseInt(quizVal.total_skipped);
        this.quizSetData[index].percentage = parseFloat(quizVal.percentage);
      });
      //console.log('abc = '+users);
      users = users.filter(function (elem, pos) {
        return users.indexOf(elem) == pos;
      });
      this.total_quizSets = users.length; //Student Attempted count for school, class, section dashboard
     // console.log(this.total_quizSets)
      if (this.student_id > 0 && this.student_id != undefined) {
        this.total_quizSets = this.quizSetData.length; //Attempted Post Test for student dashboard
      }

      this.getTotalBelowAvg = this.getBelowAvgCount();
      this.getTotalAvg = this.getAvgCount();
      this.getFirstAvg = this.getFirstAvgCount();
      this.getTotalAboveAvg = this.getAboveAvgCount();
      this.getAvgPercentage();

      this.pieChartData = [this.getTotalAboveAvg, this.getFirstAvg, this.getTotalAvg, this.getTotalBelowAvg];
      // console.log(this.pieChartData);
      return quizSets;
    },
      error => this.errorMessage = error as any);
  }


  getBelowAvgCount() {
    return this.quizSetData.map(td => {
      let totalCorrect = 0;
      let totalIncorrect = 0;
      let totalSkipped = 0;
      let totalVal = 0;
      let totalAvgCount = 0;

      if (td.total_correct !== undefined || td.total_correct !== '' || td.total_correct !== null) {
        totalCorrect = td.total_correct;
      }

      if (td.total_incorrect !== undefined || td.total_incorrect !== '' || td.total_incorrect !== null) {
        totalIncorrect = td.total_incorrect;
      }

      if (td.total_skipped !== undefined || td.total_skipped !== '' || td.total_skipped !== null) {
        totalSkipped = td.total_skipped;
      }
      totalVal = (totalCorrect / (totalCorrect + totalIncorrect + totalSkipped)) * 100;
      if (totalVal < 40) {
        totalAvgCount = totalAvgCount + 1;
      }
      return totalAvgCount;
    }
    )
      .reduce((acc, value) => {
        return acc + value;
      }, 0
      );
  }

  getAvgCount() {
    return this.quizSetData.map(td => {
      let totalCorrect = 0;
      let totalIncorrect = 0;
      let totalSkipped = 0;
      let totalVal = 0;
      let totalAvgCount = 0;

      if (td.total_correct !== undefined || td.total_correct !== '' || td.total_correct !== null) {
        totalCorrect = td.total_correct;
      }

      if (td.total_incorrect !== undefined || td.total_incorrect !== '' || td.total_incorrect !== null) {
        totalIncorrect = td.total_incorrect;
      }

      if (td.total_skipped !== undefined || td.total_skipped !== '' || td.total_skipped !== null) {
        totalSkipped = td.total_skipped;
      }
      totalVal = (totalCorrect / (totalCorrect + totalIncorrect + totalSkipped)) * 100;
      if (totalVal >= 40 && totalVal <= 60) {
        totalAvgCount = totalAvgCount + 1;
      }
      return totalAvgCount;
    }
    )
      .reduce((acc, value) => {
        return acc + value;
      }, 0
      );
  }

  getFirstAvgCount() {
    return this.quizSetData.map(td => {
      let totalCorrect = 0;
      let totalIncorrect = 0;
      let totalSkipped = 0;
      let totalVal = 0;
      let totalAvgCount = 0;

      if (td.total_correct !== undefined || td.total_correct !== '' || td.total_correct !== null) {
        totalCorrect = td.total_correct;
      }

      if (td.total_incorrect !== undefined || td.total_incorrect !== '' || td.total_incorrect !== null) {
        totalIncorrect = td.total_incorrect;
      }

      if (td.total_skipped !== undefined || td.total_skipped !== '' || td.total_skipped !== null) {
        totalSkipped = td.total_skipped;
      }
      totalVal = (totalCorrect / (totalCorrect + totalIncorrect + totalSkipped)) * 100;
      if (totalVal > 60 && totalVal <= 80) {
        totalAvgCount = totalAvgCount + 1;
      }
      return totalAvgCount;
    }
    )
      .reduce((acc, value) => {
        return acc + value;
      }, 0
      );
  }

  getAboveAvgCount() {
    return this.quizSetData.map(td => {
      let totalCorrect = 0;
      let totalIncorrect = 0;
      let totalSkipped = 0;
      let totalVal = 0;
      let totalAvgCount = 0;

      if (td.total_correct !== undefined || td.total_correct !== '' || td.total_correct !== null) {
        totalCorrect = td.total_correct;
      }

      if (td.total_incorrect !== undefined || td.total_incorrect !== '' || td.total_incorrect !== null) {
        totalIncorrect = td.total_incorrect;
      }

      if (td.total_skipped !== undefined || td.total_skipped !== '' || td.total_skipped !== null) {
        totalSkipped = td.total_skipped;
      }
      totalVal = (totalCorrect / (totalCorrect + totalIncorrect + totalSkipped)) * 100;
      if (totalVal > 80) {
        totalAvgCount = totalAvgCount + 1;
      }
      return totalAvgCount;
    }
    )
      .reduce((acc, value) => {
        return acc + value;
      }, 0
      );
  }

  getAvgPercentage() {
    let totalPercent = 0;
    let count = 0;
    this.quizSetData.forEach(td => {
      if (td.percentage != undefined) {
        totalPercent = totalPercent + td.percentage;
        count++;
      }
    });
    this.avgPercent = (totalPercent / count).toFixed(2);
    if (isNaN(this.avgPercent)) { this.avgPercent = 0; }
  }

  public chartClicked(e: any): void {
    // console.log('hii')
    //console.log("school", this.school_id);
    //console.log("section", this.section_id);
   // console.log("classID", this.class_id);
    //console.log("quiz_set_id", this.quiz_set_id);
    if (this.chartData.chartName == 'school') {
      //console.log('hii12')
      if (this.schoolData[0].above_80 > 0 || this.schoolData[0].between_60_80 > 0 || this.schoolData[0].between_40_60 > 0 || this.schoolData[0].below_40 > 0) {
        if (this.school_id != undefined && this.class_id == undefined) {
          //console.log('school to class')
          this.router.navigate(['/post_class_dashboard', `${this.school_id}`], {
            queryParams: {
              postTest: 1,
             // quiz_set_id: this.schoolData[0].quiz_set_id,
              chartName: "class"
            }
          });
        }
      }

    } else if (this.chartData.chartName == 'class') {
      // code for when chartName is 'class'
      if (this.classData.above_80 > 0 || this.classData.between_60_80 > 0 || this.classData.between_40_60 > 0 || this.classData.below_40 > 0) {
        if (this.class_id != undefined && this.section_id == undefined) {
        //  console.log('class to secction')
          this.router.navigate(['/post_section_dashboard', `${this.school_id}`, `${this.class_id}`], {
            queryParams: {
              postTest: 1,
              //quiz_set_id: this.classData.quiz_set_id,
              chartName: "section"
            }
          });
        }
      }

    } else if (this.chartData.chartName == 'section') {
      // code for when chartName is 'section'
      //console.log('gsdvdvc')
      if (this.sectionData.above_80 > 0 || this.sectionData.between_60_80 > 0 || this.sectionData.between_40_60 > 0 || this.sectionData.below_40 > 0) {
        if (this.section_id != undefined && this.quiz_set_id) {
          //console.log('secction to lesson')
          this.router.navigate(['/post_quiz_dashboard', `${this.school_id}`, `${this.class_id}`, `${this.section_id}`], {
            queryParams: {
              postTest: 1,
             // quiz_set_id: this.sectionData.quiz_set_id,
              chartName: "lesson"
            }
          });
        }
      }



    } else {
      if (this.quizSetData.length > 0) {

        if (this.school_id != undefined && this.class_id == undefined) {
          this.router.navigate(['/post_class_dashboard', `${this.school_id}`]);
        }

        if (this.class_id != undefined && this.section_id == undefined) {
          this.router.navigate(['/post_section_dashboard', `${this.school_id}`, `${this.class_id}`]);
        }

        if (this.section_id != undefined && this.quiz_set_id == undefined) {
          this.router.navigate(['/post_quiz_dashboard', `${this.school_id}`, `${this.class_id}`, `${this.section_id}`],
            {
              queryParams: {
                tabType: 1
              }
            });
        }

        // if(this.section_id != undefined && this.quiz_set_id == undefined){
        //   this.router.navigate(['/user_quiz_dashboard']);
        // }

        if (this.student_id != undefined) {
          this.router.navigate(['/post_student_quiz_dash', `${this.school_id}`, `${this.class_id}`, `${this.section_id}`, `${this.student_id}`]);
        }

        if (this.quiz_set_id != undefined) {
          // this.router.navigate(['/topic_dashboard', `${this.school_id}`, `${this.class_id}`, `${this.section_id}`, `${this.quiz_set_id}`]);
          this.router.navigate(['/questionHistory', this.quiz_set_id], {
            queryParams: {
              school_id: this.school_id,
              class_id: this.class_id,
              section_id: this.section_id,
              postTest: 1
            }
          });
        }



        // if (this.topic_id != undefined) {
        // this.router.navigate(['/questionHistory', this.quiz_set_id], {
        //   queryParams: {
        //     school_id: this.school_id,
        //     class_id: this.class_id,
        //     section_id: this.section_id
        //   }
        // });
        // }
      }
    }

  }

}
