import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quiz-set-items',
  templateUrl: './quiz-set-items.component.html',
  styleUrls: ['./quiz-set-items.component.css']
})
export class QuizSetItemsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
