import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DashboardService } from '../../dashboard.service';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PostDashboardService } from '../../post-dashboard.service';

@Component({
  selector: 'app-post-user-dash',
  templateUrl: './post-user-dash.component.html',
  styleUrls: ['./post-user-dash.component.css']
})
export class PostUserDashComponent implements OnInit {

  @Input() inputData;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  school_id: any;
  class_id: any;
  section_id: any;
  limit = 12;
  offset = 0;
  total_count: number;
  customFilters = {}
  studentList: any;
  heading = false;
  total_students: number;
  studentLength: number;
  section_name: string;
  rowHeight: string = "650px";
  searchFilter: any = {
    start_date: '',
    end_date: '',
    subject_label: [],
  };
  currentDate = new Date();
  subject_label: any;
  start_date: Date;
  end_date: Date;


  constructor(private postDashboardService: PostDashboardService,
    private dashboardService: DashboardService,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.params.subscribe(
      params => {
        this.school_id = +params['school_id'];
        this.class_id = +params['class_id'];
        this.section_id = +params['section_id'];
      }
    );
    this.customFilters = {
      school_id: this.school_id,
      class_id: this.class_id,
      section_id: this.section_id,
      limit: this.limit,
      offset: this.offset
    }
    this.section_name = this.inputData.section_name;

    // this.dashboardService.getStudentList(this.customFilters).subscribe((studentData: any) => {
    //   // console.log(studentData);
    //   this.total_students = studentData.total_students;
    //   this.studentList = studentData.data;
    //   this.studentLength = this.total_students;
    //     if(this.studentLength > 2){
    //       this.studentLength = 3;
    //       this.rowHeight = "450px"
    //       this.heading = true;
    //     }
    // });
    this.refreshData();
    this.dashboardService.getSearchFilter().subscribe(searchFilterData => {
     // console.log(searchFilterData);
      this.searchFilter.subject_label = searchFilterData.subject_label;
      this.searchFilter.start_date = searchFilterData.start_date;
      this.searchFilter.end_date = searchFilterData.end_date;
    });

  }

  filterData(searchFilter){
   // console.log(searchFilter)
    this.subject_label = searchFilter.subject_label;
    this.start_date = searchFilter.start_date;
    this.end_date = searchFilter.end_date;
    let searchFilterData = {
      subject_label: this.subject_label,
      end_date: this.end_date,
      start_date: this.start_date
    }
    this.dashboardService.setSearchFilter(searchFilterData);
    this.refreshData();
  }
   
  resetSearchFilter(searchPanel: any) {
    this.searchFilter = {};
    this.end_date = null;
    this.start_date = null;
    this.subject_label = null;
    let searchFilterData = {
      subject_label: '',
      end_date: '',
      start_date: '',
    }
    this.dashboardService.setSearchFilter(searchFilterData);
    this.refreshData();
  }

  refreshData(){
    this.dashboardService.getStudentList(this.customFilters).subscribe((studentData: any) => {
      // console.log(studentData);
      this.total_students = studentData.total_students;
      this.studentList = studentData.data;
      this.studentLength = this.total_students;
        if(this.studentLength > 2){
          this.studentLength = 3;
          this.rowHeight = "450px"
          this.heading = true;
        }
    });
    
  }

  ngAfterViewInit() {

    merge( this.paginator.page)
    .pipe(
    tap(() => {
        this.offset = this.paginator.pageIndex * this.paginator.pageSize;
        this.limit = this.paginator.pageSize;
        this.ngOnInit();
        })
    )
    .subscribe();
}

}
