import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-post-lesson-dash-chart',
  templateUrl: './post-lesson-dash-chart.component.html',
  styleUrls: ['./post-lesson-dash-chart.component.css']
})
export class PostLessonDashChartComponent implements OnInit {

  @Input() chartData;
  @Input() quizSet;
  @Input() quiz_set_id;
  chartGridWidth = '380px';
  
  public pieChartType = 'pie';
  public pieChartLabels: Array<string> = ['Above 80%', 'Betweeen 61% and 80%', 'Betweeen 40% and 60%', 'Below 40%'];
  public pieChartData: Array<number> = [0, 0, 0];
  public pieChartColors: Array<any> = [
    { // all colors in order
      backgroundColor: ['green', 'blue', 'orange', 'red'],
      color: ['white', 'white', 'white', 'white']
    }
  ];
  

  public pieChartOptions: any = {
    pieceLabel: {
      render: (args) => {
        const label = args.label,
          value = args.value;
        return value;
      },
      fontColor: '#fff'
    },
    legend: { position: 'right' }
  };
  currentUser: any;
  average: any;
  attempted_students: any;
  total_students: any;
  school_id: any;
  class_id: any;
  section_id: any;
  lesson_id: any;
  above_80: any;
  between_60_80: any;
  below_40: any;
  between_40_60: any;
  topic_id: any;



  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    //console.log(this.chartData)
   // console.log(this.quizSet)
    this.school_id = this.chartData.school_id;
    this.class_id = this.chartData.class_id;
    this.section_id = this.chartData.section_id;
    this.lesson_id = this.quizSet.lesson_id
    this.topic_id = this.quizSet.topic_id;
    this.average = this.quizSet.averagePerc;
    this.total_students = this.quizSet.totalStudents;
    this.attempted_students = this.quizSet.studentAttempted,
    this.above_80 = this.quizSet.above_80,
    this.between_60_80 = this.quizSet.between_60_80,
    this.between_40_60= this.quizSet.between_40_60,
    this.below_40 = this.quizSet.below_40,
    this.pieChartData = [this.above_80, this.between_60_80, this.between_40_60, this.below_40 ];
    //this.quiz_set_id = this.quizSet.quiz_set_id;
   // console.log(this.lesson_id);
  //console.log(this.quiz_set_id)
 // console.log(this.quizSet)
    //console.log(this.topic_id)
  }

  public chartClicked(e: any): void {
   
    if (this.quizSet.above_80 >0 || this.quizSet.between_60_80>0 || this.quizSet.between_40_60>0 || this.quizSet.below_40 >0){
      // if(this.chartData.chartName === 'Lesson'){
      //   //console.log('kd1')
      //   this.quiz_set_id = this.quizSet.quiz_set_id;
      //   this.router.navigate(['/post-lesson-topic-dash', `${this.school_id}`, `${this.class_id}`, 
      //   `${this.section_id}`, `${this.lesson_id}`],{
      //     queryParams: {
      //       postTest: 1,
      //       quiz_set_id: this.quiz_set_id
      //     }
      //   });
      // } else {
      //   //console.log('kd2')
      //   this.router.navigate(['/post-lesson-topic-ques', `${this.school_id}`, `${this.class_id}`, 
      //   `${this.section_id}`,`${this.lesson_id}`,`${this.topic_id}` ],{
      //     queryParams: {
      //       postTest: 1,
      //       quiz_set_id: this.quiz_set_id,
      //       topic_name: this.quizSet.topic_name
      //     }
      //   });

      if(this.chartData.chartName === 'Lesson'){
        this.router.navigate(['/post-lesson-topic-ques', `${this.school_id}`, `${this.class_id}`, 
        `${this.section_id}`,`${this.lesson_id}` ],{
          queryParams: {
            postTest: 1,
            quiz_set_id: this.quizSet.quiz_set_id,
          }
        });
      }else {
          //console.log('kd2')
          if(this.chartData.main_dash == true){
            this.router.navigate(['/post-lesson-topic-ques', `${this.school_id}`, `${this.class_id}`, 
            `${this.section_id}`,`${this.lesson_id}`],{
              queryParams: {
                topic_id: this.topic_id,
                postTest: 1,
                quiz_set_id: this.quiz_set_id,
                topic_name: this.quizSet.topic_name
                
              }
            });
          } else {
            this.router.navigate(['/post-lesson-topic-ques', `${this.school_id}`, `${this.class_id}`, 
            `${this.section_id}`,`${this.lesson_id}`],{
              queryParams: {
                topic_id: this.topic_id,
                postTest: 1,
                quiz_set_id: this.quiz_set_id,
                ques_dash: 'ques_dash',
                topic_name: this.quizSet.topic_name,  
                
              }
            });
          }
         
        }
    }
    

  }

  
}
