import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/_services/backend.service';

@Component({
  selector: 'app-post-test-items',
  templateUrl: './post-test-items.component.html',
  styleUrls: ['./post-test-items.component.css']
})
export class PostTestItemsComponent implements OnInit {

  sub: Subscription;
  classId: number;
  lessonId: number;
  lessonData: any;
  lessonName: string;

  constructor(
    private backendService: BackendService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params)=>{
      this.lessonId = +params['id'];
      this.getLesson(this.lessonId); 
    });
    this.route.queryParams.subscribe((params) => {
      this.classId = params.class_id
    })
    
  }

  getLesson(lesson_id){
    this.sub = this.backendService.getLesson(lesson_id)
    .subscribe((data)=>{
      // console.log(data)
      this.lessonData = data;
      if(this.lessonData.status){    
        if(this.lessonData.data){
         // this.classId = this.lessonData.data.subject_lessons.class_id;
          this.lessonId = this.lessonData.data.id;
          this.lessonName = this.lessonData.data.lesson_name;
        } else {          
          this.invalidValue();
        }
      } else {
        this.invalidValue();
      }
    })
  }

  openSnackBar(message: string, action: string, duration = 2500) {
    this.snackBar.open(message, action, {
      duration: duration
    });
  }

  invalidValue(){
    this.openSnackBar("Invalid Value. Please try again.",'close');
    this.router.navigate(['post-test']);
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }

}
