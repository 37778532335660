import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from 'src/app/_services/backend.service';
import { environment } from 'src/environments/environment';
import { MsContentService } from '../ms-content.service';

@Component({
  selector: 'app-ms-textbook',
  templateUrl: './ms-textbook.component.html',
  styleUrls: ['./ms-textbook.component.css']
})
export class MsTextbookComponent implements OnInit {

  class_id: number;
  lesson_id: any;
  routeLessonId: number
  topic_id: any
  contentData: any;
  path: string;
  content_id: number;
  contentUrl = environment.contentUrl;
  title: string;
  topicName: string;
  lessonName: string;
  className: string;
  country_id: number;
  school_id: number;
  currentUser: any;
  user_id: number;
  schoolArr = [];
  classArr = [];
  sectionArr = [];
  country_lesson_id: number;

  constructor(
    private route: ActivatedRoute,
    private contentService: MsContentService,
    private http : HttpClient,
    private sanitizer: DomSanitizer,
    private _backendService: BackendService
    ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(this.currentUser){ 
      this.user_id = this.currentUser.id;
      this.school_id = this.currentUser.currentSelSchoolId;
     }
     this._backendService.getSchoolById({school_id:this.school_id}).subscribe((schoolData: any)=> {
      //  console.log(schoolData);
       this.country_id = schoolData.data.schoolCountry.id;
       if(this.country_id == 2){
        this.contentService.getMapCountryLessons({lesson_id: this.lesson_id}).subscribe((data: any)=> {
          this.country_lesson_id = data.data.country_lesson_id;
          // console.log(this.country_lesson_id);
        })
      }
     })
    this.route.params.subscribe(params => {
      this.class_id = parseInt(params.class_id);
      this.content_id = params.content_id;
      this.routeLessonId = params.lesson_id
      // console.log(this.title);
    });

    let obj = {user_id: this.user_id}
    this._backendService.userSchools(obj).subscribe((data: any) => {
      // console.log(data);
      data.data.forEach(element => { this.schoolArr.push(element.id) });
      this.classArr = data.userClass;
      this.sectionArr = data.userSection;
    });

    this.route.queryParams.subscribe(queryParams => {
      this.lesson_id = queryParams.lesson_id;
      this.topic_id = queryParams.topic_id
      this.getContent();
        // console.log("normal content")
    });
    this._backendService.getClass(this.class_id).subscribe((className: any)=> {
      this.className = className.data.class_name;
    })
  }

  getContent(){
    let obj = {
      country_id: this.country_id,
      content_id: this.content_id,
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr
    }
    this.contentService.getTextbookContentById(obj).subscribe((contentData: any) => {
      // console.log(contentData);
      this.contentData = contentData.data;
      this.title = this.contentData.title;
      this.lessonName = this.contentData.content_lesson.lesson_name;
      this.topicName = this.contentData.content_topic.topic_name;

      this.path = this.contentUrl+'/'+this.contentData.path;

      // this.path = this.contentUrl+'/'+this.contentData.path;
      // this.transform(this.path);
    });
  }

  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.path);
     }
   

}