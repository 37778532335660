import { Component, OnInit, EventEmitter, Output, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatTableDataSource, MatSnackBar } from '@angular/material';
import { BackendService } from 'src/app/_services/backend.service';
import { AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'app-online-subjects',
  templateUrl: './online-subjects.component.html',
  styleUrls: ['./online-subjects.component.css']
})
export class OnlineSubjectsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['school','class','section','subject','id'];  
  inputData: any;
  currentUser: any;
  user_id: number;
  user_type_id: number;
  dataSource: any;
  customFilter = {};
  result: any = {};
  // permission: number;
  remarks: string;
  token: string;
  tokenStatus  =false;
  schoolId: number;
  @Output() assignValue = new EventEmitter<any>();

  constructor(private backendService: BackendService,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar) {   
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = this.currentUser.id;
    this.token = this.currentUser.token;
    this.schoolId = this.currentUser.currentSelSchoolId;
    this.customFilter = {
      user_id: this.currentUser.id,
      school_id: this.schoolId
    }
    let paramsVal = {user_id: this.user_id, token: this.token};
    return this.authService.chkUserToken(paramsVal).subscribe(
      data =>{
        this.tokenStatus = data.status;
        if(!this.tokenStatus){
          this.openSnackBar("You've been logged in different Computer", 'Close');
          this.authService.logout();
        } else {
          this.backendService.getSchoolUserData(this.customFilter).subscribe((data: any) => {
            // console.log(data);
            this.dataSource = new MatTableDataSource(data.data);
            //this.dataSource.paginator = this.paginator;
          });
        }
      }
    )  
    
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    // filterValue = filterValue.startsWith();
    this.dataSource.filter = filterValue;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  joinClass(){
    // this.dialogRef.close();
  }
}
