import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControlName, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { UserService } from '../../user/user.service';
import { Subscription, Observable } from 'rxjs';
import { GenericValidator } from '../../shared/generic-validator';
import { MatSnackBar, MatOption } from '@angular/material';
import { HomeworkService } from '../homework.service';
import { BackendService } from 'src/app/_services/backend.service';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import * as ClassicEditor from '../../../assets/js/ck-editor-mathtype/ckeditor.js';
// import * as stringStripHtml from 'string-strip-html';
// import stripHtml from './../../../../node_modules/string-strip-html';
import stripHtml from './../../../../node_modules/string-strip-html';


@Component({
  selector: 'app-add-homework',
  templateUrl: './add-homework.component.html',
  styleUrls: ['./add-homework.component.css']
})

export class AddHomeworkComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  @ViewChild('allSelectedSection') private allSelectedSection: MatOption;
  @ViewChild('allSelectedLesson') private allSelectedLesson: MatOption;
  @ViewChild('allSelectedTopic') private allSelectedTopic: MatOption;
  @ViewChild('allSelectedScreen') private allSelectedScreen: MatOption;
  @ViewChild('HWFile') selectFile: ElementRef;
  
  pageTitle = 'Add Homework';
  errorMessage: any;
  homeworkForm: FormGroup;
  status = { 1: 'Active', 2: 'Inactive' };
  //  status = [{id: 1, statusVal: 'Active'}, {id: 2, statusVal: 'Inactive'}];
  displayMessage: { [key: string]: string } = {};
  currentUser: any;
  token: string;
  formSubmit = false;
  userTypes: any;
  departments: any;
  userId: string;
  department: string;
  users: any;
  salesUsers: any;
  schoolList: any;
  classList: any;
  sectionList: any;
  id: any;
  classIdArr = [];
  assignedUser: any;
  // type_order: number;
  classArr = [];
  sectionArr = [];
  subjectList: any;
  lessonList: any;
  topicList: any;
  screenList: any;
  homework_id: number;
  homeworkData: any;
  showVal = false;
  fileData: File = null;
  uploadResponse = { status: '', message: '', file: '' };
  fileUploadProgress: string = null;
  apiUrl = environment.apiUrl;
  errorMsg = "";
  homeworkFile_id: number;
  readonly = false;
  created_on = "";
  submitted_on = "";
  user_subjects = [];
  subjectArr = [];
  lessonArr = [];
  topicArr = [];
  screenArr = [];
  schoolId: number;
  customFilters: any;
  student_id: number;
  selectedSubSubjects = [];
  selectedLessons = [];
  selectedTopics = [];
  selectedScreens = [];
  countryList: any;
  currentCountryIds = [];
  subSubjectList: any;
  countryMapLessonList: any;
  countryLesson = false;

  public Editor = ClassicEditor;
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  private sub: Subscription;
  public todayDate: any = new Date();
  // public Editor = ClassicEditor;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    // private userService: UserService,
    private homeworkService: HomeworkService,
    private backendService: BackendService) {
    this.validationMessages = {
      school_id: {
        required: 'school is required.'
      },
      class_id: {
        required: 'class is required.'
      },
      section_id: {
        required: 'section is required.'
      },
      subject_id: {
        required: 'subject is required.'
      },
      title: {
        required: 'Title is required.'
      },
      /*description: {
        required: 'Description is required.'
      },*/
      status: {
        required: 'status is required.'
      },
      expiration_date: {
        required: 'expiration date is required.'
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.token = this.currentUser.token;
      this.userId = this.currentUser.id;
      // this.userId = this.currentUser.adminUser[0].id;
      this.schoolId = this.currentUser.currentSelSchoolId;
      this.user_subjects = this.currentUser.user_assigned_subjects;
      this.user_subjects.forEach(sub => {
        this.subjectArr.push(sub.subject_id);
      });
      //console.log('thiscurrentUser',this.currentUser);
      this.currentCountryIds.push(this.currentUser.countryDetail.id);
      // this.type_order = this.currentUser.user_Type.type_order;
    }
    this.getCountries(this.currentCountryIds);
    this.homeworkForm = this.fb.group({
      country_id: ['', ''],
      school_id: ['', [Validators.required]],
      class_id: ['', [Validators.required]],
      section_id: ['', [Validators.required]],
      subject_id: ['', [Validators.required]],
      sub_subject_id: ['', ''],
      homework_syllabus: ['', ''],
      lesson_id: ['', ''],
      topic_id: ['', ''],
      screen_id: ['', ''],
      //level: ['', ''],
      title: ['', [Validators.required]],
      //description: ['', [Validators.required]],
      description: ['', ''],
      filepath: ['', ''],
      status: ['', [Validators.required]],
      expiration_date: ['', [Validators.required]]
    });

    this.route.queryParams.subscribe((params: any) => {
      if (params.customFilters) {
        this.customFilters = JSON.parse(params.customFilters);
        // this.school_id= this.customFilters.school_id;
        this.classArr = [this.customFilters.class_id];
        this.sectionArr = [this.customFilters.section_id];
        this.student_id = this.customFilters.student_id;
        this.homeworkForm.patchValue({
          school_id: [this.customFilters.school_id]
        });
        let classObj = {
          board_id: 1,
          class_id: this.classArr
        }
        this.backendService.getClasses(classObj).subscribe((data: any) => {
          this.classList = data.data;
          if (this.classList.length == 1) {
            this.homeworkForm.patchValue({
              class_id: [this.classList[0].id]
            });
            this.changeClass([this.classList[0].id]);
          }
        });
        //console.log(this.customFilters);
      }else{
        let obj = { user_id: this.userId }
        this.backendService.userSchools(obj).subscribe((data: any) => {
          this.classArr = data.userClass;
          this.sectionArr = data.userSection;
            this.homeworkForm.patchValue({
              school_id: [this.schoolId]
            });
          let classObj = {
            board_id: 1,
            class_id: this.classArr
          }
          this.backendService.getClasses(classObj).subscribe((data: any) => {
            this.classList = data.data;
            if (this.classList.length == 1) {
              this.homeworkForm.patchValue({
                class_id: [this.classList[0].id]
              });
              this.changeClass([this.classList[0].id]);
            }
          });
        });
      }
    })

    // let obj = { user_id: this.userId }
    // this.backendService.userSchools(obj).subscribe((data: any) => {
    //   this.classArr = data.userClass;
    //   this.sectionArr = data.userSection;
    //     this.homeworkForm.patchValue({
    //       school_id: [this.schoolId]
    //     });
    //   let classObj = {
    //     board_id: 1,
    //     class_id: this.classArr
    //   }
    //   this.backendService.getClasses(classObj).subscribe((data: any) => {
    //     this.classList = data.data;
    //     if (this.classList.length == 1) {
    //       this.homeworkForm.patchValue({
    //         class_id: [this.classList[0].id]
    //       });
    //       this.changeClass([this.classList[0].id]);
    //     }
    //   });
    // });
 
    this.route.params.subscribe(params => {
      this.homework_id = parseInt(params.homework_id);
      // console.log(typeof(this.homework_id));
      if (this.homework_id) {
        this.getHomework();
        this.pageTitle = `Edit Homework`;
      } else {
        this.pageTitle = 'Add Homework';
        this.readonly = false;
      }
    });
  }

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => Observable.fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    Observable.merge(this.homeworkForm.valueChanges, ...controlBlurs).debounceTime(800).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.homeworkForm);
    });

    //console.log('objtype',this.sl.nativeElement);
    //this.changeLesson(this.sl.nativeElement.value);
  }

  decodeEntities(str) {
    // this prevents any overhead from creating the object each time
    const element = document.createElement('div');
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
      str = str.replace(/<\/?\w(?:[^''>]|'[^']*'|'[^']*')*>/gmi, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }
    str = str.replace(/src="/g, `src="`);
    return str;
  }

  getHomework() {
    let obj = { homework_id: this.homework_id }
    this.homeworkService.getHomeWorkById(obj).subscribe((homeworkData: any) => {
      //console.log(homeworkData);
      this.homeworkData = homeworkData.data;
      //console.log('homeworkData',this.homeworkData);
      let lesson_ids = ''; let topic_ids = ''; let screen_ids = ''; let lesson_id_arr = []; let topic_id_arr = []; let screen_id_arr = [];
      this.homeworkData.homeworkDetails.forEach((hwd) => {
        lesson_ids += (hwd.lesson_id != '') ? hwd.lesson_id+',' : '';
        topic_ids += (hwd.topic_id != '') ? hwd.topic_id+',' : '';
        screen_ids += (hwd.screen_id != '') ? hwd.screen_id+',' : '';
      });
      
      if(lesson_ids != '') {
        if(lesson_ids.length > 1) {
          lesson_ids =  lesson_ids.substring(0, lesson_ids.length - 1);
        }
        lesson_id_arr = lesson_ids.split(',');
      }
      
      if(topic_ids != '') {
        if(topic_ids.length > 1) {
          topic_ids = topic_ids.substring(0, topic_ids.length - 1);
        }
        topic_id_arr = topic_ids.split(',');
      }

      if(screen_ids != '') {
        if(screen_ids.length > 1) {
          screen_ids = screen_ids.substring(0, screen_ids.length - 1);
        }
        screen_id_arr = screen_ids.split(',');
      }
      
      this.homeworkData.lesson_id = lesson_id_arr.map(function(str) {return (str != '') ? parseInt(str) : str; });
      this.homeworkData.topic_id = topic_id_arr.map(function(str) {return (str != '') ? parseInt(str) : str; });
      this.homeworkData.screen_id = screen_id_arr.map(function(str) {return (str != '') ? parseInt(str) : str; });
      //console.log('lesson_id_arr',lesson_id_arr);
      //console.log('thishomeworkDatalesson_id',this.homeworkData.lesson_id);

      this.created_on = this.homeworkData.created_on;
      this.submitted_on = this.homeworkData.submitted_on;
      this.changeClass(homeworkData.class_id);
      this.changeSubject(homeworkData.subject_id, this.currentCountryIds[0]);
      /*if(this.homeworkData.lesson_id.length >= 1) {
        this.changeSubject(homeworkData.subject_id, this.currentCountryIds[0]);
      }*/
      if(this.homeworkData.topic_id.length >= 1) {
        this.changeLesson(this.homeworkData.lesson_id);
      }
      if(this.homeworkData.screen_id.length >= 1) {
        this.changeTopic(this.homeworkData.topic_id);
      }
      let homework_syllabus_flag = false;
      //console.log('hs',this.homeworkData.homework_syllabus);
      if(this.homeworkData.homework_syllabus == 1) {
        homework_syllabus_flag = true;
      }
      //this.homeworkForm.controls.homework_syllabus.patchValue(homework_syllabus_flag);

      //console.log('thishomeworkData',this.homeworkData);

      const stripOption = {
        ignoreTags: ['xml'],
        onlyStripTags: ['span'],
        stripTogetherWithTheirContents: ['script'],
        skipHtmlDecoding: false,
        returnRangesOnly: false,
        trimOnlySpaces: false,
        dumpLinkHrefsNearby: {
          enabled: false,
          putOnNewLine: false,
          wrapHeads: '',
          wrapTails: ''
        }
      };

      let description = this.decodeEntities(this.homeworkData.description);
      description = description.replace(/\r?\n|\r/g, '');
      description = description.replace(/>\s+</g, '><');
      let qstr1 = description.indexOf('annotation');
      do {
        if (description.indexOf('/annotation') > -1) {
          const qstr3 = '/annotation';
          const qstr2 = description.indexOf('/annotation') + qstr3.length + 2;
          const qres = description.substring(qstr1, qstr2);
          description = description.replace(qres, '');
        }
        qstr1 = description.indexOf('annotation');
      } while (qstr1 !== -1);
      description = stripHtml(description, stripOption);
      // description = description.replace(/src="/g, imgValUrl);


      this.homeworkForm.patchValue({
        title: this.homeworkData.title,
        description: description,
        expiration_date: this.homeworkData.expiration_date,
        country_id: this.homeworkData.country_id,
        school_id: homeworkData.school_id,
        class_id: homeworkData.class_id,
        section_id: homeworkData.section_id,
        subject_id: homeworkData.subject_id,
        homework_syllabus: homework_syllabus_flag, //this.homeworkData.homework_syllabus,
        sub_subject_id: this.homeworkData.sub_subject_id,
        lesson_id: this.homeworkData.lesson_id,
        topic_id: this.homeworkData.topic_id,
        screen_id: this.homeworkData.screen_id,
        //level: this.homeworkData.level,
        status: this.homeworkData.status
      });
      //console.log('thishomeworkForm', this.homeworkForm);
      if (this.homeworkData.homeworkFiles.length > 0) {
        this.homeworkFile_id = this.homeworkData.homeworkFiles[0].id;
        this.homeworkForm.patchValue({
          filepath: this.homeworkData.homeworkFiles[0].filepath
        });
      }

    });
  }

  saveHomework(): void {
    // if (this.homeworkForm.dirty && this.homeworkForm.valid) {
    //if (this.homework_id) {
      let obj = Object.assign({}, { created_by: this.userId, user_id: this.student_id }, { homework_id: this.homework_id }, { homeworkFile_id: this.homeworkFile_id }, this.homeworkForm.value);
      //console.log('obj',obj);
        if(!obj.homework_syllabus) {
          obj.lesson_id = [];
          obj.topic_id = [];
          obj.screen_id = [];
          obj.homework_syllabus = 0;
        } else {
          obj.homework_syllabus = 1;
        }

        if(obj.homework_syllabus == 1 && obj.lesson_id.length == 0) {
          this.openSnackBar('Please select the content syllabus', 'Close');
        }

      //this.homeworkService.editHomeWork(obj).subscribe((data: any) => {
      this.homeworkService.addHomeWork(obj).subscribe((data: any) => {  
        if (data.status)
          this.onSaveComplete("Homework Saved successfully");
      })
    /*} else {
      let obj = Object.assign({}, { created_by: this.userId, user_id: this.student_id }, this.homeworkForm.value);
      
      if(!obj.homework_syllabus) {
        obj.lesson_id = [];
        obj.topic_id = [];
        obj.screen_id = [];
        obj.homework_syllabus = 0;
      } else {
        obj.homework_syllabus = 1;
      }
      //console.log('objhomework_syllabus', obj);
      this.homeworkService.addHomeWork(obj).subscribe((data: any) => {
        if (data.status)
          this.onSaveComplete("Homework added successfully");
      })
    }*/
    // }
  }

  onSaveComplete(message): void {
    this.openSnackBar(message, 'Close');
    this.router.navigate(['/teacherHomework']);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  onFormSubmit(): void {
    this.formSubmit = true;
  }

  getCountries(country_ids) {
    const paramsVal = {country_ids: country_ids};
    this.backendService.getCountries(paramsVal).subscribe((result: any) => {
        this.countryList = result.data;
    });
  }

  changeClass(class_id) {
    this.classIdArr = class_id;
    let classObj = { class_id: this.classIdArr, section_id: this.sectionArr, subject_id: this.subjectArr }
    this.backendService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
    });
    this.backendService.getSubjects(classObj).subscribe((result: any) => {
      this.subjectList = result.data;
    });
  }

  changeSubject(subject_id, country_id) {
    let subjectObj = { subject_id: subject_id, country_id: country_id }
    //console.log('changeSubject',subjectObj);
    switch(country_id) {
      case 1:
        this.changeSubject1(subjectObj);
        this.countryLesson = false;
        break;

      case 2:
        this.changeCountryLesson(subject_id, country_id);
        this.countryLesson = true;
        break;

      default:
        this.changeSubject1(subjectObj);
        this.countryLesson = false;
        break;  
    }
  }

  changeSubject1(subjectObj) {
    this.backendService.getLessons(subjectObj).subscribe((result: any) => {
      this.lessonList = result.data;
      this.lessonList.forEach((element) => {
        this.selectedLessons.push(element.id);
        if(this.lessonList.length == this.selectedLessons.length) {
          this.changeLesson(this.selectedLessons);
        }
      });
    });
  }

  changeCountryLesson(subject_id, country_id) {
    let subjectObj = { subject_id: subject_id, country_id: country_id }
    //console.log('changeCountryLesson',subjectObj);
    this.backendService.getCountryLessons(subjectObj).subscribe((result: any) => {
      this.subSubjectList = result.data;
      this.subSubjectList.forEach((element) => {
        this.selectedSubSubjects.push(element.id);
        //console.log(this.subSubjectList.length+" == "+this.selectedSubSubjects.length);
        if(this.subSubjectList.length == this.selectedSubSubjects.length) {
          this.changeSubSubject(this.selectedSubSubjects, '', subject_id, country_id);
        }
      });
    });
  }

  changeSubSubject(sub_subject_id, lesson_id, subject_id, country_id) {
    let subjectObj = { country_lesson_id: sub_subject_id, lesson_id: lesson_id }
    //console.log('subsubjectObj',subjectObj);
    this.backendService.getCountryMapLessons(subjectObj).subscribe((result: any) => {
      this.countryMapLessonList = result.data;
      this.countryMapLessonList.forEach((element) => {
        this.selectedLessons.push(element.lesson_id);
        //console.log(this.countryMapLessonList.length+" == "+this.selectedLessons.length);
        if(this.countryMapLessonList.length == this.selectedLessons.length) {
          this.changeSubject1({ subject_id: subject_id, lesson_id: this.selectedLessons, country_id: country_id });
        }
        
      });
    });
  }

  changeLesson(lesson_id) {
    let lessonObj = { lesson_id: lesson_id }
    //console.log('lessonObj', lessonObj);
    this.backendService.getTopics(lessonObj).subscribe((result: any) => {
      this.topicList = result.data;
      this.topicList.forEach((element) => {
        this.selectedTopics.push(element.id);
        if(this.topicList.length == this.selectedTopics.length) {
          this.changeTopic(this.selectedTopics);
        }
      });
    });
  }

  changeTopic(topic_id) {
    let topicObj = { topic_id: topic_id }
    this.backendService.getScreens(topicObj).subscribe((result: any) => {
      //console.log(result.data);
      this.screenList = result.data;
      this.screenList.forEach((element) => {
        this.selectedScreens.push(element.id);
      });
    });
  }
  

  selectAllSection() {
    let sectionVal = [0];
    if (this.allSelectedSection.selected) {
      this.homeworkForm.controls.section_id
        .patchValue([...this.sectionList.map(item => item.id), 0]);
      sectionVal = this.homeworkForm.controls.section_id.value;
    } else {
      this.homeworkForm.controls.section_id.patchValue([]);
    }
  }

  selectAllLesson() {
    let lessonVal = [0];
    if (this.allSelectedLesson.selected) {
      this.homeworkForm.controls.lesson_id
        .patchValue([...this.lessonList.map(item => item.id), 0]);
      lessonVal = this.homeworkForm.controls.lesson_id.value;
    } else {
      this.homeworkForm.controls.lesson_id.patchValue([]);
    }
  }

  selectAllTopic() {
    let topicVal = [0];
    if (this.allSelectedTopic.selected) {
      this.homeworkForm.controls.topic_id
        .patchValue([...this.topicList.map(item => item.id), 0]);
        topicVal = this.homeworkForm.controls.topic_id.value;
    } else {
      this.homeworkForm.controls.topic_id.patchValue([]);
    }
  }

  selectAllScreen() {
    let screenVal = [0];
    if (this.allSelectedScreen.selected) {
      this.homeworkForm.controls.screen_id
        .patchValue([...this.screenList.map(item => item.id), 0]);
        screenVal = this.homeworkForm.controls.screen_id.value;
    } else {
      this.homeworkForm.controls.screen_id.patchValue([]);
    }
  }


  // mp4
  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.homeworkForm.controls.filepath.setValue(null);
      this.showVal = false;
      this.uploadResponse.message = '';
      this.fileData = event.target.files[0] as File;
      const fileName = this.fileData.name;
      if (fileName.indexOf('.zip') > 0 || fileName.indexOf('.jpg') > 0 ||
        fileName.indexOf('.pdf') > 0 || fileName.indexOf('.txt') > 0 ||
        fileName.indexOf('.png') > 0 || fileName.indexOf('.jpeg') > 0 ||
        fileName.indexOf('.mp4') > 0 || fileName.indexOf('.html') > 0) {
        this.errorMessage = "";
      }
      else {
        this.fileUploadProgress = '';
        this.errorMessage = "Only .pdf, .jpg, .png, .txt, .gif, mp4, html files are allowed"
        return;
      }
      const formData = new FormData();
      // console.log(this.fileData);
      formData.append('files', this.fileData);
      this.fileUploadProgress = '0%';
      // console.log(formData);
      this.http.post(`${this.apiUrl}/containers/upload`, formData, {
        reportProgress: true,
        observe: 'events'
      })
        .subscribe(events => {
          if (events.type === HttpEventType.UploadProgress) {
            this.fileUploadProgress = Math.round((events.loaded / events.total) * 100) + '%';
            // console.log(this.fileUploadProgress);
          } else if (events.type === HttpEventType.Response) {
            let response;
            response = events.body;
            // console.log(response.url);
            // this.uploadResponse.message = response.message;
            // this.uploadResponse.file = response.file;
            this.homeworkForm.controls.filepath.setValue(response.url);
          }
        });
    }
  }

  editableForm() {
    this.readonly = false;
  }

  openHW() {
    window.open(this.homeworkForm.value.filepath, '_blank');
  }

  deleteFile() {
    var result = confirm("Are you sure, you want to delete the homework file?");
    if (result) {
      let obj = { homework_id: this.homework_id, remark_id: 0 }
      this.homeworkService.deleteHWFile(obj).subscribe((data: any) => {
        if (data.status) {
          this.homeworkFile_id = undefined;
          this.homeworkForm.controls.filepath.setValue(null);
        }
      });
    }
  }

  removeFile() {
    var result = confirm("Are you sure, you want to delete the homework file?");
    if (result) {
      let HWFile = this.homeworkForm.value.filepath
      let homework = HWFile.split("/");
      let splitFile = homework[homework.length-2] + '/' + homework[homework.length-1];
      let obj = { filepath: splitFile }
      this.homeworkService.removeHWfile(obj).subscribe((response: any) => {
        if(response.status){
          this.selectFile.nativeElement.value = '';
          this.fileUploadProgress = null;
          this.homeworkForm.controls.filepath.setValue(null);
        }
      });
    }
  }

}
