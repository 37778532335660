import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchoolSubscriptionComponent } from './master-subscription/school-subscription/school-subscription.component';
import { UserSubscriptionComponent } from './master-subscription/user-subscription/user-subscription.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../shared/material.module';
import { RouterModule } from '@angular/router';
import { MathModule } from '../math/math.module';
import { HttpClientModule } from '@angular/common/http';
import { MasterSubscriptionComponent } from './master-subscription/master-subscription.component';
import { PaymentSubscriptionComponent } from './payment-subscription/payment-subscription.component';
import { AddSchoolSubscriptionComponent } from './master-subscription/school-subscription/add-school-subscription/add-school-subscription.component';
import { SubscriptionDetailComponent } from './master-subscription/user-subscription/subscription-detail/subscription-detail.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SubPaymentConfigComponent } from './master-subscription/user-subscription/sub-payment-config/sub-payment-config.component';
import { PaymentConfigListComponent } from './master-subscription/user-subscription/payment-config-list/payment-config-list.component';
import { PayConfigListDataComponent } from './master-subscription/user-subscription/payment-config-list/pay-config-list-data.component';
import { SubscriptionComponent } from './subscription.component';
import { RazorpayComponent } from './payment-subscription/razorpay/razorpay.component';
import { PaymentSuccessComponent } from './payment-subscription/payment-success/payment-success.component';
import { PaymentTrialSuccessComponent } from './payment-subscription/payment-trial-success/payment-trial-success.component';
import { PaymentErrorComponent } from './payment-subscription/payment-error/payment-error.component';
import { PaymentCouponComponent } from './payment-subscription/payment-coupon/payment-coupon.component';
import { AddUserSubscriptionComponent } from './master-subscription/user-subscription/add-user-subscription/add-user-subscription.component';
import { CouponSubscriptionComponent } from './coupon-subscription/coupon-subscription.component';
import { MatTableExporterModule } from 'mat-table-exporter';

const routes = [
  { path: 'schoolSubscription', component: SchoolSubscriptionComponent },
  { path: 'addschoolSubscription', component: AddSchoolSubscriptionComponent },
  { path: 'editschoolSubscription/:schoolSubId', component: AddSchoolSubscriptionComponent },
  { path: 'userSubscription', component: UserSubscriptionComponent},
  { path: 'subscriptionDetail/:user_id', component: SubscriptionDetailComponent},
  { path: 'PaymentConfigList/:user_id', component: PaymentConfigListComponent},
  { path: 'subPaymentConfig/:user_id/:school_id/:class_id', component: SubPaymentConfigComponent},
  { path: 'paymentSubscription', component: PaymentSubscriptionComponent},
  { path: 'paymentSuccess', component: PaymentSuccessComponent},
  { path: 'paymentTrialSuccess', component: PaymentTrialSuccessComponent},
  { path: 'paymentError', component: PaymentErrorComponent},
  { path: 'paymentCoupon', component: PaymentCouponComponent},
  { path: 'addUserSubscription', component: AddUserSubscriptionComponent },
  { path: 'couponSubscription', component: CouponSubscriptionComponent }
];

@NgModule({
  declarations: [
    SchoolSubscriptionComponent, 
    UserSubscriptionComponent, 
    MasterSubscriptionComponent, 
    PaymentSubscriptionComponent, 
    AddSchoolSubscriptionComponent, 
    SubscriptionDetailComponent, 
    SubPaymentConfigComponent, 
    PaymentConfigListComponent, 
    PayConfigListDataComponent,
    SubscriptionComponent,
    RazorpayComponent,
    PaymentSuccessComponent,
    PaymentTrialSuccessComponent,
    PaymentErrorComponent,
    PaymentCouponComponent,
    AddUserSubscriptionComponent,
    CouponSubscriptionComponent
  ],
  imports: [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  RouterModule.forChild(routes),
  MathModule.forRoot(),
  HttpClientModule,
  OwlDateTimeModule, 
  OwlNativeDateTimeModule,
  MatTableExporterModule
]
})

export class SubscriptionModule { }
