import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatSnackBar,
  MatPaginator,
  MatTableDataSource,
  MatSort,
  MatDialogConfig,
  PageEvent
} from '@angular/material';
import { Observable, merge } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Http, Response } from '@angular/http';
import { QuizSetService } from './quiz-set.service';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { BackendService } from '../_services/backend.service';

@Component({
  selector: 'app-quiz-set',
  templateUrl: './quiz-set.component.html',
  styleUrls: ['./quiz-set.component.css']
})
export class QuizSetComponent implements OnInit {

  pageTitle = 'Quizzes';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  pageEvent: PageEvent;

  private apiUrl = environment.apiUrl;
  displayedColumns = ['set_name', 'status', 'created_on', 'id'];
  searchFilter: any = {
    setName: '',
    created_by: '',
    school_id: [],
    class_id: [],
    section_id: [],
    limit: 0,
    offset: 0
  };
  customFilters: any = null;
  quizSets: any;
  quizSetData: any;
  errorMessage: string;
  dataSource: any;
  userId: number;
  token: string;
  currentUser: any;
  user_type_order: any;
  limit = 10;
  offset = 0;
  total_count: number;
  hostName: string;
  protocol: string;
  schoolArr = [];
  classArr = [];
  sectionArr = [];
  type_order: number;
  schoolList: any;
  classList: any;
  sectionList: any;
  userData: any;
  currentSelSchoolId: number;

  constructor(
    private http: Http,
    private snackBar: MatSnackBar,
    private quizSetService: QuizSetService,
    private backendService: BackendService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      // this.user_type_order = this.currentUser.user_Type.type_order;
      this.userId = this.currentUser.adminUser[0].id;
      this.currentSelSchoolId = this.currentUser.currentSelSchoolId;
      // this.customFilters = { 
      //   created_by: this.userId,
      //   offset: this.paginator.pageIndex * this.paginator.pageSize,
      //   limit: this.paginator.pageSize
      // };      
      // this.route.queryParams.subscribe(
      //   params => {
      //     if (params.customFilters) {
      //       this.customFilters = JSON.parse(params.customFilters);
      //       this.offset = this.paginator.pageIndex = (this.customFilters.offset/this.customFilters.limit);
      //       this.limit = this.paginator.pageSize = this.customFilters.limit;
      //     }
      //   }
      // );
      // this.getQuizSets();
    }


    this.route.queryParams.subscribe((params: any) => {
      if (params.customFilters) {
        this.customFilters = JSON.parse(params.customFilters);
         //console.log(this.customFilters);
        // this.customFilters.school_id = this.currentSelSchoolId
        this.searchFilter = this.customFilters;
        this.sectionArr = this.customFilters.section_id
        if (this.customFilters.class_id != '') {
          this.changeClass(this.customFilters.class_id);
        }
        this.offset = this.paginator.pageIndex = (this.customFilters.offset / this.customFilters.limit);
        this.limit = this.paginator.pageSize = this.customFilters.limit;
      } else {
        this.customFilters = {};
      }
    })
    if (this.type_order == 1 || this.type_order == 2) {
      this.backendService.getActiveSchools(this.userId).subscribe((data: any) => {
        this.schoolList = data.data;
        this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
        this.getUserClasses();
        this.getQuizSets();
      });
    } else {
        this.userData = this.currentUser.userData;
        // console.log(this.customFilters)
          this.userData.forEach(userVal => {
            if (this.classArr.indexOf(userVal.class.id) == -1) {
              this.classArr.push(userVal.class.id);
            }
            if (this.sectionArr.indexOf(userVal.section_id) == -1) {
              this.sectionArr.push(userVal.section_id);
            }
          });
       
          this.schoolList = this.currentUser.userSchools
          this.schoolList.forEach(school => {
            this.schoolArr.push(school.id);
          });
        // }
       
        this.getUserClasses();
        // console.log(this.customFilters);
        if (Object.keys(this.customFilters).length === 0) {
          //this.searchFilter.school_id =  [this.currentSelSchoolId]
          this.customFilters = {
             school_id: this.schoolArr,
            // school_id: [this.currentSelSchoolId],
            class_id: this.classArr,
            section_id: this.sectionArr,
            limit: this.paginator.pageSize,
            offset: this.paginator.pageIndex * this.paginator.pageSize
          }
        }
        this.getQuizSets();
    }

  }

  getUserClasses() {
    let classObj = {
      board_id: 1,
      class_id: this.classArr
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
    });
  }


  ngAfterViewInit() {

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
    .pipe(
    tap(() => {
        this.getQuizSets();
        })
    )
    .subscribe();
}


  getQuizSets() {
    this.customFilters = { 
      created_by: this.userId,
      setName: this.customFilters.setName,
      school_id: this.customFilters.school_id,
      class_id: this.customFilters.class_id,
      section_id: this.customFilters.section_id,
      offset: this.paginator.pageIndex * this.paginator.pageSize,
      limit: this.paginator.pageSize
    };
     //console.log(this.customFilters);
    this.quizSetService.getQuizSets(this.customFilters)
      .subscribe(quizSets => {
        // console.log(quizSets);
        this.quizSetData = quizSets;
        this.total_count = this.quizSetData.total_count;
        this.freshDataList(this.quizSetData.data);
        return quizSets;
      },
        error => this.errorMessage = error as any);
  }

  searchQuizSets(filters: any) {
    this.resetPageIndex();
    if (filters) {
      if (!filters.school_id || filters.school_id.length <= 0) {
        filters.school_id = this.schoolArr;
      }
      if (!filters.class_id || filters.class_id.length <= 0) {
        filters.class_id = this.classArr;
      }
      if (!filters.section_id || filters.section_id.length <= 0) {
        filters.section_id = this.sectionArr;
      }
      filters.limit = this.paginator.pageSize;
      filters.offset = this.paginator.pageIndex * this.paginator.pageSize;
      this.customFilters = filters;
      this.getQuizSets();
    }
  }

  freshDataList(quizData) {
    this.quizSetData = quizData;
    this.dataSource = new MatTableDataSource(this.quizSetData);
    //this.dataSource.paginator = this.paginator;
   // this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  reset() {
    this.resetPageIndex();
    this.searchFilter = {};
    this.customFilters = { 
      school_id: this.schoolArr,
      class_id: this.classArr,
      section_id: this.sectionArr,
      created_by: this.userId,
      limit: this.limit,
      offset: this.offset
    };
    this.getQuizSets();
  }

  resetPageIndex() {
    // reset the paginator
    this.paginator.pageIndex = 0;
  }

  resetSearchFilter(searchPanel: any) {
    this.resetPageIndex();
   // searchPanel.toggle();
    this.searchFilter = {};
    this.customFilters = { created_by: this.userId };
    //this.getQuizSets();
  }

  deleteQuiz(quiz_id) {
    var result = confirm("Are you sure, you want to delete the quiz set?");
    if (result) {
      let obj = { quiz_id: quiz_id }
      this.quizSetService.deleteQuiz(obj).subscribe((result: any) => {
        if (result.status) {
          this.getQuizSets();
          this.openSnackBar('QuizSet deleted successfully. ', 'Close');
        }
      },
        error => this.errorMessage = error as any);
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }

  copyClipboard(quizSetId){
    // console.log(quizSetId);
    quizSetId = btoa(quizSetId)
    this.hostName = window.location.hostname;
    this.protocol = window.location.protocol;
    // let url = this.protocol + '//' + this.hostName + '/attempt-quiz/' + quizSetId
    let url = this.protocol + '//' + this.hostName + '/#/attempt-quiz/' + quizSetId
    
    const selBox = document.createElement('textarea');
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    // console.log(url);
    this.openSnackBar('Quiz Set URL Copied. ', 'Close');
  }

  changeClass(class_id) {
    // this.sectionIdArr = [class_id];
    let classObj = { class_id: class_id, section_id: this.sectionArr }
    this.backendService.getClassSections(classObj).subscribe((result: any) => {
      this.sectionList = result.data;
    })
  }

}
