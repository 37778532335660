import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../user.service';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { FormBuilder, FormControlName, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GenericValidator } from 'src/app/shared/generic-validator';
import { MatSnackBar } from '@angular/material';
import { BackendService } from 'src/app/_services/backend.service';
import { AuthenticationService } from 'src/app/_services';
import { th } from 'date-fns/locale';

@Component({
  selector: 'app-add-user-invoice',
  templateUrl: './add-user-invoice.component.html',
  styleUrls: ['./add-user-invoice.component.css']
})
export class AddUserInvoiceComponent implements OnInit {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  displayMessage: { [key: string]: string } = {};
  private validationMessages: { [key: string]: { [key: string]: string } };
  private genericValidator: GenericValidator;
  user_id: any;
  user_name: string;
  errorMessage: any;
  currentUser: any;
  invoiceForm: FormGroup;
  countryList: any;
  admin_id: any;
  schoolList: any;
  classList: any;
  userEmail: any;
  class_id: any;
  school_id: any;
  order_id: any;
  frequencyTypeList: any;
  product: string;
  sku: string;
  userSubcriptionData: any;
  loginUser_name: string;
  isAmountEditable: boolean = true;
  isCheckoutAmountEditable: boolean = true;
  setReadOnly: boolean = false;
  showGSTField: boolean = false;

  selectedCountryId: any;
  statesList: any;
  statesListIndonesia: any;



  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private userService: UserService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private snackBar: MatSnackBar,
    private backendService: BackendService,
    private authenticationService: AuthenticationService
  ) {
    this.validationMessages = {
      user_id: {
        required: 'User Id required.'
      },
      order_id: {
        required: 'Order Id is required.'
      },
      firstName: {
        required: 'First name is required.'
      },
      address: {
        required: 'Address is required.'
      },
      city: {
        required: 'City is required.'
      },
      country_id: {
        required: 'Country is required.'
      },
      state: {
        required: 'State is required.'
      },
      pincode: {
        required: 'Pincode is required.'
      },
      contactNumber: {
        required: 'Contact number is required.'
      },
      email: {
        required: 'Email is required.'
      },
      product: {
        required: 'Product is required.'
      },
      payment_type: {
        required: 'Payment type is required.'
      },
      price: {
        required: 'Price is required.'
      },
      quantity: {
        required: 'Quantity is required.'
      },
      tax_amount: {
        required: 'Tax amount is required.'
      },
      // discount_amount: {
      //   required: 'Discount amount is required.'
      // },
      total_amount: {
        required: 'Total amount is required.'
      },
      received_by: {
        required: 'Received By is required.'
      },
      no_of_installment: {
        required: 'Number of EMI is required.'
      },
      emi_amount: {
        required: 'EMI amount is required.'
      },
      down_payment: {
        required: 'Down payment is required.'
      },
      down_payment_type: {
        required: 'Down payment type is required.'
      },
      payment_response_id: {
        required: 'Payment response id is required.'
      },
      cheque_receipt_no: {
        required: 'Cheque number is required.'
      },
      receipt_no: {
        required: 'Receipt number is required.'
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    // this.user_id = this.currentUser.id;
    if (this.currentUser) {
      // this.user_id = this.currentUser.id;
      // console.log(this.currentUser)
      this.admin_id = this.currentUser.adminUser[0].id;
      this.loginUser_name = this.currentUser.adminUser[0].firstName + ' ' + this.currentUser.adminUser[0].lastName;
    }
    this.subscriptionService.getAllFrequencyType({}).subscribe((data: any) => {
      // console.log(data);
      this.frequencyTypeList = data.data;
    })

    this.userService.getCountries().subscribe((data: any) => {
      this.countryList = data.data;
    })

    let indObj = {
      country_id : 1
    }
    this.userService.getStates(indObj).subscribe((data: any) => {
      this.statesList = data;
    })


    let indoObj = {
      country_id : 2
    }
    this.userService.getStates(indoObj).subscribe((data: any) => {
      this.statesListIndonesia = data;
    })


    let classObj = {
      board_id: 1
    }
    this.backendService.getClasses(classObj).subscribe((data: any) => {
      this.classList = data.data;
      // console.log(this.classList)
    });
    this.backendService.getActiveSchools({}).subscribe((data: any) => {
      this.schoolList = data.data;
      //this.schoolList.forEach(element => { this.schoolArr.push(element.id) });
    });


    this.invoiceForm = this.fb.group({
      order_id: ['', [Validators.required]],
      user_id: ['', [Validators.required]],
      school_id: ['', [Validators.required]],
      class_id: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', ''],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country_id: ['', [Validators.required]],
      state: ['', Validators.required],
      pincode: ['', [Validators.required]],
      contactNumber: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required]],
      payment_type: ['', [Validators.required]],
      price: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      tax_amount: ['', [Validators.required]],
      discount_amount: ['', ''],
      total_amount: ['', [Validators.required]],
      received_by: ['', ''],
      payment_response_id: ['', ''],
      emi_amount: ['', ''],
      no_of_installment: ['', ''],
      down_payment: ['', ''],
      checkout_amount: ['', ''],
      gst_no: ['', ''],
      state_id: ['', ''],
      cheque_receipt_no: ['', ''],
      down_payment_type: ['', '']
    });


    this.invoiceForm.get('checkout_amount').valueChanges.subscribe(value => {
      if (value === 0) {
        this.invoiceForm.controls.payment_type.setValue('demo');
        this.setReadOnly = true;

      } else {
        this.setReadOnly = false;
        this.invoiceForm.controls.payment_type.reset();
      }
    });

    this.invoiceForm.get('country_id').valueChanges.subscribe(value => {
      if (value === 1) {
        this.selectedCountryId = value;

      } else {
        this.selectedCountryId = value;
      }
    });


    this.invoiceForm.get('payment_type').valueChanges.subscribe(value => {
      if (value === 'cod') {
        this.invoiceForm.controls.received_by.setValue(this.loginUser_name);
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();

      } else if (value === 'razorpay') {
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('received_by').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();
      } else if (value === 'bajajfin' || value === 'tcpl') {
        this.invoiceForm.get('received_by').reset();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();

      } else if (value === 'demo') {
        this.invoiceForm.get('received_by').reset();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();
      }else if (value === 'cheque') {
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('received_by').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();
      }else if (value === 'doku') {
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('received_by').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();
      }

    });

    this.invoiceForm.get('payment_type').valueChanges.subscribe(value => {
      if (value === 'cod') {
        this.invoiceForm.get('received_by').setValidators([Validators.required]);
        this.invoiceForm.get('cheque_receipt_no').setValidators([Validators.required]);
        this.invoiceForm.get('payment_response_id').clearValidators();
        this.invoiceForm.get('emi_amount').clearValidators();
        this.invoiceForm.get('no_of_installment').clearValidators();
        this.invoiceForm.get('down_payment').clearValidators();
        this.invoiceForm.get('down_payment_type').clearValidators();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();
      } else if (value === 'bajajfin' || value === 'tcpl') {
        this.invoiceForm.get('cheque_receipt_no').clearValidators();
        this.invoiceForm.get('received_by').clearValidators();
        this.invoiceForm.get('payment_response_id').clearValidators();
        this.invoiceForm.get('emi_amount').setValidators([Validators.required]);
        this.invoiceForm.get('no_of_installment').setValidators([Validators.required]);
        this.invoiceForm.get('down_payment').setValidators([Validators.required]);
        this.invoiceForm.get('down_payment_type').setValidators([Validators.required]);
        this.invoiceForm.get('received_by').reset();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();
      } else if (value === 'razorpay') {
        this.invoiceForm.get('payment_response_id').setValidators([Validators.required]);
        this.invoiceForm.get('cheque_receipt_no').clearValidators();
        this.invoiceForm.get('received_by').clearValidators();
        this.invoiceForm.get('emi_amount').clearValidators();
        this.invoiceForm.get('no_of_installment').clearValidators();
        this.invoiceForm.get('down_payment').clearValidators();
        this.invoiceForm.get('down_payment_type').clearValidators();
        this.invoiceForm.get('received_by').reset();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();
      } else if (value === 'demo') {
        this.invoiceForm.get('cheque_receipt_no').clearValidators();
        this.invoiceForm.get('payment_response_id').clearValidators();
        this.invoiceForm.get('received_by').clearValidators();
        this.invoiceForm.get('emi_amount').clearValidators();
        this.invoiceForm.get('no_of_installment').clearValidators();
        this.invoiceForm.get('down_payment').clearValidators();
        this.invoiceForm.get('down_payment_type').clearValidators();
        this.invoiceForm.get('received_by').reset();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();
      } else if (value === 'cheque') {
        this.invoiceForm.get('cheque_receipt_no').setValidators([Validators.required]);
        this.invoiceForm.get('payment_response_id').clearValidators();
        this.invoiceForm.get('received_by').clearValidators();
        this.invoiceForm.get('emi_amount').clearValidators();
        this.invoiceForm.get('no_of_installment').clearValidators();
        this.invoiceForm.get('down_payment').clearValidators();
        this.invoiceForm.get('down_payment_type').clearValidators();
        this.invoiceForm.get('received_by').reset();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();
      }else if (value === 'doku') {
        this.invoiceForm.get('payment_response_id').setValidators([Validators.required]);
        this.invoiceForm.get('cheque_receipt_no').clearValidators();
        this.invoiceForm.get('received_by').clearValidators();
        this.invoiceForm.get('emi_amount').clearValidators();
        this.invoiceForm.get('no_of_installment').clearValidators();
        this.invoiceForm.get('down_payment').clearValidators();
        this.invoiceForm.get('down_payment_type').clearValidators();
        this.invoiceForm.get('received_by').reset();
        this.invoiceForm.get('cheque_receipt_no').reset();
        this.invoiceForm.get('payment_response_id').reset();
        this.invoiceForm.get('emi_amount').reset();
        this.invoiceForm.get('no_of_installment').reset();
        this.invoiceForm.get('down_payment').reset();
        this.invoiceForm.get('down_payment_type').reset();
      }


      this.invoiceForm.get('received_by').updateValueAndValidity();
      this.invoiceForm.get('cheque_receipt_no').updateValueAndValidity();
      this.invoiceForm.get('payment_response_id').updateValueAndValidity();
      this.invoiceForm.get('emi_amount').updateValueAndValidity();
      this.invoiceForm.get('no_of_installment').updateValueAndValidity();
      this.invoiceForm.get('down_payment').updateValueAndValidity();
      this.invoiceForm.get('down_payment_type').updateValueAndValidity();
    });

    this.route.params.subscribe(params => {
      this.user_id = params['user_id'];
      this.order_id = params['order_id'];
      this.invoiceForm.controls.user_id.setValue(this.user_id)
      this.invoiceForm.controls.order_id.setValue(this.order_id)
    });
    //console.log(this.user_id);
    this.getUser(this.user_id);
    this.getSubscription(this.order_id)
  }

  requireOneValidator(fieldNames: string[]): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      const fields = fieldNames.map(name => group.controls[name]);
      const hasValue = fields.some(control => control.value !== null && control.value !== '');
      return hasValue ? null : { requireOne: true };
    };
  }

  getUser(user_id: string): void {
    this.userService.getUser(this.user_id).subscribe((user: any) => {
      //console.log(user)
      this.user_name = user.firstName + ' ' + user.lastName;
      //console.log(this.user_name)
      this.userEmail = user.email,
      this.invoiceForm.patchValue({
        country_id: user.user_countries[0].country_id,
      })
      this.invoiceForm.controls.country_id.disable();
      localStorage.setItem('invoiceuser_country_id', user.user_countries[0].country_id);
      //console.log(this.user_name)
      this.getUserDetails();
    },
      (error: any) => this.errorMessage = error as any
    );
  }

  getUserDetails() {
    let obj = {
      username: this.userEmail
    }
    this.authenticationService.getUserDetails(obj).subscribe(
      data => {
        //console.log(data)
        // console.log(data.data.contactNumber)
        this.class_id = data.data.userData[0].class_id;
        this.school_id = data.data.userData[0].school_id;
        this.invoiceForm.patchValue({
          firstName: data.data.firstName,
          lastName: data.data.lastName,
          email: data.data.email,
          contactNumber: this.extractNumbersFromString(data.data.contactNumber),
         // country_id: data.countryDetail.id,
          city: data.data.userData[0].city
        })
      });
  }


  extractNumbersFromString(input: string): string {
    return input.replace(/[^\d+]/g, '');
  }

  getSubscription(order_id) {
    // console.log(order_id)
    let obj = {
      subscription_id: this.order_id
    }
    this.subscriptionService.getSubDetails(obj).subscribe((data: any) => {
      if (data.status) {
        this.userSubcriptionData = data.data[0]
        let userSubData = data.data[0]
        // console.log(userSubData);

        const sub_amount = (userSubData.amount - userSubData.discount_amt);
        const sub_tax_value = (sub_amount * 0.18)
        if (this.userSubcriptionData.payment_response_id === 'cod') {
          this.invoiceForm.patchValue({
            payment_type: this.userSubcriptionData.payment_response_id
          })
        } else if (this.userSubcriptionData.payment_response_id === 'bajajfin') {
          this.invoiceForm.patchValue({
            payment_type: this.userSubcriptionData.payment_response_id
          })
        } else if (this.userSubcriptionData.payment_response_id === 'demo') {
          this.invoiceForm.patchValue({
            payment_type: this.userSubcriptionData.payment_response_id
          })
        } else {
          this.invoiceForm.patchValue({
            payment_type: 'razorpay'
          })
        }
        this.invoiceForm.patchValue({
          school_id: userSubData.school_id,
          class_id: userSubData.class_id,
          price: userSubData.amount,
          discount_amount: userSubData.discount_amt,
          quantity: 1,
          tax_amount: userSubData.gst_amount,
          total_amount: sub_amount,
          checkout_amount: userSubData.paid_amount
        })
      }
    })
  }

  amountFunc() {

    const price = this.invoiceForm.controls.price.value;
    const quantity = this.invoiceForm.controls.quantity.value;
    //let tax_amount = this.invoiceForm.controls.tax_amount.value;
    let discount_amount = this.invoiceForm.controls.discount_amount.value;

    const amount = (price * quantity);

    if (amount > 0 && discount_amount > amount) {
      //tax_amount = 0;
      discount_amount = 0;
      this.invoiceForm.controls.discount_amount.setValue(undefined);
    }  //else if (discount_amount === amount) {
    //   tax_amount = 0;
    // }
    let amt = amount - discount_amount;
    const tax_value = (amt * 0.18);
    const total_amount = amt + tax_value;
    const exc_amoont = amt;
    this.invoiceForm.controls.tax_amount.setValue(tax_value);
    this.invoiceForm.controls.total_amount.setValue(amt);
    this.invoiceForm.controls.checkout_amount.setValue(total_amount);

  }



  onDownPaymentChange(value: string) {
    //console.log(value);
      if(this.invoiceForm.value.payment_type === 'bajajfin' || this.invoiceForm.value.payment_type === 'tcpl'){
        if (value === 'cod'){
          //console.log('cash');
          this.invoiceForm.get('received_by').setValidators([Validators.required]);
          this.invoiceForm.get('cheque_receipt_no').setValidators([Validators.required]);
          this.invoiceForm.controls.received_by.setValue(this.loginUser_name);
          this.invoiceForm.get('cheque_receipt_no').reset();
          this.invoiceForm.get('payment_response_id').reset();
          this.invoiceForm.get('payment_response_id').clearValidators();
        } else if (value === 'cheque'){
          //console.log('cheque');
          this.invoiceForm.get('cheque_receipt_no').setValidators([Validators.required]);
          this.invoiceForm.get('received_by').clearValidators();
          this.invoiceForm.get('cheque_receipt_no').reset();
          this.invoiceForm.get('received_by').reset();
          this.invoiceForm.get('payment_response_id').reset();
          this.invoiceForm.get('payment_response_id').clearValidators();
        } else if(value === 'razorpay') {
          this.invoiceForm.get('payment_response_id').setValidators([Validators.required]);
          this.invoiceForm.get('received_by').clearValidators();
          this.invoiceForm.get('cheque_receipt_no').clearValidators();
          this.invoiceForm.get('received_by').reset();
          this.invoiceForm.get('cheque_receipt_no').reset();

        }
        this.invoiceForm.get('payment_response_id').updateValueAndValidity();
        this.invoiceForm.get('cheque_receipt_no').updateValueAndValidity();
        this.invoiceForm.get('received_by').updateValueAndValidity();

      }
    
    
  }

  updateCheckoutAmount() {
    //this.invoiceForm.get('discount_amount').reset();
    this.invoiceForm.get('total_amount').reset();
    this.invoiceForm.get('checkout_amount').reset();
    this.invoiceForm.get('tax_amount').reset();

    const price = this.invoiceForm.get('price').value;
    const discountAmt = this.invoiceForm.get('discount_amount').value || 0;
    const quantity = this.invoiceForm.controls.quantity.value;
    const amount = (price * quantity);

    this.isAmountEditable = true;
    this.isCheckoutAmountEditable = false;

    if (amount == 0 || amount == undefined || amount == null) {
      //console.log('kd')
      this.invoiceForm.patchValue({
        tax_amount: 0,
        total_amount: 0,
        discount_amt: 0,
        checkout_amount: 0,
        //quantity: 0
      });
    } else {

      if (discountAmt > amount) {
        this.errorMessage = "Discount amount should not be more than amount";
        this.invoiceForm.controls.discount_amount.setValue(undefined);
        this.invoiceForm.controls.checkout_amount.setValue(undefined);
       // const taxAmount = amount * 0.18;
        let taxAmount = 0;
          if(this.selectedCountryId == 2){
            taxAmount = 0;
            //console.log('case Indo')
          } else {
            //console.log('case Ind')
            taxAmount = ((amount * 0.18));
          }
        const checkoutAmount = amount + taxAmount;
        this.invoiceForm.patchValue({
          quantity: quantity,
          total_amount: amount.toFixed(2),
          checkout_amount: checkoutAmount.toFixed(2),
          tax_amount: taxAmount.toFixed(2),
          discount_amount: 0
        });
        return;
      } else {
        this.errorMessage = "";
      }

      const totalAmount = amount - discountAmt;
      let taxAmount = 0;
      if(this.selectedCountryId == 2){
        taxAmount = 0;
        //console.log('case Indo')
      } else {
        //console.log('case Ind')
        taxAmount = ((totalAmount * 0.18));
      }
      //const taxAmount = totalAmount * 0.18;
      const checkoutAmount = totalAmount + taxAmount;

      this.invoiceForm.patchValue({
        total_amount: totalAmount.toFixed(2),
        checkout_amount: checkoutAmount.toFixed(2),
        tax_amount: taxAmount.toFixed(2),
        //quantity: quantity
      });
    }


  }


  updateAmount() {
    //this.invoiceForm.get('discount_amount').reset();
    this.invoiceForm.get('total_amount').reset();
    this.invoiceForm.get('price').reset();
    this.invoiceForm.get('tax_amount').reset();

    const checkoutAmount = this.invoiceForm.get('checkout_amount').value;
    const price = this.invoiceForm.get('price').value;
    let discountAmt = this.invoiceForm.get('discount_amount').value || 0;
    const quantity = this.invoiceForm.controls.quantity.value;
    const amount = (price * quantity);

    this.isAmountEditable = false;
    this.isCheckoutAmountEditable = true;

    if (checkoutAmount == 0 || checkoutAmount == undefined || checkoutAmount == null || checkoutAmount == '') {
      //console.log('kd')
      this.invoiceForm.patchValue({
        tax_amount: 0,
        total_amount: 0,
        discount_amount: 0,
        price: 0,
        quantity: 0
      });
    } else {


      // Calculate and update the amount based on the checkout amount field
     // const gstAmount = (checkoutAmount - ((checkoutAmount * 100) / 118));
     let gstAmount = 0;
     if(this.selectedCountryId == 2){
       gstAmount = 0;
     } else{
       gstAmount = (checkoutAmount - ((checkoutAmount * 100) / 118));
     }
     if(Number.isNaN(discountAmt)){
       discountAmt = 0
     }
      const totalAmount = checkoutAmount - gstAmount;
      const finalAmount = (totalAmount + discountAmt) / quantity;
      this.invoiceForm.patchValue({
        tax_amount: gstAmount.toFixed(2),
        total_amount: totalAmount.toFixed(2),
        price: finalAmount.toFixed(2),
      });

    }

  }

  onQuantityChange(value: number) {
    if (this.isAmountEditable) {
      this.updateCheckoutAmount();
      // console.log('amount first')
    } else if (this.isCheckoutAmountEditable) {
      this.updateAmount();
      // console.log('checkout first')
    }

    // Additional code for handling the discount amount input value
  }

  onDiscountAmountChange(value: number) {
    if (this.isAmountEditable) {
      this.updateCheckoutAmount();
      //('amount first')
    } else if (this.isCheckoutAmountEditable) {
      this.updateAmount();
      // console.log('checkout first')
    }

    // Additional code for handling the discount amount input value
  }

  saveInvoice() {

    if (this.frequencyTypeList && this.classList) {
      let freq_type_id = this.userSubcriptionData.freq_type_id;
      //console.log(this.frequencyTypeList)
      const selectedDataProd = this.frequencyTypeList.find(item => item.id === freq_type_id);

      if (selectedDataProd) {
        const { frequency, frequency_value } = selectedDataProd;
        // Patch the form values with the matched values in data
        //console.log('Frequency:', frequency);
        //  console.log('Frequency:', frequency_value);
        if (frequency_value === 1) {
          this.product = frequency_value + ' Month Plan';
        } else {
          this.product = frequency_value + ' Months Plan';
        }
        //(this.product);
        this.sku = 'G' + this.invoiceForm.value.class_id + '_CBSE_' + frequency_value + 'M'
        // console.log(this.sku);
      }

    }
    if (this.invoiceForm.value.discount_amount == undefined || this.invoiceForm.value.discount_amount == '' || this.invoiceForm.value.discount_amount == null) {
      this.invoiceForm.controls.discount_amount.setValue(0)
    }
    if (this.invoiceForm.value.price == undefined || this.invoiceForm.value.price == '' || this.invoiceForm.value.price == null) {
      this.invoiceForm.controls.price.setValue(0)
    }
    if (this.invoiceForm.value.quantity == undefined || this.invoiceForm.value.quantity == '' || this.invoiceForm.value.quantity == null) {
      this.invoiceForm.controls.quantity.setValue(0)
    }
    if (this.invoiceForm.value.checkout_amount == undefined || this.invoiceForm.value.checkout_amount == '' || this.invoiceForm.value.checkout_amount == null) {
      this.invoiceForm.controls.checkout_amount.setValue(0)
    }
    let invoiceuser_country_id = parseInt(localStorage.getItem('invoiceuser_country_id'));

    //let obj = Object.assign({}, {product: this.product}, {sku: this.sku}, {created_by: this.admin_id}, this.invoiceForm.value);
    let invoiceObj = {
      user_id: this.user_id,
      order_id: this.order_id,
      school_id: this.invoiceForm.value.school_id,
      class_id: this.invoiceForm.value.class_id,
      firstName: this.invoiceForm.value.firstName,
      lastName: this.invoiceForm.value.lastName,
      address: this.invoiceForm.value.address,
      city: this.invoiceForm.value.city,
      state: this.invoiceForm.value.state,
      country_id: invoiceuser_country_id,
      pincode: this.invoiceForm.value.pincode,
      email: this.invoiceForm.value.email,
      contactNumber: this.invoiceForm.value.contactNumber,
      payment_type: this.invoiceForm.value.payment_type,
      price: this.invoiceForm.value.price,
      discount_amount: this.invoiceForm.value.discount_amount,
      tax_amount: this.invoiceForm.value.tax_amount,
      quantity: this.invoiceForm.value.quantity,
      total_amount: this.invoiceForm.value.checkout_amount,
      product: this.product,
      sku: this.sku,
      gst_no: this.invoiceForm.value.gst_no,
      created_by: this.admin_id,
      state_id: this.invoiceForm.value.state_id,
      received_by: this.invoiceForm.value.received_by,
      payment_response_id: this.invoiceForm.value.payment_response_id,
      cheque_receipt_no: this.invoiceForm.value.cheque_receipt_no,
      no_of_installment: this.invoiceForm.value.no_of_installment,
      emi_amount: this.invoiceForm.value.emi_amount,
      down_payment: this.invoiceForm.value.down_payment,
      down_payment_type: this.invoiceForm.value.down_payment_type,

    }
  //console.log(invoiceObj);
  

  if(invoiceuser_country_id == 2) {
    this.subscriptionService.addInvoiceIndonesia(invoiceObj).subscribe((data: any) => {
      //console.log(data);
      if (data.status == true) {
        this.openSnackBar('User Invoice has been added successfully. ', 'Close');
        this.router.navigate(['/invoiceUser', this.user_id]);
      } else {
        this.errorMessage = data.message;
      }
    },
      (error) => {
        this.errorMessage = 'Server Error.';
        console.error('An error occurred:', error);
      });
  } else {
    this.subscriptionService.addInvoice(invoiceObj).subscribe((data: any) => {
      //console.log(data);
      if (data.status == true) {
        this.openSnackBar('User Invoice has been added successfully. ', 'Close');
        this.router.navigate(['/invoiceUser', this.user_id]);
      } else {
        this.errorMessage = data.message;
      }
    },
      (error) => {
        this.errorMessage = 'Server Error.';
        console.error('An error occurred:', error);
      });

    }


  }

  onCountrySelectionChange() {
    const countryId = this.invoiceForm.get('country_id').value;
    this.invoiceForm.controls.state.reset();
    this.invoiceForm.controls.pincode.reset();
    this.invoiceForm.controls.state_id.reset();
    this.selectedCountryId = countryId;
    // console.log(this.selectedCountryId)
    this.invoiceForm.controls.payment_type.reset()
    this.resetValidations();
  }

  resetValidations() {
    this.invoiceForm.get('received_by').clearValidators();
    this.invoiceForm.get('emi_amount').clearValidators();
    this.invoiceForm.get('no_of_installment').clearValidators();
    this.invoiceForm.get('down_payment').clearValidators();
    this.invoiceForm.get('down_payment_type').clearValidators();
    this.invoiceForm.get('cheque_receipt_no').clearValidators();
    this.invoiceForm.get('received_by').reset();
    this.invoiceForm.get('emi_amount').reset();
    this.invoiceForm.get('no_of_installment').reset();
    this.invoiceForm.get('down_payment').reset();
    this.invoiceForm.get('cheque_receipt_no').reset();
    this.invoiceForm.get('down_payment_type').reset();
    this.invoiceForm.get('payment_response_id').reset();
  }

  toggleGSTField(event: Event) {
    event.preventDefault();
    this.invoiceForm.controls.gst_no.reset();
    this.showGSTField = !this.showGSTField;
  }

  onStateSelectionChange(selectedState: any) {
    // console.log(selectedState)
    const state = this.statesList.find(item => item.state_name === selectedState)
    //console.log(state)
    this.invoiceForm.patchValue({ state_id: state.id });
    //console.log(this.invoiceForm.value.state_id)
  }

  onStateSelectionChangeIndo(selectedState: any) {
    // console.log(selectedState)
    const state = this.statesListIndonesia.find(item => item.state_name === selectedState)
    //console.log(state)
    this.invoiceForm.patchValue({ state_id: state.id });
    //console.log(this.invoiceForm.value.state_id)
  }

  getPincodeMaxLength(): number {
    const countryId = this.invoiceForm.get('country_id').value;
    return countryId === 1 ? 6 : countryId === 2 ? 5 : null;
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1500,
    });
  }


}
