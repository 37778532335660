import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '../_services/backend.service';

@Component({
  selector: 'app-post-test',
  templateUrl: './post-test.component.html',
  styleUrls: ['./post-test.component.css']
})
export class PostTestComponent implements OnInit {
  currentUser: any;
  userId: number;
  classArr = [];
  subjects: any;
  lessons: any;
  topics: any;
  userData: any;
  userType: number;
  selClassId: number;
  defaultTab = 0;

  constructor(
    private backendService: BackendService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userId = this.currentUser.id;
    this.userData = this.currentUser.userData;
    this.userType = this.currentUser.userData[0].user_type;
    this.userData.forEach(userVal => {
      this.classArr.push({id:userVal.class.id,className: userVal.class.class_name})
    });
    this.selClassId = this.classArr[0].id;
    this.getSubjects(this.selClassId);
  }

  getSubjects(classId){
    classId = [classId];
    let params = {class_id: classId};
    this.backendService.getSubjects(params).subscribe(subData =>{
      this.subjects = subData;
      this.subjects = this.subjects.data;
      this.getLessons(this.subjects[0].id);
    });    
  }

  getLessons(subjectId){
    subjectId = [subjectId];
    let params = {subject_id: subjectId};
    this.backendService.getLessons(params).subscribe(lessonData =>{
      this.lessons = lessonData;
      this.lessons = this.lessons.data;
    });    
  }

  openPostTest(lessonId){
    this.router.navigate(['/post-test-items/',lessonId])
  }
}
