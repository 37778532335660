import { Component, OnInit } from '@angular/core';
import { ReportService } from 'src/app/report/report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-user-detail',
  templateUrl: './download-user-detail.component.html',
  styleUrls: ['./download-user-detail.component.css']
})
export class DownloadUserDetailComponent implements OnInit {

  private siteUrl = environment.liveUrl;
  private filepath = this.siteUrl+'/mail/attachment/user_details.xlsx';
  subscriptionList: any;
  loadFlag = false;
  fileExist = false;
  constructor(
    private reportService: ReportService
  ) { }

  ngOnInit() {
    /*var fs = require('fs');
    if (fs.existsSync(this.filepath)) {
      this.fileExist = true;
    }*/
  }

  download(){
    this.loadFlag = true;
    let subObj = {};
    this.reportService.downloadSubscription(subObj).subscribe((data: any) => {
      this.subscriptionList = data;
      if(this.subscriptionList.status) {
        this.loadFlag = false;
      }
    });
  }
}
