import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from 'src/app/_services/backend.service';
import { QuizSetService } from 'src/app/_services/quiz-set.service';

@Component({
  selector: 'app-latest-quiz',
  templateUrl: './latest-quiz.component.html',
  styleUrls: ['./latest-quiz.component.css']
})
export class LatestQuizComponent implements OnInit {

  currentUser: any;
  userId: number;
  userData: any;
  currentSelSchoolId: number;
  userType: number;
  user_subjects: any;
  classIds = [];
  sectionIds = [];
  subjectIds = []; 
  quizSetData: any;
  adminId: number;
  email: string;

  constructor(
    private backendService: BackendService,
    private quizSetService: QuizSetService,
    private router: Router
  ) { }

  ngOnInit() {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.userId = this.currentUser.id;
      this.currentSelSchoolId = this.currentUser.currentSelSchoolId;
      this.userData = this.currentUser.userData;
      this.userType = this.currentUser.userData[0].user_type;
      this.user_subjects = this.currentUser.user_assigned_subjects;
      this.email = this.currentUser.userData[0].email;
  
      this.userData.forEach(userVal => {
        if(this.classIds.indexOf(userVal.class.id) == -1){
          this.classIds.push(userVal.class.id);
          // this.classes.push({id:userVal.class.id,className: userVal.class.class_name})
        }
        if (this.sectionIds.indexOf(userVal.section_id) == -1) {
          this.sectionIds.push(userVal.section_id);
          // this.sections.push(userVal.section);
        }
        
      });
      this.user_subjects.forEach(sub => {
        this.subjectIds.push(sub.subject_id);
      });

      if(this.currentUser.adminUser>0){
        if(this.currentUser.adminUser.length>0){
          this.adminId = this.currentUser.id;
        }
      }

      if (this.userType == 1) {
        this.getTeacherQuiz();
      } else {
        this.getStudentQuiz();
      }
    }
  }

  getStudentQuiz() {
    let obj = {
      school_id: [this.currentSelSchoolId],
      class_id: this.classIds[0],
      section_id: this.sectionIds[0],
      email: this.email,
      offset: 0,
      limit: 3
    }
    this.quizSetService.getQuizSets(obj).subscribe((quizSets: any) => {
      // console.log(quizSets);
      this.quizSetData = quizSets.data;
    });
  }

  getTeacherQuiz() {
    let obj = {
      created_by: this.adminId,
      offset: 0,
      limit: 3
    }
    this.backendService.getTeacherQuizSets(obj).subscribe((quizSets: any) => {
      // console.log(quizSets);
      this.quizSetData = quizSets.data;
    });

  }

  startQuiz(quizSetId){
    quizSetId = btoa(quizSetId)
    this.router.navigate(['/attempt-quiz',quizSetId]);
  }

}
